import { forwardRef, type ReactNode } from 'react';
import classnames from 'classnames';

import styles from './WizardStep.module.scss';

type WizardStepProps = {
  children: ReactNode;
  visible?: boolean;
  scrollMargin?: boolean;
};

export const WizardStep = forwardRef<HTMLDivElement, WizardStepProps>(
  ({ children, visible = true, scrollMargin = true }, ref) => {
    if (!visible) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={classnames(styles.stepWrapper, {
          [styles.scrollMargin]: scrollMargin,
        })}
      >
        {children}
      </div>
    );
  },
);
