export function sortByMultipleAttributes<T>(
  array: T[],
  keys: string[],
  comparisonGiver: { [key: string]: (a: T, b: T) => number },
): T[] {
  return array.sort((a, b) => {
    for (const key of keys) {
      const comparison = comparisonGiver[key](a, b);
      if (comparison !== 0) return comparison;
    }
    return 0;
  });
}
