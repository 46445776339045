import { useMemo } from 'react';
import { FaCopy, FaPen } from 'react-icons/fa6';
import {
  ColumnHeader,
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Quote } from '../../../../model/Quote';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { QuotePriceBreakdownTooltip } from '../../../components/QuotePriceBreakdownTooltip/QuotePriceBreakdownTooltip';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import { COVERAGE_PACKAGES } from '../../../constants';
import labels from '../../../labels';
import { statusToBadge } from '../../utils/statusToBadge';

export const useQuotesTableColumns = (): TableColumn<Quote>[] => {
  const showCoveragePackage = useFeatureToggle(
    'enable_coverage_package_selection',
  );
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const enableDuplicateQuote = useFeatureToggle('enable_duplicate_quote');

  const { isWtw } = usePlatform();

  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition('id', labels.quotesTable.columns.id.title),
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.id,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
      },
      {
        ...getBaseColumnDefinition('created_at', 'Created On'),
        minWidth: 140,
        type: 'date',
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.created_time,
        renderCell: ({ row }) => (
          <DateCell
            value={row.created_time}
            createdBy={
              row.created_by_user && {
                avatarColor: row.created_by_user.avatar_color,
                name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
              }
            }
            format="SHORT_MONTH_NAME_DATE"
          />
        ),
      },
      ...(showCoveragePackage
        ? ([
            {
              ...getBaseColumnDefinition('coverage_package', 'Coverage Type'),
              minWidth: 160,
              valueGetter: ({ row }) => row.coverage_package,
              renderCell: ({ row: { coverage_package } }) => (
                <TextCell>
                  {coverage_package
                    ? COVERAGE_PACKAGES[coverage_package].label
                    : undefined}
                </TextCell>
              ),
              sortable: true,
            },
          ] as TableColumn<Quote>[])
        : []),
      {
        ...getBaseColumnDefinition(
          'external_reference',
          labels.fields.bookingReference,
        ),
        minWidth: isWtw ? 110 : 180,
        valueGetter: ({ row }) => row.external_reference,
        sortable: sortingEnabled,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
      },
      {
        ...getBaseColumnDefinition('customer', labels.fields.cargoOwner),
        valueGetter: ({ row }) => row.customer?.company_name,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: false,
        minWidth: 140,
      },
      {
        ...getBaseColumnDefinition('insured_value', 'Insured Value'),
        renderHeader: () => {
          return (
            <ColumnHeader
            >
              Insured Value
            </ColumnHeader>
          );
        },
        minWidth: 180,
        type: 'number',
        valueGetter: ({ row }) => row.insured_value,
        renderCell: ({ row }) => (
          <CurrencyCell
            value={row.insured_value}
            currency={row.insured_value_currency}
          />
        ),
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition(
          'commodity_type',
          labels.quotesTable.columns.commodity.title,
        ),
        minWidth: 200,
        valueGetter: ({ row }) => row.commodity_external_description,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('etd', 'Departure'),
        minWidth: 140,
        type: 'date',
        valueGetter: ({ row }) => row.etd,
        renderCell: ({ value, row }) => {
          const title = row.origin_port_code || row.origin_place?.display_name;
          return (
            <DateCell
              value={value as ISODate}
              title={value ? title : undefined}
              invalidComponent={<TextCell>{title}</TextCell>}
            />
          );
        },
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('eta', 'Arrival'),
        type: 'date',
        valueGetter: ({ row }) => row.eta,
        renderCell: ({ value, row }) => {
          const title =
            row.destination_port_code || row.destination_place?.display_name;

          return (
            <DateCell
              value={value as ISODate}
              title={value ? title : undefined}
              invalidComponent={<TextCell>{title}</TextCell>}
            />
          );
        },
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('customer_premium_value', 'Total'),
        type: 'number',
        valueGetter: ({ row: { tax, premium_value = 0 } }) => {
          return (tax?.value ?? 0) + premium_value;
        },
        renderCell: ({ row, value }) => {
          const {
            premium_value,
            premium_currency,
            tax,
            exchange_rate,
            converted_customer_premium_value,
            converted_tax_amount,
            status,
            distributor,
          } = row;

          if (status === 'pending_for_breeze_manual_review') {
            return <TextCell>—</TextCell>;
          }

          if (!premium_value || !premium_currency) {
            return <TextCell>—</TextCell>;
          }

          const shouldShowTooltip = Boolean(tax?.value || exchange_rate);

          return (
            <CurrencyCell
              value={value as number}
              currency={premium_currency}
              suffix={
                shouldShowTooltip && (
                  <QuotePriceBreakdownTooltip
                    className="w-3.5 h-3.5"
                    premium_value={premium_value}
                    premium_currency={premium_currency}
                    tax={tax}
                    converted_customer_premium_value={
                      converted_customer_premium_value
                    }
                    converted_tax_amount={converted_tax_amount}
                    exchange_rate={exchange_rate}
                    hide_tax_info={distributor?.hide_tax_info}
                  />
                )
              }
            />
          );
        },
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('status', 'Status'),
        minWidth: 140,
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.status,
        renderCell: ({ row }) => {
          const { label, variant, Icon } =
            statusToBadge[row.status ?? 'unknown'] ?? statusToBadge.unknown;

          return <StatusBadge variant={variant} label={label} Icon={Icon} />;
        },
      },
      {
        ...getBaseColumnDefinition('action', ''),
        minWidth: 40,
        maxWidth: 60,
        renderCell: ({ row }) => {
          const items = [
            {
              label: 'Edit quote',
              href: `/quotes/${row.id}`,
              leftIcon: <FaPen />,
              isDownload: false,
            },
            {
              label: 'Duplicate quote',
              href: `/quotes/duplicate/${row.id}`,
              leftIcon: <FaCopy />,
              isHidden: false,
              isDownload: false,
            },
          ];
          return enableDuplicateQuote ? (
            <div className="w-full flex justify-center">
              <ActionMenu items={items} id={String(row.id)} />
            </div>
          ) : null;
        },
      },
    ],
    [showCoveragePackage, sortingEnabled, enableDuplicateQuote, isWtw],
  );
};
