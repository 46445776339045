import { type ComponentProps, useCallback } from 'react';
import { Autocomplete } from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { getPolicyCertificateIdList } from '../../../network/apis/policies/policies';

type CertificateIdAutocompleteProps = Omit<
  ComponentProps<typeof Autocomplete<string>>,
  'getOptionValue' | 'defaultValue'
>;

export const CertificateIdAutocomplete = ({
  inputProps,
  ...props
}: CertificateIdAutocompleteProps) => {
  const getCertificateIdsOptions = useCallback(getPolicyCertificateIdList, []);
  const isCertificatesEnabled = useFeatureToggle('enable_certificates_page');
  const label = isCertificatesEnabled ? 'Certificate number' : 'Policy number';

  return (
    <Autocomplete<string>
      {...props}
      getOptionValue={(option) => option}
      fetchOptions={(query) => getCertificateIdsOptions({ query })}
      isOptionEqualToValue={(option, value) => option === value}
      inputProps={{
        label,
        placeholder: 'Search and select a certificate number...',
        testId: 'policy-certificate-autocomplete',
        ...inputProps,
      }}
    />
  );
};
