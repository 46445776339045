import { type SelfDescribingJson } from '@snowplow/browser-tracker';

import {
  type ClaimEntity,
  type CustomerEntity,
  type DistributorEntity,
  type MetaEntity,
  type PolicyEntity,
  type QuoteEntity,
  type UserEntity,
} from '../../model/Snowplow';

export const spGenerateMetaContext = (
  metaEntity?: MetaEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/meta_entity/jsonschema/2-0-0',
    data: {
      event_source: metaEntity?.event_source,
      event_feature: metaEntity?.event_feature,
      event_subfeature: metaEntity?.event_subfeature,
      event_action: metaEntity?.event_action,
      event_object: metaEntity?.event_object,
      event_trigger: metaEntity?.event_trigger,
      page_content: metaEntity?.page_content,
    },
  };
};

export const spGenerateUserContext = (
  userEntity?: UserEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/user_entity/jsonschema/2-0-0',
    data: {
      user_id: userEntity?.user_id,
      user_email: userEntity?.user_email,
    },
  };
};

export const spGenerateDistributorContext = (
  distributorEntity?: DistributorEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/distributor_entity/jsonschema/1-0-0',
    data: {
      distributor_id: distributorEntity?.distributor_id,
    },
  };
};

export const spGeneratePolicyContext = (
  policyEntity?: PolicyEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/policy_entity/jsonschema/1-0-0',
    data: {
      policy_id: policyEntity?.policy_id,
    },
  };
};

export const spGenerateQuoteContext = (
  quoteEntity?: QuoteEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/quote_entity/jsonschema/1-0-0',
    data: {
      quote_id: quoteEntity?.quote_id,
    },
  };
};

export const spGenerateCustomerContext = (
  customerEntity?: CustomerEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/customer_entity/jsonschema/1-0-0',
    data: {
      customer_id: customerEntity?.customer_id,
    },
  };
};

export const spGenerateClaimContext = (
  claimEntity?: ClaimEntity,
): SelfDescribingJson => {
  return {
    schema: 'iglu:com.breezeai/claim_entity/jsonschema/1-0-0',
    data: {
      claim_id: claimEntity?.claim_id,
    },
  };
};
