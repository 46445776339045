import { FaCircleExclamation } from 'react-icons/fa6';
import { Typography } from '@breezeai-frontend/cargo-ui';

import { type ExclusionReason } from '../../../model/Quote';

export function ExclusionReasons({ reasons }: { reasons: ExclusionReason[] }) {
  if (!reasons.length) return null;

  return (
    <div className="flex flex-col gap-2">
      <Typography customStyles="text-text-tertiary font-bold">
        Exceptions:
      </Typography>
      {reasons.map(({ name, value }) => (
        <div key={name} className="text-sm flex gap-2 items-center">
          <FaCircleExclamation className="w-3.5 h-3.5 text-system-red-500 shrink-0" />
          <Typography customStyles="text-sm text-nowrap text-text-tertiary">
            {name}
          </Typography>
          <Typography customStyles="text-sm text-text-secondary">
            {value}
          </Typography>
        </div>
      ))}
    </div>
  );
}
