import { useMemo } from 'react';
import {
  ColumnHeader,
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  Link,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';
import { type GridActionsColDef } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type CurrencyValue } from '../../../model/CurrencyValue';
import { type Invoice } from '../../../model/Invoice';
import { formatDate, staticDateFormats } from '../../../utils/dates';
import { InvoiceStatusBadge } from '../InvoiceStatusBadge/InvoiceStatusBadge';
import styles from './InvoicesTable.module.scss';

export const useInvoicesTableColumns = () => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');

  return useMemo(
    (): TableColumn<Invoice>[] => [
      {
        ...getBaseColumnDefinition('name', 'Invoice'),
        flex: 2,
        sortable: sortingEnabled,
        renderCell: ({ row }) => <TextCell>{row.name}</TextCell>,
      },
      {
        ...getBaseColumnDefinition('billing_period_start', 'Billing Month'),
        type: 'date',
        flex: 2,
        sortable: sortingEnabled,
        renderCell: ({ row: { billing_period_start } }) => {
          return (
            <DateCell
              value={billing_period_start}
              formatter={(date) =>
                formatDate(date, {
                  customFormat: staticDateFormats.FULL_MONTH_YEAR,
                })
              }
            />
          );
        },
      },
      {
        ...getBaseColumnDefinition('status', 'Status'),
        sortable: sortingEnabled,
        flex: 2,
        renderHeader: () => <ColumnHeader>Status</ColumnHeader>,
        renderCell: ({ row }) => <InvoiceStatusBadge status={row.status} />,
      },
      {
        ...getBaseColumnDefinition('amount', 'Amount'),
        sortable: sortingEnabled,
        type: 'number',
        flex: 2,
        valueGetter: ({ value }) => (value as CurrencyValue).value,
        renderCell: ({ row }) => <CurrencyCell currencyValue={row.amount} />,
      },
      {
        ...getBaseColumnDefinition('actions', 'Actions'),
        type: 'actions',
        flex: 1,
        hideable: false,
        cellClassName: styles.actionsCell,
        align: 'left',
        renderHeader: () => null,
        getActions: ({ row: { url: invoiceUrl } }) => [
          // TODO THEMING - migrate to TW
          <Link
            className={styles.downloadLink}
            key="download-pdf"
            underline={false}
            href={invoiceUrl}
            newTab={true}
            iconPosition="prefix"
            icon="download"
            role="invoice-download-link"
          >
            Download
          </Link>,
        ],
      } as GridActionsColDef<Invoice>,
    ],
    [sortingEnabled],
  );
};
