import { type Policy } from '../../../model/Policy';
import { normalizeQuoteResponse } from '../quotes/normalizers';
import { normalizeShipmentResponse } from '../shipments/normalizers';
import { type PolicyModel } from './types';

export const normalizePolicyResponse = (response: PolicyModel): Policy => {
  const { distributor } = response;
  const quote = response.quote
    ? normalizeQuoteResponse(response.quote!)
    : undefined;

  const shipment = response.shipment
    ? normalizeShipmentResponse(response.shipment!)
    : undefined;

  return {
    id: response.id,
    created_time: response.created_time,
    issue_date: response.issue_date,
    open_cover: response.open_cover,
    certificate_id: response.certificate_id,
    certificate_url: response.certificate_url,
    proof_of_cover_url: response.proof_of_cover_url,
    open_cover_id: response.open_cover_id,
    distributor,
    customer: response?.customer,
    quote,
    shipment,
    certificate_status: response.certificate_status,
    proof_of_cover_status: response.proof_of_cover_status,
    certificate_created_time: response.certificate_created_time,
    proof_of_cover_created_time: response.proof_of_cover_created_time,
    created_by_user: response.created_by_user,
  };
};
