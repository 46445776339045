import { useMemo } from 'react';
import {
  ColumnHeader,
  DateCell,
  getBaseColumnDefinition,
  Icon,
  type TableColumn,
  TextCell,
  Toggle,
} from '@breeze-ai/ui-library';

import { type Customer } from '../../../model/Customer';

type CustomersTableColumnProps = {
  onCustomerToggleOff: (customer: Customer) => void;
};

export const useCustomersTableColumns = ({
  onCustomerToggleOff,
}: CustomersTableColumnProps): TableColumn<Customer>[] => {
  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition('company_name', 'Company'),
        sortable: true,
        valueGetter: ({ row }) => row.company_name,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
      },
      {
        ...getBaseColumnDefinition('email', 'Email'),
        sortable: true,
        valueGetter: ({ row }) => row?.email,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
      },
      {
        ...getBaseColumnDefinition(
          'automatically_insured',
          'Automatic Insurance',
        ),
        type: 'boolean',
        valueGetter: ({ row }) => row.automatically_insured,
        renderHeader: () => (
          <ColumnHeader
            tooltip={{
              content:
                'All future shipments for this customer will be automatically insured.',
              icon: 'info',
            }}
          >
            Automatic Insurance
          </ColumnHeader>
        ),
        renderCell: ({ row }) => (
          <TextCell>
            <Toggle
              size="medium"
              value={!!row.automatically_insured}
              onChange={() => onCustomerToggleOff(row)}
            />
          </TextCell>
        ),
      },
      {
        ...getBaseColumnDefinition('opt_in_date', 'Auto-Insured Since'),
        type: 'date',
        sortable: true,
        valueGetter: ({ row }) => row.automatic_insurance_start_date,
        renderCell: ({ row }) => (
          <DateCell
            value={row.automatic_insurance_start_date}
            prefix={
              <Icon
                icon="check"
                type="circle-outlined"
                variant="success"
                size="sm"
              />
            }
          />
        ),
      },
    ],
    [onCustomerToggleOff],
  );
};
