import { type IconType } from 'react-icons';
import { BsClockHistory } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
import { type Badge } from '@breezeai-frontend/cargo-ui';

import { type QuoteStatusEnum } from '../../../network/apis/quotes/types';

export type QuoteStatusToBadge = Record<
  QuoteStatusEnum | 'unknown',
  {
    label: string;
    variant?: React.ComponentProps<typeof Badge>['variant'];
    Icon?: IconType;
  }
>;

export const statusToBadge: QuoteStatusToBadge = {
  ready_for_policy: { label: 'Ready', variant: 'success', Icon: FaCheck },
  policy_issued: { label: 'Issued', variant: 'success', Icon: FaCheck },
  pending_for_breeze_manual_review: {
    label: 'In Review',
    variant: 'warning',
    Icon: BsClockHistory,
  },
  declined: {
    label: 'Declined',
    variant: 'error',
    Icon: IoIosWarning,
  },
  expired: { label: 'Expired' },
  cancelled: { label: 'Cancelled' },
  unknown: { label: 'Unknown' },
};
