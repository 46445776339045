import { useEffect, useState } from 'react';
import { Icon, Link, Typography } from '@breeze-ai/ui-library';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { chunk } from 'lodash';

import { usePolicy } from '../../../network/apis/policies/hooks';
import { useQuote } from '../../../network/apis/quotes/hooks';
import { getQuoteConditions } from '../../../network/apis/quotes/quotes';
import { type CoverageDescription } from '../../../network/apis/quotes/types';
import { usePolicyWizardContext } from '../../policy-wizard/hooks/context-hooks';
import { Loading } from '../Loading/Loading';
import styles from './QuoteCoverageDescriptions.module.scss';

const icon = <Icon icon="chevron-down" size="sm" />;
const disclaimer = (isEuFreightForwarder: boolean) => (
  <Typography block>
    For full details of your coverage, please review your policy documents. If
    you still have questions, please contact us at{' '}
    {isEuFreightForwarder ? (
      <Link href="mailto:support-EU@breezeai.com">support-EU@breezeai.com</Link>
    ) : (
      <Link href="mailto:info@breezeai.com">info@breezeai.com</Link>
    )}
  </Typography>
);

// TODO the below fetchId is a temporary fix. The policy wizard should submit the quote and then push the quoteId to the url when scrolling into view
export const QuoteCoverageDescriptions = ({
  quoteId,
  policyId,
}: {
  quoteId?: string;
  policyId?: string;
}) => {
  // Only one of these will run depending on the page and which id is available.
  const { data: quote } = useQuote({ quoteId });
  const { data: policy } = usePolicy({ policyId });

  const derivedQuoteId = quote?.id || policy?.quote?.id;

  const { configuration } = usePolicyWizardContext();

  const [descriptions, setDescriptions] = useState<CoverageDescription[]>();
  let columns: CoverageDescription[][] = [];

  useEffect(() => {
    if (!descriptions && derivedQuoteId) {
      getQuoteConditions(derivedQuoteId).then(setDescriptions);
    }
  }, [derivedQuoteId, descriptions]);

  if (descriptions) {
    const chunkSize = descriptions.length / 2;
    columns = chunk(
      descriptions,
      descriptions.length % 2 === 0 ? chunkSize : chunkSize + 1,
    );
  }

  return columns.length > 0 ? (
    <div className={styles.wrapper} data-testid="coverage-descriptions">
      <Accordion
        disableGutters
        elevation={0}
        square
        role="main-exclusions-accordion"
      >
        <AccordionSummary className={styles.header} expandIcon={icon}>
          <Typography level="h4">Main Exclusions</Typography>
        </AccordionSummary>
        <Loading visible={!descriptions} thickness={3}>
          <AccordionDetails className={styles.content}>
            {configuration && disclaimer(configuration.isEuFreightForwarder)}
            <div className={styles.itemsList}>
              {columns.map((column, i) => (
                <div key={i} className={styles.column}>
                  {column.map((coverage, j) => (
                    <Accordion
                      key={j}
                      disableGutters
                      className={styles.collapseItem}
                    >
                      <AccordionSummary expandIcon={icon}>
                        <Typography level="h4" bold>
                          {coverage.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="secondary">
                          {coverage.description}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Loading>
      </Accordion>
    </div>
  ) : null;
};
