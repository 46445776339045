import { useState } from 'react';
import { Typography } from '@breeze-ai/ui-library';

import { PDFStatus } from '../../../../network/apis/policies/types';
import {
  type Asset,
  AssetsList,
} from '../../../components/FileList/AssetsList/AssetsList';
import { useCertificate } from '../../certificateApiHooks';

const MAX_WAIT_TIME = 20000;

export function PollingFileList({ policyId }: { policyId: string }) {
  const [isTimeoutError, setIsTimeoutError] = useState(false);

  const {
    data: certificate,
    refetch,
    isFetching,
  } = useCertificate({
    policyId,
    options: {
      refetchInterval(data) {
        setTimeout(() => {
          setIsTimeoutError(true);
        }, MAX_WAIT_TIME);

        const docsInProgress =
          data?.proof_of_cover_status !== PDFStatus.InProgress &&
          data?.certificate_status !== PDFStatus.InProgress;

        if (isTimeoutError || docsInProgress) {
          return 0;
        }

        return 4000;
      },
    },
  });

  const {
    open_cover,
    certificate_url,
    certificate_status,
    certificate_created_time,
  } = certificate ?? {};

  const isCertificateLoading =
    (certificate_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isCertificateTimeOutError =
    certificate_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isCertificateLoading;

  const policyFiles: Asset[] = [
    {
      path: certificate_url,
      name: 'Certificate',
      creationDate: certificate_created_time,
      testId: 'policy-certificate',
      isLoading: isCertificateLoading,
      isError: isCertificateTimeOutError,
      isHidden: !certificate_status,
      onTryAgain: refetch,
    },
    {
      path: open_cover?.terms_and_conditions_url,
      name: 'Policy Full Terms and Conditions',
      testId: 'policy-terms-and-conditions',
      isLoading: false,
      isError: !open_cover?.terms_and_conditions_url,
      isHidden: false,
      onTryAgain: refetch,
    },
  ];

  return certificate_status ? (
    <div
      className="bg-system-grey-white p-6 rounded-3xl"
      data-testid="documents"
    >
      <AssetsList
        assets={policyFiles}
        title={<Typography level="h4">Documents</Typography>}
      />
    </div>
  ) : null;
}
