import { Typography } from '@breeze-ai/ui-library';

import styles from './QuoteSummary.module.scss';
import { useQuoteSummaryItems } from './use-quote-summary-items';

export const QuoteSummary = () => {
  const summaryItems = useQuoteSummaryItems();

  return (
    <div className={styles.quoteSummaryWrapper} data-testid="dtc-quote-summary">
      {summaryItems.map(({ title, value }, i) => (
        <div key={i} className={styles.summaryItem} role="quote-summary-item">
          <Typography level="subtext" role="title" className={styles.title}>
            {title}
          </Typography>
          <Typography role="value">{value}</Typography>
        </div>
      ))}
    </div>
  );
};
