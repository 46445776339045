import { type Step, Steps } from '@breeze-ai/ui-library';

import { useDTCFlowContext } from '../../context/hooks';
import {
  DTCFlowSteps,
  isPresentableStep,
  stepsByFlow,
} from '../../flows-steps-config';
import styles from './DTCStepsIndicator.module.scss';

export const DTCStepsIndicator = () => {
  const { step, flowType } = useDTCFlowContext();

  if (!flowType) {
    return null;
  }

  const steps = stepsByFlow[flowType]
    .filter(isPresentableStep)
    .map<Step>(({ title, step }) => ({
      id: step,
      name: title,
    }));

  return (
    <div className={styles.wrapper}>
      <div className={styles.flowSteps}>
        <Steps
          steps={steps}
          currentStep={step}
          completed={step === DTCFlowSteps.SUCCESS}
          testId="steps-indicator"
        />
      </div>
    </div>
  );
};
