import { useEffect, useRef, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Loader } from '@breeze-ai/ui-library';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { useQuote } from '../../network/apis/quotes/hooks';
import { useRouteParams } from '../../router/router-hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { useScrollToElement } from '../hooks/use-scroll-to-element';
import { WizardErrorDialog } from './components/WizardErrorDialog/WizardErrorDialog';
import { WizardLoadingDialog } from './components/WizardLoadingDialog/WizardLoadingDialog';
import { WizardStep } from './components/WizardStep/WizardStep';
import { WizardSteps } from './constants';
import { PolicyWizardContextProvider } from './context/PolicyWizardContext';
import { type PolicyWizardUrlParams } from './context/types';
import { usePolicyWizardContext } from './hooks/context-hooks';
import { usePolicyWizardInitialization } from './hooks/use-policy-wizard-initialization';
import { useSubmitPolicyCreationRequest } from './hooks/use-submit-shipment-information-form';
import styles from './PolicyWizard.module.scss';
import { FormContext } from './steps/QuoteForm/FormContext';
import { QuoteForm } from './steps/QuoteForm/QuoteForm';
import { useQuoteFormLogic } from './steps/QuoteForm/useQuoteFormLogic';
import { QuoteSummary } from './steps/QuoteSummary/QuoteSummary';
import { ShipmentInformationForm } from './steps/ShipmentInformationForm/ShipmentInformationForm';

const loader = (
  <div className={styles.loader}>
    <Loader
      type="progress-bar"
      thickness={5}
      color="var(--loader-loader-fill)"
    />
  </div>
);

type StepsMap = { [K in WizardSteps]?: HTMLDivElement | null };

export const PolicyWizardContent = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyWizardUrlParams>();
  const { data: quote } = useQuote({ quoteId });

  usePolicyWizardInitialization();
  const scrollToElement = useScrollToElement();
  const submitPolicyCreationRequest = useSubmitPolicyCreationRequest();
  const { step } = usePolicyWizardContext();
  const stepsMap = useRef<StepsMap>({});

  const setStepRef = (step: WizardSteps, element: HTMLDivElement | null) => {
    stepsMap.current[step] = element;
  };

  const [quoteFormKey, setQuoteFormKey] = useState(0);

  useEffect(() => {
    scrollToElement(stepsMap.current[step] ?? null);
  }, [scrollToElement, step, quote]);

  if (step === WizardSteps.INIT) {
    return loader;
  }

  return (
    <div className="w-full" data-testid="policy-wizard">
      <WizardStep
        scrollMargin={false}
        ref={(el) => setStepRef(WizardSteps.QUOTE_FORM, el)}
        className="h-full"
      >
        <FormContext.Provider
          value={{ resetForm: () => setQuoteFormKey(quoteFormKey + 1) }}
        >
          <QuoteForm key={quoteFormKey} />
        </FormContext.Provider>
      </WizardStep>
      <WizardStep
        visible={step >= WizardSteps.QUOTE_SUMMARY}
        ref={(el) => setStepRef(WizardSteps.QUOTE_SUMMARY, el)}
        className="min-h-full flex flex-col"
      >
        {quote && (
          <QuoteSummary
            quote={quote}
            onUpdateDetails={() =>
              scrollToElement(stepsMap.current[WizardSteps.QUOTE_FORM] ?? null)
            }
          />
        )}
      </WizardStep>
      <WizardStep
        visible={step >= WizardSteps.SHIPMENT_INFORMATION}
        ref={(el) => setStepRef(WizardSteps.SHIPMENT_INFORMATION, el)}
      >
        {quote && (
          <ShipmentInformationForm
            quote={quote}
            onSubmit={submitPolicyCreationRequest}
          />
        )}
      </WizardStep>
      <WizardLoadingDialog visible={step === WizardSteps.LOADING} />
      <WizardErrorDialog />
    </div>
  );
};

export const PolicyWizard = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyWizardUrlParams>();

  const { data: quote } = useQuote({ quoteId });
  const { isDuplicate } = useQuoteFormLogic();
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  return (
    <PolicyWizardContextProvider>
      <SectionLayout
        title={
          <span className="flex flex-row items-center gap-2">
            <Link to="/quotes">
              <FaChevronLeft />
            </Link>
            {quote && !isDuplicate ? `Quote ${quote.id}` : 'Get a Quote'}
          </span>
        }
        tooltip={
          isWtw && <ActiveOpenCoverTooltip distributor={user.distributor} />
        }
      >
        <PolicyWizardContent />
      </SectionLayout>
    </PolicyWizardContextProvider>
  );
};
