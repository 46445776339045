import { type ValidationResult } from '../../../../utils/validators';
import {
  type ClaimCreationContextAction,
  type ClaimCreationState,
  type FileErrors,
} from './types';

export const claimCreationFlowReducer = (
  state: ClaimCreationState,
  { type, payload }: ClaimCreationContextAction,
): ClaimCreationState => {
  switch (type) {
    case 'set-claim-creation-flow-step':
      return { ...state, step: payload };

    case 'set-forms-data':
      return {
        ...state,
        formsData: { ...state.formsData, ...payload },
      };

    case 'set-form-error':
      if (payload.field === 'files') {
        const { errors } = payload as FileErrors;

        return {
          ...state,
          formErrors: {
            ...state.formErrors,
            files: errors?.reduce(
              (acc, fileError) => {
                const { name, reason } = fileError;
                acc![name] = reason;
                return acc;
              },
              {} as typeof state.formErrors.files,
            ),
          },
        };
      } else {
        const { field, error, reason } = payload as ValidationResult;
        return {
          ...state,
          formErrors: {
            ...state.formErrors,
            [field]: error ? { reason } : undefined,
          },
        };
      }

    case 'set-claim':
      return { ...state, claim: payload };
  }
};
