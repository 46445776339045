import { type ComponentProps } from 'react';
import { ErrorMessage } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { useAuthActions } from '../../context/auth/auth-hooks';
import { refresh } from '../../utils/browser';
import styles from './PlatformError.module.scss';

type PlatformErrorProps = ComponentProps<typeof ErrorMessage> & {
  className?: string;
};

export const PlatformError = ({ className, ...props }: PlatformErrorProps) => {
  const { logout } = useAuthActions();

  return (
    <div className={classnames(styles.platformError, className)}>
      <ErrorMessage
        actions={[
          {
            level: 'secondary',
            children: 'Refresh',
            prefixIcon: 'refresh',
            onClick: refresh,
          },
          {
            ghost: true,
            children: 'Logout',
            prefixIcon: 'sign-out',
            variant: 'error',
            onClick: logout,
          },
        ]}
        {...props}
      />
    </div>
  );
};
