import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Policy } from '../../../model/Policy';
import { getPolicies } from '../../../network/apis/policies/policies';
import { type PoliciesSortableFields } from '../../../network/apis/policies/types';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';
import {
  CargoOwnersFilter,
  CreatedByFilter,
  CreatedOnFilter,
  useFilterStore,
} from '../../components/TableFilters';
import labels from '../../labels';
import styles from './PoliciesTable.module.scss';
import { usePoliciesTableColumns } from './use-policies-table-columns';

export const PoliciesTable = () => {
  const DEFAULT_QUERY_PARAMS = {
    limit: 10,
  };

  const enableFilters = useFeatureToggle('enable_column_filters');

  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));

  const { setError } = useSectionError();
  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const columns = usePoliciesTableColumns();
  const { navigateToPolicy } = useAppNavigation();

  const fetchPoliciesPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<Policy>> => {
      return getPolicies({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        //TODO This type cast is necessary because the type of sortBy is string | undefined. Would be nice to fix this in the table component.
        sort_by: params?.sortBy as PoliciesSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.policies,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [filters, setError],
  );

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  return (
    <div className="h-full w-full">
      <ServerSideTableV2<Policy>
        testId="policies-table"
        pinnedColumns={{ right: ['action'] }}
        sx={{
          '.MuiDataGrid-pinnedColumns--right': {
            boxShadow: 'none',
          },
          '.MuiDataGrid-pinnedColumnHeaders--right': {
            display: 'none',
          },
        }}
        fetchPage={fetchPoliciesPage}
        columns={columns}
        getRowId={(row) => row.id}
        getRowClassName={() => styles.policyRow}
        onRowClick={({ row }: GridRowParams<Policy>) =>
          navigateToPolicy(`${row.id}`)
        }
        actions={{ search: true, pageSize: true, filters: true }}
        searchProps={{
          placeholder: `${labels.policiesTable.searchPlaceholder}...`,
        }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        autoHeight={false}
        filters={
          enableFilters ? (
            <ErrorBoundary fallbackRender={() => <></>}>
              <div className={styles.filters}>
                <CreatedOnFilter />
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CreatedByFilter />
                </ErrorBoundary>
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CargoOwnersFilter label={labels.fields.cargoOwner} />
                </ErrorBoundary>
                <Button
                  onPress={clearFilters}
                  variant="ghost"
                  width="fixed"
                  size="small"
                  isDisabled={!filters.length}
                  label="Clear filters"
                />
              </div>
            </ErrorBoundary>
          ) : undefined
        }
      />
    </div>
  );
};
