import { Typography } from '@breezeai-frontend/cargo-ui';

import { StorageLocationDetailsSectionLayout } from './StorageLocationDetailsSectionLayout';

export function Notes({ notes }: { notes?: string }) {
  return (
    <StorageLocationDetailsSectionLayout
      title="Notes"
      isCollapsed={!notes || notes.length === 0}
    >
      <Typography level="base" customStyles="text-text-tertiary">
        {notes}
      </Typography>
    </StorageLocationDetailsSectionLayout>
  );
}
