import { useEffect } from 'react';

import { useTracking } from '../../../utils/snowplow/hooks';
import { DTCFlowTypes } from '../constants';
import { useDTCFlowContext, useDTCStateAction } from '../context/hooks';
import { DTCFlowSteps, stepsByFlow } from '../flows-steps-config';
import { DTCFlowLayout } from '../shared/DTCFlowLayout/DTCFlowLayout';
import { GeneralSuccessPage } from '../steps/GeneralSuccessPage/GeneralSuccessPage';
import { GeneralWelcomePage } from '../steps/GeneralWelcomePage/GeneralWelcomePage';
import { InsuredDetailsPage } from '../steps/InsuredDetailsPage/InsuredDetailsPage';

export const TradeCreditInsuranceFlow = () => {
  useTracking();

  const { step } = useDTCFlowContext();
  const { setFlowType, setStep } = useDTCStateAction();
  const firstStep = stepsByFlow[DTCFlowTypes.TRADE_CREDIT_INSURANCE][0].step;

  useEffect(() => {
    setFlowType(DTCFlowTypes.TRADE_CREDIT_INSURANCE);
    setStep(firstStep);
  }, [firstStep, setFlowType, setStep]);

  return (
    <DTCFlowLayout>
      {step === DTCFlowSteps.WELCOME && (
        <GeneralWelcomePage title="Get a quote for trade credit insurance!" />
      )}
      {step === DTCFlowSteps.INSURED_DETAILS && (
        <InsuredDetailsPage
          type="potential-lead"
          extendedInsuredDetails={true}
        />
      )}
      {step === DTCFlowSteps.SUCCESS && <GeneralSuccessPage />}
    </DTCFlowLayout>
  );
};
