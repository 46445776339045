import { type PropsWithChildren, useContext, useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';
import { usePlatform } from '../context/PlatformContext';

export function BreezeProtectedRoute({ children }: PropsWithChildren) {
  const { authenticated } = useContext(AuthContext);

  return authenticated ? <>{children}</> : <Navigate to="/login" replace />;
}

export function TradeFlowProtectedRoute({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams();
  const gcId = searchParams.get('gcid');

  const { isWtw } = usePlatform();

  const { authenticated } = useContext(AuthContext);

  const redirectPath = isWtw && gcId ? `/login?gcid=${gcId}` : '/login';

  return authenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectPath} replace />
  );
}

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();

  const { isWtw } = usePlatform();

  const { setRedirectToOnLogin } = useContext(AuthContext);

  // Set the redirect path if the user is not logged in - to be used after login
  useEffect(() => {
    if (pathname !== '/login' && pathname !== '/' && setRedirectToOnLogin)
      setRedirectToOnLogin(pathname);
  }, [pathname, setRedirectToOnLogin]);

  return isWtw ? (
    <TradeFlowProtectedRoute>{children}</TradeFlowProtectedRoute>
  ) : (
    <BreezeProtectedRoute>{children}</BreezeProtectedRoute>
  );
};
