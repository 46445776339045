import { type PlaceSuggestion } from '../../../model/Place';
import { type Quote } from '../../../model/Quote';
import { type Shipment } from '../../../model/Shipment';
import { type Location } from '../../../network/apis/locations/types';
import { type QuoteModel } from '../../../network/apis/quotes/types';
import { type RouteDetailsFormData } from '../../components/RouteDetails/types';

type LocationPropName = keyof Pick<
  RouteDetailsFormData,
  'origin' | 'destination'
>;

// TODO: move this somewhere else as it's also being used in the Quote form.
// Maybe it could be moved to src/ui/components/RouteDetails/utils
export const getDefaultPortOrPlace = (
  // TODO: Needs to support both Quote and Quote model due to inconsistent normalization of
  // the data received from API. We need to address this at some point.
  data: Quote | QuoteModel | Shipment,
  locationProp: LocationPropName,
): Location | undefined => {
  const quotePort =
    locationProp === 'origin' ? data.origin_port : data.destination_port;
  const quoteLocation =
    locationProp === 'origin' ? data.origin_place : data.destination_place;
  const primaryQuoteMot = data.primary_transport_mode_code;

  if (quotePort) {
    return {
      type: 'port',
      port: {
        ...quotePort,
        type:
          primaryQuoteMot === 'sea'
            ? 'marine'
            : primaryQuoteMot === 'air'
            ? 'air'
            : undefined,
      },
      place: null,
    };
  }

  if (quoteLocation) {
    return {
      type: 'place',
      place: {
        place_id: quoteLocation.provider_place_uuid,
        description: quoteLocation.full_address || '',
      },
      port: null,
    };
  }

  return;
};

export const isPlaceSuggestion = (
  suggestion: unknown,
): suggestion is PlaceSuggestion => {
  if (typeof suggestion !== 'object' || suggestion === null) return false;
  return 'place_id' in suggestion && 'description' in suggestion;
};
