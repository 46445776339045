import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { PlatformError } from '../../../router/PlatformError/PlatformError';
import { useTracking } from '../../../utils/snowplow/hooks';
import { StorageCreationModal } from '../StorageCreationModal/StorageCreationModal';
import { StorageTable } from './StorageTable/StorageTable';

export const StoragePage = () => {
  useTracking();
  const isEnabled = useFeatureToggle('enable_storage');

  return isEnabled ? (
    <SectionLayout
      title="Locations"
      data-testid="storage-page"
      actions={
        <LinkButton
          size="small"
          data-testid="add-location-button"
          href="/storage/create/details"
          width="auto"
          label="Add New Location"
        />
      }
    >
      <StorageTable />
      <StorageCreationModal />
    </SectionLayout>
  ) : (
    <PlatformError />
  );
};
