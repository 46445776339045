import { FaUserFriends } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { clsxMerge } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../../context/auth/auth-hooks';
import { InfoItem } from '../../../components/InfoItem/InfoItem';
import { PageSection } from '../../../components/PageSection/PageSection';
import { useCertificate } from '../../certificateApiHooks';
import { gridClass } from '../CertificateShipmentDetails/CertificateShipmentDetails';

type PolicyShipmentDetailsProps = {
  className?: string;
  policyId?: string;
};

export const NamedAssuredDetails = ({
  className,
  policyId,
}: PolicyShipmentDetailsProps) => {
  const { data: certificate } = useCertificate({ policyId });
  const user = useUser();

  return (
    <PageSection
      title="Assured Details"
      className={clsxMerge(
        'w-full bg-system-grey-white box-border rounded-3xl p-6 flex flex-col',
        className,
      )}
      data-testid="assured-details"
    >
      <div className={gridClass}>
        <InfoItem
          title="Primary assured"
          value={user?.distributor.legal_name}
          testId="primary-assured"
          Icon={FaUserFriends}
        />
        <InfoItem
          title="Named Assured"
          value={certificate?.customer?.name}
          testId="named-assured"
          Icon={FaUserFriends}
        />
        <InfoItem
          className="lg:col-span-2"
          title="Named Assured Address"
          value={certificate?.customer_address?.full_address}
          testId="named-assured-address"
          Icon={FaLocationDot}
        />
      </div>
    </PageSection>
  );
};
