import { FaCopy, FaPen } from 'react-icons/fa';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@breeze-ai/ui-library';
import { LinkButton } from '@breezeai-frontend/cargo-ui';

import { useCertificate } from '../../certificateApiHooks';
import { type CertificatesRouteParams } from '../../types';

export default function Title() {
  const { certificateId, policyId } = useParams<CertificatesRouteParams>();
  const { data: certificate, isLoading } = useCertificate({
    policyId,
    options: { suspense: false },
  });
  const isEditDisabled = certificate?.associated_with_open_claim || isLoading;

  return (
    <div className="flex flex-row sm:items-center sm:justify-between text-nowrap gap-2 w-full">
      <div className="flex flex-row">
        <Link to="/certificates" className="flex justify-center items-center">
          <FaChevronLeft className="size-5 mr-2" />
        </Link>
        {/* External certificate id */}
        <Typography level="h2">Certificate {certificateId}</Typography>
      </div>

      <div className="flex sm:flex-row flex-col items-center gap-2">
        <LinkButton
          variant="secondary"
          label="Duplicate"
          leftIcon={<FaCopy />}
          width="auto"
          href={`/certificates/duplicate/${certificateId}/${policyId}`}
        />
        <LinkButton
          variant="secondary"
          label="Edit"
          leftIcon={<FaPen />}
          width="auto"
          isDisabled={isEditDisabled}
          href={`/certificates/update/${certificateId}/${policyId}`}
        />
      </div>
    </div>
  );
}
