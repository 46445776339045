import { type Port } from '../../model/Port';

export const getPortSearchDisplayValue = ({
  code,
  iata_code,
  name,
  country_name,
}: Port) => {
  if (iata_code && country_name) {
    return `${code} / ${iata_code} - ${name}, ${country_name}`;
  }

  if (iata_code) {
    return `${code} / ${iata_code} - ${name}`;
  }

  if (country_name) {
    return `${code} - ${name}, ${country_name}`;
  }

  return `${code} - ${name}`;
};

export const getPortShortDisplayValue = ({ name, country_name }: Port) =>
  country_name ? `${name}, ${country_name}` : name;
