import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  Button,
  LinkButton,
  ListBox,
  ListBoxItem,
  Popover,
  Typography,
} from '@breezeai-frontend/cargo-ui';

type ActionMenuItem = {
  label: string;
  href?: string;
  leftIcon: React.ReactNode;
  isDisabled?: boolean;
  isDownload: boolean;
};

export function ActionMenu({
  items,
  id,
}: {
  items: ActionMenuItem[];
  id: string;
}) {
  return (
    <Popover
      offset={-7}
      placement="left top"
      anchorElement={
        <Button
          leftIcon={<FaEllipsisVertical />}
          variant="ghost"
          data-testid={`action-menu-button-${id}`}
        />
      }
    >
      <ListBox className="space-y-1" aria-label="Actions">
        {items.map((item) => (
          <ListBoxItem key={item.label} className="p-0" textValue={item.label}>
            <LinkButton
              isDisabled={item.isDisabled}
              customStyles="justify-start rounded-lg text-text-primary"
              width="full"
              href={item.href}
              leftIcon={
                <span className="text-icons-primary">{item.leftIcon}</span>
              }
              variant="ghost"
              target={item.isDownload ? '_blank' : undefined}
            >
              <Typography>{item.label}</Typography>
            </LinkButton>
          </ListBoxItem>
        ))}
      </ListBox>
    </Popover>
  );
}
