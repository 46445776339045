import { CurrencyCell, Typography } from '@breeze-ai/ui-library';

import { type Claim } from '../../../model/Claim';
import { PageSection } from '../../components/PageSection/PageSection';
import styles from './ClaimCosts.module.scss';

type ClaimCostsProps = {
  claim: Claim;
};

export const ClaimCosts = ({
  claim: { currency, reported_amount, paid_amount },
}: ClaimCostsProps) => {
  return (
    <PageSection className={styles.wrapper}>
      {paid_amount && (
        <div className={styles.item}>
          <Typography variant="primary" bold>
            Paid Amount
          </Typography>
          <CurrencyCell
            testId="paid-amount"
            value={paid_amount}
            currency={currency}
            typographyProps={{ variant: 'primary', level: 'h1', bold: true }}
          />
        </div>
      )}
      <div className={styles.item}>
        <Typography variant="input">Loss Amount</Typography>
        <CurrencyCell
          testId="reported-amount"
          value={reported_amount}
          currency={currency}
          typographyProps={{
            variant: 'primary',
            level: 'h2',
            bold: true,
            color: 'pink',
            // TODO THEME: Add theme variable for color of this text
            style: { color: 'var(--cards-summary-details-bg)' },
          }}
        />
      </div>
    </PageSection>
  );
};
