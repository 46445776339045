import { ErrorFlag } from '../../../components/common/illustrations/ErrorFlag';
import { clsxMerge } from '../../../components/common/utils/classNameUtils';
import { Typography } from '../../../components/Typography/Typography';

interface ErrorMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;

  /**
   * Each detail will be a different paragraph to help with centering the error message.
   */
  details?: string[];
}

export function ErrorMessage({
  title,
  details,
  className,
  ...props
}: ErrorMessageProps) {
  return (
    <div
      {...props}
      className={clsxMerge(
        'flex flex-col items-center justify-center space-y-2 w-full',
        className,
      )}
    >
      <ErrorFlag width={180} height={180} />
      <Typography level="h2" color="primary">
        {title}
      </Typography>
      {details &&
        details.length &&
        details.map((detail) => (
          <Typography
            key={detail}
            color="secondary"
            customStyles="text-lg leading-5"
          >
            {detail}
          </Typography>
        ))}
    </div>
  );
}
