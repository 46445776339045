type DistributorType = 'freight_forwarder';
type DistributorDataManagementSystem = 'Cargowise' | 'API';
export enum DistributorRegionEnum {
  UK = 'UK',
  US = 'US',
  EU = 'EU',
}

export type Distributor = {
  id: number;
  company_type: DistributorType;
  company_name: string;
  legal_name: string;
  shipment_data_management_system_name?: DistributorDataManagementSystem;
  parent?: Distributor;
  children?: Distributor[];
  display_logo_url?: string;
  region: DistributorRegionEnum;
  billing_email: string;
  hide_tax_info?: boolean;
};
