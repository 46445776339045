import { FaUser } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';

import { type Claim } from '../../../model/Claim';
import { AttributesGrid } from '../../components/AttributesGrid/AttributesGrid';
import { InfoItem } from '../../components/InfoItem/InfoItem';
import { PageSection } from '../../components/PageSection/PageSection';

type ClaimContactDetailsProps = {
  claim: Claim;
};

export const ClaimContactDetails = ({
  claim: { contact_person },
}: ClaimContactDetailsProps) => {
  return (
    <PageSection title="Contact details" data-testid="claim-contact-details">
      <AttributesGrid>
        <InfoItem
          Icon={FaUser}
          title="Name"
          value={contact_person.name}
          testId="contact-name"
        />
        <InfoItem
          Icon={FaEnvelope}
          title="Email"
          value={contact_person.email}
          testId="contact-email"
        />
        <InfoItem
          Icon={FaPhone}
          title="Phone"
          value={contact_person.mobile}
          testId="contact-phone"
        />
      </AttributesGrid>
    </PageSection>
  );
};
