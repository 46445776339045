import { createTV } from 'tailwind-variants';

import { twMergeConfig } from './customTwMerge';

/**
 * Use custom tailwind-merge configuration. This ensures that the tailwind-merge
 * logic used under the hood by tailwind-variants uses correct configuration.
 */
export const tv = createTV({
  twMergeConfig: twMergeConfig,
});
