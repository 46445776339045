import { useState } from 'react';
import { FaChevronLeft, FaRedo } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import { PiArchiveFill } from 'react-icons/pi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ActionConfirmationModal,
  Button,
  clsxMerge,
  LinkButton,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateStorageLocation } from '../hooks/useCreateStorageLocation';
import {
  type StorageLocationModel,
  StorageLocationStatusEnum,
  type StorageRouteParams,
} from '../types';

export default function Title({ data }: { data: StorageLocationModel }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { policyId, storageLocationId } = useParams<StorageRouteParams>();
  const { mutate, isLoading } = useCreateStorageLocation({
    policyId,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(['storageLocation', policyId]);
        navigate('/storage');
      },
    },
  });

  return (
    <div className="flex flex-row sm:items-center sm:justify-between gap-2">
      <div className="flex flex-row items-center">
        <div className="flex flex-row gap-2">
          <Link to="/storage" className="flex justify-center items-center">
            <FaChevronLeft className="size-5 mr-2" />
          </Link>
          <Typography
            level="h2"
            data-testid="storage-location-title"
            customStyles="break-words whitespace-normal line-clamp-2"
          >
            {`${data?.location_name}`}
          </Typography>
        </div>
        <div
          className={clsxMerge(
            data.status === StorageLocationStatusEnum.ACTIVE
              ? 'bg-system-green-100'
              : 'bg-system-grey-300',
            'flex gap-1 items-center px-2 py-1 rounded-full ml-2.5',
          )}
        >
          {data.status === StorageLocationStatusEnum.ACTIVE && (
            <FaCheck size={14} className="text-system-green-500" />
          )}
          {data.status === StorageLocationStatusEnum.ARCHIVED && (
            <PiArchiveFill size={14} className="text-system-grey-700" />
          )}
          <Typography level="subtext" color="primary" text="capitalize">
            {data.status}
          </Typography>
        </div>
      </div>

      <div className="flex sm:flex-row flex-col items-center gap-2">
        <LinkButton
          variant="ghost"
          label="Edit"
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          href={`/storage/update/details/${storageLocationId}/${policyId}`}
          isDisabled={
            isLoading || data.status === StorageLocationStatusEnum.ARCHIVED
          }
        />
        <Button
          variant="ghost"
          label={
            data.status === StorageLocationStatusEnum.ARCHIVED
              ? 'Restore'
              : 'Archive'
          }
          isLoading={isLoading}
          data-testid={
            data.status === StorageLocationStatusEnum.ARCHIVED
              ? 'restore-button'
              : 'archive-button'
          }
          loadingText={
            data.status === StorageLocationStatusEnum.ARCHIVED
              ? 'Restoring'
              : 'Archiving'
          }
          leftIcon={
            data.status === StorageLocationStatusEnum.ARCHIVED ? (
              <FaRedo />
            ) : (
              <PiArchiveFill size={16} />
            )
          }
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          onPress={() => setShowAlertModal(true)}
        />
      </div>
      <ActionConfirmationModal
        title={`Are you sure you would like to ${
          data.status === StorageLocationStatusEnum.ARCHIVED
            ? 'restore'
            : 'archive'
        } ${data.location_name}?`}
        description={`You will still be able to ${
          data.status === StorageLocationStatusEnum.ARCHIVED
            ? 'restore'
            : 'archive'
        } this location at any time`}
        heading={
          <div className="mb-3 flex p-2.5 items-center justify-center rounded-full border border-field-border-default self-start">
            {data.status === StorageLocationStatusEnum.ARCHIVED ? (
              <FaRedo />
            ) : (
              <PiArchiveFill size={16} />
            )}
          </div>
        }
        onOpenChange={(value: boolean) => setShowAlertModal(value)}
        isOpen={showAlertModal}
        onConfirmChanges={() =>
          mutate({
            status:
              data.status === StorageLocationStatusEnum.ARCHIVED
                ? StorageLocationStatusEnum.ACTIVE
                : StorageLocationStatusEnum.ARCHIVED,
          })
        }
      />
    </div>
  );
}
