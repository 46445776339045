import { type ComponentProps } from 'react';
import { FaCheckDouble } from 'react-icons/fa6';
import { Badge, clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';
import { type IconName } from '@fortawesome/fontawesome-svg-core';

import { type Claim, type ClaimStatus } from '../../../model/Claim';

type BadgeConfig = {
  label: string;
  variant?: Variants.Color;
  icon?: IconName;
};

const badgeVariants: Record<ClaimStatus, BadgeConfig> = {
  unassigned: { label: 'Unassigned', icon: 'circle-notch' },
  FNOL: { label: 'Submitted', variant: 'info', icon: 'check-double' },
  under_evaluation: { label: 'In Review', icon: 'spinner' },
  reserved: { label: 'In Review', icon: 'spinner' },
  settled: { label: 'Paid', variant: 'success', icon: 'check' },
  ex_gratia: { label: 'Paid', variant: 'success', icon: 'check' },
  rejected: {
    label: 'Rejected',
    variant: 'error',
    icon: 'exclamation-triangle',
  },
  withdrawn_by_client: {
    label: 'Withdrawn by Client',
    variant: 'success',
    icon: 'check',
  },
};

type ClaimStatusBadgeProps = Partial<ComponentProps<typeof Badge>> & {
  claim: Claim;
};

export const ClaimStatusBadge = ({
  claim: { status },
  className,
  ...props
}: ClaimStatusBadgeProps) => {
  const { label, variant } = badgeVariants[status];

  return (
    <Badge
      variant={variant}
      role="claim-status-badge"
      className={clsxMerge('gap-1', className)}
      {...props}
    >
      <FaCheckDouble />
      <Typography level="subtext">{label}</Typography>
    </Badge>
  );
};
