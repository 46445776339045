import { type ComponentProps } from 'react';
import { Typography } from '@breeze-ai/ui-library';

import { type CurrencyExchangeRate as CurrencyExchangeRateType } from '../../../model/CurrencyValue';

type CurrencyExchangeRateProps = CurrencyExchangeRateType & {
  typographyProps?: ComponentProps<typeof Typography>;
};

export const CurrencyExchangeRate = ({
  from_currency,
  to_currency,
  exchange_rate,
  typographyProps,
}: CurrencyExchangeRateProps) => {
  const { code: sourceCurrency } = from_currency;
  const { code: baseCurrency } = to_currency;
  const rate = exchange_rate.toFixed(4);

  return (
    <Typography {...typographyProps} role="currency-exchange-rate">
      1 {sourceCurrency} = {rate} {baseCurrency}
    </Typography>
  );
};
