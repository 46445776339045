import { type BaseQueryParams, type PageState } from '@breeze-ai/ui-library';
import { useMutation } from '@tanstack/react-query';

import {
  type FilterableFields,
  type FilterBaseParam,
} from '../../../network/apis/types.ts';
import { reportException } from '../../../utils/error-reporting/error-reporting.ts';
import { getStorageLocations } from '../StoragePage/StorageTable/StorageTable.tsx';
import {
  type StorageLocationModel,
  type StorageLocationsSortableFields,
} from '../types.ts';

interface StorageLocationsParams
  extends Omit<BaseQueryParams, 'filters' | 'sortBy' | 'pagePointer'> {
  filters: FilterBaseParam<FilterableFields>[];
  sortBy: StorageLocationsSortableFields;
  pagePointer: string | undefined;
  paginate: boolean;
}

export const useStorageLocations = () => {
  return useMutation<
    PageState<StorageLocationModel>,
    Error,
    StorageLocationsParams
  >(async (params: StorageLocationsParams) => {
    try {
      const page = await getStorageLocations({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as StorageLocationsSortableFields,
        page_pointer: params?.pagePointer,
        filters: params?.filters,
        paginate: true,
      });
      return {
        records: page.locations,
        nextPagePointer: page.next_page_pointer,
        prevPagePointer: page.prev_page_pointer,
        totalRows: page.total_num_records,
      };
    } catch (e) {
      reportException(e);
      return {
        records: [],
        nextPagePointer: null,
        prevPagePointer: null,
        totalRows: 0,
      };
    }
  });
};
