import { useEffect, useRef } from 'react';
import { SuccessDialog } from '@breeze-ai/ui-library';
import { SectionLayout, Spinner } from '@breezeai-frontend/cargo-ui';

import { useAppNavigation } from '../../router/use-app-navigation';
import { useScrollToElement } from '../hooks/use-scroll-to-element';
import { WizardErrorDialog } from './components/WizardErrorDialog/WizardErrorDialog';
import { WizardLoadingDialog } from './components/WizardLoadingDialog/WizardLoadingDialog';
import { WizardStep } from './components/WizardStep/WizardStep';
import { WizardSteps } from './constants';
import { PolicyWizardContextProvider } from './context/PolicyWizardContext';
import { usePolicyWizardContext } from './hooks/context-hooks';
import { usePolicyWizardInitialization } from './hooks/use-policy-wizard-initialization';
import { useSubmitPolicyCreationRequest } from './hooks/use-submit-shipment-information-form';
import { QuoteSubmissionForm } from './steps/QuoteSubmissionForm/QuoteSubmissionForm';
import { QuoteSummary } from './steps/QuoteSummary/QuoteSummary';
import { ShipmentInformationForm } from './steps/ShipmentInformationForm/ShipmentInformationForm';

type StepsMap = { [K in WizardSteps]?: HTMLDivElement | null };

export const PolicyWizardContent = () => {
  usePolicyWizardInitialization();
  const scrollToElement = useScrollToElement();
  const submitPolicyCreationRequest = useSubmitPolicyCreationRequest();
  const { configuration, step, policy } = usePolicyWizardContext();
  const stepsMap = useRef<StepsMap>({});

  const { navigateToPolicy } = useAppNavigation();

  const setStepRef = (step: WizardSteps, element: HTMLDivElement | null) => {
    stepsMap.current[step] = element;
  };

  const goToPolicy = () => {
    if (policy?.id) {
      navigateToPolicy(policy?.id);
    }
  };

  useEffect(() => {
    scrollToElement(stepsMap.current[step] ?? null);
  }, [scrollToElement, step]);

  if (step === WizardSteps.INIT) {
    return <Spinner />;
  }

  return (
    <div className="w-full space-y-10" data-testid="policy-wizard">
      <WizardStep
        scrollMargin={false}
        ref={(el) => setStepRef(WizardSteps.QUOTE_FORM, el)}
      >
        <QuoteSubmissionForm />
      </WizardStep>
      <WizardStep
        visible={step >= WizardSteps.QUOTE_SUMMARY}
        ref={(el) => setStepRef(WizardSteps.QUOTE_SUMMARY, el)}
      >
        <QuoteSummary
          onUpdateDetails={() =>
            scrollToElement(stepsMap.current[WizardSteps.QUOTE_FORM] ?? null)
          }
        />
      </WizardStep>
      <WizardStep
        visible={step >= WizardSteps.SHIPMENT_INFORMATION}
        ref={(el) => setStepRef(WizardSteps.SHIPMENT_INFORMATION, el)}
      >
        <ShipmentInformationForm onSubmit={submitPolicyCreationRequest} />
      </WizardStep>
      <WizardLoadingDialog visible={step === WizardSteps.LOADING} />
      <WizardErrorDialog />
      {configuration?.isEuFreightForwarder ? (
        <SuccessDialog
          visible={step === WizardSteps.SUCCESS}
          onConfirm={goToPolicy}
          data-testid="policy-creation-success-dialog"
          title="Thank You!"
          message="The policy was successfully referred."
          confirmText="Go to Referral Details"
          closable={false}
        />
      ) : (
        <SuccessDialog
          visible={step === WizardSteps.SUCCESS}
          onConfirm={goToPolicy}
          data-testid="policy-creation-success-dialog"
          title="Thank You!"
          message="The policy was successfully generated."
          confirmText="Go to Policy"
          closable={false}
        />
      )}
    </div>
  );
};

export const PolicyWizard = () => {
  return (
    <PolicyWizardContextProvider>
      <SectionLayout>
        <PolicyWizardContent />
      </SectionLayout>
    </PolicyWizardContextProvider>
  );
};
