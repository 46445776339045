import { type ComponentProps, useState } from 'react';
import { ErrorDialog, Link, Typography } from '@breeze-ai/ui-library';

import { useReCAPTCHA } from '../../../../context/recaptcha/ReCaptchaProvider';
import {
  createDTCCustomer,
  createDTCCustomerLead,
} from '../../../../network/apis/externals/externals';
import {
  createDTCCustomerLeadPayload,
  createDTCCustomerPayload,
} from '../../../../network/apis/externals/normalizers';
import {
  type DTCCustomerErrorType,
  type DTCCustomerType,
} from '../../../../network/apis/externals/types';
import { type ErrorResponse } from '../../../../network/apis/types';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { useTracking } from '../../../../utils/snowplow/hooks';
import { NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../constants';
import {
  useDTCFlowContext,
  useDTCForms,
  useDTCStateAction,
} from '../../context/hooks';
import { type DTCFormsData } from '../../context/types';
import { useDTCNavigation } from '../../dtc-navigation';
import { useDTCFormValidation } from '../../shared/forms-validations';
import { ContactDetailsForm } from './ContactDetailsForm/ContactDetailsForm';
import { InsuredDetailsForm } from './InsuredDetailsForm/InsuredDetailsForm';
import styles from './InsuredDetailsPage.module.scss';

const linkProps: ComponentProps<typeof Link> = {
  newTab: true,
  icon: false,
  underline: false,
};

type InsuredDetailsPageProps = {
  type?: DTCCustomerType;
  extendedInsuredDetails?: boolean;
};

export const InsuredDetailsPage = ({
  type = 'customer',
  extendedInsuredDetails = false,
}: InsuredDetailsPageProps) => {
  useTracking();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const { brand, flowType } = useDTCFlowContext();
  const { data: formsData } = useDTCForms();
  const { setStep, setCustomer, setFormError } = useDTCStateAction();
  const validatePageForms = useDTCFormValidation();
  const { verifyReCAPTCHA } = useReCAPTCHA();
  const { navigateToPreviousStep, getNextStep } = useDTCNavigation();

  if (!flowType) {
    return null;
  }

  const endpoints = {
    customer: {
      createPayload: createDTCCustomerPayload,
      submit: createDTCCustomer,
    },
    'potential-lead': {
      createPayload: createDTCCustomerLeadPayload,
      submit: createDTCCustomerLead,
    },
  };

  const formsValid = validatePageForms([
    'companyName',
    'companyAddress',
    'contactName',
    'contactEmail',
    'contactPhone',
    ...(extendedInsuredDetails
      ? ([
          'yearsInBusinessId',
          'companyIndustry',
          'previousCreditInsurance',
        ] as const)
      : []),
  ]);

  const countryNotSupportedError = (
    <span>
      Sorry, only cargo owners based in the USA can be insured currently. Please
      contact{' '}
      <Link
        href={`mailto:${brand.supportMail}`}
        underline={false}
        className={styles.helperTextLink}
      >
        {brand.supportMail}
      </Link>{' '}
      for further details.{' '}
    </span>
  );

  const handleCustomerCreationError = (
    e: ErrorResponse<DTCCustomerErrorType>,
  ) => {
    const { error_type } = e?.response?.data ?? {};
    switch (error_type) {
      case 'CountryNotSupported':
        setFormError({
          error: true,
          field: 'companyAddress',
          reason: countryNotSupportedError,
        });
        break;

      default:
      case 'GenericException':
        reportException(e);
        setError(true);
        break;
    }
  };

  const submitCustomerForms = async () => {
    if (formsValid) {
      setLoading(true);
      const { createPayload, submit } = endpoints[type];
      const payload = createPayload(
        flowType,
        formsData as Required<DTCFormsData>,
      );

      try {
        const reCaptchaResult = await verifyReCAPTCHA('insured_details_page');

        if (reCaptchaResult?.success) {
          const customer = await submit(payload);
          setCustomer(customer);
          setStep(getNextStep());
        } else {
          setError(true);
        }
      } catch (e) {
        handleCustomerCreationError(e as ErrorResponse<DTCCustomerErrorType>);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      className={styles.insuredDetailsContent}
      data-testid="dtc-insured-details-page"
    >
      <InsuredDetailsForm extended={extendedInsuredDetails} />
      <ContactDetailsForm />
      <Typography block className={styles.externalLinks}>
        By clicking continue you agree to our{' '}
        <Link href={TERMS_OF_USE_URL} role="toc-link" {...linkProps}>
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          href={PRIVACY_POLICY_URL}
          role="privacy-policy-link"
          {...linkProps}
        >
          Privacy Policy
        </Link>
      </Typography>
      <NavigationFooter
        onBack={navigateToPreviousStep}
        onNext={submitCustomerForms}
        nextProps={{ isLoading: loading, isDisabled: !formsValid }}
      />
      <ErrorDialog visible={error} onConfirm={() => setError(false)} />
    </div>
  );
};
