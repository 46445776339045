import { Icon, Typography } from '@breeze-ai/ui-library';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { useDTCFlowContext } from '../../../context/hooks';
import styles from './CoverageDescriptions.module.scss';

const expandIcon = <Icon icon="chevron-down" size="xs" />;

export const CoverageDescriptions = () => {
  const { configuration } = useDTCFlowContext();
  const { coverage_descriptions } = configuration ?? {};

  return (
    <div className={styles.wrapper} data-testid="coverage-descriptions">
      <Accordion
        disableGutters
        elevation={0}
        square
        role="main-exclusions-accordion"
      >
        <AccordionSummary className={styles.header} expandIcon={expandIcon}>
          <Typography level="h3">Main Exclusions</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.content}>
          <div className={styles.itemsList}>
            {coverage_descriptions?.map(({ title, description }, i) => (
              <Accordion key={i} disableGutters className={styles.collapseItem}>
                <AccordionSummary
                  className={styles.itemHeader}
                  expandIcon={expandIcon}
                >
                  <Typography level="h5" bold>
                    {title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.content}>
                  <Typography>{description}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
