import {
  createContext,
  type Dispatch,
  type ReactNode,
  useContext,
  useReducer,
} from 'react';
import moment from 'moment';

import { useUserSetting } from '../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../model/CurrencyValue';
import { toISODate } from '../../utils/dates';

type InsightsPageContextType = {
  state: InsightsPageState;
  dispatch: Dispatch<InsightsPageContextAction>;
};

type InsightsPageState = {
  startDate: string;
  endDate: string;
  currencyCode: SupportedCurrencies;
};

type SetDates = {
  type: 'set-dates';
  payload: {
    startDate?: string;
    endDate?: string;
  };
};

type SetCurrency = {
  type: 'set-currency';
  payload: SupportedCurrencies;
};

type InsightsPageContextAction = SetDates | SetCurrency;

const insightsPageContextReducer = (
  state: InsightsPageState,
  action: InsightsPageContextAction,
): InsightsPageState => {
  switch (action.type) {
    case 'set-dates':
      return { ...state, ...action.payload };

    case 'set-currency':
      return { ...state, currencyCode: action.payload };
  }
};

const initialState: InsightsPageState = {
  startDate: toISODate(moment().startOf('year')),
  endDate: toISODate(moment().endOf('year')),
  currencyCode: 'GBP',
};

const InsightsPageContext = createContext<InsightsPageContextType>({
  state: initialState,
  dispatch: () => undefined,
});

export const useInsightsPageContext = () => {
  const { state } = useContext(InsightsPageContext);

  return state;
};

export const useInsightsPageContextAction = () => {
  const { dispatch } = useContext(InsightsPageContext);

  return dispatch;
};

export const InsightsPageContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  const [state, dispatch] = useReducer(insightsPageContextReducer, {
    ...initialState,
    currencyCode: defaultCurrency,
  });

  return (
    <InsightsPageContext.Provider value={{ state, dispatch }}>
      {children}
    </InsightsPageContext.Provider>
  );
};
