import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import {
  type ClaimsInsights,
  type PoliciesInsightsResponse,
} from '../../model/Insights';
import { type ErrorResponse } from '../../network/apis/types';
import { get } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';
import { type BaseInsightsQuery } from './types';

export const usePoliciesInsights = ({
  params,
  options,
}: {
  params: BaseInsightsQuery;
  options?: UseQueryOptions<
    PoliciesInsightsResponse,
    ErrorResponse,
    PoliciesInsightsResponse
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  return useQuery({
    queryKey: ['policiesInsights', queryParams],
    queryFn: () =>
      get<PoliciesInsightsResponse>(
        `${bffServiceUrl}/insights/policies${queryParams}`,
      ),
    suspense: false, // TODO: enable once we re-write the page with Suspense wrappers
    ...options,
  });
};

export const useClaimsInsights = ({
  params,
  options,
}: {
  params: BaseInsightsQuery;
  options?: UseQueryOptions<ClaimsInsights, ErrorResponse, ClaimsInsights>;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  return useQuery({
    queryKey: ['claimsInsights', queryParams],
    queryFn: () =>
      get<ClaimsInsights>(`${bffServiceUrl}/insights/claims${queryParams}`),
    suspense: false, // TODO: enable once we re-write the page with Suspense wrappers
    ...options,
  });
};
