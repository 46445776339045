import { type User } from '../../../model/User';
import { type FreightForwarder } from './types';

export const userToEmailPayload = (user: User) => ({
  name: `${user?.first_name} ${user?.last_name}`,
  email: user?.email ?? '',
  freight_forwarder: {
    name: user?.distributor?.company_name ?? '',
    logo_url: user?.distributor?.display_logo_url ?? '',
  } as FreightForwarder,
});
