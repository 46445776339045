import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
} from '@breezeai-frontend/cargo-ui';

import { useAppConfiguration } from '../../../../../context/app-configuration/AppConfigurationProvider';
import { type TitledOption } from '../../../../../model/TitledOption';

export function CommodityComboBox({
  ...props
}: Omit<ComboBoxProps<TitledOption>, 'children'>) {
  const { commodities } = useAppConfiguration();

  return (
    <ComboBox {...props} defaultItems={commodities} allowsCustomValue>
      {commodities?.map((commodity) => {
        const { id, title } = commodity;
        return (
          <ComboBoxItem
            key={id}
            id={JSON.stringify(commodity)}
            textValue={title}
          >
            {title}
          </ComboBoxItem>
        );
      })}
    </ComboBox>
  );
}
