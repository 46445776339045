import { type Shipment } from '../../../model/Shipment';
import { normalizePlaceResponse } from '../places/normalizers';
import { type ShipmentModel } from './types';

export const normalizeShipmentResponse = (
  response: ShipmentModel,
): Shipment => ({
  id: response.id,
  external_shipment_id: response.external_shipment_id,
  created_time: response.created_time,
  primary_transport_mode_code: response.primary_transport_mode_code,
  customer: response.customer,
  distributor: response.distributor,
  origin_port_code: response.origin_port_code,
  origin_port: response.origin_port,
  origin_place: normalizePlaceResponse(response.origin_place),
  destination_port: response.destination_port,
  destination_port_code: response.destination_port_code,
  destination_place: normalizePlaceResponse(response.destination_place),
  commodity_value: response.commodity_value,
  commodity_currency: response.commodity_currency?.code,
  commodity_description: response.commodity_description,
  commodity_type: response.commodity_type,
  freight_cost: response.freight_cost,
  freight_cost_currency: response.freight_cost_currency?.code,
  duty_cost: response.duty_cost,
  duty_cost_currency: response.duty_cost_currency?.code,
  container_mode: response.container_mode,
  container_ids: response.container_reference_numbers,
  eta: response.eta,
  etd: response.etd,
  incoterm_code: response.incoterm_code,
  vessel_name: response.vessel_name,
  secondary_mode_of_transport: response.secondary_mode_of_transport,
  special_conditions: response?.special_conditions?.map(({ value }) => value),
});
