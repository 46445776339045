import { Tooltip, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { isPlace, type Place, type PlaceModel } from '../../../model/Place';
import { type Port } from '../../../model/Port';
import {
  type ConveyanceType,
  type ShipmentLocationType,
} from '../../../model/Shipment';
import { CONVEYANCE_TYPE_ICONS } from '../../constants';
import styles from './JourneySummary.module.scss';

type JourneySummaryProps = {
  stops: (Place | Port | PlaceModel)[];
  conveyances?: ConveyanceType[];
  variant?: 'dark' | 'light';
  className?: string;
};

const JourneyStop = ({ place }: { place: ShipmentLocationType }) => {
  const name = isPlace(place) ? place.name : place.name;
  const label = isPlace(place) ? place.display_name : place.code.toUpperCase();

  return (
    <div className={styles.stop}>
      <div className={styles.circle} />
      <Tooltip content={name} placement="bottom" className={styles.tooltip}>
        <Typography>{label ?? '-'}</Typography>
      </Tooltip>
    </div>
  );
};

export const JourneySummary = ({
  stops,
  conveyances = [],
  variant = 'light',
  className,
}: JourneySummaryProps) => {
  return (
    <div
      className={classnames(
        styles.journeySummaryWrapper,
        styles[variant],
        className,
      )}
      data-testid="journey-summary"
    >
      <div className={styles.conveyanceIcons}>
        {conveyances?.length > 0 &&
          conveyances.map((conveyance, i) => {
            const Icon = CONVEYANCE_TYPE_ICONS[conveyance];
            return <Icon key={i} className="w-6 h-6" />;
          })}
      </div>
      <div className={styles.journey}>
        {stops.map((stop, i) => (
          <JourneyStop key={i} place={stop} />
        ))}
      </div>
    </div>
  );
};
