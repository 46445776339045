import { useEffect } from 'react';

import { useTracking } from '../../../utils/snowplow/hooks';
import { DTCFlowTypes } from '../constants';
import { useDTCFlowContext, useDTCStateAction } from '../context/hooks';
import { DTCFlowSteps, stepsByFlow } from '../flows-steps-config';
import { DTCFlowLayout } from '../shared/DTCFlowLayout/DTCFlowLayout';
import { InsuranceSuccessPage } from '../steps/InsuranceSuccessPage/InsuranceSuccessPage';
import { InsuranceWelcomePage } from '../steps/InsuranceWelcomePage/InsuranceWelcomePage';
import { InsuredDetailsPage } from '../steps/InsuredDetailsPage/InsuredDetailsPage';
import { ManualReviewPage } from '../steps/ManualReviewPage/ManualReviewPage';
import { ReviewPage } from '../steps/ReviewPage/ReviewPage';
import { ShipmentDetailsPage } from '../steps/ShipmentDetailsPage/ShipmentDetailsPage';

export const PerShipmentFlow = () => {
  useTracking();

  const { step } = useDTCFlowContext();
  const { setFlowType, setStep } = useDTCStateAction();
  const firstStep = stepsByFlow[DTCFlowTypes.PER_SHIPMENT][0].step;

  useEffect(() => {
    setFlowType(DTCFlowTypes.PER_SHIPMENT);
    setStep(firstStep);
  }, [firstStep, setFlowType, setStep]);

  return (
    <DTCFlowLayout>
      {step === DTCFlowSteps.WELCOME && <InsuranceWelcomePage />}
      {step === DTCFlowSteps.INSURED_DETAILS && <InsuredDetailsPage />}
      {step === DTCFlowSteps.SHIPMENT_DETAILS && <ShipmentDetailsPage />}
      {step === DTCFlowSteps.QUOTE_MANUAL_REVIEW && <ManualReviewPage />}
      {step === DTCFlowSteps.REVIEW && <ReviewPage />}
      {step === DTCFlowSteps.SUCCESS && <InsuranceSuccessPage />}
    </DTCFlowLayout>
  );
};
