import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Spinner } from '@breezeai-frontend/cargo-ui';

import { type ClaimEventLocationType } from '../../model/Claim';
import { type CurrencyDefinition } from '../../model/CurrencyValue';
import {
  type ConveyanceType,
  type SpecialConditionOptionType,
} from '../../model/Shipment';
import {
  type LabeledOption,
  type TitledOption,
} from '../../model/TitledOption';
import { getAppConfiguration } from '../../network/apis/configurations/configurations';
import { PlatformError } from '../../router/PlatformError/PlatformError';
import { reportException } from '../../utils/error-reporting/error-reporting';

export type AppConfiguration = {
  currencies: CurrencyDefinition[];
  incoterms: TitledOption[];
  container_modes: Record<ConveyanceType, TitledOption[]>;
  commodities: TitledOption[];
  special_conditions: SpecialConditionOptionType[];
  claim_location_types: LabeledOption<ClaimEventLocationType>[];
};

export const AppConfigurationContext = createContext<AppConfiguration>({
  currencies: [],
  incoterms: [],
  container_modes: {
    sea: [],
    air: [],
    road: [],
    rail: [],
  },
  commodities: [],
  special_conditions: [],
  claim_location_types: [],
});

export const useAppConfiguration = () => {
  const configuration = useContext(AppConfigurationContext);
  return configuration;
};

export const AppConfigurationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [configuration, setConfiguration] = useState<AppConfiguration>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    if (!configuration) {
      getAppConfiguration()
        .then((configuration) => setConfiguration(configuration))
        .catch((e) => {
          reportException(e);
          setError(true);
        });
    }
  }, [configuration]);

  if (error) {
    return <PlatformError />;
  }

  if (!configuration) {
    return <Spinner />;
  }

  return (
    <AppConfigurationContext.Provider value={configuration}>
      {children}
    </AppConfigurationContext.Provider>
  );
};
