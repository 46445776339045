import { useMemo, useState } from 'react';
import { Avatar } from '@breeze-ai/ui-library';

import {
  FilterableFields,
  FilterOperators,
} from '../../../../network/apis/types';
import { useUsers } from '../../../../network/apis/users/hooks';
import { MultiSelectRadioDropdown } from '../components/MultiSelectRadioDropdown';
import { useFilterStore } from '../useTableFilters';

export function CreatedByFilter() {
  const [query, setQuery] = useState<string>('');

  const {
    data: users,
    isError,
    isLoading,
  } = useUsers({
    params: {
      paginate: true,
      limit: 100,
      order: 'asc',
      query,
    },
    options: {
      suspense: false,
    },
  });

  const {
    setSelectedUsers,
    selectedUsers,
    updateFilter,
    removeFilter,
    filters,
  } = useFilterStore((state) => ({
    setSelectedUsers: state.setSelectedUsers,
    selectedUsers: state.selectedUsers,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
    filters: state.filters.find(
      (filter) => filter.field === FilterableFields.CREATED_BY_USER_ID,
    )?.values,
  }));

  const onSubmit = () =>
    updateFilter({
      field: FilterableFields.CREATED_BY_USER_ID,
      operator: FilterOperators.EQUALS,
      values: selectedUsers,
    });

  const onReset = () => removeFilter(FilterableFields.CREATED_BY_USER_ID);

  const createdByOptions = useMemo(
    () =>
      users?.map(({ first_name, last_name, id, avatar_color }) => ({
        label: `${first_name} ${last_name}`,
        value: String(id),
        prefix: (
          <Avatar
            alt={`${first_name} ${last_name}`}
            size="small"
            type={'initials'}
            name={`${first_name} ${last_name}`}
            data-testid="user-avatar"
            backgroundColor={avatar_color ?? 'blue'}
          />
        ),
      })),
    [users],
  );

  const appliedOptions = useMemo(
    () =>
      createdByOptions?.filter((option) => filters?.includes(option.value)) ??
      [],
    [createdByOptions, filters],
  );

  const nonAppliedOptions = useMemo(
    () =>
      createdByOptions?.filter((option) => !filters?.includes(option.value)) ??
      [],
    [createdByOptions, filters],
  );

  return (
    <MultiSelectRadioDropdown
      appliedOptions={appliedOptions}
      optionsCount={filters?.length}
      selected={selectedUsers}
      setSelected={setSelectedUsers}
      onSearch={setQuery}
      name="createdBy"
      label="Created by"
      dropdownIcon="user"
      nonAppliedOptions={nonAppliedOptions}
      onSubmit={onSubmit}
      onReset={onReset}
      placeholder="Search team member..."
      isError={isError}
      isLoading={isLoading}
      triggerTestId="created-by-filter-trigger"
      contentTestId="created-by-filter-content"
    />
  );
}
