import { type ComponentProps } from 'react';
import { type Input } from '@breeze-ai/ui-library';

import {
  type CurrencyDefinition,
  type SupportedCurrencies,
} from '../../../../model/CurrencyValue';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';

type CommodityValueSelectorProps = Omit<
  ComponentProps<typeof Input>,
  'value' | 'onChange'
> & {
  currencyOptions: CurrencyDefinition[];
  value?: number;
  currency?: SupportedCurrencies;
  onChange?: (value: number | undefined) => void;
  onCurrencyChange?: (value: SupportedCurrencies) => void;
};

export const CommodityValueSelector = ({
  value,
  currency,
  currencyOptions,
  onChange,
  onCurrencyChange,
  ...props
}: CommodityValueSelectorProps) => {
  return (
    <CurrencyValueInput
      value={value}
      currencyOptions={currencyOptions}
      currency={currency}
      onChange={onChange}
      onCurrencyChange={onCurrencyChange}
      label="Commodity Value"
      placeholder="Enter amount"
      testId="commodity-value-input"
      required={true}
      {...props}
    />
  );
};
