import { type ComponentProps } from 'react';
import { Badge, clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';

import { type InvoiceStatus } from '../../../model/Invoice';

type InvoiceStatusBadgeProps = {
  status: InvoiceStatus;
  className?: string;
};

const variantByStatus: Record<
  InvoiceStatus,
  ComponentProps<typeof Badge>['variant']
> = {
  paid: 'success',
  overdue: 'error',
  pending: 'default',
};

export const InvoiceStatusBadge = ({
  status,
  className,
}: InvoiceStatusBadgeProps) => {
  const variant = variantByStatus[status];

  return (
    <Badge variant={variant} className={clsxMerge('capitalize', className)}>
      <Typography level="subtext">{status}</Typography>
    </Badge>
  );
};
