import { useEffect } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import { LinkButton } from '@breezeai-frontend/cargo-ui';

import { useTracking } from '../../../../utils/snowplow/hooks';
import { useDTCStateAction } from '../../context/hooks';
import { DTCFlowLayout } from '../DTCFlowLayout/DTCFlowLayout';
import styles from './DTCFlowSelection.module.scss';

export const DTCFlowSelection = () => {
  useTracking();

  const { resetDTCState } = useDTCStateAction();

  useEffect(() => {
    resetDTCState();
  }, [resetDTCState]);

  return (
    <DTCFlowLayout>
      <div className={styles.wrapper} data-testid="dtc-flow-selection-page">
        <Typography level="h2">Choose your coverage type</Typography>
        <div className={styles.buttons}>
          <LinkButton
            href="/flexport/shipment-insurance"
            aria-details="per shipment button"
            variant="secondary"
            width="full"
            data-testid="per-shipment-button"
            label="Per Shipment"
          />
          <LinkButton
            href="/flexport/annual-policy"
            aria-details="annual policy button"
            variant="secondary"
            width="full"
            data-testid="annual-policy-button"
            label="Annual Policy"
          />

          {/**
           * We'd like to hide this option for the time being.
           * If re-enabled, don't forget to also enable the e2e test
           * in trade-credit-insurance-flow.cy.ts
           */}

          {/* <LinkButton
            href="/flexport/trade-credit-insurance"
            aria-details="trade credit insurance button"
            variant="secondary"
            width="full"
            data-testid="trade-credit-insurance-button"
            label="Trade Credit Insurance"
          /> */}
        </div>
      </div>
    </DTCFlowLayout>
  );
};
