import { useQuery } from '@tanstack/react-query';

import { useUser } from '../../../context/auth/auth-hooks.ts';
import {
  type CurrencyValue,
  type DefaultCurrency,
} from '../../../model/CurrencyValue.ts';
import type { ErrorResponse } from '../../../network/apis/types.ts';
import { get, type NetworkResponse } from '../../../network/apis/utils.ts';
import { bffServiceUrl } from '../../../network/netconfig.ts';

export const useStorageLocationConfiguration = () => {
  const currentUser = useUser();
  return useQuery<
    NetworkResponse<{
      policy_insured_value_limit: number;
      default_currency: CurrencyValue;
    }>,
    ErrorResponse,
    {
      default_currency: DefaultCurrency;
      policy_insured_value_limit: number;
    }
  >(
    ['storage-location-configuration'],
    () =>
      get(
        `${bffServiceUrl}/bff-storage-locations/${currentUser?.distributor.id}/configuration`,
      ),
    {
      enabled: !!currentUser?.distributor.id,
    },
  );
};
