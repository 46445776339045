import { useState } from 'react';
import {
  ErrorDialog,
  Link,
  Typography,
  useWindowSize,
} from '@breeze-ai/ui-library';

import { useReCAPTCHA } from '../../../../context/recaptcha/ReCaptchaProvider';
import { createDTCPolicy } from '../../../../network/apis/externals/externals';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import { useDTCFlowContext, useDTCStateAction } from '../../context/hooks';
import { useDTCNavigation } from '../../dtc-navigation';
import { QuoteSummary } from '../../shared/QuoteSummary/QuoteSummary';
import { CoverageDescriptions } from './CoverageDescriptions/CoverageDescriptions';
import { CustomerSummary } from './CustomerSummary/CustomerSummary';
import { PremiumBreakdown } from './PremiumBreakdown/PremiumBreakdown';
import { QuoteConfirmationDialog } from './QuoteConfirmationDialog/QuoteConfirmationDialog';
import styles from './ReviewPage.module.scss';

export const ReviewPage = () => {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [dialogVisible, setDialogVisibility] = useState<boolean>();
  const { customer, quote, brand } = useDTCFlowContext();
  const { setStep } = useDTCStateAction();
  const { verifyReCAPTCHA } = useReCAPTCHA();
  const { navigateToPreviousStep, getNextStep } = useDTCNavigation();
  const { isMobile } = useWindowSize();

  const { termsAndConditionsUrl } = brand;

  const onConfirmQuote = async () => {
    if (customer && quote) {
      setLoading(true);

      try {
        const reCaptchaResult = await verifyReCAPTCHA('confirmation_page');

        if (reCaptchaResult?.success) {
          await createDTCPolicy(customer.id, quote.id);
          setStep(getNextStep());
        } else {
          setError(true);
        }
      } catch (e) {
        reportException(e);
        setError(true);
      } finally {
        setLoading(false);
        setDialogVisibility(false);
      }
    }
  };

  if (!quote) {
    return null;
  }

  return (
    <>
      <div className={styles.reviewPageContent} data-testid="dtc-review-page">
        <div className={styles.gridLayout}>
          <div className={styles.leftColumn}>
            <CustomerSummary />
            <CoverageDescriptions />
          </div>
          <div className={styles.rightColumn}>
            <Typography level="h4">Summary</Typography>
            <hr />
            <QuoteSummary />
            <hr />
            <Typography block className={styles.termsLink}>
              See full policy{' '}
              <Link
                href={termsAndConditionsUrl}
                newTab
                underline={false}
                icon={false}
              >
                Terms and Conditions
              </Link>
            </Typography>
            <hr />
            {!isMobile && <PremiumBreakdown />}
          </div>
        </div>
        {isMobile ? (
          <div className={styles.stickyPremiumBreakdown} role="sticky-footer">
            <PremiumBreakdown />
            <NavigationFooter
              onNext={() => setDialogVisibility(true)}
              nextText="Confirm"
            />
          </div>
        ) : (
          <NavigationFooter
            onBack={navigateToPreviousStep}
            onNext={() => setDialogVisibility(true)}
            nextText="Confirm"
          />
        )}
        <QuoteConfirmationDialog
          visible={dialogVisible}
          onConfirm={onConfirmQuote}
          onClose={() => setDialogVisibility(false)}
          loading={loading}
        />
      </div>
      <ErrorDialog visible={error} onConfirm={() => setError(false)} />
    </>
  );
};
