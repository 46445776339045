import { Checkbox, Typography } from '@breeze-ai/ui-library';

import {
  type ShipmentSpecialCondition,
  type SpecialConditionOptionType,
} from '../../../../../model/Shipment';
import styles from './ShipmentSpecialConditionsSelector.module.scss';

type ShipmentSpecialConditionsSelectorProps = {
  options: SpecialConditionOptionType[];
  selected?: ShipmentSpecialCondition[];
  onChange: (newSelected: ShipmentSpecialCondition[]) => void;
};

export const ShipmentSpecialConditionsSelector = ({
  options,
  selected = [],
  onChange,
}: ShipmentSpecialConditionsSelectorProps) => {
  const handleChange = (
    option: SpecialConditionOptionType,
    checked: boolean,
  ) => {
    const rest = selected.filter((condition) => condition !== option.value);
    const newSelection = checked ? [...rest, option.value] : rest;
    onChange(newSelection);
  };

  const checkboxes = options.map((option: SpecialConditionOptionType) => {
    const { id, label, value } = option;
    const checked = !!selected.find((condition) => condition === value);
    const checkboxLabel = (
      <Typography className={styles.label}>{label}</Typography>
    );

    return (
      <div key={id} className={styles.checkboxItem}>
        <Checkbox
          onChange={(checked) => handleChange(option, checked)}
          label={checkboxLabel}
          checked={checked}
          role="special-condition-checkbox"
          data-value={value}
          data-testid={`special-condition-checkbox-${value}`}
        />
      </div>
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.checkboxList}>{checkboxes}</div>
    </div>
  );
};
