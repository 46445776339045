import {
  FormLayout,
  FormRow,
  Input,
  Select,
  type SelectOptionType,
  Typography,
} from '@breeze-ai/ui-library';

import { PlaceAutocomplete } from '../../../../components/PlaceAutocomplete/PlaceAutocomplete';
import {
  useDTCFlowContext,
  useDTCForms,
  useDTCStateAction,
} from '../../../context/hooks';
import sharedStyles from '../../../shared/dtc-styles.module.scss';
import { useDTCInputValidation } from '../../../shared/forms-validations';

type InsuredDetailsFormProps = {
  extended?: boolean;
};

export const InsuredDetailsForm = ({
  extended = false,
}: InsuredDetailsFormProps) => {
  const { configuration } = useDTCFlowContext();
  const { data, errors } = useDTCForms();
  const { setFormData, setFormError } = useDTCStateAction();
  const validateInput = useDTCInputValidation();

  const { years_in_business = [] } = configuration ?? {};
  const {
    companyName,
    companyAddress,
    companyDBA,
    companyIndustry,
    yearsInBusinessId,
    previousCreditInsurance,
  } = data;

  const yearsInBusinessOptions = years_in_business.map<
    SelectOptionType<number>
  >(({ title, id }) => ({
    value: id,
    payload: id,
    testId: `${id}`,
    label: `${title} years`,
  }));

  const creditInsuranceOptions: SelectOptionType<boolean>[] = [
    {
      value: 'no',
      payload: false,
      testId: 'no',
      label: 'No',
    },
    {
      value: 'yes',
      payload: true,
      testId: 'yes',
      label: 'Yes',
    },
  ];

  return (
    <div
      className={sharedStyles.sectionWrapper}
      data-testid="insured-details-form"
    >
      <Typography level="h4">Insured Details</Typography>
      <hr />
      <FormLayout>
        <FormRow className={sharedStyles.formRow}>
          <Input
            type="text"
            label="Insured Business Name"
            placeholder="Enter full name"
            value={companyName}
            onChange={({ target: { value } }) =>
              setFormData({ companyName: value })
            }
            required={true}
            validator={(value) => validateInput('companyName', value)}
            error={!!errors?.companyName}
            errorHelperText={errors?.companyName?.reason}
            testId="dtc-company-name-input"
          />
          {extended && (
            <Input
              type="text"
              label="Company DBA (if applicable)"
              placeholder="Enter full name"
              value={companyDBA}
              onChange={({ target: { value } }) =>
                setFormData({ companyDBA: value })
              }
              validator={(value) => validateInput('companyDBA', value)}
              error={!!errors?.companyDBA}
              errorHelperText={errors?.companyDBA?.reason}
              testId="dtc-company-dba-input"
            />
          )}
          <PlaceAutocomplete
            authApi={false}
            value={companyAddress}
            onChange={(companyAddress) => {
              setFormError({ field: 'companyAddress', error: false });
              setFormData({ companyAddress });
            }}
            showPlacesAttribution={true}
            inputProps={{
              label: 'Insured Address',
              placeholder: 'Enter full address',
              helperText: 'Only US locations are supported',
              validator: (value) => validateInput('companyAddress', value),
              error: !!errors?.companyAddress,
              errorHelperText: errors?.companyAddress?.reason,
              testId: 'dtc-company-address-autocomplete',
            }}
          />
        </FormRow>
        {extended && (
          <FormRow className={sharedStyles.formRow}>
            <Select<number>
              value={yearsInBusinessId}
              onChange={({ payload }) => {
                validateInput('yearsInBusinessId', `${payload}`);
                setFormData({ yearsInBusinessId: payload });
              }}
              options={yearsInBusinessOptions}
              label="Years in Business"
              placeholder="Select..."
              required={true}
              testId="dtc-years-in-business-input"
              validator={(value) => validateInput('yearsInBusinessId', value)}
              error={!!errors?.yearsInBusinessId}
              errorHelperText={errors?.yearsInBusinessId?.reason}
            />
            <Select<boolean>
              // @ts-expect-error Fix the type of previousCreditInsurance
              value={
                previousCreditInsurance
                  ? creditInsuranceOptions.find(
                      (option) => option.payload === previousCreditInsurance,
                    )?.value
                  : undefined
              }
              onChange={({ payload }) => {
                validateInput('previousCreditInsurance', `${payload}`);
                setFormData({ previousCreditInsurance: payload });
              }}
              options={creditInsuranceOptions}
              label="Have you ever carried credit insurance?"
              placeholder="Select..."
              required={true}
              testId="dtc-previous-credit-insurance-input"
              validator={(value) =>
                validateInput('previousCreditInsurance', value)
              }
              error={!!errors?.previousCreditInsurance}
              errorHelperText={errors?.previousCreditInsurance?.reason}
            />
            <Input
              type="text"
              label="Company Industry"
              placeholder="Enter company industry"
              value={companyIndustry}
              onChange={({ target: { value } }) =>
                setFormData({ companyIndustry: value })
              }
              required={true}
              validator={(value) => validateInput('companyIndustry', value)}
              error={!!errors?.companyIndustry}
              errorHelperText={errors?.companyIndustry?.reason}
              testId="dtc-company-industry-input"
            />
          </FormRow>
        )}
      </FormLayout>
    </div>
  );
};
