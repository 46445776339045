import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { type InvoiceDetails } from '../../../model/Invoice';
import { getInvoiceDetails } from '../../../network/apis/invoices/invoices';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';

type InvoiceRouteParams = {
  invoiceId: string;
};

export const useInvoiceDetails = (): InvoiceDetails | undefined => {
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetails>();
  const { invoiceId } = useParams<InvoiceRouteParams>();
  const { setError } = useSectionError();

  useEffect(() => {
    if (invoiceId && !invoiceDetails) {
      getInvoiceDetails(invoiceId)
        .then(setInvoiceDetails)
        .catch((e) => {
          reportException(e);
          setError(e);
        });
    }
  }, [invoiceDetails, invoiceId, setError]);

  return invoiceDetails;
};
