import {
  Link,
  Typography,
  UnderReview,
  useWindowSize,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useDTCFlowContext } from '../../context/hooks';
import { QuoteSummary } from '../../shared/QuoteSummary/QuoteSummary';
import styles from './ManualReviewPage.module.scss';

export const ManualReviewPage = () => {
  const { isMobile } = useWindowSize();
  const { brand } = useDTCFlowContext();
  const { homepageUrl, supportMail } = brand;

  return (
    <div
      className={styles.manualReviewPage}
      data-testid="dtc-manual-review-page"
    >
      <div className={styles.contentWrapper}>
        <div className={styles.illustration}>
          <UnderReview />
        </div>
        <div>
          <Typography level={isMobile ? 'h3' : 'h2'}>
            We've received your request and we're on it!
          </Typography>
          <Typography className={styles.message}>
            This request needs to be processed manually, due to some exceptions.
            A representative will be in touch with you by email within one
            business day.
          </Typography>
        </div>
        <Link href={homepageUrl} underline={false}>
          <Button variant="secondary" width="fixed" label="Back to Home Page" />
        </Link>
        <Typography block className={styles.supportMail}>
          Need help? Drop us an email{' '}
          <Link href={`mailto:${supportMail}`} underline={false}>
            {supportMail}
          </Link>
        </Typography>
      </div>
      <div className={styles.quoteSummary}>
        <Typography level="h4">Summary</Typography>
        <hr />
        <QuoteSummary />
      </div>
    </div>
  );
};
