import { useCallback } from 'react';

import { useUser } from '../../context/auth/auth-hooks';
import { sendSupportIssueEmail } from '../../network/apis/emails/emails';
import { reportException } from '../../utils/error-reporting/error-reporting';

export const useSendSupportIssueEmail = () => {
  const user = useUser();

  return useCallback(
    async (message: string): Promise<void> => {
      if (!user) {
        return;
      }

      try {
        await sendSupportIssueEmail({
          message,
          freight_forwarder: { name: user?.distributor?.company_name ?? '' },
        });
      } catch (e) {
        reportException(e);
      }
    },
    [user],
  );
};
