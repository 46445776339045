import { type Customer } from '../../../model/Customer';
import { type Distributor } from '../../../model/Distributor';
import { type OpenCover } from '../../../model/OpenCover';
import { type Place } from '../../../model/Place';
import { type Policy } from '../../../model/Policy';
import { type ClaimModel } from '../claims/types';
import { type QuoteModel } from '../quotes/types';
import { type ShipmentModel } from '../shipments/types';
import { type PaginationBaseResponse } from '../types';
import { type AccountUser } from '../users/types';

export type PoliciesSortableFields = 'id' | 'certificate_id' | 'created_at';
export type PoliciesErrorType =
  | 'PolicyNotFoundException'
  | 'ExcludedCargoOwnerAddress';

export enum PDFStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
}

export type PolicyModel = {
  id: number;
  open_cover: OpenCover;
  distributor: Distributor;
  quote_id: number;
  certificate_inception_date: ISODate;
  certificate_termination_date: ISODate;
  created_time: ISODate;
  issue_date: ISODate;
  certificate_id: string;
  customer_id?: number;
  shipment_id?: number;
  invoice_id?: number;
  terms_and_conditions_url?: string;
  open_cover_id?: number;
  quote?: QuoteModel;
  shipment?: ShipmentModel;
  customer?: Customer;
  external_reference?: string;
  certificate_url?: string;
  proof_of_cover_url?: string;
  certificate_status?: PDFStatus;
  proof_of_cover_status?: PDFStatus;
  certificate_created_time?: ISODate;
  proof_of_cover_created_time?: ISODate;
  created_by_user?: AccountUser;
  letter_of_credit?: string;
};

export type CreatePolicyRequest = {
  quote_id?: string;
  eta?: string;
  etd?: string;
  issue_date?: string;
  vessel_name?: string;
  external_reference?: string;
  container_ids?: string[];
  cargo_owner?: {
    name?: string;
    id?: string;
    place?: string | Place;
    phone_number?: string;
    email?: string;
  };
  incoterm_code?: string;
};

export type GetPoliciesResponse = PaginationBaseResponse & {
  policies: PolicyModel[];
};

export type GetPoliciesNormalizedResponse = Omit<
  GetPoliciesResponse,
  'policies'
> & {
  policies: Policy[];
};

export type GetPolicyClaimsResponse = {
  claims: ClaimModel[];
};
