import { useEffect, useState } from 'react';
import { ErrorDialog } from '@breeze-ai/ui-library';

import { useReCAPTCHA } from '../../../../context/recaptcha/ReCaptchaProvider';
import { fsIdentifyUser } from '../../../../frameworks/fullstory';
import { createDTCQuote } from '../../../../network/apis/externals/externals';
import {
  createDTCQuotePayload,
  normalizeDTCQuoteResponse,
} from '../../../../network/apis/externals/normalizers';
import { isTestEnv } from '../../../../utils/env';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import { LOCATION_TYPES_BY_CONVEYANCE } from '../../../constants';
import {
  useDTCFlowContext,
  useDTCForms,
  useDTCStateAction,
} from '../../context/hooks';
import { type DTCFormsData } from '../../context/types';
import { useDTCNavigation } from '../../dtc-navigation';
import { DTCFlowSteps } from '../../flows-steps-config';
import { useDTCFormValidation } from '../../shared/forms-validations';
import { CargoDetailsForm } from './CargoDetailsForm/CargoDetailsForm';
import { ShipmentDetailsForm } from './ShipmentDetailsForm/ShipmentDetailsForm';
import styles from './ShipmentDetailsPage.module.scss';

export const ShipmentDetailsPage = () => {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const { customer } = useDTCFlowContext();
  const { data: formsData } = useDTCForms();
  const { setStep, setQuote } = useDTCStateAction();
  const validatePageForms = useDTCFormValidation();
  const { verifyReCAPTCHA } = useReCAPTCHA();
  const { navigateToPreviousStep, getNextStep } = useDTCNavigation();

  useEffect(() => {
    customer && !isTestEnv && fsIdentifyUser(customer);
  }, [customer]);

  if (!customer) {
    return null;
  }

  const { primaryTransportMode } = formsData;
  const formsValid = validatePageForms([
    ...(LOCATION_TYPES_BY_CONVEYANCE[primaryTransportMode] === 'port'
      ? (['originPort', 'destinationPort'] as const)
      : (['originPlace', 'destinationPlace'] as const)),
    'etd',
    'eta',
    'containerModeId',
    'commodityValue',
    'commodityCategoryId',
    'commodityDescription',
  ]);

  const submitQuoteDetails = async () => {
    if (formsValid) {
      setLoading(true);
      const payload = createDTCQuotePayload(
        customer.id,
        formsData as Required<DTCFormsData>,
      );

      try {
        const reCaptchaResult = await verifyReCAPTCHA('shipment_details_page');

        if (reCaptchaResult?.success) {
          const quote = await createDTCQuote(payload);
          setQuote(normalizeDTCQuoteResponse(quote));
          setStep(
            quote.status === 'approved'
              ? getNextStep()
              : DTCFlowSteps.QUOTE_MANUAL_REVIEW,
          );
        } else {
          setError(true);
        }
      } catch (e) {
        reportException(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      className={styles.shipmentDetailsContent}
      data-testid="dtc-shipment-details-page"
    >
      <ShipmentDetailsForm />
      <CargoDetailsForm />
      <NavigationFooter
        onBack={navigateToPreviousStep}
        onNext={submitQuoteDetails}
        nextProps={{ isDisabled: !formsValid, isLoading: loading }}
      />
      <ErrorDialog visible={error} onConfirm={() => setError(false)} />
    </div>
  );
};
