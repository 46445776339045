import { Suspense } from 'react';
import {
  LinkButton,
  SectionLayout,
  Spinner,
} from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { useAuthenticatedUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { PlatformError } from '../../../router/PlatformError/PlatformError';
import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { CertificatesTable } from './CertificatesTable/CertificatesTable';

export const CertificatesPage = () => {
  useTracking();
  const isEnabled = useFeatureToggle('enable_certificates_page');
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  return isEnabled ? (
    <PlatformErrorBoundary>
      <Suspense fallback={<Spinner className="self-center" />}>
        <SectionLayout
          title="Certificates"
          tooltip={
            isWtw && <ActiveOpenCoverTooltip distributor={user.distributor} />
          }
          data-testid="certificates-page"
          actions={
            <LinkButton
              size="small"
              data-testid="get-certificate-button"
              href="/certificates/create"
              width="auto"
              label="Get a Certificate"
            />
          }
        >
          <CertificatesTable />
        </SectionLayout>
      </Suspense>
    </PlatformErrorBoundary>
  ) : (
    <PlatformError />
  );
};
