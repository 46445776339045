import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { type ErrorResponse } from '../../../network/apis/types';
import { type NetworkResponse, post, put } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';
import {
  type CreateStorageLocationPayload,
  type StorageLocationModel,
  type UpdateStorageLocationPayload,
} from '../types';

export const useCreateStorageLocation = ({
  policyId,
  options,
}: {
  policyId?: string;
  options?: UseMutationOptions<
    NetworkResponse<StorageLocationModel>,
    ErrorResponse<
      | 'StorageLocationAddressNotAllowed'
      | 'StorageLocationInsuredValueException'
    >,
    CreateStorageLocationPayload | UpdateStorageLocationPayload
  >;
}) => {
  return useMutation({
    mutationFn: (payload) =>
      policyId
        ? put(`${bffServiceUrl}/bff-storage-locations/${policyId}`, payload)
        : post(`${bffServiceUrl}/bff-storage-locations`, payload),
    ...options,
  });
};
