import { useEffect, useState } from 'react';
import { FormLayout, FormRow, Input, Typography } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useAppConfiguration } from '../../../../context/app-configuration/AppConfigurationProvider';
import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import { type ConveyanceType } from '../../../../model/Shipment';
import { useBlockNavigation } from '../../../../router/router-hooks';
import { trackPageView } from '../../../../utils/snowplow/utils';
import { CoverageTypeSelector } from '../../../components/CoverageTypeSelector/CoverageTypeSelector';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { PlaceAutocomplete } from '../../../components/PlaceAutocomplete/PlaceAutocomplete';
import { PortAutocomplete } from '../../../components/PortAutocomplete/PortAutocomplete';
import { LOCATION_TYPES_BY_CONVEYANCE } from '../../../constants';
import { QuoteUpdateConfirmationDialog } from '../../components/QuoteUpdateConfirmationDialog/QuoteUpdateConfirmationDialog';
import { CommodityTypeSelector } from '../../components/selectors/CommodityTypeSelector';
import { CommodityValueSelector } from '../../components/selectors/CommodityValueSelector';
import { ContainerModeSelector } from '../../components/selectors/ContainerModeSelector';
import { ConveyanceTypeSelector } from '../../components/selectors/ConveyanceTypeSelector/ConveyanceTypeSelector';
import { CustomerSelector } from '../../components/selectors/CustomerSelector';
import { DistributorSelector } from '../../components/selectors/DistributorSelector/DistributorSelector';
import { ShipmentSpecialConditionsSelector } from '../../components/selectors/ShipmentSpecialConditionsSelector/ShipmentSpecialConditionsSelector';
import { WizardTitle } from '../../components/WizardTitle/WizardTitle';
import { WizardSteps } from '../../constants';
import {
  useInputValidation,
  usePolicyWizardContext,
  useResetFormEdits,
  useSetFormData,
} from '../../hooks/context-hooks';
import { useQuoteForm } from '../../hooks/use-quote-form';
import { useSubmitQuoteForm } from '../../hooks/use-submit-quote-form';
import styles from './QuoteSubmissionForm.module.scss';

type ConfirmationDialogState = {
  visible: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const QuoteSubmissionForm = () => {
  const showCoverageTypeSelector = useFeatureToggle(
    'enable_coverage_package_selection',
  );

  const { step, quote } = usePolicyWizardContext();
  const user = useUser();
  const { currencies, commodities, container_modes, special_conditions } =
    useAppConfiguration();
  const { configuration, data, errors, isValid, isDirty } = useQuoteForm();
  const { distributor } = useUser() ?? {};
  const setFormData = useSetFormData('quote');
  const resetFormEdits = useResetFormEdits();
  const validateInput = useInputValidation();
  const { createQuote, updateQuote } = useSubmitQuoteForm();
  const [quoteUpdateDialog, setQuoteUpdateDialog] =
    useState<ConfirmationDialogState>();

  const { isBlocked, proceed, unblock } = useBlockNavigation(isDirty);
  const distributorSelectorEnabled =
    !!distributor?.parent || !!distributor?.children?.length;

  const showDutyCostSelector = useFeatureToggle('enable_shipments_duty_cost');

  useEffect(() => {
    step === WizardSteps.QUOTE_FORM &&
      trackPageView(
        {
          eventFeature: 'quote',
          eventSubfeature: 'form',
          eventAction: 'page_view',
        },
        user,
      );
  }, [user, step]);

  // This useEffect is causing the component to re-render
  useEffect(() => {
    setQuoteUpdateDialog({
      visible: isBlocked,
      onConfirm: proceed,
      onCancel: unblock,
    });
  }, [isBlocked, proceed, unblock]);

  if (!data || !configuration) {
    return null;
  }

  const {
    coverageType,
    transportMethod,
    distributorId,
    origin,
    destination,
    currency,
    commodity,
    freightCost,
    freightCostCurrency,
    dutyCost,
    dutyCostCurrency,
    customer,
    containerModeId,
    specialConditions,
    externalReference,
  } = data;

  const shipmentLocationType =
    transportMethod.primary &&
    LOCATION_TYPES_BY_CONVEYANCE[transportMethod.primary];

  return (
    <div
      data-testid="quote-submission-form"
      className={styles.quoteSubmissionFormWrapper}
    >
      <WizardTitle />
      <FormLayout className={styles.form}>
        {showCoverageTypeSelector && (
          <>
            <FormRow>
              <Typography level="h4">Coverage Type</Typography>
            </FormRow>
            <FormRow>
              <CoverageTypeSelector
                selected={coverageType}
                onChange={(coverageType) => setFormData({ coverageType })}
              />
            </FormRow>
            <hr />
          </>
        )}
        <FormRow>
          <Typography level="h4">Shipment Details</Typography>
        </FormRow>
        <FormRow align="space-between" className={styles.topRow}>
          <ConveyanceTypeSelector
            type="buttons"
            selected={transportMethod.primary}
            onChange={(conveyance) =>
              setFormData({
                transportMethod: { primary: conveyance as ConveyanceType },
                origin: null,
                destination: null,
              })
            }
            label="Transportation Mode"
            testId="conveyance-type-selector"
            // TODO required field should be set but does not cater for undefined initial value (when selecting shipment)
          />
          {distributorSelectorEnabled && (
            <DistributorSelector
              selectedDistributorId={distributorId}
              onChange={(distributorId) => setFormData({ distributorId })}
            />
          )}
        </FormRow>
        <FormRow>
          {shipmentLocationType === 'place' ? (
            <>
              <PlaceAutocomplete
                value={origin?.place}
                onChange={(place) => setFormData({ origin: { place } })}
                getOptionDisabled={({ provider_place_uuid }) =>
                  provider_place_uuid ===
                  destination?.place?.provider_place_uuid
                }
                inputProps={{
                  label: 'Origin',
                  placeholder: 'Origin address',
                  testId: 'location-selector-origin',
                }}
              />
              <PlaceAutocomplete
                value={destination?.place}
                onChange={(place) => setFormData({ destination: { place } })}
                getOptionDisabled={({ provider_place_uuid }) =>
                  provider_place_uuid === origin?.place?.provider_place_uuid
                }
                inputProps={{
                  label: 'Destination',
                  placeholder: 'Destination address',
                  testId: 'location-selector-destination',
                }}
              />
            </>
          ) : (
            <>
              <PortAutocomplete
                type={transportMethod.primary === 'sea' ? 'marine' : 'air'}
                value={origin?.port}
                onChange={(port) => setFormData({ origin: { port } })}
                getOptionDisabled={({ id }) => id === destination?.port?.id}
                inputProps={{
                  label: 'Origin',
                  placeholder:
                    transportMethod.primary === 'sea'
                      ? 'Origin port'
                      : 'Origin airport',
                  testId: 'location-selector-origin',
                }}
              />
              <PortAutocomplete
                type={transportMethod.primary === 'sea' ? 'marine' : 'air'}
                value={destination?.port}
                onChange={(port) => setFormData({ destination: { port } })}
                getOptionDisabled={({ id }) => id === origin?.port?.id}
                inputProps={{
                  label: 'Destination',
                  placeholder:
                    transportMethod.primary === 'sea'
                      ? 'Destination port'
                      : 'Destination airport',
                  testId: 'location-selector-destination',
                }}
              />
            </>
          )}
          <CommodityValueSelector
            value={commodity?.value}
            currency={currency}
            currencyOptions={currencies}
            onChange={(value) => setFormData({ commodity: { value } })}
            onCurrencyChange={(currency) => setFormData({ currency })}
            error={errors?.commodity?.value?.error}
            errorHelperText={errors?.commodity?.value?.reason}
            validator={(value) => validateInput('commodity.value', value)}
          />
        </FormRow>
        <FormRow>
          <CommodityTypeSelector
            value={commodity?.categoryId}
            options={commodities}
            onChange={(category, categoryId) =>
              setFormData({ commodity: { category, categoryId } })
            }
            error={errors?.commodity?.categoryId?.error}
            helperText={errors?.commodity?.categoryId?.reason}
            onBlur={(value) => validateInput('commodity.categoryId', value)}
          />
          <Input
            label="Commodity Description"
            placeholder="Describe the commodity"
            testId="commodity-description-input"
            required
            value={commodity?.description ?? ''}
            onChange={({ target: { value } }) =>
              setFormData({ commodity: { description: value } })
            }
            error={errors?.commodity?.description?.error}
            errorHelperText={errors?.commodity?.description?.reason}
            validator={(value) => validateInput('commodity.description', value)}
          />
          <CustomerSelector
            selectedCustomer={customer}
            onBlur={(option) => {
              validateInput(
                'customer.company_name',
                typeof option === 'string' ? option : option.company_name ?? '',
              );
            }}
            onChange={(option) => {
              typeof option === 'string'
                ? setFormData({ customer: { company_name: option } })
                : setFormData({ customer: option });
            }}
            error={errors?.customer?.company_name?.error}
            helperText={errors?.customer?.company_name?.reason}
          />
        </FormRow>
        <FormRow>
          <ConveyanceTypeSelector
            type="select"
            selected={transportMethod.secondary}
            onChange={(conveyance) =>
              setFormData({ transportMethod: { secondary: conveyance } })
            }
            label="Secondary Mode of Transport"
            testId="secondary-conveyance-type-selector"
          />
          <ContainerModeSelector
            options={
              transportMethod.primary
                ? container_modes[transportMethod.primary]
                : []
            }
            selectedContainerModeId={containerModeId}
            onChange={(id) => setFormData({ containerModeId: id })}
          />
          <CurrencyValueInput
            className={styles.input}
            type="number"
            label="Freight Cost"
            placeholder="Enter amount"
            value={freightCost}
            currency={freightCostCurrency}
            currencyOptions={currencies}
            onChange={(freightCost) => setFormData({ freightCost })}
            onCurrencyChange={(freightCostCurrency) =>
              setFormData({ freightCostCurrency })
            }
            required={configuration.isFreightCostRequired}
            error={errors?.freightCost?.error}
            errorHelperText={errors?.freightCost?.reason}
            validator={(value) =>
              validateInput('freightCost', value, {
                required: configuration.isFreightCostRequired,
              })
            }
            testId="freight-cost-input"
            inputDropdownProps={{ testId: 'freight-cost-currency-selector' }}
          />
        </FormRow>
        <FormRow className={styles.partialRow}>
          {showDutyCostSelector && (
            <CurrencyValueInput
              className={styles.input}
              type="number"
              label="Duty Cost"
              placeholder="Enter amount"
              value={dutyCost}
              currency={dutyCostCurrency}
              currencyOptions={currencies}
              onChange={(dutyCost) => setFormData({ dutyCost })}
              onCurrencyChange={(dutyCostCurrency) =>
                setFormData({ dutyCostCurrency })
              }
              error={errors?.dutyCost?.error}
              errorHelperText={errors?.dutyCost?.reason}
              validator={(value) => validateInput('dutyCost', value)}
              testId="duty-cost-input"
              inputDropdownProps={{ testId: 'duty-cost-currency-selector' }}
            />
          )}
          <Input
            label="Booking Reference"
            placeholder="Your internal reference"
            testId="booking-reference-input"
            defaultValue={externalReference}
            onChange={({ target: { value } }) =>
              setFormData({ externalReference: value })
            }
          />
        </FormRow>

        <FormRow className={styles.specialConditions}>
          <Typography level="h5">Special Conditions</Typography>
        </FormRow>
        <FormRow>
          <ShipmentSpecialConditionsSelector
            options={special_conditions}
            selected={specialConditions}
            onChange={(selection) =>
              setFormData({ specialConditions: selection })
            }
          />
        </FormRow>
        <FormRow align="end" gutter={12} className={styles.formButtons}>
          {isDirty && (
            <Button
              data-testid="cancel-changes-button"
              variant="ghost"
              width="auto"
              onPress={() =>
                setQuoteUpdateDialog({
                  visible: true,
                  onConfirm: resetFormEdits,
                })
              }
            >
              Cancel Changes
            </Button>
          )}
          <Button
            width="auto"
            customStyles="rounded-[40px]"
            size="small"
            data-testid="quote-submit-button"
            onPress={quote && isDirty ? updateQuote : createQuote}
            isDisabled={
              quote
                ? !isValid || !isDirty
                : !isValid || step === WizardSteps.LOADING
            }
            label={quote ? 'Save Changes' : 'Get a Quote'}
          />
        </FormRow>
      </FormLayout>
      <QuoteUpdateConfirmationDialog
        visible={quoteUpdateDialog?.visible}
        onCancel={() => {
          const { onCancel } = quoteUpdateDialog ?? {};
          setQuoteUpdateDialog({ visible: false });
          onCancel?.();
        }}
        onConfirm={() => {
          const { onConfirm } = quoteUpdateDialog ?? {};
          setQuoteUpdateDialog({ visible: false });
          onConfirm?.();
        }}
      />
    </div>
  );
};
