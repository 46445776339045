import { Link, type LinkProps } from 'react-router-dom';

import { Typography } from '../Typography/Typography';

export type FooterProps = {
  links: { label: string; url: string; linkProps?: LinkProps }[];
};

export function Footer({ links }: FooterProps) {
  return (
    <footer className="flex w-full items-center justify-end flex-grow px-16 py-7 max-h-8 bg-surfaces-secondary">
      <div className="flex flex-row gap-4">
        {links.map(({ label, linkProps, url }) => (
          <Link
            {...linkProps}
            key={label}
            className="cursor-pointer"
            to={url}
            target="_blank"
          >
            <Typography level="subtext" color="tertiary">
              {label}
            </Typography>
          </Link>
        ))}
      </div>
    </footer>
  );
}
