import React from 'react';
import { clsxMerge } from '@breezeai-frontend/cargo-ui';

interface CapacityMeterProps {
  value: number;
  valueFixedWidth?: boolean;
}

function percentageToColor(value: number) {
  switch (true) {
    case value <= 60:
      return 'bg-[#7F35B2]';
    case value <= 90:
      return 'bg-system-orange-500';
    default:
      return 'bg-system-red-500';
  }
}

export const CapacityMeter: React.FC<CapacityMeterProps> = ({
  value,
  valueFixedWidth = false,
}) => {
  const roundedValue = parseFloat(value.toFixed(2));
  return (
    <div className="flex items-center gap-2 w-full">
      <span className={valueFixedWidth ? 'w-[52px]' : ''}>{roundedValue}%</span>
      <div className="flex items-center gap-0.5 px-0.5 h-2.5 rounded-sm w-[84px] border-rounded border-2 border-field-border-default">
        <div
          style={{ width: `${roundedValue}%` }}
          className={clsxMerge(
            'h-1 rounded-sm',
            percentageToColor(roundedValue),
          )}
          aria-label="capacity-meter"
        />
        {roundedValue > 100 && (
          <div
            className={'h-1 rounded-sm w-4 bg-system-red-700'}
            aria-label="capacity-meter-overflow"
          />
        )}
      </div>
    </div>
  );
};
