import { cloneDeep, isEqual, isMatchWith, mergeWith, sortBy } from 'lodash';
import { type PartialDeep } from 'type-fest/source/partial-deep';

import { type PolicyWizardFormsData } from './types';

export const consolidateFormData = (
  current: PolicyWizardFormsData | undefined,
  payload: PartialDeep<PolicyWizardFormsData>,
): PolicyWizardFormsData => {
  return mergeWith(cloneDeep(current), payload, (_a, b) => {
    // if the new value is undefined, set the merged result to 'null'
    // to override the current state with an empty value.
    if (b === undefined) {
      return null;
    }

    // if the new value is an array, override the current state with it (no merge).
    if (Array.isArray(b)) {
      return b;
    }

    // Merge current state value and new value regularly.
    return undefined;
  });
};

export const checkFormStateIsDirty = (
  data?: PolicyWizardFormsData,
  updates?: PolicyWizardFormsData,
): boolean => {
  return data && updates
    ? !isMatchWith(data, updates, (a, b) => {
        if (Array.isArray(a) && Array.isArray(b)) {
          return a.length === b.length && isEqual(sortBy(a), sortBy(b));
        }
        return;
      })
    : false;
};
