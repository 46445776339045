import { useQuery } from '@tanstack/react-query';

import { bffServiceUrl } from '../../netconfig';
import { get } from '../utils';
import { normalizeShipmentResponse } from './normalizers';
import { type ShipmentModel } from './types';

export const useShipment = ({
  shipmentId,
}: {
  shipmentId: string | undefined;
}) => {
  return useQuery(
    ['shipments', shipmentId],
    () => get<ShipmentModel>(`${bffServiceUrl}/bff_shipments/${shipmentId}`),
    {
      suspense: true,
      enabled: !!shipmentId,
      select: normalizeShipmentResponse,
    },
  );
};
