import { useCallback } from 'react';

import { useUser, useUserSetting } from '../../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../../model/CurrencyValue';
import { normalizeQuoteResponse } from '../../../network/apis/quotes/normalizers';
import * as quotesApi from '../../../network/apis/quotes/quotes';
import { type QuotesErrorType } from '../../../network/apis/quotes/types';
import {
  type BaseErrorType,
  type ErrorResponse,
} from '../../../network/apis/types';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { WIZARD_ERROR_MESSAGES, WizardSteps } from '../constants';
import { type WizardError } from '../context/types';
import {
  usePolicyWizardContext,
  useSetQuoteData,
  useSetWizardError,
  useSetWizardStep,
} from './context-hooks';

const getErrorDetails = (
  errorType: QuotesErrorType | BaseErrorType,
  retry?: () => void,
): WizardError => {
  switch (errorType) {
    case 'QuoteNotUpdatableException':
      return {
        title: 'Policy Already Exists',
        message: WIZARD_ERROR_MESSAGES.quoteNotUpdatable,
        fallbackStep: WizardSteps.QUOTE_SUMMARY,
      };

    default:
    case 'GenericException':
      return {
        message: WIZARD_ERROR_MESSAGES.updateQuote,
        confirmText: 'Try again',
        fallbackStep: WizardSteps.QUOTE_SUMMARY,
        retry,
      };
  }
};

export const useSubmitQuoteForm = () => {
  const user = useUser();
  const { forms, quote } = usePolicyWizardContext();
  const setWizardStep = useSetWizardStep();
  const setWizardError = useSetWizardError();
  const setQuoteData = useSetQuoteData();
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  const createQuote = useCallback(async () => {
    if (user && forms.data) {
      setWizardStep(WizardSteps.LOADING);

      try {
        const quote = await quotesApi.createQuote(forms.data);
        setQuoteData(normalizeQuoteResponse(quote));
        setWizardStep(WizardSteps.QUOTE_SUMMARY);
      } catch (error) {
        reportException(error);
        setWizardError({
          fallbackStep: WizardSteps.QUOTE_FORM,
          message: WIZARD_ERROR_MESSAGES.createQuote,
        });
      }
    }
  }, [user, forms.data, setWizardStep, setQuoteData, setWizardError]);

  const updateQuote = useCallback(async () => {
    if (quote && forms.updates) {
      setWizardStep(WizardSteps.LOADING);
      let updates = forms.updates;
      // If the user has not selected a currency for the commodity, send the default currency as part of the updated payload
      if (!forms.updates?.currency && !quote.commodity_currency) {
        updates = {
          ...forms.updates,
          currency: defaultCurrency,
        };
      }

      try {
        const updatedQuote = await quotesApi.updateQuote(quote.id, updates);
        setQuoteData(normalizeQuoteResponse(updatedQuote));
        setWizardStep(WizardSteps.INIT); // re-trigger wizard initialization with the updated state
      } catch (error) {
        reportException(error);
        const { response } = error as ErrorResponse<QuotesErrorType>;
        const { error_type } = response.data;
        const wizardError = getErrorDetails(error_type, updateQuote);

        setWizardError(wizardError);
      }
    }
  }, [
    forms.updates,
    quote,
    setQuoteData,
    setWizardError,
    setWizardStep,
    defaultCurrency,
  ]);

  return { createQuote, updateQuote };
};
