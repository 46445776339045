import { useTracking } from '../utils/snowplow/hooks';
import { PlatformError } from './PlatformError/PlatformError';
import { useAppNavigation } from './use-app-navigation';

export const PageNotFound = () => {
  useTracking();

  const { navigateToSignIn } = useAppNavigation();

  return (
    <PlatformError
      code={404}
      actions={[
        {
          level: 'secondary',
          children: 'Back to home',
          role: 'back-navigation-button',
          onClick: () => navigateToSignIn(),
        },
      ]}
    />
  );
};
