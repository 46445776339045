import { useMemo } from 'react';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Claim } from '../../../model/Claim';
import { ClaimStatusBadge } from '../ClaimStatusBadge/ClaimStatusBadge';

export const useClaimsTableColumns = (): TableColumn<Claim>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const certificatesEnabled = useFeatureToggle('enable_certificates_page');

  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition<Claim>('claim_number', 'Claim Number'),
        minWidth: 150,
        valueGetter: ({ row }) => row.claim_number,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>(
          'policy_certificate_id',
          `${certificatesEnabled ? 'Certificate' : 'Policy'} Number`,
        ),
        minWidth: 190,
        valueGetter: ({ row }) => row?.policy?.certificate_id,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>('reported_time', 'Submission Date'),
        minWidth: 180,
        type: 'date',
        valueGetter: ({ row }) => row.reported_time,
        renderCell: ({ row }) => (
          <DateCell
            format="SHORT_MONTH_NAME_DATE"
            value={row.reported_time}
            createdBy={
              row.created_by_user && {
                name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
                avatarColor: row.created_by_user.avatar_color,
              }
            }
          />
        ),
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>(
          'customer_name',
          `${certificatesEnabled ? 'Named Assured' : 'Cargo Owner'}`,
        ),
        valueGetter: ({ row: { policy } }) => policy?.customer?.name,
        minWidth: 180,

        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>('commodity_value', 'Cargo Value'),
        minWidth: 150,
        valueGetter: ({ row }) => row?.policy?.quote?.commodity_value,
        renderCell: ({ row: { policy } }) => (
          <CurrencyCell
            value={policy?.quote?.commodity_value}
            currency={policy?.quote?.commodity_currency}
          />
        ),

        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>('claimed_amount', 'Claimed Amount'),
        type: 'number',
        minWidth: 180,
        valueGetter: ({ row }) => row.total_amount,
        renderCell: ({ row }) => (
          <CurrencyCell value={row.total_amount} currency={row.currency} />
        ),

        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>('paid_amount', 'Paid Amount'),
        type: 'number',
        minWidth: 150,
        valueGetter: ({ row }) => row.paid_amount,
        renderCell: ({ row }) => (
          <CurrencyCell value={row.paid_amount} currency={row.currency} />
        ),

        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition<Claim>('status', 'Status'),
        valueGetter: ({ row }) => row.status,
        renderCell: ({ row }) => <ClaimStatusBadge claim={row} />,
        sortable: sortingEnabled,
      },
    ],
    [sortingEnabled, certificatesEnabled],
  );
};
