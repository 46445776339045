import {
  FaCalendarAlt,
  FaCoins,
  FaInfoCircle,
  FaPlane,
  FaShip,
  FaTrain,
  FaTruck,
} from 'react-icons/fa';
import {
  FaFileLines,
  FaLocationDot,
  FaMoneyBills,
  FaShirt,
  FaUsers,
} from 'react-icons/fa6';
import {
  Currency,
  DateCell,
  Typography as BreezeUITypography,
} from '@breeze-ai/ui-library';
import {
  Button,
  clsxMerge,
  Tooltip,
  TooltipTriggerWrapper,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { toPercentage } from '@breezeai-frontend/cargo-ui';

import { getTheme } from '../../../../utils/getTheme';
import { InfoItem } from '../../../components/InfoItem/InfoItem';
import { PageSection } from '../../../components/PageSection/PageSection';
import { getPlaceModelDisplayValue } from '../../../utils/places';
import { getPortShortDisplayValue } from '../../../utils/ports';
import { useCertificate } from '../../certificateApiHooks';

type CertificateShipmentDetailsProps = {
  className?: string;
  policyId?: string;
};

export const gridClass = 'grid lg:grid-cols-4 sm:grid-cols-2 gap-y-12';
const isWtw = getTheme() === 'wtw';

export const CertificateShipmentDetails = ({
  className,
  policyId,
}: CertificateShipmentDetailsProps) => {
  const { data: certificate } = useCertificate({ policyId });

  const getTransportModeIcon = (mode?: string) => {
    switch (mode) {
      case 'sea':
        return FaShip;
      case 'air':
        return FaPlane;
      case 'road':
        return FaTruck;
      case 'rail':
        return FaTrain;
      default:
        return FaLocationDot;
    }
  };

  const {
    origin_place,
    origin_port,
    destination_place,
    destination_port,
    etd,
    eta,
    primary_transport_mode_code,
    loading_place,
    loading_transport_mode_code,
    delivery_place,
    delivery_transport_mode_code,
    vessel_name,
    goods_marks,
    commodity_external_description,
    commodity_currency,
    external_reference,
    value_insured,
    commodity_value,
    letter_of_credit,
    uplift_percentage,
  } = certificate?.quote ?? {};

  const insuredValueTooltipContent = `Insured Value is ${
    isWtw ? 'Commercial Invoice Value' : 'Cargo Value & Freight Cost'
  } + ${toPercentage(uplift_percentage ?? 0.1)}%`;

  const originPortValue = origin_port
    ? getPortShortDisplayValue(origin_port)
    : undefined;
  const originPlaceValue = origin_place
    ? getPlaceModelDisplayValue(origin_place)
    : undefined;
  const originValue = originPortValue || originPlaceValue;

  const destinationPortValue = destination_port
    ? getPortShortDisplayValue(destination_port)
    : undefined;
  const destinationPlaceValue = destination_place
    ? getPlaceModelDisplayValue(destination_place)
    : undefined;
  const destinationValue = destinationPortValue || destinationPlaceValue;

  return (
    <PageSection
      title="Shipment details"
      actions={
        external_reference && (
          <span role="booking-reference" className="flex flex-row gap-1">
            <Typography level="base" color="tertiary">
              Reference:
            </Typography>
            <Typography level="h5">{external_reference}</Typography>
          </span>
        )
      }
      className={clsxMerge(
        'w-full bg-system-grey-white box-border rounded-3xl p-6 flex flex-col',
        className,
      )}
      data-testid="shipment-details"
    >
      <div className={gridClass}>
        <InfoItem
          title="Place of Loading"
          value={
            loading_place ? getPlaceModelDisplayValue(loading_place) : undefined
          }
          testId="place-of-loading"
          Icon={getTransportModeIcon(loading_transport_mode_code)}
        />
        <InfoItem
          title="Origin"
          value={originValue}
          testId="origin"
          Icon={getTransportModeIcon(primary_transport_mode_code)}
        />
        <InfoItem
          title="Destination"
          value={destinationValue}
          testId="destination"
          Icon={getTransportModeIcon(primary_transport_mode_code)}
        />

        <InfoItem
          title="Place of discharge"
          value={
            delivery_place
              ? getPlaceModelDisplayValue(delivery_place)
              : undefined
          }
          testId="final-destination"
          Icon={getTransportModeIcon(delivery_transport_mode_code)}
        />

        <InfoItem
          className="col-start-1"
          title="Date of departure"
          value={
            <DateCell
              value={etd as ISODate}
              typographyProps={{ level: 'h5', bold: true }}
            />
          }
          testId="etd"
          Icon={FaCalendarAlt}
        />
        <InfoItem
          title="Date of arrival"
          value={
            <DateCell
              value={eta as ISODate}
              typographyProps={{ level: 'h5', bold: true }}
            />
          }
          testId="eta"
          Icon={FaCalendarAlt}
        />
        <InfoItem
          title="Commercial Invoice Value"
          value={
            commodity_value &&
            commodity_currency && (
              <span>
                <Currency
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={commodity_value}
                  currency={commodity_currency.code}
                  typographyProps={{ level: 'h5', bold: true, ellipsis: true }}
                />
              </span>
            )
          }
          Icon={FaCoins}
          testId="cargo-value"
        />
        <InfoItem
          title={
            <div className="flex flex-row items-center pt-1">
              <BreezeUITypography
                variant="input"
                level="subtext"
                capitalize={true}
                ellipsis={true}
              >
                Insured Value
              </BreezeUITypography>
              <TooltipTriggerWrapper delay={0}>
                <Button
                  variant="icon"
                  customStyles="p-0 ml-1 h-min text-icons-default"
                >
                  <FaInfoCircle />
                </Button>
                <Tooltip placement="top">{insuredValueTooltipContent}</Tooltip>
              </TooltipTriggerWrapper>
            </div>
          }
          value={
            value_insured &&
            commodity_currency && (
              <span>
                <Currency
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={value_insured}
                  currency={commodity_currency.code}
                  typographyProps={{ level: 'h5', bold: true, ellipsis: true }}
                />
              </span>
            )
          }
          Icon={FaMoneyBills}
          testId="insured-value"
        />
        <InfoItem
          title="Description of cargo"
          value={commodity_external_description}
          Icon={FaShirt}
          testId="goods-description"
        />
        <InfoItem
          title="Marks & Numbers"
          value={goods_marks}
          Icon={FaShirt}
          testId="marks-and-numbers"
        />
        <InfoItem
          title="Vessel Name"
          value={vessel_name}
          Icon={FaUsers}
          testId="vessel-name"
        />
      </div>
      {letter_of_credit && (
        <div className="w-1/2 mt-8">
          <InfoItem
            title="Letter of Credit"
            value={letter_of_credit}
            Icon={FaFileLines}
            testId="shipment-letter-of-credit"
          />
        </div>
      )}
    </PageSection>
  );
};
