import { type ComponentProps } from 'react';
import {
  type Location,
  Navigate,
  type Params,
  type To,
  useLocation,
} from 'react-router-dom';

import { useRouteParams } from './router-hooks';

type ToFunction = (route: {
  params: Params<string>;
  location: Location;
  searchParams: Params<string>;
}) => To;

type NavigateWithRouteProps = Omit<ComponentProps<typeof Navigate>, 'to'> & {
  to: ToFunction | ComponentProps<typeof Navigate>['to'];
};

/**
 * Utility component for passing route information into Navigate
 * component for "stateful" redirect based on the source route.
 */
export const NavigateWithRoute = ({ to, ...props }: NavigateWithRouteProps) => {
  const location = useLocation();
  const { params, searchParams } = useRouteParams();
  const targetPath: To =
    typeof to === 'function' ? to({ params, searchParams, location }) : to;

  return <Navigate to={targetPath} {...props} />;
};
