import { Typography } from '@breezeai-frontend/cargo-ui';

import { useRouteParams } from '../../../../router/router-hooks';
import { PageSection } from '../../../components/PageSection/PageSection';
import { useCertificate } from '../../certificateApiHooks';
import { type CertificatesRouteParams } from '../../types';

export function CertificateAdditionalClauses() {
  const { params } = useRouteParams<CertificatesRouteParams>();
  const { policyId } = params;

  const { data: certificate } = useCertificate({ policyId });

  const additionalClauses = certificate?.quote?.additional_clauses;

  if (!additionalClauses) {
    return null;
  }

  return (
    <PageSection>
      <div className="flex flex-col gap-1.5">
        <Typography level="h3">Additional clauses</Typography>
        <p className="text-text-secondary text-sm leading-3 text-left">
          {additionalClauses}
        </p>
      </div>
    </PageSection>
  );
}
