import { FaCheckCircle, FaPlus } from 'react-icons/fa';

import { LinkButton } from '../../../../components/Button/LinkButton';
import { Typography } from '../../../../components/Typography/Typography';
import { Modal, type ModalProps } from '../../../../layouts/Modal/Modal';

export function CreateCertificateModal({
  onExit,
  showActionButton = false,
  title = 'Your certificate is ready!',
  description = 'You can now view your certificate!',
  ...props
}: {
  onExit: () => void;
  showActionButton?: boolean;
  title?: string;
  description?: string;
} & Omit<ModalProps, 'children'>) {
  return (
    <Modal
      {...props}
      onExit={onExit}
      showExitButton
      isDismissable
      className="max-w-xl"
      heading={
        <div className="flex items-center flex-col py-3 space-y-3">
          <>
            <FaCheckCircle size={42} className="fill-system-green-500" />
            <Typography level="h2" customStyles="text-center">
              {title}
            </Typography>
            <Typography color="secondary" customStyles="text-center w-[308px]">
              {description}
            </Typography>
          </>
        </div>
      }
    >
      {showActionButton && (
        <div className="flex flex-row gap-3 py-6 w-full justify-center">
          <LinkButton
            variant="ghost"
            leftIcon={<FaPlus />}
            size="medium"
            width="full"
            href="/certificates/create"
            onPress={() => {
              onExit && onExit();
            }}
            label="Create new Certificate"
          />
        </div>
      )}
    </Modal>
  );
}
