import { type Step, Steps } from '@breeze-ai/ui-library';

import { claimCreationFlowSteps } from '../../constants';
import { useClaimCreationFlowContext } from '../context/hooks';

export const ClaimCreationStepsIndicator = () => {
  const { step } = useClaimCreationFlowContext();

  const steps = claimCreationFlowSteps.map<Step>(({ title, step }) => ({
    id: step,
    name: title,
  }));

  return (
    <div className="flex justify-center bg-gradient-to-b from-surfaces-secondary from-80% to-transparent">
      <div className="w-[450px]">
        <Steps steps={steps} currentStep={step} testId="steps-indicator" />
      </div>
    </div>
  );
};
