import { type ReactNode } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import styles from './SectionLayout.module.scss';

type MainPageLayoutProps = {
  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  testId?: string;
  className?: string;
  contentClassName?: string;
};

export const SectionLayout = ({
  title,
  actions,
  children,
  testId,
  className,
  contentClassName,
}: MainPageLayoutProps) => {
  return (
    <div
      className={classnames(styles.sectionLayoutWrapper, className)}
      data-testid={testId}
    >
      <div className={styles.titleBar}>
        <Typography level="h2">{title}</Typography>
        <div className={styles.actions}>{actions}</div>
      </div>
      <div className={classnames(styles.content, contentClassName)}>
        {children}
      </div>
    </div>
  );
};
