export function DarkMinified() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="19"
      viewBox="0 0 25 19"
      fill="none"
    >
      <path
        d="M21.7352 18.7927C23.246 18.7927 24.4707 17.568 24.4707 16.0572C24.4707 14.5465 23.246 13.3217 21.7352 13.3217C20.2245 13.3217 18.9998 14.5465 18.9998 16.0572C18.9998 17.568 20.2245 18.7927 21.7352 18.7927Z"
        fill="#47CBE3"
      />
      <path
        d="M14.3933 11.0924C13.7363 10.3224 12.724 9.85394 11.3724 9.67086V9.64932C12.584 9.46624 13.4833 9.01392 14.0702 8.28159C14.6625 7.54925 14.9533 6.68769 14.9533 5.68612C14.9533 4.15145 14.4364 2.97757 13.4025 2.1537C12.3686 1.33521 10.7639 0.925964 8.58849 0.925964H-0.000244141V18.5827H8.98697C11.114 18.5827 12.7079 18.1734 13.7794 17.3496C14.8672 16.5149 15.411 15.3357 15.411 13.8225C15.411 12.751 15.0718 11.8409 14.3933 11.0924ZM4.44221 4.29684H7.78616C9.42852 4.29684 10.247 4.93763 10.247 6.2246C10.247 7.51156 9.42852 8.14697 7.78616 8.14697H4.44221V4.29684ZM8.21156 15.3464H4.44221V11.4371H8.21156C9.87007 11.4371 10.6993 12.0832 10.6993 13.3648C10.6993 14.6464 9.87007 15.3464 8.21156 15.3464Z"
        fill="#1A1C28"
      />
    </svg>
  );
}
