import { Typography, useWindowSize } from '@breeze-ai/ui-library';

import { useDTCFlowContext } from '../../../context/hooks';
import styles from './CustomerSummary.module.scss';

export const CustomerSummary = () => {
  const { customer } = useDTCFlowContext();
  const { isMobile } = useWindowSize();

  if (!customer) {
    return null;
  }

  const { company_name, address_line1, contact_person, email, phone } =
    customer;

  return (
    <div className={styles.customerSummary} data-testid="dtc-customer-summary">
      <div className={styles.insuredDetailsSummary}>
        <Typography level={isMobile ? 'h3' : 'h2'} className={styles.title}>
          Insured Details
        </Typography>
        <Typography>{company_name}</Typography>
        <Typography>{address_line1}</Typography>
      </div>
      <div className={styles.contactDetailsSummary}>
        <Typography level={isMobile ? 'h3' : 'h2'} className={styles.title}>
          Contact Details
        </Typography>
        <Typography>{contact_person}</Typography>
        <Typography>{email}</Typography>
        <Typography>{phone}</Typography>
      </div>
    </div>
  );
};
