import { useEffect } from 'react';
import { isValidDate } from '@breeze-ai/ui-library';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import {
  FilterableFields,
  FilterOperators,
} from '../../../../network/apis/types';
import {
  type HandleCustomDateRangeChangeProps,
  RadioDropdown,
} from '../components/RadioDropdown';
import { useFilterStore } from '../useTableFilters';

const getUpperDateRange = (option: string) => {
  switch (option) {
    case 'today':
      return moment().format('DD/MM/YYYY');
    case 'last_week':
      return moment().subtract(1, 'weeks').format('DD/MM/YYYY');
    case 'last_month':
      return moment().subtract(1, 'months').format('DD/MM/YYYY');
    default:
      return moment().subtract(1000, 'years').format('DD/MM/YYYY');
  }
};

interface CreatedOnFilterProps {
  triggerTestId?: string;
  contentTestId?: string;
  name?: string;
  label?: string;
  dropdownIcon?: IconName;
}

export function CreatedOnFilter({
  triggerTestId = 'created-on-filter-trigger',
  contentTestId = 'created-on-filter-content',
  name = 'createdOn',
  label = 'Created on',
  dropdownIcon = 'clock',
}: CreatedOnFilterProps) {
  const {
    setSelectedTime,
    updateFilter,
    selectedTime,
    setCustomDateRange,
    customDateRange,
    removeFilter,
  } = useFilterStore((state) => ({
    setSelectedTime: state.setSelectedTime,
    updateFilter: state.updateFilter,
    selectedTime: state.selectedTime,
    setCustomDateRange: state.setCustomDateRange,
    customDateRange: state.customDateRange,
    removeFilter: state.removeFilter,
  }));

  const onSelect = (option: string) => {
    setSelectedTime(option);
    if (option === 'custom_dates') {
      return;
    }

    updateFilter({
      field: FilterableFields.CREATED_AT,
      operator: FilterOperators.BETWEEN,
      values: [option, moment().format('DD/MM/YYYY')],
    });
  };

  const handleCustomDateRangeChange = ({
    type,
    value,
  }: HandleCustomDateRangeChangeProps) => {
    if (!isValidDate(value)) return;
    if (type === 'start') {
      setCustomDateRange({
        startDate: value.format('DD/MM/YYYY'),
        endDate: customDateRange?.endDate,
      });
    }

    if (type === 'end') {
      setCustomDateRange({
        startDate: customDateRange?.startDate,
        endDate: value.format('DD/MM/YYYY'),
      });
    }
  };

  useEffect(() => {
    if (
      customDateRange?.startDate &&
      customDateRange?.endDate &&
      selectedTime === 'custom_dates'
    ) {
      updateFilter({
        field: FilterableFields.CREATED_AT,
        operator: FilterOperators.BETWEEN,
        values: [customDateRange?.startDate, customDateRange?.endDate],
      });
    }
  }, [customDateRange, updateFilter, selectedTime]);

  return (
    <RadioDropdown
      triggerTestId={triggerTestId}
      contentTestId={contentTestId}
      name={name}
      label={label}
      dropdownIcon={dropdownIcon}
      options={[
        { label: 'Today', value: getUpperDateRange('today') },
        {
          label: 'Last week',
          value: getUpperDateRange('last_week'),
        },
        {
          label: 'Last month',
          value: getUpperDateRange('last_month'),
        },
        { label: 'Custom dates', value: 'custom_dates' },
      ]}
      onSelect={onSelect}
      selected={selectedTime}
      setSelected={setSelectedTime}
      onReset={() => {
        setSelectedTime(undefined);
        setCustomDateRange({
          startDate: undefined,
          endDate: undefined,
        });
        removeFilter(FilterableFields.CREATED_AT);
      }}
      customDateRange={
        selectedTime === 'custom_dates'
          ? {
              onCustomDateChange: handleCustomDateRangeChange,
              minEndDate: customDateRange?.startDate,
              maxStartDate: customDateRange?.endDate,
            }
          : undefined
      }
    />
  );
}
