import { type ConveyanceType } from '../../../model/Shipment';

export const getCarrierFieldLabel = (primaryMot?: ConveyanceType) => {
  switch (primaryMot) {
    case 'air': {
      return 'Flight Number';
    }
    case 'road':
    case 'rail': {
      return 'Carrier Name';
    }
    case 'sea':
    default: {
      return 'Vessel Name';
    }
  }
};
