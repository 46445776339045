import { forwardRef } from 'react';
import {
  composeRenderProps,
  FieldError as RacFieldError,
  type FieldErrorProps as RACFieldErrorProps,
  Group,
  type GroupProps,
  Input as RACInput,
  type InputProps as RacInputProps,
  Label as RACLabel,
  type LabelProps,
  TextArea as RacTextArea,
  type TextAreaProps as RacTextAreaProps,
} from 'react-aria-components';
import { FaExclamationCircle } from 'react-icons/fa';

import { Typography } from '../..';
import { tv } from '../../utils/customTv';
import { clsxMerge } from '../common/utils/classNameUtils';
import { type TypographyProps } from '../Typography/Typography';

export const fieldStyles = tv({
  base: 'w-full flex flex-col text-base',
  variants: {
    isInvalid: {
      true: 'relative',
    },
  },
});

export function Label({
  isRequired,
  ...props
}: LabelProps & { isRequired?: boolean }) {
  return (
    <RACLabel
      {...props}
      className={clsxMerge(
        'text-sm cursor-default w-fit whitespace-nowrap',
        props.className,
      )}
    >
      {props.children}
      {isRequired && <span className="text-field-border-error ml-0.5">*</span>}
    </RACLabel>
  );
}

export function Description(props: TypographyProps) {
  return (
    <Typography
      {...props}
      slot="description"
      level="subtext"
      customStyles="text-text-disabled text-nowrap mt-1"
    />
  );
}

export const fieldErrorStyles = tv({
  base: 'flex flex-row gap-1 items-center text-nowrap text-sm text-red-600',
  variants: {
    absolute: {
      true: 'absolute bottom-[-22px]',
    },
  },
});

interface FieldErrorProps extends RACFieldErrorProps {
  absolute?: boolean;
}

export function FieldError({ children, absolute, ...props }: FieldErrorProps) {
  return (
    <RacFieldError
      {...props}
      className={clsxMerge(fieldErrorStyles({ absolute }), props.className)}
    >
      <>
        <span className="bg-white rounded-full z-10">
          <FaExclamationCircle className="text-red-500 " size={12} />
        </span>
        {children}
      </>
    </RacFieldError>
  );
}

export const fieldBorderStyles = tv({
  base: `border border-field-border-default`,
  variants: {
    isInvalid: {
      true: `border-field-border-error`,
    },
    isFocusWithin: {
      true: `border-field-border-focused`,
    },
    isFocused: {
      true: `border-field-border-focused`,
    },
    isDisabled: {
      true: 'border-field-border-disabled',
    },
  },
});

export const fieldGroupStyles = tv({
  extend: fieldBorderStyles,
  base: `group flex items-center h-9 bg-field-bg-default text-field-text-input-filled rounded-lg overflow-hidden disabled:bg-field-bg-disabled disabled:text-text-disabled`,
});

export function FieldGroup(props: GroupProps) {
  return (
    <Group
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({ ...renderProps, className }),
      )}
    />
  );
}

export const inputStyles = tv({
  extend: fieldBorderStyles,
  base: `px-2 py-2 outline outline-0 bg-field-bg-default ring-0
         rounded-lg  h-9
        text-base text-field-text-filled
        disabled:text-text-disabled
        disabled:bg-field-bg-disabled
        `,
});

export const Input = forwardRef<HTMLInputElement, RacInputProps>(
  function ForwardedInput(props, ref) {
    return (
      <RACInput
        {...props}
        ref={ref}
        role="input"
        className={composeRenderProps(
          props.className,
          (className, renderProps) =>
            inputStyles({ ...renderProps, className }),
        )}
      />
    );
  },
);

const textAreaStyles = tv({
  extend: fieldBorderStyles,
  base: `px-2 py-2 outline outline-0 rounded-lg text-base text-field-text-filled disabled:text-text-disabled disabled:bg-field-bg-disabled min-h-24`,
  variants: fieldBorderStyles.variants,
});

export function TextArea(props: RacTextAreaProps) {
  return (
    <RacTextArea
      {...props}
      role="textarea"
      className={composeRenderProps(props.className, (className, renderProps) =>
        textAreaStyles({ ...renderProps, className }),
      )}
    />
  );
}
