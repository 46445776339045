import { type ComponentProps } from 'react';
import { FaChevronLeft } from 'react-icons/fa6';
import { FaChevronRight } from 'react-icons/fa6';
import { useWindowSize } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import styles from './NavigationFooter.module.scss';

type NavigationFooterProps = {
  onNext: () => void;
  onBack?: () => void;
  nextText?: string;
  backText?: string;
  nextProps?: ComponentProps<typeof Button>;
  backProps?: ComponentProps<typeof Button>;
};

export const NavigationFooter = ({
  onNext,
  onBack,
  nextText = 'Continue',
  backText = 'Back',
  nextProps,
  backProps,
}: NavigationFooterProps) => {
  const { isMobile } = useWindowSize();

  const backBtnProps: ComponentProps<typeof Button> = {
    onPress: onBack,
    variant: 'ghost',
    width: 'auto',
    leftIcon: <FaChevronLeft />,
    ...backProps,
  };

  const nextBtnProps: ComponentProps<typeof Button> = {
    onPress: onNext,
    width: 'auto',
    variant: 'primary',
    rightIcon: <FaChevronRight />,
    ...nextProps,
  };

  if (isMobile) {
    return (
      <Button
        {...nextBtnProps}
        label={nextText}
        width="full"
        data-testid="next-button"
        aria-description="back-button"
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {onBack && (
        <Button
          {...backBtnProps}
          label={backText}
          aria-description="back-button"
          data-testid="back-button"
        />
      )}
      <Button
        {...nextBtnProps}
        label={nextText}
        aria-description="next-button"
        data-testid="next-button"
      />
    </div>
  );
};
