import { useCallback, useState } from 'react';
import {
  Alert,
  FormLayout,
  FormRow,
  Icon,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { Button, LinkButton } from '@breezeai-frontend/cargo-ui';

import { useAuthActions } from '../../../context/auth/auth-hooks';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useTracking } from '../../../utils/snowplow/hooks';
import { validateEmail } from '../../../utils/validateEmail';
import { useKeypressEffect } from '../../hooks/use-keypress-effect';
import styles from './ResetPassword.module.scss';

export const ResetPassword = () => {
  useTracking();

  const [emailSent, setEmailSent] = useState<boolean>();
  const [userInput, setUserInput] = useState<string>();
  const [authenticationError, setAuthenticationError] = useState<
    string | false
  >();
  const [validationError, setValidationError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const { navigateToSignIn } = useAppNavigation();
  const { resetPassword } = useAuthActions();

  const sendResetEmail = useCallback(async () => {
    if (!loading) {
      if (!userInput || !validateEmail(userInput)) {
        setValidationError(true);
        return;
      }

      setValidationError(false);
      setAuthenticationError(false);
      setLoading(true);

      try {
        await resetPassword(userInput);
        setEmailSent(true);
      } catch (e) {
        reportException(e);
        console.error(e);
        setAuthenticationError(
          'Incorrect email address, please double-check and try again.',
        );
      } finally {
        setLoading(false);
      }
    }
  }, [loading, resetPassword, userInput]);

  useKeypressEffect('Enter', sendResetEmail);

  const resetPasswordForm = (
    <div className={styles.resetForm} data-testid="reset-password-page">
      <Typography level="h2">Forgot Your Password?</Typography>
      <Typography className={styles.subtitle}>
        Enter your email address and we'll send you a verification mail to
        complete the process.
      </Typography>
      {authenticationError && (
        <Alert
          variant="error"
          role="error-alert"
          dismissible={true}
          onDismiss={() => setAuthenticationError(false)}
          className={styles.errorAlert}
        >
          {authenticationError}
        </Alert>
      )}
      <FormLayout>
        <FormRow>
          <Input
            id="email"
            required
            label="Email Address"
            type="email"
            placeholder="example@gmail.com"
            disabled={loading}
            validator={validateEmail}
            error={validationError}
            errorHelperText="Please enter a valid email address"
            onError={() => setValidationError(true)}
            onValid={() => setValidationError(false)}
            onChange={({ target: { value } }) => setUserInput(value)}
            testId="email-input"
          />
        </FormRow>
      </FormLayout>
      <div className="flex flex-col items-center mt-3 gap-3">
        <Button
          size="large"
          isLoading={loading}
          onPress={sendResetEmail}
          label="Send Email"
          width="full"
          data-testid="submit"
        />
        <LinkButton size="large" href="/login" variant="ghost" width="full">
          Back to Sign In
        </LinkButton>
      </div>
    </div>
  );

  const successMessage = (
    <div
      className={styles.successMessageWrapper}
      data-testid="email-sent-success-message"
    >
      <Icon
        icon="check"
        variant="success"
        type="circle"
        className={styles.icon}
      />
      <Typography level="h2" className={styles.title}>
        The email has been sent successfully!
      </Typography>
      <Typography className={styles.subtitle}>
        We've sent an email to <span className={styles.email}>{userInput}</span>
        . Please check your email to reset your password.
      </Typography>
      <Button
        size="large"
        width="fixed"
        onPress={() => navigateToSignIn()}
        label="Back to Sign In"
      />
      <Typography className={styles.resendAction}>
        Didn't get an email?
        <Typography variant="primary" clickable={true} onClick={sendResetEmail}>
          Click here to resend.
        </Typography>
      </Typography>
    </div>
  );

  return emailSent ? successMessage : resetPasswordForm;
};
