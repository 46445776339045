import { formatDate, normalizeDate } from '@breeze-ai/ui-library';
import { Select, SelectItem } from '@breezeai-frontend/cargo-ui';
import moment from 'moment';

import { staticDateFormats } from '../../../../utils/dates';

type YearSelectorProps = {
  maxPastYear?: number;
  earliestPastYear?: number;
  onChange?: (payload: YearSelectionPayload) => void;
};

export type YearSelectionPayload = {
  year: number;
  startDate: string;
  endDate: string;
};

const currentYear = moment().get('year');

const getYearDates = (year: number) => {
  const startDate = normalizeDate(moment({ year }).startOf('year'));
  const endDate = normalizeDate(moment({ year }).endOf('year'));
  const customFormat = staticDateFormats.ISO_DATE;

  return {
    startDate: formatDate(startDate, { customFormat }),
    endDate: formatDate(endDate, { customFormat }),
  };
};

export const YearSelector = ({
  maxPastYear = 2,
  earliestPastYear = 0,
  onChange,
}: YearSelectorProps) => {
  const years = Array(maxPastYear + 1)
    .fill(0)
    .map((_y, i) => currentYear - i)
    .filter((y) => y >= earliestPastYear);

  return (
    <>
      <Select
        aria-label="Year Selector"
        data-testid="insights-year-selector"
        defaultSelectedKey={String(currentYear)}
        placeholder={String(currentYear)}
        onSelectionChange={(key) =>
          onChange?.({
            year: Number(key),
            ...getYearDates(Number(key)),
          })
        }
      >
        {years.map((year) => (
          <SelectItem key={year} id={year} aria-label={String(year)}>
            {year}
          </SelectItem>
        ))}
      </Select>
    </>
  );
};
