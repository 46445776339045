import { type AppConfiguration } from '../../../context/app-configuration/AppConfigurationProvider';
import { get } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { type PolicyWizardConfigurationResponse } from './types';

export const getAppConfiguration = async (): Promise<AppConfiguration> => {
  const { data } = await get<AppConfiguration>(
    `${bffServiceUrl}/configurations`,
  );

  return data;
};

export const getPolicyWizardConfiguration =
  async (): Promise<PolicyWizardConfigurationResponse> => {
    const { data } = await get<PolicyWizardConfigurationResponse>(
      `${bffServiceUrl}/form/quote`,
    );

    return data;
  };
