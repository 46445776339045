import { type HTMLProps, type ReactNode } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import styles from './PageSection.module.scss';

type PageSectionProps = HTMLProps<HTMLDivElement> & {
  title?: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  separator?: boolean;
  scroll?: boolean;
};

export const PageSection = ({
  title,
  subtitle,
  actions,
  separator = true,
  children,
  className,
  scroll,
  ...props
}: PageSectionProps) => {
  const showHeader = title || actions;

  return (
    <div
      className={classnames(styles.pageSection, className)}
      {...props}
      style={{ overflow: scroll ? 'auto' : 'visible' }}
    >
      {showHeader && (
        <div className={styles.header}>
          <div className={styles.title}>
            <Typography level="h4">{title}</Typography>
            {subtitle}
          </div>
          {actions}
        </div>
      )}
      {showHeader && separator && <hr />}
      {children}
    </div>
  );
};
