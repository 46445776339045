import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

import { Button } from '../../../../components/Button/Button';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Typography } from '../../../../components/Typography/Typography';
import { Modal, type ModalProps } from '../../../../layouts/Modal/Modal';

const Heading = ({ isError }: { isError?: boolean }) =>
  isError ? (
    <>
      <FaTimes size={42} className="fill-system-red-500" />
      <Typography level="h2" customStyles="text-center">
        There was an error updating your certificate.
      </Typography>
      <Typography color="secondary" customStyles="text-center w-[308px]">
        Please try again later
      </Typography>
    </>
  ) : (
    <div className="space-y-2">
      <Typography level="h3">
        Are you sure you want to update the certificate?
      </Typography>
      <Typography color="secondary">
        Saving changes will cancel this existing certificate and replace it with
        a new certificate
      </Typography>
    </div>
  );

export function UpdateCertificateModal({
  onOpenChange,
  onConfirmChanges,
  ...props
}: {
  onOpenChange: (isOpen: boolean) => void;
  onConfirmChanges: () => void;
} & Omit<ModalProps, 'children'>) {
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    if (!props.isOpen) {
      setIsAgree(false);
    }
  }, [props.isOpen]);

  return (
    <Modal
      {...props}
      showExitButton
      onExit={() => onOpenChange(false)}
      onOpenChange={onOpenChange}
      className="max-w-xl"
      heading={
        <div className="flex justify-between items-center flex-col gap-3 h-[202x] pt-3">
          <Heading />
        </div>
      }
    >
      <>
        <div className="w-full py-6">
          <Checkbox onChange={() => setIsAgree(!isAgree)}>
            <Typography>Agree to delete and replace certificate</Typography>
          </Checkbox>
        </div>
        <div className="flex flex-row gap-3 w-full justify-end items-baseline">
          <Button
            width="auto"
            variant="secondary"
            onPress={() => onOpenChange(false)}
            label="Cancel"
            size="large"
          />
          <Button
            width="auto"
            onPress={() => {
              onConfirmChanges();
              onOpenChange(false);
            }}
            label="Save Changes"
            size="large"
            isDisabled={!isAgree}
            data-testid="modal-confirm-changes-button"
          />
        </div>
      </>
    </Modal>
  );
}
