import { type BrandConfig } from '../context/types';
import { FlexportLogo } from './FlexportLogo';

export const flexportConfig: BrandConfig = {
  id: 'flexport',
  name: 'Flexport Insurance',
  locale: 'en-US',
  logo: <FlexportLogo />,
  supportMail: 'flexport@breezeai.com',
  homepageUrl: 'https://www.flexport.com/products/cargo-insurance/',
  termsAndConditionsUrl:
    'https://storage.googleapis.com/breeze-public-static-files/terms_and_conditions.pdf',
  favicon: 'flexport-favicon.ico',
  pageTitle: 'Flexport Insurance',
};
