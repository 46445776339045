import { useEffect } from 'react';

import { GCID, REDIRECT_TO_PATH } from '../../../network/apis/auth/auth';
import { getCookie, setCookie } from '../../../network/apis/auth/cookies';
import { usePlatform } from '../../PlatformContext';
import { type AuthActions } from '../AuthContext';

type Args = {
  logout: AuthActions['logout'];
};

export const useTradeFlowLogoutLogic = ({ logout }: Args) => {
  const { isWtw } = usePlatform();

  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  const searchParamsGcId = searchParams.get('gcid');

  const cookieGcId = getCookie(GCID);

  const shouldLogOutFromTradeFlow =
    isWtw && searchParamsGcId && cookieGcId !== searchParamsGcId;

  useEffect(() => {
    if (!shouldLogOutFromTradeFlow) return;

    if (!pathname.includes('login')) {
      setCookie(REDIRECT_TO_PATH, pathname + search);
    }

    logout();

    // We only want this effect to run once on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { shouldLogOutFromTradeFlow };
};
