import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from 'react';

import {
  type BaseErrorType,
  type ErrorResponse,
} from '../../../network/apis/types';

export type SectionContextType = {
  error?: ErrorResponse;
  setError: Dispatch<SetStateAction<ErrorResponse | undefined>>;
};

export const SectionContext = createContext<SectionContextType | null>(null);

export const useSectionContext = () => {
  return useContext(SectionContext) as SectionContextType;
};

export const useSectionError = <T = BaseErrorType>() => {
  const { error, setError } = useSectionContext();

  const exception = error as ErrorResponse<T>;
  const { response } = exception ?? {};

  return {
    exception,
    status: response?.status,
    data: response?.data,
    setError: setError as Dispatch<
      SetStateAction<ErrorResponse<T> | undefined>
    >,
  };
};
