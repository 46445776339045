type FormatPriceProps = {
  currency: string;
  value: number;
  locale?: string | string[];
};

export const formatPrice = (
  { currency, value, locale }: FormatPriceProps,
  numberFormatOptions?: Intl.NumberFormatOptions,
) => {
  const hasDecimals = value % 1 !== 0;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: hasDecimals ? 2 : 0,
    minimumFractionDigits: hasDecimals ? 2 : 0,
    ...numberFormatOptions,
  }).format(value);
};
