import { type SVGProps } from 'react';

export const White = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="-3 3 120 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6886_3599)">
      <path
        d="M33.5791 11.6538H30.9785V10.2872H37.9309V11.6538H35.3302V19.5935H33.5791V11.6538Z"
        fill="white"
      />
      <path
        d="M38.7279 12.7863H40.3091V14.0618C40.7404 13.2028 41.3807 12.6951 42.5307 12.6821V14.1529C41.1455 14.1659 40.3091 14.6084 40.3091 16.0532V19.5935H38.7279V12.7863Z"
        fill="white"
      />
      <path
        d="M43.3145 17.7062C43.3145 16.1183 44.8566 15.5456 46.8169 15.5456H47.6663V15.1941C47.6663 14.2829 47.3527 13.8274 46.4248 13.8274C45.6014 13.8274 45.1963 14.2179 45.118 14.8948H43.5891C43.7198 13.3068 44.9874 12.643 46.5164 12.643C48.0454 12.643 49.2478 13.2677 49.2478 15.129V19.5934H47.6926V18.7604C47.2482 19.3461 46.6471 19.7237 45.6016 19.7237C44.347 19.7237 43.3145 19.125 43.3145 17.7062ZM47.6664 17.2116V16.5348H46.8562C45.6539 16.5348 44.8829 16.8081 44.8829 17.6411C44.8829 18.2138 45.1966 18.5913 45.9806 18.5913C46.9476 18.5913 47.6664 18.0836 47.6664 17.2116Z"
        fill="white"
      />
      <path
        d="M50.6194 16.3005V16.1964C50.6194 14.0226 51.9132 12.643 53.6905 12.643C54.8013 12.643 55.4939 13.1507 55.873 13.8274V9.63637H57.4542V19.5935H55.873V18.4481C55.5071 19.1249 54.6315 19.7237 53.6253 19.7237C51.9132 19.7237 50.6194 18.5132 50.6194 16.3005ZM55.9253 16.2224V16.1184C55.9253 14.6215 55.2327 13.8796 54.0827 13.8796C52.9196 13.8796 52.24 14.6865 52.24 16.1573V16.2614C52.24 17.7583 52.9979 18.4741 54.0174 18.4741C55.1151 18.4741 55.9253 17.7452 55.9253 16.2224Z"
        fill="white"
      />
      <path
        d="M58.8784 16.2614V16.1573C58.8784 14.0488 60.329 12.643 62.2761 12.643C63.9751 12.643 65.504 13.6452 65.504 16.0792V16.5348H60.4989C60.5512 17.8233 61.2046 18.5522 62.3807 18.5522C63.3348 18.5522 63.8312 18.1747 63.9488 17.5108H65.4778C65.2558 18.9426 64.0926 19.7236 62.3414 19.7236C60.329 19.7237 58.8784 18.4091 58.8784 16.2614ZM63.9359 15.4804C63.8575 14.309 63.2563 13.7883 62.2761 13.7883C61.3222 13.7883 60.6818 14.4261 60.525 15.4804H63.9359Z"
        fill="white"
      />
      <path
        d="M70.1558 10.2872H75.9452V11.6668H71.894V14.4392H75.0826V15.7799H71.894V19.5935H70.1558V10.2872Z"
        fill="white"
      />
      <path
        d="M77.3692 9.63637H78.9505V19.5935H77.3692V9.63637Z"
        fill="white"
      />
      <path
        d="M80.4141 16.2614V16.1573C80.4141 14.0488 81.917 12.643 83.9295 12.643C85.9289 12.643 87.4318 14.0356 87.4318 16.1183V16.2223C87.4318 18.3439 85.9289 19.7237 83.9164 19.7237C81.904 19.7237 80.4141 18.331 80.4141 16.2614ZM85.8114 16.2354V16.1443C85.8114 14.7385 85.1056 13.8796 83.9295 13.8796C82.7534 13.8796 82.0345 14.7256 82.0345 16.1313V16.2354C82.0345 17.6281 82.7272 18.5002 83.9295 18.5002C85.1056 18.5002 85.8114 17.6281 85.8114 16.2354Z"
        fill="white"
      />
      <path
        d="M88.0066 12.7863H89.6925L91.0516 17.7062L92.5022 12.7863H93.8222L95.1552 17.7062L96.5797 12.7863H98.1217L95.9784 19.5935H94.3711L93.1034 15.0119L91.7182 19.5935H90.0977L88.0066 12.7863Z"
        fill="white"
      />
      <path
        d="M4.07054 10.3174L4.05 10.3059C4.04748 10.3097 4.04528 10.3137 4.04276 10.3174H4.07054Z"
        fill="white"
      />
      <path
        d="M23.9516 23.5466L20.6873 20.2296C20.6228 20.1641 20.5183 20.1641 20.4539 20.2296L19.4424 21.2576C19.3779 21.3232 19.3779 21.4294 19.4424 21.4948L20.7629 22.8366H12.0042C7.85254 22.8366 4.475 19.4044 4.475 15.1855C4.475 14.206 4.65921 13.2416 5.01278 12.3429C5.0558 12.2335 4.97503 12.1149 4.85905 12.1149H3.33848C3.26717 12.1149 3.20312 12.1612 3.18078 12.23C2.87365 13.1782 2.7142 14.1754 2.7142 15.1855C2.7142 20.3909 6.88164 24.6257 12.0041 24.6257H20.633L19.4043 25.8743C19.3398 25.9399 19.3398 26.046 19.4043 26.1115L20.4158 27.1395C20.4803 27.205 20.5848 27.205 20.6492 27.1395L23.9515 23.7838C24.0161 23.7183 24.0161 23.6121 23.9516 23.5466Z"
        fill="white"
      />
      <path
        d="M11.9958 5.75147H3.36676L4.5955 4.50286C4.65999 4.43733 4.65999 4.3311 4.5955 4.26568L3.58399 3.23781C3.5195 3.17228 3.41496 3.17228 3.35059 3.23781L0.0483104 6.59339C-0.0161747 6.65892 -0.0161747 6.76515 0.0483104 6.83057L3.31251 10.1476C3.377 10.2131 3.48154 10.2131 3.54591 10.1476L4.55743 9.11969C4.62191 9.05416 4.62191 8.94793 4.55743 8.88252L3.23691 7.54064H11.8926C15.8697 7.54064 19.2385 10.6367 19.5075 14.669C19.5734 15.6562 19.4517 16.6331 19.1582 17.5533C19.124 17.6607 19.2048 17.7705 19.3158 17.7705H20.8066C20.8813 17.7705 20.9474 17.7199 20.9668 17.6465C21.1771 16.8514 21.2856 16.0269 21.2856 15.1918C21.2857 9.98632 17.1183 5.75147 11.9958 5.75147Z"
        fill="white"
      />
      <path
        d="M12.042 19.7202C9.58153 19.7202 7.57985 17.6861 7.57985 15.1859C7.57985 12.6856 9.58153 10.6516 12.042 10.6516C14.5024 10.6516 16.5041 12.6856 16.5041 15.1859C16.5041 17.6861 14.5024 19.7202 12.042 19.7202ZM12.042 12.4407C10.5524 12.4407 9.34053 13.6722 9.34053 15.1859C9.34053 16.6996 10.5524 17.931 12.042 17.931C13.5315 17.931 14.7434 16.6995 14.7434 15.1859C14.7434 13.6722 13.5315 12.4407 12.042 12.4407Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6886_3599">
        <rect
          width="99"
          height="28"
          fill="white"
          transform="translate(0 0.925964)"
        />
      </clipPath>
    </defs>
  </svg>
);
