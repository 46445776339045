import React from 'react';
import {
  composeRenderProps,
  OverlayArrow,
  Tooltip as AriaTooltip,
  type TooltipProps as AriaTooltipProps,
  TooltipTrigger,
  type TooltipTriggerComponentProps,
} from 'react-aria-components';
import { tv, type VariantProps } from 'tailwind-variants';

import { clsxMerge } from '../common/utils/classNameUtils';

const tooltip = tv({
  base: 'group bg-white  text-black text-sm rounded-lg drop-shadow-lg will-change-transform px-3 py-2',
});

export interface TooltipProps
  extends Omit<AriaTooltipProps, 'children'>,
    VariantProps<typeof tooltip> {
  children: React.ReactNode;
  showArrow?: boolean;
}

export function Tooltip({
  children,
  showArrow = true,
  ...props
}: TooltipProps) {
  return (
    <AriaTooltip
      {...props}
      offset={props.offset ?? 10}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tooltip({
          ...renderProps,
          className,
        }),
      )}
    >
      {showArrow && (
        <OverlayArrow>
          <svg
            width={8}
            height={8}
            viewBox="0 0 8 8"
            className={clsxMerge('fill-white', {
              ['transform rotate-90']: props.placement === 'right',
              ['transform -rotate-90']: props.placement === 'left',
              ['transform rotate-135']: props.placement === 'top',
              ['transform rotate-180']: props.placement === 'bottom',
            })}
          >
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>
      )}
      {children}
    </AriaTooltip>
  );
}

export interface TooltipTriggerWrapperProps
  extends Omit<TooltipTriggerComponentProps, 'children'> {
  children: React.ReactNode;
}

export function TooltipTriggerWrapper({
  children,
  ...props
}: TooltipTriggerWrapperProps) {
  return <TooltipTrigger {...props}>{children}</TooltipTrigger>;
}
