import {
  composeRenderProps,
  DateField as AriaDateField,
  type DateFieldProps as AriaDateFieldProps,
  DateInput as AriaDateInput,
  type DateInputProps,
  DateSegment,
  type DateValue,
  type ValidationResult,
} from 'react-aria-components';

import { clsxMerge } from '../..';
import { tv } from '../../utils/customTv';
import {
  Description,
  FieldError,
  fieldGroupStyles,
  fieldStyles,
  Label,
} from '../Field/Field';

export interface DateFieldProps<T extends DateValue>
  extends AriaDateFieldProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function DateField<T extends DateValue>({
  label,
  description,
  isInvalid,
  errorMessage,
  ...props
}: DateFieldProps<T>) {
  return (
    <AriaDateField
      {...props}
      className={clsxMerge(
        fieldStyles({ isInvalid }),
        props.className,
        'flex flex-col gap-1',
      )}
      isInvalid={isInvalid}
      validationBehavior="aria"
    >
      {label && <Label>{label}</Label>}
      <DateInput />
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </AriaDateField>
  );
}

const segmentStyles = tv({
  base: `inline p-0.5 type-literal:px-0 rounded outline outline-0 forced-color-adjust-none caret-transparent text-field-text-input-filled text-base`,
  variants: {
    isPlaceholder: {
      true: `text-field-text-input`,
    },
    isDisabled: {
      true: `text-text-disabled`,
    },
    isFocused: {
      true: `text-field-text-input-filled`,
    },
  },
});

export function DateInput(props: Omit<DateInputProps, 'children'>) {
  return (
    <AriaDateInput
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({
          ...renderProps,
          className: clsxMerge(
            className,
            'flex-1 px-2 py-1.5 text- border-none rounded-lg h-9 bg-transparent',
          ),
        }),
      )}
    >
      {(segment) => <DateSegment segment={segment} className={segmentStyles} />}
    </AriaDateInput>
  );
}
