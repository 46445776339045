import { type EntitySetting } from '../../../model/EntitySetting';
import { type User } from '../../../model/User';
import { get } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';

export const getUserDetails = async (): Promise<User> => {
  const { data } = await get<User>(`${bffServiceUrl}/users/info`);

  return data;
};

export const getUserSettings = async (): Promise<EntitySetting[]> => {
  const { data } = await get<EntitySetting[]>(
    `${bffServiceUrl}/users/settings`,
  );

  return data;
};
