import { type ReactNode, useMemo } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import { toPercentage } from '@breezeai-frontend/cargo-ui';

import { formatPrice } from '../../../../../../packages/cargo-ui/src/utils/tools';
import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type Quote } from '../../../model/Quote';
import { getFormattedContainerMode } from '../../../network/apis/quotes/helpers';
import { getTheme } from '../../../utils/getTheme';
import { formatCurrencyValue } from '../../../utils/Internationalization';
import { getAppLocale } from '../../../utils/locale';
import labels from '../../labels';
import { CurrencyExchangeRate } from '../CurrencyExchangeRate/CurrencyExchangeRate';
import { TaxInfoTooltip } from '../TaxInfoTooltip/TaxInfoTooltip';
import styles from './PolicySummary.module.scss';

export type SummaryItem = {
  title: ReactNode;
  value?: ReactNode;
  hidden?: boolean;
  testId?: string;
};

const locale = getAppLocale();

const isWtw = getTheme() === 'wtw';

const useSummaryMainItems = ({
  premium_value,
  premium_currency,
  premium_rate,
  converted_customer_premium_value = 0,
  tax,
  converted_tax_amount = 0,
  exchange_rate,
  deductible_value = 0,
  deductible_currency,
  external_reference,
  distributor,
}: Quote): SummaryItem[][] => {
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');

  return useMemo(
    () => [
      [
        {
          title: (
            <Typography level="h4" data-testid="title">
              {labels.quoteSummaryCard.title}
            </Typography>
          ),
          value: (
            <Typography level="h5" data-testid="value">
              {external_reference}
            </Typography>
          ),
          testId: 'booking-reference-id',
        },
      ],
      [
        {
          title: (
            <Typography className={styles.subItem} data-testid="title">
              Premium
            </Typography>
          ),
          value:
            premium_value !== undefined && premium_currency ? (
              <Typography className={styles.subItem} data-testid="value">
                {formatPrice({
                  locale,
                  currency: premium_currency,
                  value: distributor?.hide_tax_info
                    ? premium_value + (tax?.value ?? 0)
                    : premium_value,
                })}
              </Typography>
            ) : null,
          testId: 'premium',
        },
        ...(tax?.value && !distributor?.hide_tax_info
          ? [
              {
                title: (
                  <Typography data-testid="title" className={styles.subItem}>
                    Taxes
                  </Typography>
                ),
                value: !tax ? null : (
                  <Typography className={styles.subItem} data-testid="value">
                    {formatPrice({
                      locale,
                      currency: tax.currency_code,
                      value: tax.value,
                    })}
                  </Typography>
                ),
                hidden: !tax,
                testId: 'taxes',
              },
            ]
          : []),
        {
          title: (
            <Typography data-testid="title" className={styles.subItem}>
              Deductible
            </Typography>
          ),
          value:
            !deductible_value || !deductible_currency ? null : (
              <Typography className={styles.subItem} data-testid="value">
                {formatPrice({
                  locale,
                  currency: deductible_currency,
                  value: deductible_value,
                })}
              </Typography>
            ),
          hidden: !deductible_value,
          testId: 'deductible',
        },
      ],
      [
        {
          title: (
            <div className="flex gap-1 items-center">
              <Typography level="h4" data-testid="title">
                Total
              </Typography>
              {distributor?.hide_tax_info && !!tax?.value && (
                <TaxInfoTooltip className="text-cards-summary-details-info" />
              )}
            </div>
          ),
          value:
            !tax || premium_value === undefined || !premium_currency ? null : (
              <Typography level="h2" data-testid="value">
                {formatPrice({
                  locale,
                  currency: premium_currency,
                  value: premium_value + tax.value,
                })}
              </Typography>
            ),
          hidden: premium_currency !== tax?.currency_code,
          testId: 'total',
        },
        ...(showExchangeRate
          ? [
              {
                title: (
                  <Typography data-testid="title">
                    Total in {exchange_rate?.to_currency.code}
                  </Typography>
                ),
                value: !exchange_rate ? null : (
                  <Typography data-testid="value">
                    {formatPrice({
                      locale,
                      currency: exchange_rate.to_currency.code,
                      value:
                        converted_customer_premium_value + converted_tax_amount,
                    })}
                  </Typography>
                ),
                hidden: !exchange_rate,
                testId: 'converted-total',
              },
              {
                title: !exchange_rate ? null : (
                  <CurrencyExchangeRate
                    {...exchange_rate}
                    typographyProps={{
                      level: 'subtext',
                      className: styles.subItem,
                    }}
                  />
                ),
                hidden: !exchange_rate,
              },
            ]
          : []),
        ...(isWtw && premium_rate
          ? [
              {
                title: (
                  <Typography
                    level="subtext"
                    data-testid="title"
                    className={styles.subItem}
                  >
                    Premium Rate(%) {toPercentage(premium_rate, 3)}
                  </Typography>
                ),
                testId: 'premium-rate',
              },
            ]
          : []),
      ],
    ],
    [
      showExchangeRate,
      converted_customer_premium_value,
      converted_tax_amount,
      deductible_currency,
      deductible_value,
      exchange_rate,
      premium_currency,
      premium_value,
      tax,
      external_reference,
      distributor?.hide_tax_info,
      premium_rate,
    ],
  );
};

const useSummaryFooterItems = ({
  commodity_category,
  insured_value,
  insured_value_currency,
  container_mode,
}: Quote): SummaryItem[] => {
  const { isBreeze } = usePlatform();

  return useMemo(() => {
    const items = [
      {
        title: 'Insured Value',
        value:
          formatCurrencyValue({
            value: insured_value,
            currency_code: insured_value_currency,
          }) ?? '',
        testId: 'insured-value',
      },
      {
        title: labels.fields.commodity.category,
        value: commodity_category,
        testId: 'commodity-type',
      },
    ];

    if (isBreeze) {
      items.push({
        title: 'Load Type',
        value: getFormattedContainerMode(container_mode),
        testId: 'container-mode',
      });
    }

    return items;
  }, [
    isBreeze,
    commodity_category,
    container_mode,
    insured_value,
    insured_value_currency,
  ]);
};

export const usePolicySummaryItems = (quote: Quote) => {
  const mainItems = useSummaryMainItems(quote);
  const footerItems = useSummaryFooterItems(quote);

  return { mainItems, footerItems };
};
