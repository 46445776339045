import { useContext, useMemo } from 'react';

import { type Quote } from '../../../model/Quote';
import {
  type DTCConfiguration,
  type DTCCustomer,
} from '../../../network/apis/externals/types';
import { type ValidationResult } from '../../../utils/validators';
import { type DTCFlowTypes } from '../constants';
import { type DTCFlowSteps } from '../flows-steps-config';
import { DTCFlowContext } from './DTCFlowContext';
import { type DTCFormsData } from './types';

export const useDTCFlowContext = () => {
  const { state, dispatch } = useContext(DTCFlowContext);

  return { ...state, dispatch };
};

export const useDTCForms = () => {
  const { forms, formErrors } = useDTCFlowContext();

  return { data: forms, errors: formErrors };
};

export const useDTCStateAction = () => {
  const { dispatch } = useDTCFlowContext();

  return useMemo(
    () => ({
      resetDTCState: () => dispatch({ type: 'reset-dtc-state' }),
      setFlowType: (payload: DTCFlowTypes) =>
        dispatch({ type: 'set-dtc-flow-type', payload }),
      setStep: (payload: DTCFlowSteps) =>
        dispatch({ type: 'set-step', payload }),
      setConfiguration: (payload: DTCConfiguration) =>
        dispatch({ type: 'set-configuration', payload }),
      setFormData: (payload: Partial<DTCFormsData>) =>
        dispatch({ type: 'set-forms-data', payload }),
      setFormError: (payload: ValidationResult) =>
        dispatch({ type: 'set-form-error', payload }),
      resetFormFields: (payload: (keyof DTCFormsData)[]) =>
        dispatch({ type: 'reset-form-fields', payload }),
      resetFormsErrors: () => dispatch({ type: 'reset-forms-errors' }),
      setCustomer: (payload: DTCCustomer) =>
        dispatch({ type: 'set-customer', payload }),
      setQuote: (payload: Quote) => dispatch({ type: 'set-quote', payload }),
    }),
    [dispatch],
  );
};
