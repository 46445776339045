import TagManager from 'react-gtm-module';

export const initializeGoogleTagManager = () => {
  const gtmId = import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_ID;

  if (gtmId) {
    const tagManagerArgs = {
      gtmId: gtmId,
      auth: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_AUTH,
      preview: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_PREVIEW,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
