import { useState } from 'react';
import { Icon, Popover, Typography } from '@breeze-ai/ui-library';
import { Button, clsxMerge } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';

import { type User } from '../../../../model/User';
import { useSidebarUserMenuItems } from '../../sidebar-hooks';
import { UserBadge } from '../UserBadge/UserBadge';
import styles from './UserMenu.module.scss';

type UserMenuProps = {
  user: User;
  minified: boolean;
};

export const UserMenu = ({ user, minified }: UserMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  const userMenuItems = useSidebarUserMenuItems();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuTrigger = (
    <div
      role="menu"
      data-testid="user-menu-trigger-button"
      onClick={toggleMenu}
      className={clsxMerge(styles.userMenu, {
        [styles.minified]: minified,
        ['hover:bg-navbar-logout-bg--hover']: !minified,
        [styles.menuOpen]: isMenuOpen,
      })}
    >
      <UserBadge user={user} minified={minified} />
      {!minified && (
        <Icon icon="ellipsis-vertical" size="sm" className={styles.menuIcon} />
      )}
    </div>
  );

  return (
    <Popover
      data-testid="user-menu"
      anchorElement={menuTrigger}
      placement="right-bottom"
      onClose={() => setIsMenuOpen(false)}
      className={classnames(styles.userMenuPopover, {
        [styles.sidebarMinified]: minified,
      })}
    >
      <div
        className={clsxMerge(
          styles.header,
          'border-b border-navbar-logout-stroke--default',
        )}
        role="heading"
      >
        <UserBadge user={user} />
      </div>
      <div className={styles.itemsList}>
        {userMenuItems.map(({ title, icon, onPress }) => (
          <div key={title}>
            <Button
              data-testid="user-menu-item"
              onPress={onPress}
              width="full"
              size="medium"
              customStyles={clsxMerge(
                `flex items-center justify-start rounded-md text-navbar-logout-text bg-transparent enabled:hover:bg-navbar-logout-bg--hover
                enabled:active:bg-navbar-user-options-bg-pressed`,
              )}
              leftIcon={icon}
              variant="primary"
            >
              <Typography>{title}</Typography>
            </Button>
          </div>
        ))}
      </div>
    </Popover>
  );
};
