import { useMemo, useState } from 'react';
import { FaWarehouse } from 'react-icons/fa';
import {
  FaCheck,
  FaEllipsisVertical,
  FaHouseFloodWater,
  FaPen,
  FaTrash,
} from 'react-icons/fa6';
import { PiArchiveFill, PiFarm } from 'react-icons/pi';
import {
  CurrencyCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';
import {
  Button,
  clsxMerge,
  LinkButton,
  ListBox,
  ListBoxItem,
  Popover,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { capitalize, words } from 'lodash';
import moment from 'moment';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { CapacityMeter } from '../../components/CapacityMeter.tsx';
import { useCreateStorageLocation } from '../../hooks/useCreateStorageLocation';
import {
  type StorageLocationModel,
  StorageLocationStatusEnum,
  StorageLocationTypeEnum,
} from '../../types';

function getStorageLocationIcon(type: StorageLocationTypeEnum) {
  switch (type) {
    case StorageLocationTypeEnum.WAREHOUSE:
      return <FaWarehouse className="size-4 fill-icons-default" />;
    case StorageLocationTypeEnum.FLOATING:
      return <FaHouseFloodWater className="size-4 fill-icons-default" />;
    case StorageLocationTypeEnum.OPEN_YARD:
      return <PiFarm className="size-4 fill-icons-default" />;
    default:
      return null;
  }
}

const ActionMenu = ({
  row,
  refreshTable,
}: {
  row: StorageLocationModel;
  refreshTable: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, isLoading } = useCreateStorageLocation({
    policyId: row.id.toString(),
    options: {
      onSuccess: () => {
        refreshTable();
      },
      onSettled: () => {
        setIsOpen(false);
      },
    },
  });

  return (
    <Popover
      isOpen={isOpen}
      offset={-7}
      placement="left top"
      isKeyboardDismissDisabled
      onOpenChange={(open) => setIsOpen(open)}
      anchorElement={
        <Button
          leftIcon={<FaEllipsisVertical />}
          variant="ghost"
          onPress={() => setIsOpen(!isOpen)}
        />
      }
    >
      <ListBox className="space-y-1 flex flex-col" aria-label="Actions">
        <ListBoxItem className="p-0" textValue="Edit Details">
          <LinkButton
            onPress={() => {
              setIsOpen(false);
            }}
            customStyles="justify-start rounded-lg text-text-primary"
            width="full"
            href={`storage/update/inputs/${row.location_id}/${row.id}`}
            leftIcon={<FaPen className="fill-icons-primary" />}
            variant="ghost"
            isDisabled={isLoading}
          >
            Edit Details
          </LinkButton>
        </ListBoxItem>
        <ListBoxItem className="p-0" textValue="Edit Details">
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            customStyles="justify-start rounded-lg text-text-primary"
            width="full"
            leftIcon={<FaTrash className="fill-icons-primary" />}
            variant="ghost"
            onPress={() => {
              mutate({
                status: StorageLocationStatusEnum.ARCHIVED,
              });
            }}
          >
            {isLoading ? 'Archiving...' : 'Archive Location'}
          </Button>
        </ListBoxItem>
      </ListBox>
    </Popover>
  );
};

export const useStorageLocationsTableColumns = ({
  refreshTable,
}: {
  refreshTable: () => void;
}): TableColumn<StorageLocationModel>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');

  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition('status', 'Status'),
        valueGetter: ({ row }) => row.status,
        renderCell: ({ value }) => (
          <div
            className={clsxMerge(
              value === StorageLocationStatusEnum.ACTIVE
                ? 'bg-system-green-100'
                : 'bg-system-grey-300',
              'flex gap-1 items-center px-2 py-1 rounded-full',
            )}
          >
            {value === StorageLocationStatusEnum.ACTIVE && (
              <FaCheck size={14} className="text-system-green-500" />
            )}
            {value === StorageLocationStatusEnum.ARCHIVED && (
              <PiArchiveFill size={14} className="text-system-grey-700" />
            )}
            <Typography level="subtext" color="primary" text="capitalize">
              {String(value)}
            </Typography>
          </div>
        ),
        minWidth: 135,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('location_id', 'Location ID'),
        valueGetter: ({ row }) => row.location_id,
        renderCell: ({ value }) => <TextCell>{String(value)}</TextCell>,
        sortable: sortingEnabled,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('location_name', 'Location Name'),
        valueGetter: ({ row }) => row.location_name,
        renderCell: ({ value }) => (
          <Tooltip content={String(value)}>
            <div className="flex flex-row items-center gap-2 truncate w-full">
              <TextCell>{value as string}</TextCell>
            </div>
          </Tooltip>
        ),
        sortable: false,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('address', 'Address'),
        valueGetter: ({ row }) => row.address.full_address,
        renderCell: ({ value }) => (
          <Tooltip content={String(value)}>
            <span className="truncate">{String(value)}</span>
          </Tooltip>
        ),
        sortable: false,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('type', 'Location Type'),
        valueGetter: ({ row }) => row.type,
        renderCell: ({ value }) => (
          <span className="flex flex-row items-center gap-2">
            {getStorageLocationIcon(value as StorageLocationTypeEnum)}
            {words(String(value).replace('_', ' ')).map(capitalize).join(' ')}
          </span>
        ),
        sortable: sortingEnabled,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('created_date_time', 'Location Added Date'),
        valueGetter: ({ row }) => row.created_date_time,
        renderCell: ({ value }) => (
          <span className="flex flex-row items-center gap-2">
            {moment(value as Date).format('DD MMM, YYYY')}
          </span>
        ),
        sortable: sortingEnabled,
        minWidth: 200,
      },
      {
        ...getBaseColumnDefinition(
          'active_total_insured_value',
          'Estimated TIV On Risk',
        ),
        type: 'number',
        valueGetter: ({ row }) => row?.active_total_insured_value,
        renderCell: ({ row }) => (
          <CurrencyCell
            value={row?.active_total_insured_value}
            currency={row?.active_total_insured_value_currency?.code}
          />
        ),
        sortable: sortingEnabled,
        minWidth: 200,
      },
      {
        ...getBaseColumnDefinition('capacity', 'Capacity'),
        sortable: sortingEnabled,
        minWidth: 200,
        valueGetter: ({ row }) => row.capacity,
        renderCell: ({ value, row }) => {
          return (
            <div className="flex flex-row items-center">
              <CapacityMeter
                valueFixedWidth
                value={(value as number) ? (value as number) * 100 : 0}
              />
              <ActionMenu row={row} refreshTable={refreshTable} />
            </div>
          );
        },
      },
    ],
    [sortingEnabled, refreshTable],
  );
};
