import { useState } from 'react';
import {
  clsxMerge,
  LinkButton,
  Tooltip,
  TooltipTriggerWrapper,
  Typography,
} from '@breezeai-frontend/cargo-ui';

import { type SidebarItem as SidebarItemType } from '../../types';

type SidebarItemProps = SidebarItemType & {
  active?: boolean;
  minified?: boolean;
};

export const SidebarItem = ({
  path,
  Icon,
  title,
  active,
  minified,
}: SidebarItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TooltipTriggerWrapper delay={0} isDisabled={!minified}>
      <LinkButton
        href={`/${path}`}
        variant="ghost"
        aria-current={active ? 'page' : undefined}
        data-testid={`sidebar-navigation-item-${path}`}
        width="full"
        customStyles={clsxMerge(
          `h-10 gap-4 text-navbar-item-default-text cursor-pointer justify-start rounded-xl transition duration-100 ease-in hover:bg-navbar-item-hover-bg hover:text-navbar-item-hover-text`,
          {
            ['w-10 h-10 aspect-square justify-center']: minified,
            ['bg-navbar-item-focused-bg text-navbar-item-focused-text hover:bg-navbar-item-focused-bg']:
              active,
          },
        )}
        leftIcon={
          <Icon
            className={clsxMerge('w-4 h-4 text-navbar-item-default-icon', {
              ['text-navbar-item-focused-icon']:
                active || (isHovered && !minified),
            })}
          />
        }
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        {/*TODO Text appearing without animation when toggling minification  */}
        {!minified && <Typography>{title}</Typography>}
      </LinkButton>
      <Tooltip placement="right">{minified ? title : undefined}</Tooltip>
    </TooltipTriggerWrapper>
  );
};
