import { useCallback } from 'react';

/**
 * Smoothly scrolls the page to the top of the given element.
 */
const defaultScrollOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
};

export const useScrollToElement = () =>
  useCallback((element: Element | null, options?: ScrollIntoViewOptions) => {
    if (!element) {
      return;
    }

    element.scrollIntoView({ ...defaultScrollOptions, ...options });
  }, []);
