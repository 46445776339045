import {
  DatePicker,
  FormLayout,
  FormRow,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import moment from 'moment';

import { useAppConfiguration } from '../../../../context/app-configuration/AppConfigurationProvider';
import { type Place } from '../../../../model/Place';
import { PlaceAutocomplete } from '../../../components/PlaceAutocomplete/PlaceAutocomplete';
import { PolicySummary } from '../../../components/PolicySummary/PolicySummary';
import { ETD_MIN_DAYS_OFFSET } from '../../../constants';
import { IncotermSelector } from '../../components/selectors/IncotermSelector';
import { SHIPMENT_FORM_LABELS, WizardSteps } from '../../constants';
import { useInputValidation, useSetFormError } from '../../hooks/context-hooks';
import {
  usePolicyWizardContext,
  useSetFormData,
} from '../../hooks/context-hooks';
import { useShipmentInformationForm } from '../../hooks/use-shipment-information-form';
import styles from './ShipmentInformationForm.module.scss';

type ShipmentInformationFormProps = {
  onSubmit: () => void;
};

export const ShipmentInformationForm = ({
  onSubmit,
}: ShipmentInformationFormProps) => {
  const { quote, step } = usePolicyWizardContext();
  const { incoterms } = useAppConfiguration();
  const { configuration, data, isValid, errors } = useShipmentInformationForm();
  const setFormData = useSetFormData('shipment-info');
  const setFormError = useSetFormError();
  const validateInput = useInputValidation();

  if (!quote || !configuration) {
    return null;
  }

  const labels =
    data.transportMethod.primary &&
    SHIPMENT_FORM_LABELS[data.transportMethod.primary];
  const { etd, eta, containerIds, vehicleName, incoterm, customer } = data;

  const { company_name, address, email } = customer ?? {};

  return (
    <div className={styles.wrapper} data-testid="shipment-information-form">
      <Typography level="h2" className={styles.title}>
        We just need a few more details before we{' '}
        {configuration.isEuFreightForwarder ? 'refer' : 'issue'} the policy
        {configuration.isEuFreightForwarder ? '.' : ' '}
        <br />
        {configuration.isEuFreightForwarder
          ? 'Once completed, the insurer will contact the Cargo Owner.'
          : ''}
      </Typography>
      <div className={styles.body}>
        <FormLayout className={styles.form}>
          <FormRow>
            <Typography level="h3">Cargo Owner Details</Typography>
          </FormRow>
          <FormRow>
            <Input
              label="Cargo Owner Name"
              testId="customer-name-input"
              value={company_name}
              disabled={!!company_name}
              onChange={({ target: { value: company_name } }) =>
                setFormData({ customer: { company_name } })
              }
              error={errors?.customer?.company_name?.error}
              errorHelperText={errors?.customer?.company_name?.reason}
              validator={(value) =>
                validateInput('customer.company_name', value)
              }
            />
            <PlaceAutocomplete
              value={address as Place}
              inputProps={{
                label: 'Address',
                placeholder: 'Cargo owner address',
                testId: 'customer-address-autocomplete',
                required: configuration?.isAddressRequired,
                error: errors?.customer?.address?.error,
                errorHelperText: errors?.customer?.address?.reason,
                validator: (value) =>
                  validateInput('customer.address', value, {
                    required: configuration?.isAddressRequired,
                  }),
              }}
              onChange={(place) =>
                setFormData({ customer: { address: place } })
              }
              getOptionLabel={(value: Place) =>
                value?.address?.full_address ?? value.name ?? ''
              }
            />
          </FormRow>
          {configuration.isEuFreightForwarder && (
            <FormRow className={styles.partialRow}>
              <Input
                label="Cargo Owner Email"
                testId="customer-email-input"
                value={email}
                onChange={({ target: { value: email } }) =>
                  setFormData({ customer: { email } })
                }
                required={configuration.isEuFreightForwarder}
                error={errors?.customer?.email?.error}
                errorHelperText={errors?.customer?.email?.reason}
                validator={(value) =>
                  validateInput('customer.email', value, {
                    required: configuration.isEuFreightForwarder,
                  })
                }
              />
            </FormRow>
          )}
          <FormRow>
            <Typography level="h3">Shipment Details</Typography>
          </FormRow>
          <FormRow>
            <Input
              defaultValue={quote.external_reference ?? ''}
              disabled={!!quote.external_reference}
              label="Booking Reference"
              placeholder="Your internal reference"
              testId="shipment-information-booking-reference-input"
              onChange={({ target: { value: externalReference } }) =>
                setFormData({ externalReference })
              }
              error={errors?.externalReference?.error}
              errorHelperText={errors?.externalReference?.reason}
              validator={(value) =>
                validateInput('externalReference', value, {
                  required: configuration?.isShipmentIdRequired,
                })
              }
            />
            <Input
              value={containerIds ?? ''}
              label={labels?.containerIds}
              placeholder="Number1, number2,..."
              onChange={({ target: { value: containerIds } }) =>
                setFormData({ containerIds })
              }
              testId="container-ids-input"
              error={errors?.containerIds?.error}
              required={configuration?.isContainerNumbersRequired}
              helperText="Insert comma-separated numbers"
              errorHelperText={errors?.containerIds?.reason}
              validator={(value) =>
                validateInput('containerIds', value, {
                  required: configuration?.isContainerNumbersRequired,
                })
              }
            />
          </FormRow>
          <FormRow>
            <DatePicker
              value={etd}
              minDate={moment()
                .startOf('day')
                .subtract(ETD_MIN_DAYS_OFFSET, 'days')}
              onChange={(etd) => setFormData({ etd: etd ?? undefined })}
              onValidation={(error, reason) =>
                setFormError({ field: 'etd', error, reason })
              }
              testId="etd-date-picker"
              required={true}
              inputProps={{
                label: 'ETD',
                error: errors?.etd?.error,
                errorHelperText: errors?.etd?.reason,
              }}
            />
            <DatePicker
              value={eta}
              minDate={moment(etd).startOf('day')}
              onChange={(eta) => setFormData({ eta: eta ?? undefined })}
              onValidation={(error, reason) =>
                setFormError({ field: 'eta', error, reason })
              }
              testId="eta-date-picker"
              required={true}
              inputProps={{
                label: 'ETA',
                testId: 'eta-date-picker-input',
                error: errors?.eta?.error,
                errorHelperText: errors?.eta?.reason,
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              value={vehicleName ?? ''}
              label={labels?.vehicleName}
              placeholder="Enter name"
              testId="vehicle-name-input"
              onChange={({ target: { value: vehicleName } }) =>
                setFormData({ vehicleName })
              }
              required={false}
            />
            <IncotermSelector
              options={incoterms}
              selectedIncoterm={incoterm}
              onChange={(incoterm) => setFormData({ incoterm })}
            />
          </FormRow>
          <FormRow align="end">
            <Button
              variant="primary"
              width="fixed"
              data-testid="shipment-information-form-submit-button"
              onPress={onSubmit}
              isDisabled={!isValid || step === WizardSteps.LOADING}
              label={
                configuration.isEuFreightForwarder
                  ? 'Refer Policy'
                  : 'Create Policy'
              }
            />
          </FormRow>
        </FormLayout>
        <div className={styles.summary}>
          <PolicySummary quote={quote} />
        </div>
      </div>
    </div>
  );
};
