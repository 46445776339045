import { Currency, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { type PriceComponentTypographyProps } from '../PremiumBreakdown';
import styles from './PriceComponent.module.scss';

type PriceComponentProps = {
  title: string;
  name?: string;
  value?: number;
  currency?: SupportedCurrencies;
  className?: string;
  typographyProps?: PriceComponentTypographyProps;
};

const defaults: PriceComponentTypographyProps = {
  title: { className: styles.title },
  currency: {},
};

export const PriceComponent = ({
  title,
  value,
  currency,
  className,
  typographyProps,
  name = 'price-component',
}: PriceComponentProps) => {
  if (!value || !currency) {
    return null;
  }

  const titleProps = { ...defaults.title, ...typographyProps?.title };
  const currencyProps = { ...defaults.currency, ...typographyProps?.currency };

  return (
    <div className={classnames(styles.priceComponent, className)}>
      <Typography {...titleProps}>{title}</Typography>
      <Currency
        value={value}
        currency={currency}
        typographyProps={{ ...currencyProps, role: name }}
      />
    </div>
  );
};
