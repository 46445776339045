import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDTCFlowContext, useDTCStateAction } from './context/hooks';
import { type DTCFlowSteps, stepsByFlow } from './flows-steps-config';

type DTCSection =
  | 'start'
  | 'shipment-insurance'
  | 'annual-policy'
  | 'trade-credit-insurance';

const flowBaseUrl = '/flexport';

export const useDTCNavigation = () => {
  const navigate = useNavigate();
  const { step: currentStep, flowType } = useDTCFlowContext();
  const { setStep, resetFormsErrors } = useDTCStateAction();

  const navigateToDTCSection = useCallback(
    (section: DTCSection) => navigate(`${flowBaseUrl}/${section}`),
    [navigate],
  );

  const getPreviousStep = useCallback((): DTCFlowSteps => {
    if (!flowType) {
      return currentStep;
    }

    const flowSteps = stepsByFlow[flowType];
    const currentIdx = flowSteps.findIndex(({ step }) => step === currentStep);

    return currentIdx > 0 ? flowSteps[currentIdx - 1].step : currentStep;
  }, [currentStep, flowType]);

  const navigateToPreviousStep = useCallback(() => {
    resetFormsErrors();
    setStep(getPreviousStep());
  }, [getPreviousStep, resetFormsErrors, setStep]);

  const getNextStep = useCallback((): DTCFlowSteps => {
    if (!flowType) {
      return currentStep;
    }

    const flowSteps = stepsByFlow[flowType];
    const currentIdx = flowSteps.findIndex(({ step }) => step === currentStep);

    return currentIdx < flowSteps.length - 1
      ? flowSteps[currentIdx + 1].step
      : currentStep;
  }, [currentStep, flowType]);

  return { navigateToDTCSection, navigateToPreviousStep, getNextStep };
};
