/* eslint-disable react/no-children-prop */
import { useRef, useState } from 'react';
import {
  DatePicker,
  getDate,
  TextField,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import moment from 'moment';
import { z } from 'zod';

import { useAppConfiguration } from '../../../../context/app-configuration/AppConfigurationProvider';
import { useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext.tsx';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { type PlaceSuggestion } from '../../../../model/Place';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { CustomerComboBox } from '../../../components/CustomerComboBox/CustomerComboBox';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import { PlacesComboBox } from '../../../components/PlacesComboBox/PlacesComboBox';
import { LetterOfCreditSelector } from '../../../policy-wizard-v2/components/selectors/LetterOfCreditSelector/LetterOfCreditSelector.tsx';
import { getCarrierFieldLabel } from '../../../utils/forms';
import { type CertificateFormProps } from './types';

export function ShipmentDetailsForm({
  form,
}: {
  form: FormApi<CertificateFormProps, typeof zodValidator>;
}) {
  const { isWtw } = usePlatform();

  const user = useUser();

  const { currencies } = useAppConfiguration();
  const [isAssuredAddressInputDisabled, setIsAssuredAddressInputDisabled] =
    useState(form.getFieldValue('assuredName')?.company_name.length === 0);
  const assuredNameRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Typography
        level="h3"
        customStyles="pt-5 pb-4 sticky top-0 bg-gradient-to-b from-white from-80% to-transparent z-10"
      >
        Shipment Details
      </Typography>
      <div className="xl:grid xl:grid-cols-2 xl:gap-y-5 xl:gap-x-4 max-xl:space-y-6 mb-4">
        <form.Field
          name="etd"
          validators={{
            onSubmit: z.string().min(1, 'Please enter a valid date value'),
          }}
          children={(field) => (
            <DatePicker
              isRequired
              data-testid="etd-date-picker"
              label="Date of Departure"
              defaultValue={getDate(field.state.value)}
              id={field.name}
              name={field.name}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.toString())}
              isInvalid={field.state.meta.errors.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
              isDateUnavailable={(date) => {
                const etdMoment = moment(date.toString());
                const etaMoment = moment(field.form.getFieldValue('eta'));
                return etaMoment.isBefore(etdMoment);
              }}
            />
          )}
        />
        <form.Field
          name="eta"
          children={(field) => {
            return (
              <DatePicker
                data-testid="eta-date-picker"
                isInvalid={field.state.meta.errors.length > 0}
                label="Date of Arrival"
                defaultValue={getDate(field.state.value)}
                id={field.name}
                name={field.name}
                onBlur={field.handleBlur}
                isDateUnavailable={(date) => {
                  const etaMoment = moment(date.toString());
                  const etdMoment = moment(field.form.getFieldValue('etd'));
                  return etaMoment.isBefore(etdMoment);
                }}
                onChange={(e) => e && field.handleChange(e.toString())}
              />
            );
          }}
        />
        <form.Field
          name="cargoValue"
          validators={{
            onSubmit: ({ value }) => {
              if (Number(value) <= 0) {
                return 'Please enter a valid amount';
              }
              return;
            },
          }}
          children={(field) => {
            return (
              <CurrencyValueInput
                type="number"
                step="any"
                aria-required
                data-testid="cargo-value-input"
                // TODO The way this is implemented is different to the rest of the fields. It will be replaced so the evil type coersion can be ignored
                value={
                  field.state.value ? Number(field.state.value) : undefined
                }
                currencyOptions={currencies}
                name={field.name}
                id={field.name}
                onChange={(value) => {
                  field.handleChange(value ? value.toString() : '');
                }}
                onBlur={field.handleBlur}
                currency={
                  field.form.state.values.cargoCurrency as SupportedCurrencies
                }
                onCurrencyChange={(currency) => {
                  field.handleChange(field.form.state.values.cargoValue);
                  field.form.setFieldValue('cargoCurrency', currency, {
                    touch: true,
                  });
                }}
                label="Commercial Invoice Value *"
                placeholder="Enter amount"
                testId="commodity-value-input"
                error={field.state.meta.errors?.length > 0}
                errorHelperText={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />
        <form.Field
          name="bookingReference"
          children={(field) => (
            <TextField
              data-testid="booking-reference-input"
              placeholder="Enter booking reference"
              label="Reference"
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="cargoDescription"
          validators={{
            onSubmit: z.string().min(1, 'Please enter a valid description'),
            onChange: z
              .string()
              .max(300, 'Input exceeds maximum character limit of 300'),
          }}
          children={(field) => (
            <TextField
              isRequired
              data-testid="goods-description-text-area"
              variant="area"
              placeholder="Enter description"
              label="Description of Cargo"
              inputStyles="min-h-44 resize-none "
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <div className="space-y-5">
          <form.Subscribe
            selector={(state) => [state.values.primaryMot]}
            children={([primaryMot]) => (
              <form.Field
                name="vesselName"
                children={(field) => (
                  <TextField
                    data-testid="vessel-name-input"
                    placeholder="Enter name"
                    label={getCarrierFieldLabel(primaryMot)}
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={field.handleChange}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                  />
                )}
              />
            )}
          />
          <form.Field
            name="marksAndNumbers"
            validators={{
              onChange: z
                .string()
                .max(300, 'Input exceeds maximum character limit of 300'),
            }}
            children={(field) => (
              <TextField
                data-testid="marks-and-numbers-text-area"
                inputStyles="min-h-[100px] resize-none"
                variant="area"
                placeholder="Enter marks & numbers"
                label="Marks & Numbers"
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={field.handleChange}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            )}
          />
        </div>

        {isWtw && <LetterOfCreditSelector form={form} />}

        <div className="xl:grid xl:grid-cols-2 xl:col-span-full xl:gap-x-4 max-xl:space-y-6">
          <Typography level="h4" customStyles="xl:col-span-full mt-6 mb-4">
            Assured Details
          </Typography>
          <div className="xl:col-span-full gap-y-2 mb-5">
            <Typography level="subtext" color="tertiary">
              Primary Assured
            </Typography>
            <Typography>{user?.distributor?.legal_name}</Typography>
          </div>
          <form.Field
            name="assuredName"
            validators={{
              onSubmit: () => {
                if (
                  !assuredNameRef.current?.value &&
                  !isAssuredAddressInputDisabled
                ) {
                  return 'Please enter an assured name';
                }
                return;
              },
            }}
            children={(field) => (
              <CustomerComboBox
                data-testid="assured-name-combobox"
                inputRef={assuredNameRef}
                label="Named Assured"
                menuTrigger="focus"
                placeholder="Enter named assured name"
                id={field.name}
                name={field.name}
                onBlur={(e) => {
                  // This is to handle the case where the user only types the company name and does not select from the dropdown
                  const isCreatingNewCustomer =
                    field.state.value?.company_name !== e.target.value;
                  if (isCreatingNewCustomer) {
                    field.setValue({
                      company_name: e.target.value.trim(),
                    });
                    field.setMeta({
                      ...field.state.meta,
                      isDirty: true,
                    });
                  }

                  // If the user clears the input, we need to clear the assured address as well.
                  if (e.target.value.length === 0) {
                    field.form.setFieldValue('assuredAddress', {
                      description: '',
                      place_id: '',
                    });
                    setIsAssuredAddressInputDisabled(true);
                    field.setMeta({
                      ...field.state.meta,
                      isDirty: true,
                    });
                  }

                  setIsAssuredAddressInputDisabled(
                    e.target.value.trim().length === 0,
                  );
                  field.handleBlur();
                }}
                onSelectionChange={(option) => {
                  if (option) {
                    const customer: CustomerOption = JSON.parse(String(option));
                    field.handleChange(customer);
                    customer.address?.address &&
                      field.form.setFieldValue(
                        'assuredAddress',
                        {
                          description: customer.address?.address?.full_address,
                          place_id: customer.address?.provider_place_uuid,
                        },
                        { touch: false },
                      );
                    setIsAssuredAddressInputDisabled(false);
                  }
                }}
                aria-label="Assured Name"
                defaultInputValue={field.state.value?.company_name}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            )}
          />
          <form.Field
            name="assuredAddress"
            children={(field) => (
              <PlacesComboBox
                data-testid="assured-address-combobox"
                isDisabled={isAssuredAddressInputDisabled}
                aria-label="Named Assured Address"
                label="Named Assured Address"
                placeholder="Enter named assured address"
                id={field.name}
                name={field.name}
                onBlur={() => {
                  if (field.state.value?.place_id.length === 0) {
                    field.handleChange({
                      ...field.state.value,
                      place_id: '',
                      description: '',
                    });
                  }
                  field.handleBlur();
                }}
                selectedKey={JSON.stringify(field.state.value)}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
                inputValue={field.state.value?.description}
                onInputChange={(value) => {
                  field.handleChange({
                    ...field.state.value,
                    place_id: '',
                    description: value,
                  });
                }}
                onSelectionChange={(option) => {
                  const parsedOption: PlaceSuggestion = JSON.parse(
                    String(option),
                  );
                  const isFieldUpdated =
                    parsedOption.place_id !== field.prevState.value?.place_id;
                  isFieldUpdated && field.handleChange(parsedOption);
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
