import { useEffect } from 'react';
import {
  DatePicker,
  FormLayout,
  FormRow,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import { useAppConfiguration } from '../../../../context/app-configuration/AppConfigurationProvider';
import { useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Place, QueryLevelEnum } from '../../../../model/Place';
import { type Quote } from '../../../../model/Quote';
import { PlaceAutocomplete } from '../../../components/PlaceAutocomplete/PlaceAutocomplete';
import { PolicySummary } from '../../../components/PolicySummary/PolicySummary';
import { ETD_MIN_DAYS_OFFSET } from '../../../constants';
import labels from '../../../labels';
import { IncotermSelector } from '../../components/selectors/IncotermSelector';
import { SHIPMENT_FORM_LABELS, WizardSteps } from '../../constants';
import {
  useInputValidation,
  usePolicyWizardContext,
  useSetFormData,
  useSetFormError,
} from '../../hooks/context-hooks';
import { useShipmentInformationForm } from '../../hooks/use-shipment-information-form';

type ShipmentInformationFormProps = {
  quote: Quote;
  onSubmit: () => void;
};

export const ShipmentInformationForm = ({
  quote,
  onSubmit,
}: ShipmentInformationFormProps) => {
  const { isBreeze, isWtw } = usePlatform();

  const user = useUser();
  const { incoterms } = useAppConfiguration();

  const { step } = usePolicyWizardContext();
  const { configuration, data, isValid, errors } = useShipmentInformationForm();
  const setFormData = useSetFormData('shipment-info');
  const setFormError = useSetFormError();
  const validateInput = useInputValidation();

  useEffect(() => {
    if (isBreeze) return;

    setFormData({ issueDate: moment() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!configuration) {
    return null;
  }

  const shipmentFormLabels =
    quote.primary_transport_mode_code &&
    SHIPMENT_FORM_LABELS[quote.primary_transport_mode_code];
  const { etd, eta, issueDate, containerIds, vehicleName, incoterm, customer } =
    data;

  const { company_name, address, email } = customer ?? {};

  const enableReferPolicyFlow = Boolean(
    user?.settings.find((s) => s.name === 'enable_refer_a_policy_flow')?.value,
  );

  return (
    <div
      className="flex flex-col items-center py-7 px-11 bg-white rounded-[30px] mt-5"
      data-testid="shipment-information-form"
    >
      <Typography level="h2">
        We just need a few more details before we{' '}
        {enableReferPolicyFlow ? 'refer' : 'issue'} the{' '}
        {labels.terms.policy.singular}
        {enableReferPolicyFlow ? '.' : ' '}
        <br />
        {enableReferPolicyFlow
          ? 'Once completed, the insurer will contact the Cargo Owner.'
          : ''}
      </Typography>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-8 gap-y-12 xl:gap-y-0 mt-12 text-pretty">
        <FormLayout className="col-span-2">
          <FormRow>
            <Typography level="h3">
              {labels.fields.cargoOwner} Details
            </Typography>
          </FormRow>
          <FormRow>
            <Input
              label={`${labels.fields.cargoOwner} Name`}
              testId="customer-name-input"
              value={company_name}
              disabled={!!company_name}
              onChange={({ target: { value: company_name } }) =>
                setFormData({ customer: { company_name } })
              }
              error={errors?.customer?.company_name?.error}
              errorHelperText={errors?.customer?.company_name?.reason}
              validator={(value) =>
                validateInput('customer.company_name', value)
              }
            />
            <PlaceAutocomplete
              value={address as Place}
              suggestionsQueryLevel={QueryLevelEnum.STREET}
              inputProps={{
                label: 'Address',
                placeholder: 'Cargo owner address',
                testId: 'customer-address-autocomplete',
                required: configuration?.isAddressRequired,
                error: errors?.customer?.address?.error,
                errorHelperText: errors?.customer?.address?.reason,
                validator: (value) =>
                  validateInput('customer.address', value, {
                    required: configuration?.isAddressRequired,
                  }),
              }}
              onChange={(place) =>
                setFormData({ customer: { address: place } })
              }
              getOptionLabel={(value: Place) =>
                value?.address?.full_address ?? value.name ?? ''
              }
            />
          </FormRow>
          {enableReferPolicyFlow && (
            <FormRow className="col-span-1">
              <Input
                label="Cargo Owner Email"
                testId="customer-email-input"
                value={email}
                onChange={({ target: { value: email } }) =>
                  setFormData({ customer: { email } })
                }
                required={enableReferPolicyFlow}
                error={errors?.customer?.email?.error}
                errorHelperText={errors?.customer?.email?.reason}
                validator={(value) =>
                  validateInput('customer.email', value, {
                    required: enableReferPolicyFlow,
                  })
                }
              />
            </FormRow>
          )}
          <FormRow>
            <Typography level="h3">Shipment Details</Typography>
          </FormRow>

          <FormRow>
            {isWtw && (
              <DatePicker
                value={issueDate}
                minDate={
                  quote.open_cover
                    ? moment(quote.open_cover.inception_date)
                    : moment().startOf('day').subtract(365, 'days')
                }
                maxDate={
                  quote.open_cover
                    ? moment(quote.open_cover.termination_date)
                    : moment().startOf('day').add(365, 'days')
                }
                onChange={(issueDate) =>
                  setFormData({ issueDate: issueDate ?? undefined })
                }
                onValidation={(error, reason) =>
                  setFormError({ field: 'issueDate', error, reason })
                }
                testId="issue-date-picker"
                required={true}
                inputProps={{
                  label: labels.fields.issueDate,
                  error: errors?.issueDate?.error,
                  errorHelperText: errors?.issueDate?.reason,
                }}
              />
            )}
            <DatePicker
              value={etd}
              minDate={
                isWtw && quote.open_cover
                  ? moment(quote.open_cover.inception_date)
                  : moment()
                      .startOf('day')
                      .subtract(ETD_MIN_DAYS_OFFSET, 'days')
              }
              maxDate={
                isWtw
                  ? quote.open_cover
                    ? moment(quote.open_cover.termination_date)
                    : moment().startOf('day').add(365, 'days')
                  : undefined
              }
              onChange={(etd) => setFormData({ etd: etd ?? undefined })}
              onValidation={(error, reason) =>
                setFormError({ field: 'etd', error, reason })
              }
              testId="etd-date-picker"
              required={true}
              inputProps={{
                label: labels.fields.etd,
                error: errors?.etd?.error,
                errorHelperText: errors?.etd?.reason,
              }}
            />
            <DatePicker
              value={eta}
              minDate={
                isWtw && quote.open_cover
                  ? moment(quote.open_cover.inception_date)
                  : moment(etd).startOf('day')
              }
              maxDate={
                isWtw
                  ? quote.open_cover
                    ? moment(quote.open_cover.termination_date)
                    : moment().startOf('day').add(365, 'days')
                  : undefined
              }
              onChange={(eta) => setFormData({ eta: eta ?? undefined })}
              onValidation={(error, reason) =>
                setFormError({ field: 'eta', error, reason })
              }
              testId="eta-date-picker"
              required={isWtw ? false : true}
              inputProps={{
                testId: 'eta-date-picker-input',
                label: labels.fields.eta,
                error: errors?.eta?.error,
                errorHelperText: errors?.eta?.reason,
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              defaultValue={quote.external_reference ?? ''}
              disabled={!!quote.external_reference}
              label={labels.fields.bookingReference}
              placeholder="Your internal reference"
              testId="shipment-information-booking-reference-input"
              onChange={({ target: { value: externalReference } }) =>
                setFormData({ externalReference })
              }
              error={errors?.externalReference?.error}
              errorHelperText={errors?.externalReference?.reason}
              validator={(value) =>
                validateInput('externalReference', value, {
                  required: configuration?.isShipmentIdRequired,
                })
              }
            />
            <Input
              value={containerIds ?? ''}
              label={
                isWtw
                  ? labels.fields.containers
                  : shipmentFormLabels?.containerIds
              }
              placeholder="Number1, number2,..."
              onChange={({ target: { value: containerIds } }) =>
                setFormData({ containerIds })
              }
              testId="container-ids-input"
              error={errors?.containerIds?.error}
              required={configuration?.isContainerNumbersRequired}
              helperText="Insert comma-separated numbers"
              errorHelperText={errors?.containerIds?.reason}
              validator={(value) =>
                validateInput('containerIds', value, {
                  required: configuration?.isContainerNumbersRequired,
                })
              }
            />
          </FormRow>
          <FormRow>
            <Input
              value={vehicleName ?? ''}
              label={shipmentFormLabels?.vehicleName}
              placeholder="Enter name"
              testId="vehicle-name-input"
              onChange={({ target: { value: vehicleName } }) =>
                setFormData({ vehicleName })
              }
              required={false}
            />
            <IncotermSelector
              options={incoterms}
              selectedIncoterm={incoterm}
              onChange={(incoterm) => setFormData({ incoterm })}
            />
          </FormRow>
          <FormRow align="end" className="mt-auto">
            <Button
              variant="primary"
              data-testid="shipment-information-form-submit-button"
              onPress={onSubmit}
              isDisabled={!isValid || step === WizardSteps.LOADING}
              label={
                enableReferPolicyFlow
                  ? `Refer ${capitalize(labels.terms.policy.singular)}`
                  : `Create ${capitalize(labels.terms.policy.singular)}`
              }
            />
          </FormRow>
        </FormLayout>
        <div className="row-start-1 xl:row-auto">
          <PolicySummary quote={quote} />
        </div>
      </div>
    </div>
  );
};
