import { clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';

export function StorageLocationDetailsSectionLayout({
  title,
  children,
  isCollapsed,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  isCollapsed?: boolean;
}) {
  return (
    <div className="w-full rounded-md bg-system-grey-white p-6">
      <div className={clsxMerge(!isCollapsed && 'border-b-2 pb-7')}>
        {typeof title === 'string' ? (
          <Typography level="h4" customStyles="text-text-secondary">
            {title}
          </Typography>
        ) : (
          title
        )}
      </div>
      {!isCollapsed && <div className="pt-7">{children}</div>}
    </div>
  );
}
