import { useEffect } from 'react';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useRouteParams } from '../../../router/router-hooks';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ClaimCreationFlowSteps } from '../constants';
import { ClaimCreationStepsIndicator } from './ClaimCreationStepsIndicator/ClaimCreationStepsIndicator';
import { ClaimDetails } from './ClaimDetails/ClaimDetails';
import { ClaimFilesUpload } from './ClaimFilesUpload/ClaimFilesUpload';
import { ClaimReview } from './ClaimReview/ClaimReview';
import { ClaimCreationContextProvider } from './context/ClaimCreationContext';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
  useInitClaimForms,
} from './context/hooks';

type RouteSearchParams = {
  certificate_id?: string;
};

export const ClaimCreationFlowContent = () => {
  useInitClaimForms();
  const { step } = useClaimCreationFlowContext();
  const { setFormData } = useClaimCreationStateAction();

  const params = useRouteParams<never, RouteSearchParams>();

  useEffect(() => {
    if (params) {
      setFormData({ certificateId: params.searchParams.certificate_id });
    }
  }, [params, setFormData]);

  return (
    <SectionLayout
      data-testid="claim-creation-flow"
      title={<ClaimCreationStepsIndicator />}
    >
      <div className="flex flex-col h-full w-full gap-y-6 pt-10 items-center">
        {step === ClaimCreationFlowSteps.CLAIM_DETAILS && <ClaimDetails />}
        {step === ClaimCreationFlowSteps.UPLOAD_FILES && <ClaimFilesUpload />}
        {step === ClaimCreationFlowSteps.REVIEW && <ClaimReview />}
      </div>
    </SectionLayout>
  );
};

export const ClaimCreationFlow = () => {
  useTracking();

  return (
    <ClaimCreationContextProvider>
      <ClaimCreationFlowContent />
    </ClaimCreationContextProvider>
  );
};
