import { captureException, captureMessage, withScope } from '@sentry/react';

import { type SeverityLevel } from './types';

export const reportException = (exception: unknown) =>
  captureException(exception);

export const reportMessage = (message: string, severity?: SeverityLevel) =>
  captureMessage(message, severity);

type ErrorReportingScope = {
  setLevel: (level: SeverityLevel) => void;
};

export const scopedReporting = (
  scopedFunc: (scope: ErrorReportingScope) => void,
) => {
  withScope(scopedFunc);
};
