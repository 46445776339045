import { useEffect } from 'react';
import { Spinner } from '@breezeai-frontend/cargo-ui';

import { get } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';

export type GetAuthTradeflowLoginResponse = {
  auth_url: string;
  response_type: string;
  client_id: string;
  scope: string;
};

export function RICSignInRedirect({ gcId }: { gcId: string }) {
  useEffect(() => {
    get<GetAuthTradeflowLoginResponse>(
      `${bffServiceUrl}/auth/tradeflow/login`,
      undefined,
      { auth: false },
    ).then((data) => {
      const { auth_url, response_type, client_id, scope } = data;

      const { protocol, host } = window.location;

      const redirectURI = encodeURIComponent(
        `${protocol}//${host}/login/callback`,
      );
      const state = encodeURIComponent(
        `${protocol}//${host}/login/callback?gcid=${gcId}`,
      );

      const stsUrl = `${auth_url}?response_type=${encodeURIComponent(
        response_type,
      )}&client_id=${encodeURIComponent(client_id)}&scope=${encodeURIComponent(
        scope,
      )}&redirect_uri=${redirectURI}&state=${state}`;

      window.location.replace(stsUrl);
    });
  }, [gcId]);

  return <Spinner data-testid="ric-spinner" className="self-center" />;
}
