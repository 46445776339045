import { useCallback } from 'react';

import {
  isInValidCommodityRange,
  isNonEmpty,
  isValidCommaSeparatedList,
  isValidEmail,
  type ValidationOptions,
  type ValidationResult,
  type Validator,
} from '../../../utils/validators';
import { useDTCForms, useDTCStateAction } from '../context/hooks';
import { type DTCFormsData } from '../context/types';

const getFieldValidators: {
  [K in keyof Partial<DTCFormsData>]: (
    options?: ValidationOptions,
  ) => Validator[];
} = {
  companyName: () => [isNonEmpty],
  companyAddress: () => [isNonEmpty],
  contactName: () => [isNonEmpty],
  contactEmail: () => [isValidEmail, isNonEmpty],
  contactPhone: () => [isNonEmpty],
  yearsInBusinessId: () => [isNonEmpty],
  originPlace: () => [isNonEmpty],
  originPort: () => [isNonEmpty],
  destinationPlace: () => [isNonEmpty],
  destinationPort: () => [isNonEmpty],
  etd: () => [isNonEmpty],
  eta: () => [isNonEmpty],
  containerModeId: () => [isNonEmpty],
  containerIds: () => [isValidCommaSeparatedList],
  commodityValue: () => [isInValidCommodityRange, isNonEmpty],
  commodityDescription: () => [isNonEmpty],
  commodityCategoryId: () => [isNonEmpty],
  freightCost: () => [isInValidCommodityRange],
  previousCreditInsurance: () => [isNonEmpty],
  companyIndustry: () => [isNonEmpty],
};

export const useDTCInputValidation = () => {
  const { setFormError } = useDTCStateAction();

  return useCallback(
    (field: keyof DTCFormsData, value: string, options?: ValidationOptions) => {
      const checks = getFieldValidators?.[field]?.(options) ?? [];
      const validationResult = checks.reduce<ValidationResult>(
        (result, check) => {
          const { valid, reason } = check(value);

          if (!valid && reason) {
            result.error = true;
            result.reason = reason;
          }

          return result;
        },
        { field, error: false },
      );

      setFormError(validationResult);
      return !validationResult.error;
    },
    [setFormError],
  );
};

export const useDTCFormValidation = () => {
  const { data: formsData, errors } = useDTCForms();

  return useCallback(
    (requiredFields: (keyof DTCFormsData)[]) => {
      const formContainsErrors = Object.values(errors).some((e) => e);
      const requiredFieldsFilled = requiredFields.every(
        (field) => formsData?.[field as keyof DTCFormsData] !== undefined,
      );

      return requiredFieldsFilled && !formContainsErrors;
    },
    [errors, formsData],
  );
};
