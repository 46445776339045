import { useEffect, useState } from 'react';

import { type Invoice } from '../../../model/Invoice';
import { getInvoices } from '../../../network/apis/invoices/invoices';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';

export const useInvoices = (): Invoice[] | undefined => {
  const [invoices, setInvoices] = useState<Invoice[]>();
  const { setError } = useSectionError();

  useEffect(() => {
    if (!invoices) {
      getInvoices()
        .then(setInvoices)
        .catch((e) => {
          reportException(e);
          setError(e);
        });
    }
  }, [invoices, setError]);

  return invoices;
};
