import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { type ErrorResponse } from '../../../network/apis/types';
import { get } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';
import { type StorageLocationModel } from '../types';

export const useStorageLocation = ({
  options,
  policyId,
}: {
  policyId?: string;
  options?: UseQueryOptions<
    StorageLocationModel,
    ErrorResponse,
    StorageLocationModel
  >;
}) => {
  const key = ['storageLocation', policyId];

  return useQuery({
    enabled: !!policyId,
    queryKey: key,
    queryFn: () =>
      get<StorageLocationModel>(
        `${bffServiceUrl}/bff-storage-locations/${policyId}`,
      ),
    ...options,
  });
};
