import { useCallback } from 'react';

import { LOCATION_TYPES_BY_CONVEYANCE } from '../../constants';
import { type PolicyWizardFormsData } from '../context/types';
import { checkFormStateIsDirty, consolidateFormData } from '../context/utils';
import { useMappedFormsErrors, usePolicyWizardContext } from './context-hooks';

/**
 * Determines whether there are required fields that aren't yet filled out
 */
const useAreRequiredFieldsNotEmpty = () => {
  const { configuration } = usePolicyWizardContext();

  return useCallback(
    (data: PolicyWizardFormsData) => {
      if (!data || !data?.transportMethod?.primary) {
        return false;
      }

      const shipmentLocationType =
        LOCATION_TYPES_BY_CONVEYANCE[data?.transportMethod.primary];

      const origin = data.origin?.[shipmentLocationType];
      const destination = data.destination?.[shipmentLocationType];

      return [
        origin,
        destination,
        data?.commodity?.categoryId,
        data?.commodity?.value,
        data?.commodity?.description,
        data?.customer?.company_name,
        configuration?.isFreightCostRequired ? data?.freightCost : true,
        data?.containerModeId,
      ].every((v) => v === 0 || !!v);
    },
    [configuration?.isFreightCostRequired],
  );
};

/**
 * An entry point hook for exposing Quote form aggregated data
 * to consumer components.
 * In case there are any pending updates to the Quote data, they'll override the original data.
 */
export const useQuoteForm = () => {
  const { configuration, forms } = usePolicyWizardContext();
  const areRequiredFieldsFilled = useAreRequiredFieldsNotEmpty();
  const errorMap = useMappedFormsErrors();

  const { data, updates } = forms;

  const updatedData = consolidateFormData(
    data,
    updates as PolicyWizardFormsData,
  );
  const isDirty = checkFormStateIsDirty(data, updates);
  const isValid =
    areRequiredFieldsFilled(updatedData) && forms.errors.length === 0;

  return {
    data: updatedData,
    errors: errorMap,
    isValid,
    isDirty,
    configuration,
  };
};
