import { useCallback, useState } from 'react';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { useRouteDetailsContext } from './RouteDetailsInputProvider';
import { type RouteDetailsFormData } from './types';
import { isLocation, isPlaceLocation, isPortLocation } from './utils';

export const useRouteDetailsLogic = (
  form: FormApi<RouteDetailsFormData, typeof zodValidator>,
) => {
  const primaryMot = form.getFieldValue('primaryMot');

  const allFields: Array<keyof RouteDetailsFormData> = [
    'origin',
    'destination',
    'placeOfLoading',
    'placeOfDelivery',
    'primaryMot',
    'placeOfLoadingMot',
    'placeOfDeliveryMot',
  ];

  const [showSecondaryTransport, setShowSecondaryTransport] = useState<boolean>(
    primaryMot === 'sea' || primaryMot === 'air',
  );

  const [showPlaceOfLoading, setShowPlaceOfLoading] = useState<boolean>(
    Boolean(form.getFieldValue('placeOfLoading')),
  );
  const [showPlaceOfDelivery, setShowPlaceOfDelivery] = useState<boolean>(
    Boolean(form.getFieldValue('placeOfDelivery')),
  );

  // TODO: It would nice to remove this state so input labels can be associated with values (as in certs form)
  const {
    originInputValue,
    destinationInputValue,
    placeOfLoadingInputValue,
    placeOfDeliveryInputValue,
    setOriginInputValue,
    setDestinationInputValue,
    setPlaceOfLoadingInputValue,
    setPlaceOfDeliveryInputValue,
  } = useRouteDetailsContext((state) => ({
    originInputValue: state.originInputValue,
    destinationInputValue: state.destinationInputValue,
    placeOfLoadingInputValue: state.placeOfLoadingInputValue,
    placeOfDeliveryInputValue: state.placeOfDeliveryInputValue,
    setOriginInputValue: state.setOriginInputValue,
    setDestinationInputValue: state.setDestinationInputValue,
    setPlaceOfLoadingInputValue: state.setPlaceOfLoadingInputValue,
    setPlaceOfDeliveryInputValue: state.setPlaceOfDeliveryInputValue,
  }));

  const isRouteDetailsDirty = allFields.some(
    (field) => form.getFieldMeta(field)?.isDirty,
  );

  const resetRouteDetails = () => {
    const defaultValues = form.options.defaultValues ?? {};

    allFields.forEach((field) => {
      // Get field default value
      const defaultValue = defaultValues[field];
      // Set field value to default value
      form.setFieldValue(field, defaultValue);
      // Reset field meta
      form.setFieldMeta(field, {
        isTouched: false,
        errors: [],
        isPristine: true,
        errorMap: {},
        isValidating: false,
        touchedErrors: [],
        isDirty: false,
      });
      let label = '';
      // Set input value based on default value
      switch (field) {
        case 'origin':
        case 'destination':
          if (isLocation(defaultValue) && isPortLocation(defaultValue)) {
            label = `${defaultValue?.port?.code} - ${defaultValue?.port?.name}, ${defaultValue?.port?.country_name}`;
            field === 'origin'
              ? setOriginInputValue(label)
              : setDestinationInputValue(label);
            break;
          }
          if (isLocation(defaultValue) && isPlaceLocation(defaultValue)) {
            label = defaultValue?.place?.description;
            field === 'origin'
              ? setOriginInputValue(label)
              : setDestinationInputValue(label);
            break;
          }
          field === 'origin'
            ? setOriginInputValue('')
            : setDestinationInputValue('');

          break;
        case 'placeOfLoading':
        case 'placeOfDelivery':
          if (isLocation(defaultValue) && isPlaceLocation(defaultValue)) {
            label = defaultValue?.place?.description;
            field === 'placeOfLoading'
              ? setPlaceOfLoadingInputValue(label)
              : setPlaceOfDeliveryInputValue(label);
            // Show the input field if it has been hidden
            field === 'placeOfLoading'
              ? setShowPlaceOfLoading(true)
              : setShowPlaceOfDelivery(true);
            break;
          }
          handleCloseSecondary(
            field === 'placeOfLoading' ? 'loading' : 'delivery',
          );
          break;
      }
    });
  };

  const handleCloseSecondary = useCallback(
    (type: 'loading' | 'delivery') => {
      const fields: Array<keyof RouteDetailsFormData> =
        type === 'loading'
          ? ['placeOfLoading', 'placeOfLoadingMot']
          : ['placeOfDelivery', 'placeOfDeliveryMot'];
      const isUpdate = !!form.options.defaultValues?.[fields[0]];
      fields.forEach((field) => {
        const updatedValue = isUpdate ? null : undefined;
        form.setFieldValue(field, updatedValue);
        form.setFieldMeta(field, {
          isTouched: isUpdate ? true : false,
          errors: [],
          isPristine: isUpdate ? false : true,
          errorMap: {},
          isValidating: false,
          touchedErrors: [],
          isDirty: isUpdate ? true : false,
        });
        form.setFieldValue(field, updatedValue);
        switch (field) {
          case 'placeOfLoading':
            setPlaceOfLoadingInputValue('');
            setShowPlaceOfLoading(false);
            break;
          case 'placeOfDelivery':
            setPlaceOfDeliveryInputValue('');
            setShowPlaceOfDelivery(false);
            break;
        }
      });
    },
    [form, setPlaceOfDeliveryInputValue, setPlaceOfLoadingInputValue],
  );

  const handleCloseSecondaries = useCallback(() => {
    handleCloseSecondary('loading');
    handleCloseSecondary('delivery');
    setShowSecondaryTransport(false);
  }, [handleCloseSecondary]);

  return {
    primaryMot,
    showSecondaryTransport,
    setShowSecondaryTransport,
    showPlaceOfLoading,
    setShowPlaceOfLoading,
    showPlaceOfDelivery,
    setShowPlaceOfDelivery,
    originInputValue,
    destinationInputValue,
    placeOfLoadingInputValue,
    placeOfDeliveryInputValue,
    setOriginInputValue,
    setDestinationInputValue,
    setPlaceOfLoadingInputValue,
    setPlaceOfDeliveryInputValue,
    resetRouteDetails,
    handleCloseSecondary,
    handleCloseSecondaries,
    isRouteDetailsDirty,
  };
};
