import { Icon, Popover, Typography } from '@breeze-ai/ui-library';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../context/auth/auth-hooks';
import { useTracking } from '../../utils/snowplow/hooks';
import styles from './InvoicesPage.module.scss';
import { InvoicesTable } from './InvoicesTable/InvoicesTable';
import { useInvoices } from './InvoicesTable/use-invoices';

export const InvoicesPage = () => {
  useTracking({
    eventFeature: 'invoice',
    eventSubfeature: 'list',
    eventAction: 'page_view',
    eventTrigger: 'invoices',
  });

  const user = useUser();

  const invoices = useInvoices();

  const anchorElement = (
    <span role="premium-breakdown-anchor">
      <Icon icon="info-circle" size="sm" className={styles.anchorElement} />
    </span>
  );

  const title = (
    <div>
      Invoices
      <Popover
        anchorElement={anchorElement}
        trigger="hover"
        className={styles.wrapper}
        placement="bottom-right"
        role="premium-breakdown-tooltip"
      >
        <Typography className={styles.header}>
          Invoices are automatically generated and will appear here at the
          beginning of each calendar month. We will also send you a copy of the
          invoice over email.
          <br />
          <br />
          Please contact us if you would like to change your billing email
          address(es), which is currently set to:{' '}
          {user?.distributor.billing_email}.
          <br />
          <br />
          Payment is due within 30 days of invoice issue date. If payment is not
          received on time, affected policies may be cancelled.
          <br />
          <br />
          We accept payment by wire or bank transfer only.
        </Typography>
      </Popover>
    </div>
  );

  return (
    <SectionLayout title={title} data-testid="invoices-page">
      <InvoicesTable invoices={invoices} />
    </SectionLayout>
  );
};
