import {
  Alert,
  Dialog,
  Typography,
  useWindowSize,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useDTCFlowContext } from '../../../context/hooks';
import styles from './QuoteConfirmationDialog.module.scss';

type QuoteConfirmationDialogProps = {
  visible?: boolean;
  loading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const QuoteConfirmationDialog = ({
  visible,
  loading,
  onClose,
  onConfirm,
}: QuoteConfirmationDialogProps) => {
  const { customer } = useDTCFlowContext();
  const { isMobile } = useWindowSize();

  return (
    <Dialog
      visible={visible}
      alignment="start"
      width={560}
      onClose={onClose}
      closable={false}
      className={styles.confirmationDialog}
      componentClassNames={{ content: styles.content }}
      maskClassName={styles.mask}
      data-testid="dtc-confirmation-dialog"
    >
      <Dialog.Title slot="title">
        <Typography level={isMobile ? 'h3' : 'h2'}>
          Confirm your email address
        </Typography>
      </Dialog.Title>
      <Dialog.Content slot="content">
        <Typography
          variant="secondary"
          className={styles.chip}
          role="customer-email"
        >
          {customer?.email}
        </Typography>
        <div className={styles.infoList}>
          <Typography className={styles.item} prefixIcon="check-circle">
            Your policy documents will arrive by email. This may take a few
            minutes.
          </Typography>
          <Typography className={styles.item} prefixIcon="check-circle">
            <Typography bold>A payment link</Typography> will be shared in the
            email for online payment.
          </Typography>
        </div>
        <Alert variant="warning" className={styles.alert}>
          <Typography>
            If your payment is not received before the shipment departure date,
            your policy will be invalidated and canceled.
          </Typography>
        </Alert>
      </Dialog.Content>
      <Dialog.Footer slot="footer">
        <Button
          variant="secondary"
          onPress={onClose}
          width="fixed"
          aria-details="cancel-button"
          data-testid="cancel-button"
          label="Go back"
        />
        <Button
          variant="primary"
          width="fixed"
          aria-details="confirm-button"
          data-testid="confirm-button"
          onPress={onConfirm}
          isLoading={loading}
          label="Confirm"
        />
      </Dialog.Footer>
    </Dialog>
  );
};
