import { Icon, Link, Typography, useWindowSize } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useDTCFlowContext } from '../../context/hooks';
import styles from './GeneralSuccessPage.module.scss';

export const GeneralSuccessPage = () => {
  const { isMobile } = useWindowSize();
  const { brand } = useDTCFlowContext();
  const { supportMail, homepageUrl } = brand;

  return (
    <div className={styles.successPage} data-testid="dtc-success-page">
      <Icon icon="check-circle" className={styles.icon} />
      <div className={styles.text}>
        <Typography level={isMobile ? 'h3' : 'h2'}>
          We've received your request and we're on it!
        </Typography>
        <Typography block>
          A representative will be in touch within 2 business days to complete
          the process.
        </Typography>
      </div>
      <Link href={homepageUrl} underline={false}>
        <Button variant="secondary" width="fixed" label="Back to Home Page" />
      </Link>
      <Typography block className={styles.supportMail} role="support-email">
        Need help? Drop us an email{' '}
        <Link href={`mailto:${supportMail}`} underline={false}>
          {supportMail}
        </Link>
      </Typography>
    </div>
  );
};
