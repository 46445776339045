import { toLower, upperCase, upperFirst } from 'lodash';

import { ContainerModeEnum } from '../../../model/Shipment';

// TODO I'm not sure where this helper function shoud live
export const getFormattedContainerMode = (containerMode?: string): string => {
  return upperCase(containerMode) === upperCase(ContainerModeEnum.PALLET) ||
    upperCase(containerMode) === upperCase(ContainerModeEnum.CONTAINER) ||
    upperCase(containerMode) === upperCase(ContainerModeEnum.CARTONS) ||
    upperCase(containerMode) === upperCase(ContainerModeEnum.PARCELS) ||
    upperCase(containerMode) === upperCase(ContainerModeEnum.SCN)
    ? upperFirst(toLower(containerMode))
    : upperCase(containerMode);
};
