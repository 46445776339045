import { Suspense } from 'react';
import {
  LinkButton,
  SectionLayout,
  Spinner,
} from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { PlatformErrorBoundary } from '../../router/PlatformErrorBoundary';
import { useTracking } from '../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { ClaimsTable } from './ClaimsTable/ClaimsTable';

export const ClaimsPage = () => {
  useTracking();
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  if (!user) {
    return null;
  }

  return (
    <PlatformErrorBoundary>
      <Suspense fallback={<Spinner className="self-center" />}>
        <SectionLayout
          title="Claims"
          tooltip={
            isWtw && <ActiveOpenCoverTooltip distributor={user.distributor} />
          }
          data-testid="claims-page"
          actions={
            <LinkButton
              // TODO THEMING: Replace href with the enum
              href="/claims/start"
              data-testid="get-quote-button"
              variant="primary"
              width="auto"
              label="File A Claim"
            />
          }
        >
          <ClaimsTable />
        </SectionLayout>
      </Suspense>
    </PlatformErrorBoundary>
  );
};
