import {
  isInValidCommodityRange,
  isNonEmpty,
  isPositive,
  isValidCommaSeparatedList,
  isValidEmail,
  type ValidationOptions,
  type ValidationResult,
  type Validator,
} from '../../utils/validators';

const getValidators: {
  [key: string]: (options: ValidationOptions) => Validator[];
} = {
  freightCost: ({ required }) => [
    ...(required ? [isNonEmpty] : []),
    isPositive,
  ],
  dutyCost: () => [isPositive],
  'commodity.value': () => [isNonEmpty, isInValidCommodityRange],
  'commodity.description': () => [isNonEmpty],
  'commodity.categoryId': () => [isNonEmpty],
  'customer.company_name': () => [isNonEmpty],
  'customer.address': ({ required }) => (required ? [isNonEmpty] : []),
  'customer.email': ({ required }) => [
    ...(required ? [isNonEmpty] : []),
    isValidEmail,
  ],
  shipmentId: ({ required }) => (required ? [isNonEmpty] : []),
  containerIds: ({ required }) => [
    ...(required ? [isNonEmpty] : []),
    isValidCommaSeparatedList,
  ],
};

export const validate = (
  field: string,
  value: string,
  options: ValidationOptions = {},
): ValidationResult => {
  const checks = getValidators?.[field]?.(options) ?? [];
  return checks.reduce<ValidationResult>(
    (result, check) => {
      const { valid, reason } = check(value);

      if (!valid && reason) {
        result.error = true;
        result.reason = reason;
      }

      return result;
    },
    {
      field,
      error: false,
    },
  );
};
