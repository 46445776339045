import { useContext } from 'react';

import {
  type EntitySetting,
  type SettingName,
} from '../../model/EntitySetting';
import { type FeatureToggle, type User } from '../../model/User';
import { type AuthActions, AuthContext } from './AuthContext';

/**
 *
 * @returns the authenticated user details, or `undefined` if there is
 * no user
 */
export const useUser = (): User | undefined => {
  const { user } = useContext(AuthContext);

  return user;
};

/**
 *
 * @returns the authenticated user. If no user object is found,
 * an error will be thrown. This hook can be used in "auth-only"
 * routes to avoid having to guard against an `undefined` user
 * object
 */
export const useAuthenticatedUser = (): User => {
  const { user } = useContext(AuthContext);

  if (!user) {
    throw 'Expected user to be authenticated.';
  }

  return user;
};

export const useAuthActions = () => {
  const { login, resetPassword, logout } = useContext(AuthContext);

  return { login, resetPassword, logout } as Required<AuthActions>;
};

/**
 * Returns `true` if the specified `featureToggle` is toggled on for the
 * authenticated user, or `false` otherwise.
 */
export const useFeatureToggle = (
  featureToggle: FeatureToggle | undefined,
): boolean => {
  const user = useUser();

  if (!user || !featureToggle) {
    return false;
  }

  const { feature_toggles } = user;
  return !!feature_toggles?.[featureToggle];
};

/**
 * Returns the complete list of supported settings definitions along with
 * the user override values, where available.
 */
export const useUserSettingsList = (): EntitySetting[] => {
  const { settings } = useUser()!;

  return settings;
};

/**
 * Returns the specific setting override `value` defined by the user, or
 * falls back to the `default_value`.
 */
export const useUserSetting = <T = string | number | boolean>(
  settingName: SettingName,
): T => {
  const { settings } = useUser()!;
  const setting = settings.find((s) => s.name == settingName)!;

  return (setting?.value ?? setting?.default_value) as unknown as T;
};
