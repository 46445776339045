import { FaCheck } from 'react-icons/fa';
import { clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';

function StepCircle({
  step,
  isCurrentStep,
  isComplete,
}: {
  step: { number: number; name: string };
  isCurrentStep?: boolean;
  isComplete?: boolean;
}) {
  return (
    <div className="relative flex justify-center items-center">
      <div
        className={clsxMerge(
          'rounded-full size-8 flex justify-center items-center transition-colors',
          {
            'bg-loader-loader-fill text-white': isCurrentStep,
            'bg-field-border-default text-text-disabled': !isCurrentStep,
            'bg-system-green-500 text-white': isComplete,
          },
        )}
      >
        {isComplete ? (
          <FaCheck />
        ) : (
          <Typography level="h5">{step.number}</Typography>
        )}
      </div>
      <Typography
        color={isCurrentStep ? 'primary' : 'disabled'}
        customStyles="absolute w-max top-10"
      >
        {step.name}
      </Typography>
    </div>
  );
}

export function StepperProgressBar({ currentStep }: { currentStep: number }) {
  return (
    <div className="flex flex-row justify-center items-center w-full h-20 px-[104px] my-3">
      <StepCircle
        step={{
          number: 1,
          name: 'Location & Capacity Details',
        }}
        isCurrentStep={currentStep === 1}
      />
      <div className="flex-1 bg-field-border-default h-0.5" />
      <StepCircle
        step={{
          number: 2,
          name: 'Survey Report & Other Documents',
        }}
        isCurrentStep={currentStep === 2}
      />
    </div>
  );
}
