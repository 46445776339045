import {
  type DateType,
  formatDate as dateFormatter,
  type FormatterOptions,
  normalizeDate,
} from '@breeze-ai/ui-library';

import { getAppLocale } from './locale';

type ISODateFormatterOptions = {
  time?: boolean;
};

type RangeFormatterOptions = FormatterOptions & {
  startDate: DateType;
  endDate: DateType;
  separator?: string;
};

export const staticDateFormats = {
  ISO_DATE: 'YYYY-MM-DD',
  ISO_DATE_WITH_TIME: 'YYYY-MM-DDTHH:mm:ss',
  SHORT_MONTH: 'MMM',
  FULL_MONTH: 'MMMM',
  FULL_MONTH_YEAR: 'MMMM YYYY',
};

export const toISODate = (
  date: DateType,
  { time = false }: ISODateFormatterOptions = {},
): ISODate => {
  const format = time
    ? staticDateFormats.ISO_DATE_WITH_TIME
    : staticDateFormats.ISO_DATE;

  return dateFormatter(normalizeDate(date), { customFormat: format });
};

export const formatDate = (date: DateType, options?: FormatterOptions) => {
  const locale = options?.locale ?? getAppLocale();
  const normalizedDate = normalizeDate(date);
  return dateFormatter(normalizedDate, {
    locale,
    invalidFormat: '—/—/—',
    ...options,
  });
};

export const formatDateRange = (options: RangeFormatterOptions) => {
  const {
    startDate,
    endDate,
    locale = getAppLocale(),
    format = 'FULL_DATE_NUMERIC',
    separator = ' - ',
    invalidFormat = '—/—/—',
  } = options;

  const start = normalizeDate(startDate);
  const end = normalizeDate(endDate);

  return [start, end]
    .map((d) => dateFormatter(d, { format, locale, invalidFormat }))
    .join(separator);
};
