import { useEffect, useState } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { trackPageView } from '../../../../utils/snowplow/utils';
import { QuoteCoverageDescriptions } from '../../../components/QuoteCoverageDescriptions/QuoteCoverageDescriptions';
import { QuoteDetails } from '../../../components/QuoteDetails/QuoteDetails';
import { QuoteManualReviewMessage } from '../../components/QuoteManualReviewMessage/QuoteManualReviewMessage';
import { ShareQuoteDialog } from '../../components/ShareQuoteDialog/ShareQuoteDialog';
import { WIZARD_ERROR_MESSAGES, WizardSteps } from '../../constants';
import {
  usePolicyWizardContext,
  useSetWizardError,
  useSetWizardStep,
} from '../../hooks/context-hooks';
import { useQuoteForm } from '../../hooks/use-quote-form';
import { useShareQuote } from '../../hooks/use-share-quote';
import styles from './QuoteSummary.module.scss';

type QuoteSummaryProps = {
  onUpdateDetails: () => void;
};

export const QuoteSummary = ({ onUpdateDetails }: QuoteSummaryProps) => {
  const { quote, step } = usePolicyWizardContext();
  const [shareModalVisible, setShareModalVisibility] = useState<boolean>(false);
  const { configuration } = useQuoteForm();
  const setWizardStep = useSetWizardStep();
  const setWizardError = useSetWizardError();
  const shareQuote = useShareQuote();
  const showShareQuote = useFeatureToggle('show_share_quote_via_email');
  const user = useUser();
  useEffect(() => {
    step === WizardSteps.QUOTE_SUMMARY &&
      trackPageView(
        {
          eventFeature: 'quote',
          eventSubfeature: 'summary',
          eventAction: 'page_view',
          quoteId: quote?.id,
        },
        user,
      );
  }, [user, step, quote]);
  const onClose = () => {
    setShareModalVisibility(false);
  };

  if (!quote || !configuration) {
    return null;
  }

  const onSend = async (recipientEmail: string) => {
    try {
      await shareQuote(quote.id, recipientEmail);
    } catch (error) {
      reportException(error);
      setWizardError({
        fallbackStep: WizardSteps.QUOTE_SUMMARY,
        message: WIZARD_ERROR_MESSAGES.shareQuote,
      });
    } finally {
      setShareModalVisibility(false);
    }
  };

  const submitButtonText = `${
    configuration.isEuFreightForwarder ? 'Refer' : 'Issue'
  } a Policy`;

  return (
    <div className={styles.wrapper} data-testid="quote-summary">
      {quote.status === 'pending_for_breeze_manual_review' ? (
        <QuoteManualReviewMessage />
      ) : (
        <>
          <QuoteDetails quote={quote} />
          <div className={styles.actions}>
            <Button
              variant="primary"
              width="full"
              data-testid="issue-policy-button"
              onPress={() => setWizardStep(WizardSteps.SHIPMENT_INFORMATION)}
              isDisabled={step > WizardSteps.QUOTE_SUMMARY}
              label={submitButtonText}
            />
            <Button
              variant="secondary"
              width="full"
              data-testid="update-details-button"
              onPress={onUpdateDetails}
              label={'Update Details'}
            />
            {showShareQuote && (
              <Button
                variant="ghost"
                width="full"
                leftIcon={<FaShareAlt />}
                data-testid="share-quote-button"
                onPress={() => setShareModalVisibility(true)}
                label="Share quote via email"
              />
            )}
          </div>
          <div className={styles.coverageDescriptions}>
            <QuoteCoverageDescriptions quoteId={String(quote.id)} />
          </div>
          <ShareQuoteDialog
            visible={shareModalVisible}
            onSend={onSend}
            onClose={onClose}
          />
        </>
      )}
    </div>
  );
};
