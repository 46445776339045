/**
 * @deprecated Prefer using `utils/validators.ts` when possible.
 */
export function validateEmail(value: string | undefined): boolean {
  if (value) {
    const pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
    );
    return pattern.test(String(value));
  } else {
    return false;
  }
}
