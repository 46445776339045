/**
 * Sets the viewport maximum scale factor to `1` in iOS devices,
 * to prevent an iOS-exclusive accessibility behavior of automatically
 * zooming into form inputs.
 * This only sets the property for iOS devices which allows other
 * regular zoom operations, while Android device won't.
 */
export const setIOSMaxScale = () => {
  const devices = ['iPad', 'iPhone'];
  const { userAgent } = window.navigator;
  const viewport = document.getElementsByName('viewport')[0];

  if (viewport && devices.some((name) => userAgent.includes(name))) {
    const existingContent = viewport.getAttribute('content');
    viewport.setAttribute('content', `${existingContent}, maximum-scale=1`);
  }
};

/**
 * Update the current page favicon to the given favicon file.
 * This assumes the file is stored under /public/<fileName>
 */
export const setFavicon = (fileName: string) => {
  const oldFavicon = document.getElementById('favicon');
  const newFavicon = document.createElement('link');
  newFavicon.id = 'favicon';
  newFavicon.rel = 'icon';
  newFavicon.href = `/${fileName}`;

  if (oldFavicon) {
    document.head.removeChild(oldFavicon);
  }

  document.head.appendChild(newFavicon);
};

export const setPageTitle = (pageTitle: string) => {
  document.title = pageTitle;
};

export const setDefaultFavicon = () => {
  setFavicon('breeze-favicon.ico');
};

export const setDefaultPageTitle = () => {
  setPageTitle('Breeze');
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const refresh = () => {
  location.reload();
};
