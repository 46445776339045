import {
  type ComponentProps,
  type PropsWithChildren,
  type ReactNode,
} from 'react';
import { Loader } from '@breeze-ai/ui-library';

import styles from './Loading.module.scss';

type LoadingProps = ComponentProps<typeof Loader> & {
  visible?: boolean;
  loader?: ReactNode;
};

export const Loading = ({
  visible,
  loader,
  children,
  type = 'progress-bar',
  width = '50%',
  ...props
}: PropsWithChildren<LoadingProps>) => {
  if (visible) {
    const loaderComponent = loader ?? (
      <Loader
        type={type}
        width={width}
        color="var(--loader-loader-fill)"
        {...props}
      />
    );

    return (
      <div className={styles.loaderWrapper} role="loader-wrapper">
        {loaderComponent}
      </div>
    );
  }

  return <>{children}</>;
};
