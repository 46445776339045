import { type SVGProps } from 'react';

export const ErrorFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 810 810"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="No Results">
      <g id="Payment Failed Backup 2">
        <g id="&#229;&#136;&#134;&#231;&#187;&#132; 5">
          <g id="&#229;&#136;&#134;&#231;&#187;&#132; 3 copy 10">
            <path
              id="&#232;&#183;&#175;&#229;&#190;&#132;"
              d="M806.629 727.743C826.166 689.081 770.331 495.773 383.191 495.773C103.754 495.773 -24.5002 653.999 3.85427 734.186"
              fill="url(#paint0_radial_930_52752)"
            />
            <mask
              id="mask0_930_52752"
              maskUnits="userSpaceOnUse"
              x="0"
              y="495"
              width="810"
              height="240"
            >
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132;_2"
                d="M806.629 727.743C826.166 689.081 770.331 495.773 383.191 495.773C103.754 495.773 -24.5002 653.999 3.85427 734.186"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_930_52752)">
              <ellipse
                id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162; copy 2"
                opacity="0.671968"
                cx="323.447"
                cy="604.185"
                rx="21.2909"
                ry="11.826"
                fill="url(#paint1_linear_930_52752)"
              />
              <ellipse
                id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162; copy 3"
                opacity="0.729957"
                cx="540.803"
                cy="586.128"
                rx="33.5923"
                ry="18.4486"
                fill="url(#paint2_linear_930_52752)"
              />
              <ellipse
                id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162; copy 4"
                opacity="0.729957"
                cx="158.491"
                cy="536.91"
                rx="33.5923"
                ry="18.4486"
                fill="url(#paint3_linear_930_52752)"
              />
            </g>
          </g>
          <g
            id="&#229;&#136;&#134;&#231;&#187;&#132; 5 copy 6"
            opacity="0.320685"
          >
            <ellipse
              id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162;"
              cx="628.195"
              cy="173.386"
              rx="22.7103"
              ry="22.706"
              fill="url(#paint4_linear_930_52752)"
            />
            <path
              id="&#232;&#183;&#175;&#229;&#190;&#132;_3"
              d="M613.986 155.364C604.239 153.99 597.114 155.067 595.777 158.741C593.632 164.633 607.18 174.972 626.038 181.835C644.896 188.697 661.922 189.484 664.067 183.592C665.416 179.885 659.231 174.697 650.741 169.443"
              stroke="url(#paint5_linear_930_52752)"
              strokeWidth="8.11081"
            />
            <path
              id="&#232;&#183;&#175;&#229;&#190;&#132;_4"
              d="M651.011 173.386C651.011 160.846 640.844 150.68 628.301 150.68C615.759 150.68 607.902 158.697 606.281 167.738C606.281 167.738 619.336 175.91 625.364 177.667C627.287 178.227 629.193 178.954 631.134 179.598C633.304 180.319 635.514 180.955 637.751 181.506C639.282 181.883 641.381 182.467 643.339 182.831C645.923 183.311 648.237 183.526 648.419 183.526C648.739 183.526 649.09 182.098 649.813 180.434C650.536 178.771 651.011 173.386 651.011 173.386Z"
              fill="url(#paint6_linear_930_52752)"
            />
          </g>
          <circle
            id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162; copy 60"
            opacity="0.418364"
            cx="129.254"
            cy="307.285"
            r="26.4808"
            fill="url(#paint7_linear_930_52752)"
          />
          <ellipse
            id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162; copy 61"
            opacity="0.5"
            cx="415.911"
            cy="533.19"
            rx="79.4423"
            ry="21.8077"
            fill="url(#paint8_linear_930_52752)"
          />
          <g id="&#229;&#136;&#134;&#231;&#187;&#132; 24 copy">
            <g id="&#229;&#136;&#134;&#231;&#187;&#132; 25">
              <path
                id="&#231;&#159;&#169;&#229;&#189;&#162;"
                d="M385.5 84.0938L394.283 87.2903L231.79 533.735C230.907 536.16 228.226 537.41 225.801 536.528V536.528C223.375 535.645 222.125 532.963 223.008 530.538L385.5 84.0938Z"
                fill="#D8D8D8"
              />
              <path
                id="&#231;&#159;&#169;&#229;&#189;&#162;_2"
                d="M385.266 84.3281L454.231 129.767C459.9 133.502 466.233 136.116 472.886 137.468V137.468C508.892 144.784 532.366 179.638 525.611 215.754L509.32 302.852C509.244 303.255 508.671 303.267 508.573 302.868V302.868C501.014 272.383 472.253 251.862 440.984 254.813V254.813C426.738 256.158 412.597 251.35 402.123 241.602L346.772 190.088L385.266 84.3281Z"
                fill="url(#paint9_linear_930_52752)"
              />
              <circle
                id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162; copy 62"
                cx="389.13"
                cy="82.822"
                r="6.23077"
                transform="rotate(20 389.13 82.822)"
                fill="#D8D8D8"
              />
            </g>
            <path
              id="&#232;&#183;&#175;&#229;&#190;&#132; 58"
              d="M284.766 351.159C284.766 351.159 289.439 348.822 289.439 349.601C289.439 350.38 289.439 358.947 289.439 358.947L286.323 360.505L284.766 351.159Z"
              fill="#FFE9E9"
            />
          </g>
          <g id="&#229;&#136;&#134;&#231;&#187;&#132; 28">
            <g id="&#229;&#136;&#134;&#231;&#187;&#132; 26">
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132; 31"
                d="M373.181 507.141C373.181 507.141 371.609 501.414 367.475 501.042C363.341 500.671 351.695 512.991 349.26 518.078C346.826 523.164 353.475 530.295 361.067 523.442C368.659 516.59 373.181 507.141 373.181 507.141Z"
                fill="#677285"
              />
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132; 29"
                d="M378.092 523.861L373.344 510.613C371.432 505.278 364.163 504.599 361.296 509.487V509.487C360.724 510.463 360.407 511.558 360.424 512.689C360.504 518.048 361.378 534.254 368.973 536.44C378.092 539.064 378.092 523.861 378.092 523.861Z"
                fill="url(#paint10_linear_930_52752)"
              />
              <g id="&#229;&#136;&#134;&#231;&#187;&#132; 27">
                <path
                  id="&#232;&#183;&#175;&#229;&#190;&#132; 31_2"
                  d="M436.702 507.141C436.702 507.141 438.274 501.414 442.408 501.042C446.542 500.671 458.188 512.991 460.623 518.078C463.057 523.164 456.407 530.295 448.816 523.442C441.224 516.59 436.702 507.141 436.702 507.141Z"
                  fill="#677285"
                />
                <path
                  id="&#232;&#183;&#175;&#229;&#190;&#132; 29_2"
                  d="M431.791 523.861L436.539 510.613C438.451 505.278 445.72 504.599 448.586 509.487V509.487C449.159 510.463 449.476 511.558 449.459 512.689C449.379 518.048 448.504 534.254 440.91 536.44C431.791 539.064 431.791 523.861 431.791 523.861Z"
                  fill="url(#paint11_linear_930_52752)"
                />
              </g>
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132; 26"
                d="M379.461 484.055C379.461 484.055 376.856 489.171 376.856 492.768C374.25 498.603 369.959 506.455 362.526 522.833C359.357 529.815 361.968 533.459 366.008 535.342C374.097 539.114 383.159 534.191 390.062 528.533L404.286 516.874L427.945 536.408C432.236 539.952 438.445 539.927 442.709 536.35V536.35C447.093 532.671 448.239 526.265 445.712 521.13C442.872 515.359 438.657 506.317 432.874 492.768C432.874 489.209 430.601 485.795 430.601 485.795L379.461 484.055Z"
                fill="url(#paint12_linear_930_52752)"
              />
            </g>
            <g id="&#229;&#136;&#134;&#231;&#187;&#132; 12">
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132; 2"
                d="M436.146 443.539C440.395 449.746 439.261 465.266 439.261 465.266H426.8L431.305 495.553C431.823 499.04 429.864 502.42 426.535 503.578C420.086 505.82 409.509 509.14 402.313 509.566C395.086 509.993 383.576 506.878 376.536 504.693C372.986 503.591 371 500.002 372.101 496.451C373.914 490.602 377.512 480.449 384.368 465.266C386.968 468.307 372.28 465.266 372.28 465.266C372.28 465.266 373.197 451.446 376.953 443.539C380.71 435.633 389.415 430.351 389.415 430.351L411.427 427.008C411.427 427.008 420.569 428.793 425.242 431.283C429.915 433.772 431.896 437.332 436.146 443.539Z"
                fill="url(#paint13_linear_930_52752)"
              />
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132; 23"
                d="M438.052 465.187L437.557 479.949L435.885 487.336L424.381 534.79L426.216 538.749L427.402 543.889L426.216 547.16L423.1 545.998L416.87 540.929L416.248 539.063C415.652 537.276 415.839 535.32 416.763 533.678L419.044 529.625L427.402 485.323L426.561 465.745L438.052 465.187Z"
                fill="#FDE7E6"
              />
              <path
                id="&#232;&#183;&#175;&#229;&#190;&#132; 23 copy"
                d="M372.547 465.775L373.893 479.979L375.565 487.366L387.069 534.82L385.234 538.779L384.048 543.919L385.234 547.19L388.349 546.028L394.58 540.959L395.202 539.093C395.798 537.306 395.611 535.35 394.687 533.708L392.406 529.655L384.048 485.352L384.697 465.775L372.547 465.775Z"
                fill="#FDE7E6"
              />
              <g id="&#229;&#136;&#134;&#231;&#187;&#132; 10">
                <path
                  id="&#229;&#144;&#136;&#229;&#185;&#182;&#229;&#189;&#162;&#231;&#138;&#182;"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M410.075 414.844H397.805V431.288H410.075V414.844ZM402.713 440.702C402.713 438.367 397.805 431.291 397.805 431.291H410.075C410.075 431.291 402.713 443.037 402.713 440.702Z"
                  fill="#FECECD"
                />
                <ellipse
                  id="&#230;&#164;&#173;&#229;&#156;&#134;&#229;&#189;&#162;_2"
                  cx="403.246"
                  cy="412.2"
                  rx="10.8785"
                  ry="16.2"
                  fill="#FFE9E9"
                />
                <path
                  id="&#232;&#183;&#175;&#229;&#190;&#132; 20"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M391.286 414.881C392.156 411.794 392.156 404.984 393.897 404.762C395.638 404.54 399.119 405.829 402.6 403.328C404.34 401.954 405.211 401.782 405.211 401.093C413.914 402.736 408.709 407.695 413.043 413.158C413.495 413.727 414.784 413.158 414.784 413.158C414.784 413.158 414.784 399.707 413.914 398.507C413.043 397.307 409.562 392.475 407.822 395.922C407.822 394.198 404.34 392.42 400.859 393.74C397.378 395.06 392.156 397.645 391.286 401.093C390.416 404.54 389.546 411.434 391.286 414.881Z"
                  fill="#513450"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_930_52752"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(351.3 578.455) rotate(-90) scale(138.619 1447.73)"
      >
        <stop stopColor="#EBEDF0" stopOpacity="0.24" />
        <stop offset="0.536775" stopColor="#EBEDF0" stopOpacity="0.845525" />
        <stop offset="1" stopColor="#E0E4E7" stopOpacity="0.01" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_930_52752"
        x1="910.622"
        y1="1785.77"
        x2="917.109"
        y2="1799.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1E7EA" />
        <stop offset="1" stopColor="#D0D6DC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_930_52752"
        x1="1527.02"
        y1="1719.76"
        x2="1538.89"
        y2="1744.52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1E7EA" />
        <stop offset="1" stopColor="#D0D6DC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_930_52752"
        x1="386.868"
        y1="1574.13"
        x2="393.584"
        y2="1600.89"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBFBFB" />
        <stop offset="1" stopColor="#D9DFE3" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_930_52752"
        x1="1808.14"
        y1="459.11"
        x2="1819.49"
        y2="498.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ECECEC" />
        <stop offset="0.717538" stopColor="#E1E7EA" />
        <stop offset="1" stopColor="#BCC3CA" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_930_52752"
        x1="613.079"
        y1="173.862"
        x2="661.23"
        y2="191.387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BAC6D2" />
        <stop offset="1" stopColor="#7F8F9F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_930_52752"
        x1="619.902"
        y1="171.432"
        x2="627.329"
        y2="186.434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1E7EA" />
        <stop offset="1" stopColor="#D0D6DC" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_930_52752"
        x1="369.614"
        y1="876.755"
        x2="343.172"
        y2="832.099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DAE2E6" />
        <stop offset="0.58" stopColor="#E3EBEF" />
        <stop offset="1" stopColor="#EDF6F9" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_930_52752"
        x1="1029.22"
        y1="1558.54"
        x2="1073.84"
        y2="1627.96"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4CDD1" />
        <stop offset="1" stopColor="#C6CFD3" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_930_52752"
        x1="474.639"
        y1="116.858"
        x2="420.485"
        y2="265.646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7D78" />
        <stop offset="1" stopColor="#FF6C95" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_930_52752"
        x1="378.092"
        y1="536.826"
        x2="378.092"
        y2="502.242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A87AF" />
        <stop offset="1" stopColor="#444F77" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_930_52752"
        x1="431.791"
        y1="536.826"
        x2="431.791"
        y2="502.242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A87AF" />
        <stop offset="1" stopColor="#444F77" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_930_52752"
        x1="447.177"
        y1="538.967"
        x2="447.177"
        y2="484.055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A87AF" />
        <stop offset="1" stopColor="#444F77" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_930_52752"
        x1="474.145"
        y1="464.727"
        x2="403.352"
        y2="540.607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E9F0" />
        <stop offset="1" stopColor="#D2DAE8" />
      </linearGradient>
    </defs>
  </svg>
);
