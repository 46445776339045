import { ErrorDialog } from '@breeze-ai/ui-library';

import {
  usePolicyWizardContext,
  useSetWizardError,
  useSetWizardStep,
} from '../../hooks/context-hooks';

export const WizardErrorDialog = () => {
  const { error } = usePolicyWizardContext();
  const setWizardStep = useSetWizardStep();
  const setWizardError = useSetWizardError();

  if (!error) {
    return null;
  }

  const { retry, fallbackStep, onConfirm, ...dialogProps } = error;

  const closeDialog = () => {
    setWizardError(undefined);
    setWizardStep(fallbackStep);
  };

  const onDialogAction = () => {
    closeDialog();
    onConfirm?.();
    retry?.();
  };

  return (
    <ErrorDialog
      visible={true}
      onClose={closeDialog}
      onConfirm={onDialogAction}
      {...dialogProps}
    />
  );
};
