import { Button } from '../../../../../../packages/cargo-ui/src/components/Button/Button';
import { Typography } from '../../../../../../packages/cargo-ui/src/components/Typography/Typography';
import {
  Modal,
  type ModalProps,
} from '../../../../../../packages/cargo-ui/src/layouts/Modal/Modal';

export function CancelChangesModal({
  onOpenChange,
  onDiscardChanges,
  title,
  subtitle,
  ...props
}: {
  onOpenChange: (isOpen: boolean) => void;
  onDiscardChanges: () => void;
  title: string;
  subtitle: string;
} & Omit<ModalProps, 'children'>) {
  return (
    <Modal
      {...props}
      onOpenChange={onOpenChange}
      heading={
        <div className="space-y-2 w-full pb-10 pt-4">
          <Typography level="h3">{title}</Typography>
          <Typography color="secondary">{subtitle}</Typography>
        </div>
      }
      className="max-w-xl"
    >
      <div className="flex flex-row gap-3 w-full justify-end">
        <Button
          width="auto"
          variant="secondary"
          onPress={() => onOpenChange(false)}
          label="Cancel"
          size="large"
        />
        <Button
          width="auto"
          variant="error"
          onPress={() => {
            onDiscardChanges();
            onOpenChange(false);
          }}
          label="Discard Changes"
          size="large"
          type="reset"
        />
      </div>
    </Modal>
  );
}
