import { Tooltip, Typography } from '@breeze-ai/ui-library';

import { useAppNavigation } from '../../../../router/use-app-navigation';
import { usePolicyWizardContext } from '../../hooks/context-hooks';
import styles from './WizardTitle.module.scss';

export const WizardTitle = () => {
  const { navigateToSection } = useAppNavigation();
  const { quote, shipment } = usePolicyWizardContext();

  let backIcon = false;
  let tooltip = '';
  let title = 'Get a Quote';

  const onBackClick = () => {
    quote ? navigateToSection('quotes') : navigateToSection('shipments');
  };

  if (quote) {
    backIcon = true;
    tooltip = 'Back to Quotes';
    title = `Quote ID: ${quote.id}`;
  } else if (shipment) {
    backIcon = true;
    tooltip = 'Back to Shipments';
    title = `Shipment ID: ${shipment.external_shipment_id}`;
  }

  return (
    <span className={styles.title}>
      <Tooltip content={tooltip} placement="bottom">
        {backIcon && (
          <Typography
            level="h2"
            prefixIcon="chevron-left"
            role="back-button"
            clickable
            onClick={onBackClick}
          />
        )}
      </Tooltip>
      <Typography level="h2" role="wizard-title">
        {title}
      </Typography>
    </span>
  );
};
