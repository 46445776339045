import { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import { getFileSize, splitFileName, Typography } from '@breeze-ai/ui-library';
import { Button, DatePicker } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';

import { defaultFileIcon, fileTypeIcons } from './file-icons';
import styles from './FileList.module.scss';

type FileListProps = {
  files: File[];
  errors?: { [fileName: string]: string };
  onRemove?: (file: File) => void;
  onChangeExpiryDate?: (date: string) => void;
};

export const FileList = ({
  onChangeExpiryDate,
  files,
  errors,
  onRemove,
}: FileListProps) => {
  return (
    <div className={styles.fileList}>
      {files.map((file, i) => {
        const [fileName, fileType] = splitFileName(file);
        const { kb, mb } = getFileSize(file);
        const displaySize =
          mb >= 1 ? `${mb.toFixed(1)} MB` : `${kb.toFixed(1)} KB`;
        const validationError = errors?.[file.name];

        return (
          <Fragment key={i}>
            <div
              className={classnames(styles.fileItem, {
                [styles.error]: !!validationError,
              })}
              role="file-item"
            >
              <div className={styles.illustration}>
                {fileTypeIcons[fileType.toLowerCase()] ?? defaultFileIcon}
              </div>
              <div className={styles.metadata}>
                <Typography>{fileName}</Typography>
                <Typography level="subtext" variant="input">
                  {displaySize}, {fileType.toUpperCase()}
                </Typography>
              </div>
              {onChangeExpiryDate && (
                <div>
                  <DatePicker
                    onChange={(date) => onChangeExpiryDate(date.toString())}
                  />
                </div>
              )}

              {onRemove && (
                <Button
                  onPress={() => onRemove(file)}
                  variant="ghost"
                  leftIcon={<FaTimes />}
                  size="small"
                />
              )}
            </div>

            {validationError && (
              <Typography
                level="subtext"
                variant="error"
                prefixIcon="exclamation-circle"
                role="file-validation-error"
              >
                {validationError}
              </Typography>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
