export type Geolocation = {
  lat: number;
  lng: number;
};

export type Address = {
  full_address: string;
  country_name?: string;
  country_code?: string;
  city?: string;
  street_name?: string;
  street_number?: string;
  zip_code?: string;
  state_name?: string;
  state_code?: string;
};

export type PlaceSuggestion = {
  place_id: string;
  description: string;
  session_token?: string;
};

export enum QueryLevelEnum {
  CITY = 'locality',
  STREET = 'street_address',
}

export interface Place {
  provider: string;
  provider_place_uuid: string;
  name: string;
  full_address?: string;
  address?: Address;
  geolocation?: Geolocation;
  display_name?: string;
  zip_code?: string;
  street_name?: string;
  street_number?: string;
}

export type USState = {
  name: string;
  abbreviation: string;
};

/**
 * A type representing a possible way of a Place being returned from the DB.
 * If this is the case, it'll be normalized into {@link Place}.
 */
export type PlaceModel = Omit<Place, 'address'> & Address;

export const isPlace = (place: unknown): place is Place => {
  return !!(place as Place).provider_place_uuid && !!(place as Place).provider;
};

export const isPlaceModel = (place: unknown): place is PlaceModel => {
  return !('address' in (place as PlaceModel));
};

export const isAddress = (address: unknown): address is Address => {
  return !!(address as Address)?.full_address;
};
