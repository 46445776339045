import { SectionLayout } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { useTracking } from '../../utils/snowplow/hooks';
import labels from '../labels';
import { PoliciesTable } from './PoliciesTable/PoliciesTable';

export const PoliciesPage = () => {
  useTracking();

  const title = capitalize(labels.terms.policy.plural);

  return (
    <SectionLayout title={title} data-testid="policies-page">
      <PoliciesTable />
    </SectionLayout>
  );
};
