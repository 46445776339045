import { Dialog } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

export const QuoteFormCancelChangesModal = ({
  visible,
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  visible?: boolean;
}) => {
  return (
    <Dialog
      visible={visible}
      alignment="start"
      width={550}
      onClose={onCancel}
      maskClosable={false}
      role="dialog"
    >
      <Dialog.Title slot="title">
        Are you sure you want to cancel changes?
      </Dialog.Title>
      <Dialog.Content slot="content">Your changes will be lost.</Dialog.Content>
      <Dialog.Footer slot="footer" alignment="end">
        <Button
          variant="secondary"
          aria-details="cancel-button"
          data-testid="cancel-button"
          onPress={onCancel}
          width="fixed"
        >
          Keep editing
        </Button>
        <Button
          variant="error"
          data-testid="confirm-button"
          aria-details="confirm-button"
          width="fixed"
          onPress={onConfirm}
        >
          Yes, cancel changes
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};
