import {
  Calendar as AriaCalendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader as AriaCalendarGridHeader,
  CalendarHeaderCell,
  type CalendarProps as AriaCalendarProps,
  type DateValue,
  Heading,
  Text,
  useLocale,
} from 'react-aria-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import moment from 'moment';

import { tv } from '../../utils/customTv';
import { Button } from '../Button/Button';
import { clsxMerge, focusRing } from '../common/utils/classNameUtils';

const cellStyles = tv({
  extend: focusRing,
  base: 'w-9 h-9 text-sm cursor-default rounded-full flex items-center justify-center forced-color-adjust-none unavailable:opacity-50',
  variants: {
    isSelected: {
      false: 'text-zinc-900  hover:bg-gray-100 pressed:bg-gray-200',
      true: 'bg-blue-600 invalid:bg-red-600 text-white ',
    },
    isDisabled: {
      true: `text-gray-300`,
    },
  },
});

export interface CalendarProps<T extends DateValue>
  extends Omit<AriaCalendarProps<T>, 'visibleDuration'> {
  errorMessage?: string;
}

export function Calendar<T extends DateValue>({
  errorMessage,
  ...props
}: CalendarProps<T>) {
  const todayDate = moment().date();
  return (
    <AriaCalendar {...props}>
      <CalendarHeader />
      <CalendarGrid>
        <CalendarGridHeader />
        <CalendarGridBody>
          {(date) => (
            <CalendarCell
              date={date}
              className={clsxMerge(
                cellStyles(),
                todayDate === date.day && 'font-bold',
              )}
            />
          )}
        </CalendarGridBody>
      </CalendarGrid>
      {errorMessage && (
        <Text slot="errorMessage" className="text-sm text-red-600">
          {errorMessage}
        </Text>
      )}
    </AriaCalendar>
  );
}

export function CalendarHeader() {
  const { direction } = useLocale();

  return (
    <header className="flex items-center gap-1 pb-4 px-1 w-full">
      <Button
        slot="previous"
        variant="ghost"
        leftIcon={
          direction === 'rtl' ? (
            <FaChevronRight aria-hidden />
          ) : (
            <FaChevronLeft aria-hidden />
          )
        }
      />
      <Heading className="flex-1 font-semibold text-base text-center mx-2 text-zinc-900" />
      <Button
        slot="next"
        variant="ghost"
        leftIcon={
          direction === 'rtl' ? (
            <FaChevronLeft aria-hidden />
          ) : (
            <FaChevronRight aria-hidden />
          )
        }
      />
    </header>
  );
}

export function CalendarGridHeader() {
  return (
    <AriaCalendarGridHeader>
      {(day) => (
        <CalendarHeaderCell className={'text-xs text-gray-500 font-semibold'}>
          {day}
        </CalendarHeaderCell>
      )}
    </AriaCalendarGridHeader>
  );
}
