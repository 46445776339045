import { type SVGProps } from 'react';

export const WhiteMinified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 3 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2591 16.7991C10.163 16.7991 8.45764 15.0938 8.45764 12.9977C8.45764 10.9015 10.163 9.19619 12.2591 9.19619C14.3553 9.19619 16.0606 10.9015 16.0606 12.9977C16.0606 15.0938 14.3552 16.7991 12.2591 16.7991ZM12.2591 10.6962C10.9901 10.6962 9.95764 11.7287 9.95764 12.9977C9.95764 14.2667 10.9901 15.2991 12.2591 15.2991C13.5281 15.2991 14.5606 14.2667 14.5606 12.9977C14.5605 11.7287 13.5281 10.6962 12.2591 10.6962Z"
      fill="white"
    />
    <path
      d="M12.2196 5.08806H4.86833L5.91514 4.04125C5.97008 3.98631 5.97008 3.89725 5.91514 3.84241L5.05339 2.98066C4.99845 2.92572 4.90939 2.92572 4.85455 2.98066L2.0412 5.79391C1.98627 5.84884 1.98627 5.93791 2.0412 5.99275L4.82211 8.77366C4.87705 8.82859 4.96611 8.82859 5.02095 8.77366L5.8827 7.91191C5.93764 7.85697 5.93764 7.76791 5.8827 7.71306L4.7577 6.58806H12.1318C15.5201 6.58806 18.3901 9.18372 18.6193 12.5643C18.6755 13.392 18.5718 14.211 18.3217 14.9824C18.2926 15.0725 18.3614 15.1646 18.456 15.1646H19.726C19.7897 15.1646 19.846 15.1221 19.8625 15.0606C20.0417 14.394 20.1341 13.7028 20.1341 13.0026C20.1342 8.63847 16.5837 5.08806 12.2196 5.08806Z"
      fill="white"
    />
    <path
      d="M5.46795 8.917L5.45042 8.90641C5.44827 8.90987 5.44639 8.91353 5.44423 8.917H5.46795Z"
      fill="white"
    />
    <path
      d="M22.4054 20.0072L19.6244 17.2263C19.5695 17.1713 19.4804 17.1713 19.4255 17.2263L18.5638 18.0881C18.5089 18.143 18.5089 18.2321 18.5638 18.2869L19.6888 19.4118H12.227C8.68995 19.4118 5.81248 16.5344 5.81248 12.9974C5.81248 12.1761 5.96942 11.3676 6.27064 10.6142C6.3073 10.5225 6.23848 10.423 6.13967 10.423H4.84414C4.78339 10.423 4.72883 10.4618 4.7098 10.5196C4.44814 11.3145 4.3123 12.1505 4.3123 12.9974C4.3123 17.3614 7.8627 20.9118 12.2268 20.9118H19.5781L18.5313 21.9587C18.4763 22.0136 18.4763 22.1026 18.5313 22.1575L19.393 23.0193C19.448 23.0743 19.537 23.0743 19.5919 23.0193L22.4052 20.206C22.4603 20.1511 22.4603 20.062 22.4054 20.0072Z"
      fill="white"
    />
  </svg>
);
