import { useQuery } from '@tanstack/react-query';

import { type PolicyWizardConfigurationResponse } from '../../../../../network/apis/configurations/types';
import { get } from '../../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../../network/netconfig';

export const useFormConfiguration = () => {
  return useQuery(
    ['form', 'quote'],
    () => get<PolicyWizardConfigurationResponse>(`${bffServiceUrl}/form/quote`),
    {
      suspense: true,
    },
  );
};
