import React from 'react';
import {
  composeRenderProps,
  ListBox,
  type ListBoxItemProps,
  Select as AriaSelect,
  type SelectProps as AriaSelectProps,
  SelectValue,
  type ValidationResult,
} from 'react-aria-components';
import { FaChevronDown } from 'react-icons/fa6';

import { clsxMerge } from '../common/utils/classNameUtils.ts';
import {
  Description,
  FieldError,
  fieldStyles,
  Label,
} from '../Field/Field.tsx';
import {
  DropdownItem,
  DropdownSection,
  type DropdownSectionProps,
} from '../ListBox/ListBox.tsx';
import { Popover } from '../Popover/Popover.tsx';
import { SelectButton } from './SelectButton.tsx';

export interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'children'> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  offsetErrorMessage?: boolean;
  items?: Iterable<T>;
  absoluteErrorMessage?: boolean;
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

export function Select<T extends object>({
  label,
  description,
  isInvalid,
  errorMessage,
  offsetErrorMessage,
  children,
  items,
  absoluteErrorMessage = false,
  ...props
}: SelectProps<T>) {
  return (
    <AriaSelect
      {...props}
      isInvalid={isInvalid}
      className={composeRenderProps(props.className, (className, renderProps) =>
        clsxMerge(fieldStyles({ ...renderProps }), className),
      )}
    >
      {label && <Label isRequired={props.isRequired}>{label}</Label>}
      <SelectButton isInvalid={isInvalid}>
        <SelectValue className="flex items-center gap-1" />
        <FaChevronDown aria-hidden className="w-3 h-3 fill-gray-700" />
      </SelectButton>
      {description && !isInvalid && <Description>{description}</Description>}
      <FieldError
        absolute={absoluteErrorMessage}
        className={clsxMerge(offsetErrorMessage && 'left-2.5')}
      >
        {errorMessage}
      </FieldError>
      <Popover className="min-w-[--trigger-width] overflow-hidden border-none">
        <ListBox
          items={items}
          className="outline-none p-2 max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)]"
        >
          {children}
        </ListBox>
      </Popover>
    </AriaSelect>
  );
}

export function SelectItem<T extends object>(props: ListBoxItemProps<T>) {
  return <DropdownItem {...props} />;
}

export function SelectSection<T extends object>(
  props: DropdownSectionProps<T>,
) {
  return <DropdownSection {...props} />;
}
