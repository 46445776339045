import { type Place, type PlaceModel } from '../../model/Place';

export const getPlaceDisplayValue = (place: Place) => {
  if (place.address?.city && place.address.country_name) {
    return `${place.address.city}, ${place.address.country_name}`;
  }

  if (place.address?.full_address) {
    return place.address.full_address;
  }

  if (place.full_address) {
    return place.full_address;
  }

  return place.name;
};

export const getPlaceModelDisplayValue = (place: PlaceModel) => {
  if (place.city && place.country_name) {
    return `${place.city}, ${place.country_name}`;
  }

  return place.full_address;
};
