import { useCallback, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { Dialog, Table, TableEmptyState } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { type Customer } from '../../../model/Customer';
import { type UpdateCustomerByIdPayload } from '../../../network/apis/customers/types';
import styles from './CustomersTable.module.scss';
import { useCustomersTableColumns } from './use-customers-table-columns';

type CustomersTableProps = {
  customers?: Customer[];
  onCustomerUpdate: (payload: UpdateCustomerByIdPayload) => Promise<void>;
  onCustomersModalOpen: () => void;
};

export const CustomersTable = ({
  customers,
  onCustomerUpdate,
  onCustomersModalOpen,
}: CustomersTableProps) => {
  const [confirmationDialogState, setConfirmationDialogState] = useState<{
    visible: boolean;
    loading?: boolean;
    customer?: Customer;
  }>();

  const onCustomerToggleOff = useCallback((customer: Customer) => {
    setConfirmationDialogState({ visible: true, customer });
  }, []);

  const toggleCustomerOff = (customer?: Customer) => {
    if (customer) {
      setConfirmationDialogState({
        ...confirmationDialogState,
        visible: true,
        loading: true,
      });
      onCustomerUpdate({
        customer_id: customer.id,
        automatically_insured: false,
      }).finally(() => setConfirmationDialogState({ visible: false }));
    }
  };

  const columns = useCustomersTableColumns({ onCustomerToggleOff });
  const emptyState = (
    <TableEmptyState
      className={styles.emptyState}
      title="This is your customers base, where you can automatically insure your customers"
      description="Selecting customers allows you to automatically insure all their future shipments"
      footer={
        <Button
          variant="ghost"
          width="auto"
          rightIcon={<FaChevronRight />}
          onPress={onCustomersModalOpen}
          label="Select customers"
        />
      }
    />
  );

  return (
    <div className="h-full w-full">
      <Table<Customer>
        showToolbar={false}
        rows={customers}
        columns={columns}
        emptyState={emptyState}
        loading={!customers}
        actions={{ pageSize: true }}
        tableClassName={styles.customersTable}
        getRowId={(row) => row.id}
        pageSize={10}
        pagination={true}
        testId="customers-table"
        disableVirtualization={true}
      />
      <Dialog
        visible={confirmationDialogState?.visible}
        alignment="start"
        width={550}
        onClose={() => setConfirmationDialogState({ visible: false })}
        role="confirmation-dialog"
      >
        <Dialog.Title slot="title">
          Are you sure you would like to opt-out this customer?
        </Dialog.Title>
        <Dialog.Content slot="content">
          This customer's next shipments will no longer be insured
          automatically.
        </Dialog.Content>
        <Dialog.Footer slot="footer" alignment="end">
          <Button
            variant="secondary"
            aria-describedby="cancel-button"
            data-testid="cancel-button"
            onPress={() => setConfirmationDialogState({ visible: false })}
            width="fixed"
            label="Keep opted-in"
          />
          <Button
            width="fixed"
            data-testid="confirm-button"
            aria-describedby="confirm-button"
            variant="error"
            onPress={() => toggleCustomerOff(confirmationDialogState?.customer)}
            isLoading={confirmationDialogState?.loading}
            isDisabled={confirmationDialogState?.loading}
            label="Yes, opt-out"
          />
        </Dialog.Footer>
      </Dialog>
    </div>
  );
};
