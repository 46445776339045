import { type IconType } from 'react-icons';
import { Badge, Typography } from '@breezeai-frontend/cargo-ui';

interface StatusBadgeProps extends React.ComponentProps<typeof Badge> {
  label: string;
  Icon?: IconType;
}

export function StatusBadge({
  variant,
  Icon,
  label,
  ...props
}: StatusBadgeProps) {
  return (
    <Badge {...props} variant={variant}>
      <span className="flex flex-row gap-1 items-center w-full h-5">
        {Icon && <Icon className="min-w-3 min-h-3" />}
        <Typography level="subtext" color="primary">
          {label}
        </Typography>
      </span>
    </Badge>
  );
}
