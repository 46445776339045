import { type SVGProps } from 'react';

export const WhiteMinified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="-4 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3934 10.1665C13.7365 9.39646 12.7241 8.92798 11.3726 8.7449V8.72336C12.5841 8.54027 13.4834 8.08795 14.0703 7.35562C14.6627 6.62329 14.9534 5.76172 14.9534 4.76015C14.9534 3.22549 14.4365 2.0516 13.4026 1.22773C12.3687 0.409244 10.7641 0 8.58861 0H-0.00012207V17.6567H8.98709C11.1141 17.6567 12.708 17.2475 13.7796 16.4236C14.8673 15.589 15.4111 14.4097 15.4111 12.8966C15.4111 11.825 15.0719 10.915 14.3934 10.1665ZM4.44233 3.37088H7.78628C9.42864 3.37088 10.2471 4.01167 10.2471 5.29863C10.2471 6.5856 9.42864 7.221 7.78628 7.221H4.44233V3.37088ZM8.21168 14.4205H4.44233V10.5111H8.21168C9.87019 10.5111 10.6995 11.1573 10.6995 12.4389C10.6995 13.7204 9.87019 14.4205 8.21168 14.4205Z"
      fill="white"
    />
    <path
      d="M22.1466 17.6567C23.6574 17.6567 24.8821 16.432 24.8821 14.9212C24.8821 13.4105 23.6574 12.1858 22.1466 12.1858C20.6359 12.1858 19.4111 13.4105 19.4111 14.9212C19.4111 16.432 20.6359 17.6567 22.1466 17.6567Z"
      fill="white"
    />
  </svg>
);
