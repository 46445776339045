import { stringify } from 'query-string';

import { type Claim } from '../../../model/Claim';
import { type Policy } from '../../../model/Policy';
import { type Quote } from '../../../model/Quote';
import { type PolicyWizardFormsData } from '../../../ui/policy-wizard/context/types';
import { toISODate } from '../../../utils/dates';
import { get, post } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { normalizeClaimResponse } from '../claims/normalizers';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../types';
import { normalizePolicyResponse } from './normalizers';
import {
  type CreatePolicyRequest,
  type GetPoliciesNormalizedResponse,
  type GetPoliciesResponse,
  type GetPolicyClaimsResponse,
  type PoliciesSortableFields,
  type PolicyModel,
} from './types';

const createPolicyRequestPayload = (
  data: PolicyWizardFormsData,
  quote: Quote,
): CreatePolicyRequest => {
  const {
    customer,
    eta,
    etd,
    containerIds,
    vehicleName,
    issueDate,
    externalReference,
    incoterm,
  } = data;

  return {
    quote_id: `${quote.id}`,
    container_ids: containerIds?.split(','),
    vessel_name: vehicleName,
    incoterm_code: incoterm,
    etd: etd ? toISODate(etd) : undefined,
    eta: eta ? toISODate(eta) : undefined,
    external_reference: externalReference,
    issue_date: issueDate ? toISODate(issueDate) : undefined,
    cargo_owner: {
      id: `${quote?.customer?.id}`,
      name: customer?.company_name,
      place: customer?.address,
      email: customer?.email,
    },
  };
};

export const getPolicies = async (
  params?: PaginationBaseQueryParams<PoliciesSortableFields, FilterableFields>,
): Promise<GetPoliciesNormalizedResponse> => {
  const { data } = await post<GetPoliciesResponse>(
    `${bffServiceUrl}/policies/get`,
    params,
  );

  return {
    ...data,
    policies: data.policies.map(normalizePolicyResponse),
  };
};

export const getPolicyCertificateIdList = async (
  params: Pick<
    PaginationBaseQueryParams<PoliciesSortableFields, FilterableFields>,
    'query'
  > = {},
): Promise<string[]> => {
  const { data } = await get<{ certificate_ids: string[] }>(
    `${bffServiceUrl}/policies/certificate_ids?${stringify(params)}`,
  );

  return data.certificate_ids;
};

export const getPolicyById = async (
  policyId: string | number,
): Promise<Policy> => {
  const { data } = await get<PolicyModel>(
    `${bffServiceUrl}/policies/${policyId}`,
  );

  return normalizePolicyResponse(data);
};

export const getPolicyClaims = async (
  policyId: string | number,
): Promise<Claim[]> => {
  const { data } = await get<GetPolicyClaimsResponse>(
    `${bffServiceUrl}/policies/${policyId}/claims`,
  );

  const claims = data.claims.map(normalizeClaimResponse);
  return claims;
};

export const createPolicy = async (
  formsData: PolicyWizardFormsData,
  quote: Quote,
): Promise<PolicyModel> => {
  const payload = createPolicyRequestPayload(formsData, quote);

  const { data } = await post<PolicyModel>(
    `${bffServiceUrl}/form/policy`,
    payload,
  );
  return data;
};
