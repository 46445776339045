import { FaDownload } from 'react-icons/fa6';
import { Currency, Typography } from '@breeze-ai/ui-library';
import { LinkButton } from '@breezeai-frontend/cargo-ui';
import { startCase } from 'lodash';

import { useUser } from '../../../context/auth/auth-hooks';
import { DistributorRegionEnum } from '../../../model/Distributor';
import { type InvoicePaymentInfo } from '../../../model/Invoice';
import { isAddress } from '../../../model/Place';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { useTracking } from '../../../utils/snowplow/hooks';
import { SectionLayout } from '../../components/Section/SectionLayout';
import { BillingItemsTable } from '../BillingItemsTable/BillingItemsTable';
import { InvoiceStatusBadge } from '../InvoiceStatusBadge/InvoiceStatusBadge';
import styles from './InvoiceDetailsPage.module.scss';
import { useInvoiceDetails } from './use-invoice-details';

const usPaymentInfoOrder: (keyof InvoicePaymentInfo)[] = [
  'account_name',
  'bank',
  'address',
  'account_number',
  'aba',
  'sort_code',
  'company_address',
];
const ukPaymentInfoOrder: (keyof InvoicePaymentInfo)[] = [
  'bank',
  'account_number',
  'address',
  'sort_code',
  'account_name',
];

export const InvoiceDetailsPage = () => {
  useTracking({
    eventFeature: 'invoice',
    eventSubfeature: 'details',
    eventAction: 'page_view',
    eventTrigger: 'invoice',
  });

  const user = useUser();
  const invoiceDetails = useInvoiceDetails();
  const { navigateToSection } = useAppNavigation();

  const pageTitle = !invoiceDetails ? null : (
    <div className={styles.title}>
      <Typography
        level="h2"
        data-testid="back-icon"
        prefixIcon="chevron-left"
        clickable
        onClick={() => navigateToSection('invoices')}
      />
      <Typography level="h2" data-testid="invoice-title">
        {invoiceDetails.name}
      </Typography>
      <InvoiceStatusBadge status={invoiceDetails.status} className="ml-2" />
    </div>
  );

  const totalAmountSummary = !invoiceDetails ? null : (
    <div className={styles.amountSummary}>
      <Typography level="h3">Total Invoice Amount</Typography>
      <Currency
        testId="total-invoice-amount"
        value={invoiceDetails.amount.value}
        currency={invoiceDetails.amount.currency_code}
        typographyProps={{ level: 'h1', bold: true }}
      />
    </div>
  );

  const paymentInfo = !invoiceDetails ? null : (
    <div className={styles.paymentInfo} data-testid="payment-info">
      <Typography level="h3">Payment Info</Typography>
      <div
        className={
          user?.distributor.region == DistributorRegionEnum.US
            ? styles.usInfoGrid
            : styles.infoGrid
        }
      >
        {user?.distributor.region == DistributorRegionEnum.US
          ? usPaymentInfoOrder.map((key, i) => {
              const value = invoiceDetails.payment_info[key];
              const displayedValue = isAddress(value)
                ? value.full_address
                : value;
              const keyDisplay = key == 'sort_code' ? 'swift_code' : key;

              return (
                <div className={styles.usInfoEntry} key={i}>
                  <Typography className={styles.title}>
                    {keyDisplay == 'aba' ? 'ABA' : startCase(keyDisplay)}
                  </Typography>
                  <Typography>{displayedValue}</Typography>
                </div>
              );
            })
          : ukPaymentInfoOrder.map((key, i) => {
              const value = invoiceDetails.payment_info[key];
              const displayedValue = isAddress(value)
                ? value.full_address
                : value;

              return (
                <div className={styles.infoEntry} key={i}>
                  <Typography className={styles.title}>
                    {startCase(key)}
                  </Typography>
                  <Typography>{displayedValue}</Typography>
                </div>
              );
            })}
      </div>
      <div className={styles.disclaimer}>
        We accept payment by wire or bank transfer only. Payment due within 30
        days of the issue date on this invoice. If payment is not received by
        this time, any policies due under this invoice may be cancelled.
      </div>
    </div>
  );

  return (
    <SectionLayout
      title={pageTitle}
      className={styles.invoiceDetailsPage}
      contentClassName={styles.pageContentWrapper}
      testId="invoice-details-page"
      actions={
        <LinkButton
          key="download-pdf"
          target="_blank"
          width="fixed"
          data-testid="invoice-download-button"
          href={invoiceDetails?.url}
          leftIcon={<FaDownload />}
          label="Download"
        />
      }
    >
      {invoiceDetails && (
        <>
          <div className={styles.header}>
            {totalAmountSummary}
            {paymentInfo}
          </div>
          <div className={styles.billingItemsTable}>
            <BillingItemsTable
              invoiceId={invoiceDetails.id}
              hideTaxInfo={invoiceDetails.hide_tax_info}
            />
          </div>
        </>
      )}
    </SectionLayout>
  );
};
