import { useState } from 'react';
import { Alert, ErrorDialog, LoadingDialog } from '@breeze-ai/ui-library';

import {
  updateClaim,
  uploadClaimFile,
} from '../../../../network/apis/claims/claims';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { FileList } from '../../../components/FileList/FileList';
import { NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import { PageSection } from '../../../components/PageSection/PageSection';
import { ClaimContactDetails } from '../../ClaimContactDetails/ClaimContactDetails';
import { ClaimCosts } from '../../ClaimCosts/ClaimCosts';
import { ClaimEventDetails } from '../../ClaimEventDetails/ClaimEventDetails';
import { ClaimCreationFlowSteps } from '../../constants';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import styles from './ClaimReview.module.scss';
import { ClaimSuccessDialog } from './ClaimSuccessDialog';

export const ClaimReview = () => {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();
  const { claim, formsData } = useClaimCreationFlowContext();
  const { setStep, setClaim } = useClaimCreationStateAction();

  const { files } = formsData;

  if (!claim) {
    return null;
  }

  const onNext = async () => {
    try {
      setLoading(true);
      if (files) {
        await Promise.all(
          files?.map((file) => uploadClaimFile(claim.id, file)),
        );
      }

      const response = await updateClaim(claim.id, { status: 'FNOL' });
      setClaim(response);
      setSuccess(true);
    } catch (e) {
      reportException(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.wrapper} data-testid="claim-review">
      <div className={styles.gridLayout}>
        <div className={styles.main}>
          <ClaimEventDetails claim={claim} />
          <ClaimContactDetails claim={claim} />
          {files && (
            <PageSection title="Files selected">
              <FileList files={files} />
            </PageSection>
          )}
        </div>
        <div className={styles.aside}>
          <ClaimCosts claim={claim} />
          <Alert variant="info" className={styles.alert}>
            Please ensure your claim report is accurate before clicking confirm.
          </Alert>
        </div>
      </div>
      <NavigationFooter
        onBack={() => setStep(ClaimCreationFlowSteps.UPLOAD_FILES)}
        onNext={onNext}
        nextText="Confirm"
        backProps={{ isDisabled: loading }}
        nextProps={{ isLoading: loading, rightIcon: undefined }}
      />
      <ClaimSuccessDialog visible={claim && success} claim={claim} />
      <LoadingDialog
        visible={loading}
        className={styles.loading}
        width={450}
        title="Alright!"
        message="We're uploading your files..."
      />
      <ErrorDialog visible={error} onConfirm={() => setError(false)} />
    </div>
  );
};
