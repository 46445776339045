import { type ComponentProps } from 'react';
import {
  Input,
  type InputDropdownProps,
  type SelectOptionType,
} from '@breeze-ai/ui-library';
import { type IconName } from '@fortawesome/fontawesome-svg-core';

import {
  type CurrencyDefinition,
  type SupportedCurrencies,
} from '../../../model/CurrencyValue';

type CurrencyValueInputProps = Omit<
  ComponentProps<typeof Input>,
  'value' | 'onChange' | 'inputDropdownProps'
> & {
  currencyOptions: CurrencyDefinition[];
  value?: number;
  currency?: SupportedCurrencies;
  defaultCurrency?: SupportedCurrencies;
  onChange?: (value: number | undefined) => void;
  onCurrencyChange?: (value: SupportedCurrencies) => void;
  inputDropdownProps?: Partial<InputDropdownProps>;
};

export const CurrencyValueInput = ({
  value,
  currency,
  currencyOptions,
  defaultCurrency,
  onChange,
  onCurrencyChange,
  inputDropdownProps,
  ...props
}: CurrencyValueInputProps) => {
  const selectedCurrency =
    currency ?? defaultCurrency ?? currencyOptions[0].code;
  const showCurrenciesDropdown =
    !!currencyOptions && currencyOptions?.length > 1;

  const prefixIcon = showCurrenciesDropdown
    ? undefined
    : (selectedCurrency.toLowerCase() as IconName);

  const currencies = currencyOptions.map<SelectOptionType<SupportedCurrencies>>(
    ({ code, symbol }) => ({
      value: code,
      payload: code,
      label: `${symbol} ${code}`,
    }),
  );

  return (
    <Input
      value={value ?? ''}
      type="number"
      onChange={({ target: { value } }, amount) =>
        onChange?.(value ? (amount as number) : undefined)
      }
      min={0}
      prefixIcon={prefixIcon}
      {...props}
      inputDropdownProps={
        showCurrenciesDropdown
          ? ({
              width: 90,
              position: 'prefix',
              value: selectedCurrency,
              options: currencies,
              onChange: ({ payload }) => onCurrencyChange?.(payload),
              testId: 'currency-selector',
              ...inputDropdownProps,
            } as InputDropdownProps<SupportedCurrencies>)
          : undefined
      }
    />
  );
};
