import { Link, Tooltip, Typography } from '@breeze-ai/ui-library';

import { DISTRIBUTION_EMAIL } from '../../app-constants';
import { type ConveyanceType } from '../../model/Shipment';

type LabelsByConveyance = {
  containerIds: string;
  vehicleName: string;
};

export enum WizardSteps {
  INIT = 0,
  QUOTE_FORM = 1,
  QUOTE_SUMMARY = 2,
  SHIPMENT_INFORMATION = 3,
  SUCCESS = 4,
  LOADING = 5,
}

export const WIZARD_ERROR_MESSAGES = {
  createQuote:
    'The quote could not be created at this time, please try again in a few moments.',
  updateQuote:
    'The quote could not be updated at this time, please try again in a few moments.',
  quoteNotUpdatable: (
    <Typography variant="secondary" block>
      Unable to update this quote because a policy was already created. Please{' '}
      <Tooltip content={DISTRIBUTION_EMAIL}>
        <Link href={`mailto:${DISTRIBUTION_EMAIL}`} underline={false}>
          contact us
        </Link>
      </Tooltip>{' '}
      for further support if needed.
    </Typography>
  ),
  shareQuote:
    'The quote could not be shared at this time, please try again in a few moments.',
  createPolicy:
    'The policy could not be created at this time, please try again in a few moments.',
  excludedCargoOwnerAddress: (
    <Typography variant="secondary" block>
      Please update the cargo owner address, or contact us at{' '}
      <Link href={`mailto:${DISTRIBUTION_EMAIL}`} underline={false}>
        {DISTRIBUTION_EMAIL}
      </Link>{' '}
      for support.
    </Typography>
  ),
};

export const SHIPMENT_FORM_LABELS: Record<ConveyanceType, LabelsByConveyance> =
  {
    sea: {
      containerIds: 'Container Number(s)',
      vehicleName: 'Vessel Name',
    },
    air: {
      containerIds: 'AWB',
      vehicleName: 'Flight Number',
    },
    road: {
      containerIds: 'Trailer Number(s)',
      vehicleName: 'Carrier Name',
    },
    rail: {
      containerIds: 'Container Number(s)',
      vehicleName: 'Carrier Name',
    },
  };
