export function DarkMinified() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="29"
      viewBox="0 0 24 24.5"
      fill="none"
    >
      <path
        d="M12.042 17.4575C9.5816 17.4575 7.57992 15.4234 7.57992 12.9232C7.57992 10.4229 9.5816 8.38886 12.042 8.38886C14.5025 8.38886 16.5042 10.4229 16.5042 12.9232C16.5042 15.4234 14.5024 17.4575 12.042 17.4575ZM12.042 10.178C10.5525 10.178 9.34061 11.4095 9.34061 12.9232C9.34061 14.4369 10.5525 15.6683 12.042 15.6683C13.5316 15.6683 14.7435 14.4368 14.7435 12.9232C14.7434 11.4095 13.5316 10.178 12.042 10.178Z"
        fill="#327FEF"
      />
      <path
        d="M11.9957 3.48878H3.36682L4.59556 2.24016C4.66004 2.17464 4.66004 2.0684 4.59556 2.00299L3.58404 0.975111C3.51956 0.909582 3.41502 0.909582 3.35064 0.975111L0.0483639 4.33069C-0.0161213 4.39622 -0.0161213 4.50245 0.0483639 4.56787L3.31257 7.88487C3.37705 7.9504 3.48159 7.9504 3.54597 7.88487L4.55748 6.857C4.62197 6.79147 4.62197 6.68524 4.55748 6.61982L3.23697 5.27795H11.8926C15.8698 5.27795 19.2385 8.37399 19.5076 12.4063C19.5735 13.3935 19.4518 14.3704 19.1583 15.2906C19.1241 15.398 19.2049 15.5078 19.3159 15.5078H20.8066C20.8814 15.5078 20.9475 15.4572 20.9669 15.3838C21.1772 14.5887 21.2857 13.7642 21.2857 12.9291C21.2858 7.72363 17.1182 3.48878 11.9957 3.48878Z"
        fill="#7F35B2"
      />
      <path
        d="M4.07065 8.05585L4.05007 8.04322C4.04754 8.04735 4.04534 8.05171 4.04281 8.05585H4.07065Z"
        fill="#7F35B2"
      />
      <path
        d="M23.9517 21.2839L20.6874 17.9669C20.6229 17.9014 20.5183 17.9014 20.454 17.9669L19.4424 18.9949C19.378 19.0605 19.378 19.1667 19.4424 19.2321L20.763 20.5739H12.0043C7.85261 20.5739 4.47506 17.1417 4.47506 12.9228C4.47506 11.9433 4.65927 10.9789 5.01284 10.0802C5.05587 9.97082 4.97509 9.85218 4.85911 9.85218H3.33843C3.26712 9.85218 3.20307 9.89847 3.18074 9.96735C2.87361 10.9155 2.71415 11.9127 2.71415 12.9228C2.71415 18.1282 6.88159 22.363 12.0041 22.363H20.633L19.4043 23.6117C19.3398 23.6772 19.3398 23.7833 19.4043 23.8488L20.4158 24.8768C20.4803 24.9423 20.5848 24.9423 20.6492 24.8768L23.9514 21.5211C24.0161 21.4556 24.0161 21.3494 23.9517 21.2839Z"
        fill="#7F35B2"
      />
    </svg>
  );
}
