import { Currency, Typography } from '@breeze-ai/ui-library';

import styles from './MetricValue.module.scss';

type MetricValueProps = {
  title: string;
  value: string | number;
  currency?: string;
  testId?: string;
};

export const MetricValue = ({
  value,
  title,
  currency,
  testId,
}: MetricValueProps) => {
  const noFractionDigitsProps =
    typeof value === 'number' && value > 10
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {};

  return (
    <div className={styles.wrapper}>
      {currency ? (
        <Currency
          value={value as number}
          currency={currency}
          testId={testId}
          typographyProps={{ level: 'h1', bold: true }}
          {...noFractionDigitsProps}
        />
      ) : (
        <Typography level="h1" data-testid={testId}>
          {value}
        </Typography>
      )}
      <Typography className={styles.title}>{title}</Typography>
    </div>
  );
};
