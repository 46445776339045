import { ProgressBar, type ProgressBarProps } from 'react-aria-components';
import { ImSpinner8 } from 'react-icons/im';

import { clsxMerge } from '../common/utils/classNameUtils';

export type SpinnerProps = {
  className?: string;
} & ProgressBarProps;

export function Spinner({ className, ...props }: SpinnerProps) {
  return (
    <ProgressBar
      {...props}
      aria-label="Loading"
      isIndeterminate
      className="flex items-center justify-center w-full h-full"
    >
      <ImSpinner8
        className={clsxMerge(
          'animate-spin w-16 h-16 fill-loader-loader-fill',
          className,
        )}
      />
    </ProgressBar>
  );
}
