import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { type Claim } from '../../../model/Claim';
import { type Policy } from '../../../model/Policy';
import { bffServiceUrl } from '../../netconfig';
import { normalizeClaimResponse } from '../claims/normalizers';
import { get } from '../utils';
import { normalizePolicyResponse } from './normalizers';
import { type GetPolicyClaimsResponse, type PolicyModel } from './types';

export const usePolicy = (
  { policyId }: { policyId?: string },
  options?: UseQueryOptions<PolicyModel, unknown, Policy, Array<string>>,
) => {
  return useQuery(
    ['policy', policyId!],
    () => get<PolicyModel>(`${bffServiceUrl}/policies/${policyId}`),
    {
      select: normalizePolicyResponse,
      enabled: !!policyId,
      ...options,
    },
  );
};

export const usePolicyClaims = ({
  policyId,
  options,
}: {
  policyId?: string;
  options?: UseQueryOptions<
    GetPolicyClaimsResponse,
    unknown,
    Claim[],
    Array<string>
  >;
}) => {
  return useQuery(
    ['policyClaims', String(policyId)],
    () =>
      get<GetPolicyClaimsResponse>(
        `${bffServiceUrl}/policies/${policyId}/claims`,
      ),
    {
      select: (data) => data.claims.map(normalizeClaimResponse),
      enabled: !!policyId,
      ...options,
    },
  );
};
