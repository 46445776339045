/* eslint-disable react/no-children-prop */
import { Suspense, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SectionLayout, Spinner } from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { CertificateFlowForm } from './CertificateFlowForm/CertificateFlowForm.tsx';
import { useFormLogic } from './useFormLogic';

const FormPlaceholder = () => {
  return (
    <div className="flex w-full h-full bg-white rounded-3xl p-3">
      <Spinner className="self-center" />
    </div>
  );
};

export function CertificateFlowPage() {
  const { isUpdate } = useFormLogic();
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  const { certificateId } = useFormLogic();
  const [formKey, setFormKey] = useState(0);

  const title =
    isUpdate && certificateId
      ? `Certificate ${certificateId}`
      : 'Get a Certificate';

  const resetForm = () => {
    setFormKey((prev) => prev + 1);
  };

  return (
    <PlatformErrorBoundary>
      <Suspense fallback={<Spinner className="self-center" />}>
        <SectionLayout
          title={
            <div className="flex flex-row items-center gap-2">
              <Link to="/certificates">
                <FaChevronLeft />
              </Link>

              {title}
            </div>
          }
          tooltip={
            isWtw && <ActiveOpenCoverTooltip distributor={user.distributor} />
          }
        >
          <Suspense fallback={<FormPlaceholder />}>
            <CertificateFlowForm
              key={formKey}
              // Remount the form when the key changes to reset the form
              remountForm={resetForm}
            />
          </Suspense>
        </SectionLayout>
      </Suspense>
    </PlatformErrorBoundary>
  );
}
