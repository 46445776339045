import { useMemo } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FaCopy, FaDownload, FaPen } from 'react-icons/fa6';
import {
  ColumnHeader,
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import {
  type QuoteStatusToBadge,
  statusToBadge,
} from '../../../quotes/utils/statusToBadge';
import { type Certificate } from '../../types';

const unknown = {
  label: 'Unknown',
  icon: <FaRegQuestionCircle opacity={0.8} />,
};

const certificateStatusBadgeVariants: QuoteStatusToBadge = {
  ...statusToBadge,
  // The following statuses are not used in the certificates table and will display a question mark icon with text "Unknown"
  ready_for_policy: unknown,
  unknown: unknown,
  expired: unknown,
  cancelled: unknown,
};

export const useCertificatesTableColumns = (): TableColumn<Certificate>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');

  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition('certificate_id', 'Certificate Number'),
        valueGetter: ({ row }) => row.external_certificate_id,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        minWidth: 190,
        sortable: true,
      },
      {
        ...getBaseColumnDefinition('created_at', 'Created On'),
        type: 'date',
        minWidth: 150,
        valueGetter: ({ row }) => row.created_time,
        renderCell: ({ row }) => (
          <DateCell
            format="SHORT_MONTH_NAME_DATE"
            value={row.created_time}
            createdBy={
              row.created_by_user && {
                avatarColor: row.created_by_user.avatar_color,
                name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
              }
            }
          />
        ),
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('external_reference', 'Reference'),
        valueGetter: ({ row }) => row.quote?.external_reference,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        minWidth: 180,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('customer_name', 'Named Assured'),
        valueGetter: ({ row }) => row.customer?.name,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: false,
      },
      {
        ...getBaseColumnDefinition('insured_value', 'Insured Value'),
        type: 'number',
        valueGetter: ({ row: { quote } }) =>
          Math.round(Number(quote?.value_insured)),
        renderCell: ({ row: { quote } }) => (
          <CurrencyCell
            value={quote?.insured_value}
            currency={quote?.commodity_currency}
          />
        ),
        renderHeader: () => {
          return <ColumnHeader>Insured Value</ColumnHeader>;
        },
        minWidth: 180,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('etd', 'Departure'),
        type: 'date',
        valueGetter: ({ row: { quote } }) => quote?.etd,
        renderCell: ({ row: { quote }, value }) => (
          <DateCell
            value={value as ISODate}
            title={quote?.origin_place?.display_name ?? quote?.origin_port_code}
          />
        ),
        minWidth: 150,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('eta', 'Arrival'),
        valueGetter: ({ row: { quote } }) => quote?.eta,
        renderCell: ({ row: { quote }, value }) => (
          <DateCell
            value={value as ISODate}
            title={
              quote?.destination_place?.display_name ??
              quote?.destination_port_code
            }
          />
        ),
        width: 160,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('status', 'Status'),
        minWidth: 140,
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.quote?.status,
        renderCell: ({ row }) => {
          const { label, variant, Icon } =
            certificateStatusBadgeVariants[row.quote?.status ?? 'unknown'] ??
            certificateStatusBadgeVariants.unknown;

          return <StatusBadge variant={variant} Icon={Icon} label={label} />;
        },
      },
      {
        ...getBaseColumnDefinition('action', ''),
        minWidth: 40,
        maxWidth: 60,
        renderCell: ({ row }) => {
          const items = [
            {
              label: 'Duplicate certificate',
              href: `/certificates/duplicate/${row.external_certificate_id}/${row.id}`,
              leftIcon: <FaCopy />,
              isHidden: false,
              isDownload: false,
            },
            {
              label: 'Edit details',
              href: `/certificates/update/${row.external_certificate_id}/${row.id}`,
              leftIcon: <FaPen />,
              isDisabled: row?.associated_with_open_claim,
              isDownload: false,
            },
            {
              label: 'Download certificate',
              href: row.certificate_url,
              leftIcon: <FaDownload />,
              isDisabled: !row.certificate_url,
              isDownload: true,
            },
          ];
          return (
            <div className="w-full flex justify-center">
              <ActionMenu items={items} id={row.id} />
            </div>
          );
        },
      },
    ],
    [sortingEnabled],
  );
};
