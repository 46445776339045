import { stringify } from 'query-string';

import { type Place } from '../../../model/Place';
import { get } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  type GetPlaceDetailsRequest,
  type GetPlaceSuggestionsRequest,
  type GetPlaceSuggestionsResponse,
  type PlaceAutocompleteOptions,
} from './types';

export const getPlaceSuggestions = async (
  params: GetPlaceSuggestionsRequest,
  auth: boolean = true,
): Promise<PlaceAutocompleteOptions> => {
  const queryParams = `?${stringify(params)}`;
  const path = auth
    ? `${bffServiceUrl}/locations/places`
    : `${bffServiceUrl}/externals/places`;
  const { data } = await get<GetPlaceSuggestionsResponse>(
    `${path}${queryParams}`,
    undefined,
    { auth },
  );

  const { session_token, results, provider } = data;

  return {
    session_token,
    suggestions: results.map<Place>(({ place_id, description }) => ({
      provider,
      provider_place_uuid: place_id,
      name: description,
    })),
  };
};

export const getPlaceDetails = async (
  params: GetPlaceDetailsRequest,
  auth: boolean = true,
): Promise<Place> => {
  const { provider_place_id, session_token } = params;

  const queryParams = `?${stringify({ session_token })}`;
  const path = auth
    ? `${bffServiceUrl}/locations/places/${provider_place_id}`
    : `${bffServiceUrl}/externals/places/${provider_place_id}`;

  const { data } = await get<Place>(`${path}${queryParams}`, undefined, {
    auth,
  });

  return data;
};
