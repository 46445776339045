import { useQuery } from '@tanstack/react-query';

import { type OpenCover as GetDistributorActiveOpenCoverResponse } from '../../../model/OpenCover';
import { bffServiceUrl } from '../../netconfig';
import { get } from '../utils';

export const useDistributorActiveOpenCover = ({
  distributorId,
}: {
  distributorId: number;
}) => {
  return useQuery(['distributorActiveOpenCover', distributorId], () =>
    get<GetDistributorActiveOpenCoverResponse>(
      `${bffServiceUrl}/bff-distributors/${distributorId}/active-open-cover`,
    ),
  );
};
