import { useCallback, useContext } from 'react';
import { set } from 'lodash';
import { type PartialDeep } from 'type-fest';

import { type Quote } from '../../../model/Quote';
import { type Shipment } from '../../../model/Shipment';
import {
  type ValidationOptions,
  type ValidationResult,
} from '../../../utils/validators';
import { type WizardSteps } from '../constants';
import { PolicyWizardContext } from '../context/PolicyWizardContext';
import {
  type PolicyWizardFormsData,
  type PolicyWizardFormsErrorMap,
  type WizardError,
} from '../context/types';
import { validate } from '../validators';

export const usePolicyWizardContext = () => {
  const { state } = useContext(PolicyWizardContext);

  return state;
};

export const usePolicyWizardContextAction = () => {
  const { dispatch } = useContext(PolicyWizardContext);

  return dispatch;
};

export const useSetWizardStep = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (payload: WizardSteps) => {
      dispatch({ type: 'set-wizard-step', payload });
    },
    [dispatch],
  );
};

export const useSetWizardError = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (payload?: WizardError) => {
      dispatch({ type: 'set-wizard-error', payload });
    },
    [dispatch],
  );
};

export const useSetShipmentData = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (payload: Shipment) => {
      dispatch({ type: 'set-shipment-data', payload });
    },
    [dispatch],
  );
};

export const useSetQuoteData = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (payload: Quote) => {
      dispatch({ type: 'set-quote-data', payload });
      dispatch({
        type: 'set-form-data',
        payload: { customer: payload.customer },
      });
      dispatch({ type: 'reset-form-edits' });
    },
    [dispatch],
  );
};

export const useInitFormData = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (payload: PartialDeep<PolicyWizardFormsData>) => {
      dispatch({ type: 'set-form-data', payload });
    },
    [dispatch],
  );
};

export const useSetFormData = (form: 'quote' | 'shipment-info') => {
  const { quote } = usePolicyWizardContext();
  const isDataUpdate = quote && form === 'quote';

  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (payload: PartialDeep<PolicyWizardFormsData>) => {
      isDataUpdate
        ? dispatch({ type: 'set-form-data-update', payload })
        : dispatch({ type: 'set-form-data', payload });
    },
    [dispatch, isDataUpdate],
  );
};

export const useResetFormEdits = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(() => {
    dispatch({ type: 'reset-form-edits' });
  }, [dispatch]);
};

export const useSetFormError = () => {
  const dispatch = usePolicyWizardContextAction();

  return useCallback(
    (result: ValidationResult) => {
      dispatch({ type: 'set-form-error', payload: result });
    },
    [dispatch],
  );
};

export const useInputValidation = () => {
  const setFormError = useSetFormError();

  return useCallback(
    (field: string, value: string, options?: ValidationOptions) => {
      const validationResult = validate(field, value, options) ?? true;

      setFormError(validationResult);

      // for marking validation success or failure
      return !validationResult.error;
    },
    [setFormError],
  );
};

/**
 * Serves the inputs validation errors list as a nested structure to match the
 * form's data structure, each has its validation error reason
 */
export const useMappedFormsErrors = (): PolicyWizardFormsErrorMap => {
  const { forms } = usePolicyWizardContext();

  const errorsMap = forms.errors.reduce(
    (result, { field, ...validationResult }) => {
      set(result, field, { ...validationResult });
      return result;
    },
    {} as PolicyWizardFormsErrorMap,
  );

  return errorsMap;
};
