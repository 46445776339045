export const flattenObject = (obj: object) => {
  const flattenedObject = Object.entries(obj || {}).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'object') {
        return { ...acc, ...value };
      }

      return { ...acc, [key]: value };
    },
    {},
  );

  return flattenedObject;
};
