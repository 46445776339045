import { useCallback } from 'react';
import { type NavigateOptions, useNavigate } from 'react-router-dom';
import { type To } from '@remix-run/router';

interface NavigationOptions extends NavigateOptions {
  newTab?: boolean;
}

export type AppSection =
  | 'insights'
  | 'customers'
  | 'shipments'
  | 'quotes'
  | 'policies'
  | 'claims'
  | 'invoices'
  | 'certificates'
  | 'storage';

type PolicyFlowNavigationOptions = {
  shipmentId?: string;
};

type ClaimFlowNavigationOptions = {
  certificateId?: string;
};

type InvoiceDetailsNavigationOptions = {
  invoiceId: string;
};

export const useAppNavigation = () => {
  const { origin } = window.location;
  const navigateInApp = useNavigate();

  const navigateNewTab = useCallback(
    (path: To) => {
      const url = `${origin}${path}`;
      window.open(url, '_blank');
    },
    [origin],
  );

  const navigate = useCallback(
    (path: To, options?: NavigationOptions) => {
      const { newTab = false } = options ?? {};
      newTab ? navigateNewTab(path) : navigateInApp(path);
    },
    [navigateInApp, navigateNewTab],
  );

  const navigateBack = useCallback(() => {
    navigateInApp(-1);
  }, [navigateInApp]);

  const navigateToSignIn = useCallback(
    (navigationOptions?: NavigationOptions) => {
      navigate(`/login`, navigationOptions);
    },
    [navigate],
  );

  const navigateToSection = useCallback(
    (sectionName: AppSection, navigationOptions?: NavigationOptions) => {
      navigate(`/${sectionName}`, navigationOptions);
    },
    [navigate],
  );

  const navigateToQuote = useCallback(
    (quoteId: string | number, navigationOptions?: NavigationOptions) => {
      navigate(`/quotes/${quoteId}`, navigationOptions);
    },
    [navigate],
  );

  const navigateToPolicy = useCallback(
    (policyId: string | number, navigationOptions?: NavigationOptions) => {
      navigate(`/policies/${policyId}`, navigationOptions);
    },
    [navigate],
  );

  const navigateToClaim = useCallback(
    (claimId: string | number, navigationOptions?: NavigationOptions) => {
      navigate(`/claims/${claimId}`, navigationOptions);
    },
    [navigate],
  );

  const navigateToPolicyFlow = useCallback(
    (
      options?: PolicyFlowNavigationOptions,
      navigationOptions?: NavigationOptions,
    ) => {
      const { shipmentId } = options ?? {};
      const path = shipmentId
        ? `/quotes/create?shipment_id=${shipmentId}`
        : '/quotes/create';

      navigate(path, navigationOptions);
    },
    [navigate],
  );

  const navigateToClaimFlow = useCallback(
    (
      options?: ClaimFlowNavigationOptions,
      navigationOptions?: NavigationOptions,
    ) => {
      const { certificateId } = options ?? {};
      const path = certificateId
        ? `/claims/start?certificate_id=${certificateId}`
        : `/claims/start`;

      navigate(path, navigationOptions);
    },
    [navigate],
  );

  const navigateToInvoice = useCallback(
    (
      options: InvoiceDetailsNavigationOptions,
      navigationOptions?: NavigationOptions,
    ) => {
      const { invoiceId } = options;
      const path = `/invoices/${invoiceId}`;

      navigate(path, navigationOptions);
    },
    [navigate],
  );

  return {
    navigateBack,
    navigateToSignIn,
    navigateToSection,
    navigateToQuote,
    navigateToPolicy,
    navigateToClaim,
    navigateToInvoice,
    navigateToPolicyFlow,
    navigateToClaimFlow,
  };
};
