import { createContext, type PropsWithChildren, useContext } from 'react';

import { getTheme } from '../utils/getTheme';

export enum PlatformEnum {
  BREEZE = 'breeze',
  WTW = 'wtw',
}

type PlatformContext = {
  platform: PlatformEnum;
};

export const PlatformContext = createContext<PlatformContext>({
  platform: PlatformEnum.BREEZE,
});

export const usePlatform = () => {
  const { platform } = useContext(PlatformContext);

  return {
    isBreeze: platform === PlatformEnum.BREEZE,
    isWtw: platform === PlatformEnum.WTW,
    platform,
  };
};

export function PlatformContextProvider({ children }: PropsWithChildren) {
  return (
    <PlatformContext.Provider value={{ platform: getTheme() }}>
      {children}
    </PlatformContext.Provider>
  );
}
