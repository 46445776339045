import { DEFAULT_CURRENCY } from '../app-constants';
import { type CurrencyValue } from '../model/CurrencyValue';
import { reportException } from './error-reporting/error-reporting';
import { getAppLocale } from './locale';

export const formatCurrencyByValueAndCode = (
  value: number,
  currencyCode: string = DEFAULT_CURRENCY,
): string | undefined => {
  const locale = getAppLocale();

  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    });
    return formatter.format(value);
  } catch (error) {
    reportException(error);
    return undefined;
  }
};

export const formatCurrencyValue = (
  currencyValue?: Partial<CurrencyValue>,
): string | undefined => {
  const { value, currency_code } = currencyValue ?? {};

  if (value === undefined || !currency_code) {
    return;
  }

  return formatCurrencyByValueAndCode(value, currency_code);
};
