import { type Key } from 'react';
import { type IconType } from 'react-icons';
import { FaShip } from 'react-icons/fa';
import { FaPlaneUp, FaTrain, FaTruck } from 'react-icons/fa6';
import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';

import { type ConveyanceType } from '../../../model/Shipment';

type ModeOfTransportItem = {
  value: ConveyanceType;
  label: string;
  Icon: IconType;
};

const items: ModeOfTransportItem[] = [
  {
    value: 'sea',
    label: 'Sea',
    Icon: FaShip,
  },
  {
    value: 'air',
    label: 'Air',
    Icon: FaPlaneUp,
  },
  {
    value: 'road',
    label: 'Road',
    Icon: FaTruck,
  },
  {
    value: 'rail',
    label: 'Rail',
    Icon: FaTrain,
  },
];

interface ModeOfTransportSelectProps
  extends Omit<
    SelectProps<ModeOfTransportItem>,
    'children' | 'onSelectionChange'
  > {
  onSelectionChange: (value: ConveyanceType) => void;

  /**
   * We filter the transportation options based on the primary (Origin, Destination)
   * or secondary (Place of loading, Place of Discharge) part of the journey.
   */
  type?: 'primary' | 'secondary';
}

export function ModeOfTransportSelect({
  type = 'primary',
  onSelectionChange,
  ...props
}: ModeOfTransportSelectProps) {
  const filteredItems =
    type === 'primary'
      ? items
      : items.filter(({ value }) => value === 'road' || value === 'rail');

  const handleSelectionChange = (key: Key) => {
    const item = filteredItems.find((i) => i.value === key);

    if (item) {
      onSelectionChange?.(item.value);
    }
  };
  return (
    <Select
      aria-label="Select transport"
      placeholder={props.placeholder || 'Select transport'}
      className="w-[140px] min-w-0"
      onSelectionChange={handleSelectionChange}
      absoluteErrorMessage={true}
      {...props}
    >
      {filteredItems.map(({ value, label, Icon }) => (
        <SelectItem
          data-testid={`select-option-${value}`}
          key={value}
          id={value}
          textValue={value}
          className="gap-2"
        >
          <Icon className="text-icons-primary" />
          {label}
        </SelectItem>
      ))}
    </Select>
  );
}
