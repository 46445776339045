import { type ReactNode } from 'react';
import { BreezeLogo, Typography, useWindowSize } from '@breeze-ai/ui-library';

import { Loading } from '../../../components/Loading/Loading';
import { useDTCFlowContext } from '../../context/hooks';
import { useDTCNavigation } from '../../dtc-navigation';
import {
  DTCFlowSteps,
  isPresentableStep,
  stepsByFlow,
} from '../../flows-steps-config';
import { DTCStepsIndicator } from '../DTCStepsIndicator/DTCStepsIndicator';
import styles from './DTCFlowLayout.module.scss';

type DTCFlowLayoutProps = {
  children?: ReactNode;
};

export const DTCFlowLayout = ({ children }: DTCFlowLayoutProps) => {
  const { step: currentStep, brand, flowType } = useDTCFlowContext();
  const { navigateToPreviousStep } = useDTCNavigation();
  const { isMobile } = useWindowSize();

  const flowSteps = flowType ? stepsByFlow[flowType] : [];
  const stepConfig = flowSteps.find(({ step }) => step === currentStep);
  const { showIndicator = false } = stepConfig ?? {};
  const pageTitle =
    stepConfig && isPresentableStep(stepConfig) ? stepConfig.title : undefined;

  const mobileHeader = (
    <div className={styles.mobileHeader}>
      <Typography
        level="h4"
        prefixIcon="chevron-left"
        clickable
        onClick={navigateToPreviousStep}
        className={styles.back}
      />
      <Typography level="h4">{pageTitle}</Typography>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBar}>
        <div className={styles.brandLogo}>{brand.logo}</div>
      </div>
      <div className={styles.body}>
        <Loading
          visible={currentStep === DTCFlowSteps.INIT}
          type="spinner"
          width={75}
          thickness={4}
          className={styles.pageLoader}
        >
          {isMobile && pageTitle && mobileHeader}
          {showIndicator && <DTCStepsIndicator />}
          <div className={styles.stepContent}>{children}</div>
        </Loading>
      </div>
      <div className={styles.footer}>
        <Typography level="h5" italic className={styles.attribution}>
          Powered by
        </Typography>
        <div>
          <BreezeLogo variant="white" className={styles.logo} />
        </div>
      </div>
    </div>
  );
};
