import { useEffect } from 'react';

/**
 * Triggers the given callback when the given key is pressed
 */
export const useKeypressEffect = (
  keyName: string,
  callback: (...args: never[]) => void,
) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === keyName) {
        event.preventDefault();
        callback();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [callback, keyName]);
};
