import { Typography, useWindowSize } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useDTCFlowContext, useDTCStateAction } from '../../context/hooks';
import { useDTCNavigation } from '../../dtc-navigation';
import { isPresentableStep, stepsByFlow } from '../../flows-steps-config';
import styles from './InsuranceWelcomePage.module.scss';

export const InsuranceWelcomePage = () => {
  const { brand, flowType } = useDTCFlowContext();
  const { setStep } = useDTCStateAction();
  const { navigateToDTCSection, getNextStep } = useDTCNavigation();
  const { isMobile } = useWindowSize();

  if (!flowType) {
    return null;
  }

  const steps = stepsByFlow[flowType].filter(isPresentableStep);

  const setFlowStartStep = () => {
    setStep(getNextStep());
  };

  return (
    <div className={styles.wrapper} data-testid="dtc-welcome-page">
      <Typography level={isMobile ? 'h3' : 'h2'}>
        Welcome to {brand.name}
      </Typography>
      <Typography level={isMobile ? 'h2' : 'h1'} className={styles.title}>
        Insure your goods in 3 simple steps
      </Typography>
      <div className={styles.stepsOverview}>
        {steps.map((step, i) => (
          <div key={i} className={styles.stepItem} role="step-summary">
            <span className={styles.index}>
              <Typography level="h2">{i + 1}</Typography>
            </span>
            <Typography level="h3">{step.title}</Typography>
            <Typography>{step.description}</Typography>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        <Button
          aria-details="start-button"
          data-testid="start-button"
          onPress={setFlowStartStep}
          width="full"
          label={`Got it, Let's go!`}
        />
        <Button
          width="full"
          variant="secondary"
          aria-details="back-button"
          data-testid="back-button"
          onPress={() => navigateToDTCSection('start')}
          label="Back to Select Insurance Type"
        />
      </div>
    </div>
  );
};
