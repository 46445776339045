import { useMemo } from 'react';
import {
  type Params,
  useBlocker,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

/**
 * Extracts the current main section from the current path,
 * for example insights, quotes, etc.
 */
export const useRouteSection = () => {
  const { pathname } = useLocation();
  const [, section] = pathname.split('/');

  return section;
};

/**
 * Encapsulates the usage of React Router DOM hooks and exposes the route parameters
 * with proper typing.
 */
export const useRouteParams = <
  URLParamsType extends Record<string, string | undefined> = Readonly<
    Params<string>
  >,
  SearchParamsType extends Record<string, string | undefined> = Readonly<
    Params<string>
  >,
>() => {
  const params = useParams<URLParamsType>();
  const [urlSearchParams] = useSearchParams();

  return useMemo(() => {
    const searchParams = Object.fromEntries(
      urlSearchParams.entries(),
    ) as SearchParamsType;

    return { params, searchParams };
  }, [params, urlSearchParams]);
};

/**
 * Encapsulates the usage of React Router DOM useBlocker hook.
 * This allows blocking any out-going navigation from a a page if needed.
 */
export const useBlockNavigation = (blockingCondition?: boolean) => {
  const blocker = useBlocker(!!blockingCondition);

  return {
    isBlocked: blocker.state === 'blocked',
    unblock: blocker.reset,
    proceed: blocker.proceed,
  };
};
