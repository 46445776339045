import { type ReactNode } from 'react';
import { type IconType } from 'react-icons';
import { TextCell, type TypographyModifiers } from '@breeze-ai/ui-library';
import { IconBadge } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';

import styles from './InfoItem.module.scss';

type InfoItemProps = TypographyModifiers & {
  title: ReactNode;
  value?: ReactNode;
  multiline?: boolean;
  testId?: string;
  className?: string;
  Icon: IconType;
};

export const InfoItem = ({
  title,
  value,
  Icon,
  multiline = true,
  testId,
  className,
  ...modifiers
}: InfoItemProps) => {
  const stringTitle = (
    <TextCell
      typographyProps={{
        level: 'subtext',
        variant: 'input',
        capitalize: true,
        ellipsis: true,
        ...modifiers,
      }}
    >
      {title}
    </TextCell>
  );

  const stringValue = (
    <TextCell typographyProps={{ level: 'h5', ellipsis: true, ...modifiers }}>
      {value}
    </TextCell>
  );

  return (
    <div
      role="info-item"
      className={classnames(
        styles.infoItem,
        { [styles.multiline]: multiline },
        className,
      )}
      data-testid={testId}
    >
      <IconBadge>
        <Icon />
      </IconBadge>
      <div className={styles.body} role="content">
        {!title || typeof title === 'string' ? stringTitle : title}
        {!value || typeof value === 'string' ? stringValue : value}
      </div>
    </div>
  );
};
