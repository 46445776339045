import { useState } from 'react';
import {
  Dialog,
  FormLayout,
  FormRow,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { validateEmail } from '../../../../utils/validateEmail';
import styles from './ShareQuoteDialog.module.scss';

type SendEmailDialogProps = {
  onSend: (inputString: string) => void;
  onClose: () => void;
  visible?: boolean;
};

export const ShareQuoteDialog = ({
  onSend,
  onClose,
  visible,
}: SendEmailDialogProps) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>();

  const disableSubmission = !email || error;

  const closeDialog = () => {
    setEmail('');
    setError(false);
    onClose();
  };

  const onConfirm = () => {
    const isValid = validateEmail(email);
    if (isValid) {
      onSend(email);
      setEmail('');
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      alignment="start"
      width={400}
      visible={visible}
      onClose={closeDialog}
      closable={true}
      maskClosable={false}
      className={styles.shareQuoteDialog}
      componentClassNames={{ title: styles.dialogTitle }}
      data-testid="quote-share-dialog"
    >
      <Dialog.Icon slot="icon" icon="share-nodes" />
      <Dialog.Title slot="title">Share Insurance Quote Via Email</Dialog.Title>
      <Dialog.Content slot="content">
        <Typography level="base" variant="secondary">
          Share your insurance quote with the client via email
        </Typography>
        <FormLayout className={styles.formWrapper}>
          <FormRow>
            <Input
              type="email"
              label="Email address"
              placeholder="example@gmail.com"
              testId="share-quote-email-input"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              validator={validateEmail}
              error={error}
              errorHelperText="Please enter a valid email address"
              validateOnInput={true}
              validationDebounceMs={300}
              onError={() => setError(true)}
              onValid={() => setError(false)}
            />
          </FormRow>
        </FormLayout>
      </Dialog.Content>
      <Dialog.Footer slot="footer" alignment="block" divider={true}>
        <Button
          variant="secondary"
          aria-details="cancel-button"
          width="fixed"
          data-testid="cancel-button"
          onPress={closeDialog}
          label="Cancel"
        />
        {/* TODO: This should have a loading state */}
        <Button
          variant="primary"
          isDisabled={disableSubmission}
          aria-details="submit-button"
          width="fixed"
          data-testid="submit-button"
          onPress={onConfirm}
          label="Send"
        />
      </Dialog.Footer>
    </Dialog>
  );
};
