export class NoRefreshTokenError extends Error {
  constructor(message: string = 'auth/noRefreshToken') {
    super(message);
  }
}

export class NoAuthTokenError extends Error {
  constructor(message: string = 'network/user/noAuthToken') {
    super(message);
  }
}
