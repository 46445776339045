import { Avatar } from '@breeze-ai/ui-library';
import { Typography } from '@breezeai-frontend/cargo-ui';

import { type User } from '../../../../model/User';

type UserBadgeProps = {
  user: User;
  minified?: boolean;
};

export const UserBadge = ({ user, minified }: UserBadgeProps) => {
  const { first_name, last_name, email } = user;

  return (
    <div className="flex items-center gap-x-2">
      <Avatar
        type="initials"
        data-testid="user-avatar"
        backgroundColor={user?.avatar_color || 'grey'}
        name={user.first_name}
        className="w-8 h-8"
      />
      {!minified && (
        <>
          <div className="flex flex-col gap-y-1 max-w-full overflow-hidden pe-4">
            <Typography
              level="subtext"
              data-testid="user-name"
              customStyles="text-navbar-logout-text text-ellipsis"
            >
              {first_name} {last_name}
            </Typography>
            <Typography
              level="caption"
              customStyles="text-system-grey-600 text-ellipsis overflow-hidden"
              data-testid="user-email"
            >
              {email}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
