import { clsxMerge } from '../common/utils/classNameUtils';
import { Dark as BreezeDark } from './BreezeVariants/Dark';
import { DarkMinified as BreezeDarkMinified } from './BreezeVariants/DrakMinified';
import { White as BreezeWhite } from './BreezeVariants/White';
import { WhiteMinified as BreezeWhiteMinified } from './BreezeVariants/WhiteMinified';
import { Dark as WtwDark } from './WtwVariants/Dark';
import { DarkMinified as WtwDarkMinified } from './WtwVariants/DarkMinified';
import { White as WtwWhite } from './WtwVariants/White';
import { WhiteMinified as WtwWhiteMinified } from './WtwVariants/WhiteMinified';

type LogoVariants = 'white' | 'dark';

type BreezeLogoProps = {
  variant?: LogoVariants;
  minified?: boolean;
  className?: string;
  theme?: 'breeze' | 'wtw';
};

const breezeLogoComponentMap = {
  white: { normal: BreezeWhite, minified: BreezeWhiteMinified },
  dark: { normal: BreezeDark, minified: BreezeDarkMinified },
};

const wtwLogoComponentMap = {
  dark: { normal: WtwDark, minified: WtwDarkMinified },
  white: { normal: WtwWhite, minified: WtwWhiteMinified },
};

export const Logo = ({
  theme = 'breeze',
  variant = 'white',
  minified = false,
  className,
}: BreezeLogoProps) => {
  let LogoComponent;

  switch (theme) {
    case 'breeze':
      LogoComponent =
        breezeLogoComponentMap[variant][minified ? 'minified' : 'normal'];
      break;
    case 'wtw':
      LogoComponent =
        wtwLogoComponentMap.dark[minified ? 'minified' : 'normal'];
      break;
    default:
      LogoComponent =
        breezeLogoComponentMap[variant][minified ? 'minified' : 'normal'];
  }

  return (
    <div
      className={clsxMerge('w-full', className)}
      data-testid="logo"
      role="logo"
    >
      <LogoComponent height={28} />
    </div>
  );
};
