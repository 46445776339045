import { useMemo } from 'react';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Shipment } from '../../../model/Shipment';

export const useShipmentsTableColumns = (): TableColumn<Shipment>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition('external_shipment_id', 'Shipment Number'),
        valueGetter: ({ row }) => row.external_shipment_id,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        width: 160,
        sortable: true && sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('created_at', 'Created On'),
        type: 'date',
        valueGetter: ({ row }) => row.created_time,
        renderCell: ({ row }) => (
          <DateCell value={row.created_time} format="SHORT_MONTH_NAME_DATE" />
        ),
        width: 160,
        sortable: true && sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('customer', 'Cargo Owner'),
        valueGetter: ({ row }) => row.customer?.company_name,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        sortable: false,
      },
      {
        ...getBaseColumnDefinition('commodity_type', 'Commodity'),
        valueGetter: ({ row }) => row.commodity_description,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        width: 160,
        sortable: true,
      },
      {
        ...getBaseColumnDefinition('commodity_value', 'Cargo Value'),
        type: 'number',
        valueGetter: ({ row }) => row.commodity_value,
        renderCell: ({ row }) => (
          <CurrencyCell
            value={row.commodity_value}
            currency={row.commodity_currency}
          />
        ),
        width: 160,
        sortable: true && sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('etd', 'Departure'),
        type: 'date',
        valueGetter: ({ row }) => row.etd,
        renderCell: ({ value, row }) => (
          <DateCell
            value={value as ISODate}
            title={row.origin_place?.address?.city ?? row?.origin_port_code}
          />
        ),
        width: 160,
        sortable: true && sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('eta', 'Arrival'),
        type: 'date',
        valueGetter: ({ row }) => row.eta,
        renderCell: ({ value, row }) => (
          <DateCell
            value={value as ISODate}
            title={
              row.destination_place?.address?.city ?? row?.destination_port_code
            }
          />
        ),
        width: 160,
        sortable: true && sortingEnabled,
      },
    ],
    [sortingEnabled],
  );
};
