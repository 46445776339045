import { type PolicyWizardConfiguration } from '../../../ui/policy-wizard/context/types';
import { getTheme } from '../../../utils/getTheme.ts';
import { type PolicyWizardConfigurationResponse } from './types';

const isWtw = getTheme() === 'wtw';

export const normalizePolicyWizardConfigResponse = (
  response: PolicyWizardConfigurationResponse,
): PolicyWizardConfiguration => {
  return {
    isFreightCostRequired: response.is_freight_cost_required,
    isAddressRequired: isWtw ? false : response.is_address_required,
    isShipmentIdRequired: response.is_shipment_id_required,
    isContainerNumbersRequired: response.is_container_numbers_required,
    isEuFreightForwarder: response.is_eu_freight_forwarder,
  };
};
