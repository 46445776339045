import { useParams } from 'react-router-dom';
import { SectionLayout, Spinner } from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { useStorageLocation } from '../hooks/useStorageLocation';
import { StorageCreationModal } from '../StorageCreationModal/StorageCreationModal';
import { StorageLocationFileTypeEnum, type StorageRouteParams } from '../types';
import { Documents } from './Documents';
import { Metrics } from './Metrics';
import { Notes } from './Notes';
import Title from './Title';

// TODO Create skeleton loader for this component that inherits width and height from the parent component
export const StorageDetailsPage = () => {
  const { policyId } = useParams<StorageRouteParams>();
  invariant(policyId, 'Policy ID is required');

  const { data, isSuccess } = useStorageLocation({
    policyId,
    options: {
      useErrorBoundary: true,
      suspense: false,
    },
  });

  return (
    <PlatformErrorBoundary>
      <SectionLayout
        data-testid="storage-details-page"
        title={isSuccess ? <Title data={data} /> : null}
      >
        {isSuccess ? (
          <div className="flex flex-col gap-6 w-full mt-4 sm:mt-0">
            <Metrics data={data} />
            <Documents
              type={StorageLocationFileTypeEnum.SURVEY_REPORT}
              data={data}
            />
            <Documents type={StorageLocationFileTypeEnum.OTHER} data={data} />
            <Notes notes={data?.notes} />
          </div>
        ) : (
          <Spinner />
        )}
        <StorageCreationModal />
      </SectionLayout>
    </PlatformErrorBoundary>
  );
};
