import { type Customer } from '../../../model/Customer';
import { get, post } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  type DTCConfiguration,
  type DTCCustomerLeadPayload,
  type DTCCustomerPayload,
  type DTCQuoteModel,
  type DTCQuotePayload,
} from './types';

export const getDTCFlowConfiguration = async (): Promise<DTCConfiguration> => {
  const { data } = await get<DTCConfiguration>(
    `${bffServiceUrl}/externals/configurations`,
    undefined,
    { auth: false },
  );

  return data;
};

export const createDTCCustomer = async (
  payload: DTCCustomerPayload,
): Promise<Customer> => {
  const { data } = await post<Customer>(
    `${bffServiceUrl}/externals/customers`,
    payload,
    { auth: false },
  );

  return data;
};

export const createDTCCustomerLead = async (
  payload: DTCCustomerLeadPayload,
): Promise<Customer> => {
  const { data } = await post<Customer>(
    `${bffServiceUrl}/externals/leads`,
    payload,
    {
      auth: false,
    },
  );

  return data;
};

export const createDTCQuote = async (
  payload: DTCQuotePayload,
): Promise<DTCQuoteModel> => {
  const { data } = await post<DTCQuoteModel>(
    `${bffServiceUrl}/externals/quotes`,
    payload,
    { auth: false },
  );

  return data;
};

export const createDTCPolicy = async (
  customer_id: number,
  quote_id: number,
): Promise<void> => {
  await post(
    `${bffServiceUrl}/externals/policies`,
    { customer_id, quote_id },
    { auth: false },
  );
};
