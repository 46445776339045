import { post } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { type ShareQuotePayload, type SupportIssuePayload } from './types';

export const sendQuoteSharingEmail = async (payload: ShareQuotePayload) => {
  const { data } = await post<void>(
    `${bffServiceUrl}/emails/share-quote`,
    payload,
  );

  return data;
};

export const sendSupportIssueEmail = async (payload: SupportIssuePayload) => {
  const { data } = await post<void>(
    `${bffServiceUrl}/emails/support-issue`,
    payload,
  );

  return data;
};
