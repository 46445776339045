import { type ReactNode } from 'react';
import {
  CurrencyCell,
  DateCell,
  TextCell,
  Typography,
} from '@breeze-ai/ui-library';

import { getFormattedContainerMode } from '../../../../network/apis/quotes/helpers';
import { useDTCFlowContext } from '../../context/hooks';

type SummaryItem = { title: string; value: ReactNode };

export const useQuoteSummaryItems = (): SummaryItem[] => {
  const { quote, configuration } = useDTCFlowContext();
  const { special_conditions } = configuration ?? {};

  if (!quote) {
    return [];
  }

  const origin =
    quote?.origin_place?.address?.full_address ??
    `${quote?.origin_port?.code} ${quote?.origin_port?.name}`;

  const destination =
    quote?.destination_place?.address?.full_address ??
    `${quote?.destination_port?.code} ${quote?.destination_port?.name}`;

  return [
    {
      title: 'Primary Mode of Transport',
      value: (
        <TextCell typographyProps={{ capitalize: true }}>
          {quote.primary_transport_mode_code}
        </TextCell>
      ),
    },
    {
      title: 'Secondary Mode of Transport',
      value: (
        <TextCell
          typographyProps={{ capitalize: true }}
          invalidComponent={<Typography>None</Typography>}
        >
          {quote.secondary_transport_mode_code}
        </TextCell>
      ),
    },
    {
      title: 'Origin',
      value: <TextCell>{origin}</TextCell>,
    },
    {
      title: 'Destination',
      value: <TextCell>{destination}</TextCell>,
    },
    {
      title: 'Departure Date',
      value: <DateCell value={quote.etd} />,
    },
    {
      title: 'Arrival Date',
      value: <DateCell value={quote.eta} />,
    },
    {
      title: 'Incoterms',
      value: (
        <TextCell typographyProps={{ upperCase: true }}>
          {quote.incoterm_code}
        </TextCell>
      ),
    },
    {
      title: 'Load Type',
      value: (
        <TextCell>{getFormattedContainerMode(quote.container_mode)}</TextCell>
      ),
    },
    {
      title: 'Container / Trailer / AWB Number(s)',
      value: <TextCell>{quote.container_ids?.join(', ')}</TextCell>,
    },
    {
      title: 'Vessel Name / Flight Number',
      value: <TextCell>{quote.vessel_name}</TextCell>,
    },
    {
      title: 'Commodity Category',
      value: <TextCell>{quote.commodity_category}</TextCell>,
    },
    {
      title: 'Commodity Description',
      value: <TextCell>{quote.commodity_external_description}</TextCell>,
    },
    {
      title: 'Cargo Value',
      value: (
        <CurrencyCell
          value={quote.commodity_value}
          currency={quote.commodity_currency}
        />
      ),
    },
    {
      title: 'Freight Cost',
      value: (
        <CurrencyCell
          value={quote.freight_cost}
          currency={quote.commodity_currency}
        />
      ),
    },
    {
      title: 'Special Conditions',
      value: (
        <TextCell>
          {special_conditions
            ?.filter((c) => quote?.special_conditions?.includes(c.value))
            ?.map((c) => c.label)
            .join(', ')}
        </TextCell>
      ),
    },
  ];
};
