import { useCallback } from 'react';

import { DISTRIBUTION_EMAIL } from '../../../app-constants';
import { useUser } from '../../../context/auth/auth-hooks';
import { type User } from '../../../model/User';
import { sendQuoteSharingEmail } from '../../../network/apis/emails/emails';
import { userToEmailPayload } from '../../../network/apis/emails/normalizers';
import { type ShareQuotePayload } from '../../../network/apis/emails/types';

const createShareQuotePayload = (
  quoteId: string,
  recipientEmail: string,
  user: User,
): ShareQuotePayload => {
  const { name, email, freight_forwarder } = userToEmailPayload(user);

  return {
    quote_id: quoteId,
    freight_forwarder,
    email_to: [{ email: recipientEmail, name: '' }],
    cc: [
      ...(recipientEmail !== email ? [{ name, email }] : []),
      {
        name: 'Breeze Distribution',
        email: DISTRIBUTION_EMAIL,
      },
    ],
  };
};

export const useShareQuote = () => {
  const user = useUser();

  return useCallback(
    async (quoteId: number, recipientEmail: string) => {
      if (!user) {
        return;
      }

      const payload = createShareQuotePayload(
        `${quoteId}`,
        recipientEmail,
        user,
      );

      await sendQuoteSharingEmail(payload);
    },
    [user],
  );
};
