import { enableErrorTracking } from '@snowplow/browser-plugin-error-tracking';
import { enableFormTracking } from '@snowplow/browser-plugin-form-tracking';
import { enableLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import {
  enableActivityTracking,
  setUserId,
  trackPageView as spTrackPageView,
} from '@snowplow/browser-tracker';

import { enableTracking } from '../../frameworks/snowplow';
import { type TrackingObject } from '../../model/Snowplow';
import { type User } from '../../model/User';
import { normalizeSpContextPageView } from './normalizers';

export function trackPageView(trackingObject?: TrackingObject, user?: User) {
  if (!enableTracking || !trackingObject) return;

  const { pathname } = window.location;
  const title = pathname.replace('/', '');

  const fullcontext = normalizeSpContextPageView(trackingObject, user);

  enableActivityTracking({
    minimumVisitLength: 10,
    heartbeatDelay: 10,
  });
  enableErrorTracking();
  enableFormTracking();
  enableLinkClickTracking();

  user && setUserId(String(user.id));

  spTrackPageView({
    title,
    context: fullcontext,
  });
}
