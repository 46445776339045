import { useMemo, useState } from 'react';

import { usePlatform } from '../../../../context/PlatformContext';
import { useCustomers } from '../../../../network/apis/customers/hooks';
import {
  FilterableFields,
  FilterOperators,
} from '../../../../network/apis/types';
import { MultiSelectRadioDropdown } from '../components/MultiSelectRadioDropdown';
import { useFilterStore } from '../useTableFilters';

export function CargoOwnersFilter({ label }: { label: string }) {
  const { isWtw } = usePlatform();

  const {
    filters,
    selectedCargoOwners,
    setSelectedCargoOwners,
    updateFilter,
    removeFilter,
  } = useFilterStore((state) => ({
    filters: state.filters.find(
      (filter) => filter.field === FilterableFields.CUSTOMER_ID,
    )?.values,
    selectedCargoOwners: state.selectedCargoOwners,
    setSelectedCargoOwners: state.setSelectedCargoOwners,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
  }));
  const [query, setQuery] = useState<string>('');
  const {
    data: customers,
    isError,
    isLoading,
  } = useCustomers({
    params: {
      paginate: true,
      limit: 100,
      order: 'asc',
      query,
    },
    options: {
      suspense: false,
    },
  });

  const cargoOwnersOptions = useMemo(
    () =>
      customers?.map(({ company_name, id }) => ({
        label: `${company_name}`,
        value: String(id),
      })),
    [customers],
  );

  const appliedOptions = useMemo(
    () =>
      cargoOwnersOptions?.filter((option) => filters?.includes(option.value)) ??
      [],
    [cargoOwnersOptions, filters],
  );

  const nonAppliedOptions = useMemo(
    () =>
      cargoOwnersOptions?.filter(
        (option) => !filters?.includes(option.value),
      ) ?? [],
    [cargoOwnersOptions, filters],
  );

  const onSubmit = () =>
    updateFilter({
      field: FilterableFields.CUSTOMER_ID,
      operator: FilterOperators.EQUALS,
      values: selectedCargoOwners,
    });

  const onReset = () => removeFilter(FilterableFields.CUSTOMER_ID);

  return (
    <MultiSelectRadioDropdown
      optionsCount={filters?.length}
      selected={selectedCargoOwners}
      setSelected={setSelectedCargoOwners}
      onSearch={setQuery}
      onReset={onReset}
      name="cargoOwner"
      label={label}
      dropdownIcon="users"
      nonAppliedOptions={nonAppliedOptions}
      appliedOptions={appliedOptions}
      onSubmit={onSubmit}
      placeholder={`Search ${isWtw ? 'named assured' : 'cargo owner'}...`}
      isError={isError}
      isLoading={isLoading}
    />
  );
}
