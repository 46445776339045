import { useCallback, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { ErrorDialog } from '@breeze-ai/ui-library';
import { Button, SectionLayout } from '@breezeai-frontend/cargo-ui';

import {
  getCustomers,
  updateCustomer,
  updateMultipleCustomers,
} from '../../network/apis/customers/customers';
import {
  type GetCustomersResponse,
  type UpdateCustomerByIdPayload,
} from '../../network/apis/customers/types';
import { reportException } from '../../utils/error-reporting/error-reporting';
import { useTracking } from '../../utils/snowplow/hooks';
import { useSectionError } from '../components/Section/SectionContext';
import styles from './CustomersPage.module.scss';
import { CustomersSelectionModal } from './CustomersSelectionModal/CustomersSelectionModal';
import { CustomersTable } from './CustomersTable/CustomersTable';

export const CustomersPage = () => {
  useTracking();

  const [customersPage, setCustomersPage] = useState<GetCustomersResponse>();
  const [customersModalVisible, setCustomersModalVisibility] =
    useState<boolean>();
  const [errorDialog, setErrorDialog] = useState<boolean>();
  const { setError } = useSectionError();

  const { customers } = customersPage ?? {};

  const fetchAutomaticallyInsuredCustomers = useCallback(() => {
    getCustomers({ paginate: false, automatically_insured: true })
      .then(setCustomersPage)
      .catch((e) => {
        reportException(e);
        setError(e);
      });
  }, [setError]);

  const onCustomerUpdate = (payload: UpdateCustomerByIdPayload) => {
    return updateCustomer(payload)
      .then(fetchAutomaticallyInsuredCustomers)
      .catch(() => setErrorDialog(true));
  };

  const onUpdateMultipleCustomers = (payload: UpdateCustomerByIdPayload[]) => {
    return updateMultipleCustomers(payload)
      .then(fetchAutomaticallyInsuredCustomers)
      .catch(() => {
        setCustomersModalVisibility(false);
        setErrorDialog(true);
      });
  };

  useEffect(() => {
    if (!customers) {
      fetchAutomaticallyInsuredCustomers();
    }
  }, [customers, fetchAutomaticallyInsuredCustomers]);

  const openCustomersModal = () => {
    setCustomersModalVisibility(true);
  };

  return (
    <SectionLayout
      title="Customers"
      data-testid="customers-page"
      actions={
        <Button
          variant="ghost"
          width="auto"
          size="medium"
          leftIcon={<FaPlus />}
          onPress={openCustomersModal}
          label="Add Customers"
        />
      }
      className={styles.customersPageWrapper}
    >
      <CustomersTable
        customers={customers}
        onCustomerUpdate={onCustomerUpdate}
        onCustomersModalOpen={openCustomersModal}
      />
      <CustomersSelectionModal
        visible={customersModalVisible}
        onConfirmSelection={onUpdateMultipleCustomers}
        onClose={() => setCustomersModalVisibility(false)}
        onError={() => {
          setCustomersModalVisibility(false);
          setErrorDialog(true);
        }}
      />
      <ErrorDialog
        visible={errorDialog}
        onConfirm={() => setErrorDialog(false)}
      />
    </SectionLayout>
  );
};
