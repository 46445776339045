import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';

import { type TitledOption } from '../../../../../model/TitledOption';
import { getFormattedContainerMode } from '../../../../../network/apis/quotes/helpers';

export function ContainerModeSelect({
  items,
  ...props
}: Omit<SelectProps<TitledOption>, 'children'>) {
  const itemsArray = items ? [...items] : [];

  return (
    <Select label="Load Type" isRequired validationBehavior="aria" {...props}>
      {!itemsArray.length ? (
        <SelectItem isDisabled>
          Please select a primary mode of transportation first.
        </SelectItem>
      ) : (
        itemsArray.map(({ id, title }) => {
          const displayValue = getFormattedContainerMode(title);

          return (
            <SelectItem
              data-testid={`container-mode-${displayValue}`}
              key={id}
              id={id}
              textValue={title}
            >
              {displayValue}
            </SelectItem>
          );
        })
      )}
    </Select>
  );
}
