import { DTCFlowTypes } from './constants';

export enum DTCFlowSteps {
  INIT = 0,
  WELCOME = 1,
  INSURED_DETAILS = 2,
  SHIPMENT_DETAILS = 3,
  QUOTE_MANUAL_REVIEW = 4,
  REVIEW = 5,
  SUCCESS = 6,
}

interface FlowStep {
  step: DTCFlowSteps;
  showIndicator?: boolean;
}

interface PresentableStep extends FlowStep {
  title: string;
  description?: string;
}

export const isPresentableStep = (step: FlowStep): step is PresentableStep => {
  return !!(step as PresentableStep)?.title;
};

export const stepsByFlow: Record<DTCFlowTypes, (FlowStep | PresentableStep)[]> =
  {
    [DTCFlowTypes.PER_SHIPMENT]: [
      { step: DTCFlowSteps.WELCOME },
      {
        step: DTCFlowSteps.INSURED_DETAILS,
        title: 'Insured Details',
        description: 'Tell us who you are',
        showIndicator: true,
      },
      {
        step: DTCFlowSteps.SHIPMENT_DETAILS,
        title: 'Shipment Details',
        description: 'Tell us about your shipment',
        showIndicator: true,
      },
      {
        step: DTCFlowSteps.REVIEW,
        title: 'Review Coverage',
        description: 'Make sure all details are right',
        showIndicator: true,
      },
      { step: DTCFlowSteps.SUCCESS, showIndicator: true },
    ],
    [DTCFlowTypes.ANNUAL_POLICY]: [
      { step: DTCFlowSteps.WELCOME },
      { step: DTCFlowSteps.INSURED_DETAILS, title: 'Insured Details' },
      { step: DTCFlowSteps.SUCCESS },
    ],
    [DTCFlowTypes.TRADE_CREDIT_INSURANCE]: [
      { step: DTCFlowSteps.WELCOME },
      { step: DTCFlowSteps.INSURED_DETAILS, title: 'Insured Details' },
      { step: DTCFlowSteps.SUCCESS },
    ],
  };
