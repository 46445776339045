import { Suspense, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  CreateCertificateModal,
  SectionLayout,
  Spinner,
} from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { useAuthenticatedUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { PolicyClaims } from '../../components/PolicyClaims/PolicyClaims';
import { type CertificatesRouteParams } from '../types';
import { CertificateAdditionalClauses } from './CertificateAdditionalClauses/CertificateAdditionalClauses';
import styles from './CertificateDetailsPage.module.scss';
import { CertificateShipmentDetails } from './CertificateShipmentDetails/CertificateShipmentDetails';
import { NamedAssuredDetails } from './NamedAssuredDetails/NamedAssuredDetails';
import { PollingFileList } from './PollingFileList/PollingFileList';
import Title from './Title/Title';

// TODO Create skeleton loader for this component that inherits width and height from the parent component
export const CertificateDetailsPage = () => {
  useTracking();
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  const enableClaims = useFeatureToggle('enable_claims');
  const [searchParams, setSearchParams] = useSearchParams();
  const isReady = searchParams.get('ready') === 'true';
  const [isModalOpen, setIsModalOpen] = useState(isReady);

  const { policyId } = useParams<CertificatesRouteParams>();
  invariant(policyId, 'policyId is required');

  return (
    <PlatformErrorBoundary>
      <Suspense fallback={<Spinner className="self-center" />}>
        <SectionLayout
          data-testid="certificate-details-page"
          title={<Title />}
          tooltip={
            isWtw && <ActiveOpenCoverTooltip distributor={user.distributor} />
          }
        >
          <div className="flex flex-col gap-6 w-full">
            <div className="w-full flex flex-row gap-6">
              <Suspense
                fallback={
                  <div
                    style={{ height: '330px', width: '100%' }}
                    data-testid="skeleton-loader"
                    className={styles.skeletonLoader}
                  />
                }
              >
                <CertificateShipmentDetails policyId={policyId} />
              </Suspense>
            </div>
            <div className="w-full flex flex-row gap-6">
              <Suspense
                fallback={
                  <div
                    style={{ height: '330px', width: '100%' }}
                    data-testid="skeleton-loader"
                    className={styles.skeletonLoader}
                  />
                }
              >
                <NamedAssuredDetails policyId={policyId} />
              </Suspense>
            </div>

            <Suspense
              fallback={
                <div
                  style={{ height: '200px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <CertificateAdditionalClauses />
            </Suspense>

            <Suspense
              fallback={
                <div
                  style={{ height: '200px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <PollingFileList policyId={policyId} />
            </Suspense>

            {enableClaims && (
              <Suspense
                fallback={
                  <div
                    style={{ height: '100px' }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <div className="p-6 rounded-3xl bg-system-grey-white">
                  <PolicyClaims policyId={policyId} />
                </div>
              </Suspense>
            )}
          </div>
        </SectionLayout>

        <CreateCertificateModal
          data-testid="create-certificate-modal"
          isOpen={isModalOpen}
          onOpenChange={(value) => {
            setIsModalOpen(value);
            setSearchParams(undefined, {
              replace: true,
            });
          }}
          onExit={() =>
            setSearchParams(undefined, {
              replace: true,
            })
          }
        />
      </Suspense>
    </PlatformErrorBoundary>
  );
};
