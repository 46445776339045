import { useState } from 'react';
import { Table } from '@breeze-ai/ui-library';
import { type GridSortModel } from '@mui/x-data-grid-pro';

import { type Invoice } from '../../../model/Invoice';
import { useAppNavigation } from '../../../router/use-app-navigation';
import styles from './InvoicesTable.module.scss';
import { useInvoicesTableColumns } from './use-invoices-table-columns';

type InvoicesTableProps = {
  invoices?: Invoice[];
};

const defaultSort: GridSortModel = [
  { field: 'billing_period_start', sort: 'desc' },
];

export const InvoicesTable = ({ invoices }: InvoicesTableProps) => {
  const { navigateToInvoice } = useAppNavigation();
  const columns = useInvoicesTableColumns();
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSort);

  return (
    <div className="h-full w-full">
      <Table<Invoice>
        showToolbar={false}
        rows={invoices ?? []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={!invoices}
        sortModel={sortModel}
        onRowClick={({ id }) => navigateToInvoice({ invoiceId: id.toString() })}
        onSortModelChange={setSortModel}
        wrapperClassName={styles.invoicesTableWrapper}
        testId="invoices-table"
        autoHeight={false}
        disableSelectionOnClick={true}
        disableVirtualization={true}
      />
    </div>
  );
};
