// Mimic the hook returned by `create`
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useRef,
} from 'react';
import invariant from 'tiny-invariant';
import { createStore, useStore } from 'zustand';

interface RouteDetailsInputProps {
  originInputValue?: string;
  destinationInputValue?: string;
  placeOfLoadingInputValue?: string;
  placeOfDeliveryInputValue?: string;
}

export interface RouteDetailsInputState extends RouteDetailsInputProps {
  setOriginInputValue: (value?: string) => void;
  setDestinationInputValue: (value?: string) => void;
  setPlaceOfLoadingInputValue: (value?: string) => void;
  setPlaceOfDeliveryInputValue: (value?: string) => void;
}

type RouteDetailsInputStore = ReturnType<typeof createRouteDetailsStore>;

const createRouteDetailsStore = (
  initProps?: Partial<RouteDetailsInputProps>,
) => {
  const DEFAULT_PROPS: RouteDetailsInputProps = {
    originInputValue: '',
    destinationInputValue: '',
    placeOfLoadingInputValue: '',
    placeOfDeliveryInputValue: '',
  };
  return createStore<RouteDetailsInputState>()((set) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setOriginInputValue: (value) => set({ originInputValue: value }),
    setDestinationInputValue: (value) => set({ destinationInputValue: value }),
    setPlaceOfLoadingInputValue: (value) =>
      set({ placeOfLoadingInputValue: value }),
    setPlaceOfDeliveryInputValue: (value) =>
      set({ placeOfDeliveryInputValue: value }),
  }));
};

export const RouteDetailsInputsContext =
  createContext<RouteDetailsInputStore | null>(null);

export const useRouteDetailsContext = <T,>(
  selector: (state: RouteDetailsInputState) => T,
): T => {
  const store = useContext(RouteDetailsInputsContext);
  invariant(store, 'Missing RouteDetailsInputsContext.Provider in the tree');
  return useStore(store, selector);
};

type RouteDetailsInputProviderProps = PropsWithChildren<RouteDetailsInputProps>;

export function RouteDetailsInputsProvider({
  children,
  ...props
}: RouteDetailsInputProviderProps) {
  const storeRef = useRef<RouteDetailsInputStore>();
  if (!storeRef.current) {
    storeRef.current = createRouteDetailsStore(props);
  }
  return (
    <RouteDetailsInputsContext.Provider value={storeRef.current}>
      {children}
    </RouteDetailsInputsContext.Provider>
  );
}
