import { useEffect, useState } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { Button, ErrorMessage } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Quote } from '../../../../model/Quote';
import { QuoteStatusEnum } from '../../../../network/apis/quotes/types';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { trackPageView } from '../../../../utils/snowplow/utils';
import { QuoteCoverageDescriptions } from '../../../components/QuoteCoverageDescriptions/QuoteCoverageDescriptions';
import { QuoteDetails } from '../../../components/QuoteDetails/QuoteDetails';
import labels from '../../../labels';
import { QuoteManualReviewMessage } from '../../components/QuoteManualReviewMessage/QuoteManualReviewMessage';
import { ShareQuoteDialog } from '../../components/ShareQuoteDialog/ShareQuoteDialog';
import {
  ERROR_MESSAGE_PROPS,
  WIZARD_ERROR_MESSAGES,
  WizardSteps,
} from '../../constants';
import {
  usePolicyWizardContext,
  useSetWizardError,
  useSetWizardStep,
} from '../../hooks/context-hooks';
import { useShareQuote } from '../../hooks/use-share-quote';

type QuoteSummaryProps = {
  quote: Quote;
  onUpdateDetails: () => void;
};

export const QuoteSummary = ({ quote, onUpdateDetails }: QuoteSummaryProps) => {
  const { isWtw, platform } = usePlatform();

  const user = useUser();
  const showShareQuote = useFeatureToggle('show_share_quote_via_email');

  const { step } = usePolicyWizardContext();
  const setWizardStep = useSetWizardStep();
  const setWizardError = useSetWizardError();
  const shareQuote = useShareQuote();

  const [shareModalVisible, setShareModalVisibility] = useState<boolean>(false);

  useEffect(() => {
    step === WizardSteps.QUOTE_SUMMARY &&
      trackPageView(
        {
          eventFeature: 'quote',
          eventSubfeature: 'summary',
          eventAction: 'page_view',
          quoteId: quote?.id,
        },
        user,
      );
  }, [user, step, quote]);
  const onClose = () => {
    setShareModalVisibility(false);
  };

  const onSend = async (recipientEmail: string) => {
    try {
      await shareQuote(quote.id, recipientEmail);
    } catch (error) {
      reportException(error);
      setWizardError({
        fallbackStep: WizardSteps.QUOTE_SUMMARY,
        message: WIZARD_ERROR_MESSAGES.shareQuote,
      });
    } finally {
      setShareModalVisibility(false);
    }
  };

  const enableReferPolicyFlow = user?.settings.find(
    (s) => s.name === 'enable_refer_a_policy_flow',
  )?.value;
  const submitButtonText = `${
    enableReferPolicyFlow ? 'Refer' : 'Issue'
  } a ${capitalize(labels.terms.policy.singular)}`;

  const shouldShowInsuranceOffer =
    quote.status !== QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW &&
    quote.status !== QuoteStatusEnum.DECLINED;

  return (
    <div className="grid grid-rows-[1fr_auto] w-full overflow-y-auto grow bg-system-grey-white mt-5 rounded-3xl p-7">
      <div
        className="flex flex-col items-center justify-center h-full w-full"
        data-testid="quote-summary"
      >
        {quote.status === QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW && (
          <QuoteManualReviewMessage
            exclusionReasons={quote.exclusion_reasons}
          />
        )}

        {quote.status === QuoteStatusEnum.DECLINED && (
          <ErrorMessage
            data-testid="quote-summary-error-message"
            {...ERROR_MESSAGE_PROPS[platform]}
          />
        )}

        {shouldShowInsuranceOffer && (
          <>
            <QuoteDetails quote={quote} />
            <div className="mt-4 flex flex-col gap-3">
              <div className="flex gap-2.5">
                <Button
                  variant="secondary"
                  width="full"
                  data-testid="update-details-button"
                  onPress={onUpdateDetails}
                  label="Update Details"
                />
                <Button
                  variant="primary"
                  width="full"
                  data-testid="issue-policy-button"
                  onPress={() =>
                    setWizardStep(WizardSteps.SHIPMENT_INFORMATION)
                  }
                  isDisabled={step > WizardSteps.QUOTE_SUMMARY}
                  label={submitButtonText}
                />
              </div>
              {showShareQuote && (
                <Button
                  variant="ghost"
                  width="full"
                  leftIcon={<FaShareAlt />}
                  data-testid="share-quote-button"
                  onPress={() => setShareModalVisibility(true)}
                  label="Share quote via email"
                />
              )}
            </div>
          </>
        )}
      </div>

      {!isWtw && (
        <div className="border-t border-b self-end mt-5">
          <QuoteCoverageDescriptions quoteId={String(quote.id)} />
        </div>
      )}

      <ShareQuoteDialog
        visible={shareModalVisible}
        onSend={onSend}
        onClose={onClose}
      />
    </div>
  );
};
