import { FaCopy } from 'react-icons/fa';
import { Typography } from '@breeze-ai/ui-library';
import { Badge } from '@breezeai-frontend/cargo-ui';
import { LinkButton } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { usePolicy } from '../../../../network/apis/policies/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { useAppNavigation } from '../../../../router/use-app-navigation';
import labels from '../../../labels';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

export default function Title() {
  const { navigateToSection } = useAppNavigation();
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy, isLoading } = usePolicy(
    {
      policyId,
    },
    {
      suspense: false,
    },
  );
  const certificate_id = policy?.certificate_id;
  const { certificate_status, proof_of_cover_status } = policy ?? {};

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Typography
          level="h2"
          prefixIcon="chevron-left"
          clickable
          onClick={() => navigateToSection('policies')}
        />
        {certificate_id && (
          <Typography level="h2">
            {capitalize(labels.terms.policy.singular)} {certificate_id}
          </Typography>
        )}
        <div className="ml-3">
          {!isLoading && !certificate_status && !proof_of_cover_status && (
            <Badge variant="default" data-testid="documents-pending-badge">
              <Typography>Pending Cargo Owner Approval</Typography>
            </Badge>
          )}
        </div>
      </div>
      <LinkButton
        variant="secondary"
        label="Duplicate"
        leftIcon={<FaCopy />}
        width="auto"
        href={`/quotes/duplicate/${policy?.quote?.id}`}
      />
    </div>
  );
}
