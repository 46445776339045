import { Icon } from '@breeze-ai/ui-library';
import { Typography } from '@breezeai-frontend/cargo-ui';

import styles from './QuoteManualReviewMessage.module.scss';

export const QuoteManualReviewMessage = () => {
  return (
    <div className={styles.wrapper} data-testid="quote-manual-review-message">
      <Icon icon="list-check" variant="primary" className={styles.icon} />
      <Typography level="h2" customStyles="mb-3">
        The quote needs to be processed manually, due to some exceptions.
      </Typography>

      <Typography>We will get back to you within the next 2 hours.</Typography>
    </div>
  );
};
