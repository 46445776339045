import { UILibraryContextProvider } from '@breeze-ai/ui-library';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AuthContextProvider } from './context/auth/AuthContext';
import { PlatformContextProvider } from './context/PlatformContext';
import { QueryClientProvider } from './providers/ReactQueryProvider';
import { AppRouter } from './router/AppRouter';

export default function App(): JSX.Element {
  return (
    <PlatformContextProvider>
      <QueryClientProvider>
        <UILibraryContextProvider>
          <AuthContextProvider>
            <AppRouter />
          </AuthContextProvider>
        </UILibraryContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PlatformContextProvider>
  );
}
