import { normalizeQuoteResponse } from '../../network/apis/quotes/normalizers';
import { normalizeShipmentResponse } from '../../network/apis/shipments/normalizers';
import { type Certificate, type CertificateModel } from './types';

export const normalizeCertificateResponse = (
  response: CertificateModel,
): Certificate => {
  const { distributor } = response;
  const quote = response.quote
    ? normalizeQuoteResponse(response.quote!)
    : undefined;

  const shipment = response.shipment
    ? normalizeShipmentResponse(response.shipment!)
    : undefined;

  return {
    id: response.id,
    created_time: response.created_time,
    open_cover: response.open_cover,
    certificate_url: response.certificate_url,
    proof_of_cover_url: response.proof_of_cover_url,
    open_cover_id: response.open_cover_id,
    distributor,
    customer: response?.customer,
    quote,
    shipment,
    certificate_status: response.certificate_status,
    proof_of_cover_status: response.proof_of_cover_status,
    certificate_created_time: response.certificate_created_time,
    proof_of_cover_created_time: response.proof_of_cover_created_time,
    created_by_user: response.created_by_user,
    external_certificate_id: response.certificate_id,
    associated_with_open_claim: response.associated_with_open_claim,
  };
};
