import { useCallback, useEffect, useState } from 'react';
import {
  CheckboxList,
  type CheckboxOption,
  Modal,
  SearchInput,
  Typography,
} from '@breeze-ai/ui-library';
import { Button, Spinner } from '@breezeai-frontend/cargo-ui';
import { debounce, orderBy } from 'lodash';

import { type Customer } from '../../../model/Customer';
import { getCustomers } from '../../../network/apis/customers/customers';
import {
  type GetCustomersResponse,
  type UpdateCustomerByIdPayload,
} from '../../../network/apis/customers/types';
import { Loading } from '../../components/Loading/Loading';
import styles from './CustomersSelectionModal.module.scss';

type CustomersSelectionModalProps = {
  onConfirmSelection: (payload: UpdateCustomerByIdPayload[]) => Promise<void>;
  onClose: () => void;
  onError: () => void;
  visible?: boolean;
};

const QUERY_LIMIT = 50;
const SEARCH_DEBOUNCE_MS = 1000;

export const CustomersSelectionModal = ({
  visible,
  onConfirmSelection,
  onClose,
  onError,
}: CustomersSelectionModalProps) => {
  const [loading, setLoading] = useState<boolean>();
  const [query, setQuery] = useState<string>('');
  const [customersPage, setCustomersPage] = useState<GetCustomersResponse>();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

  useEffect(() => {
    if (visible) {
      getCustomers({ query, limit: QUERY_LIMIT })
        .then(setCustomersPage)
        .catch(onError);
    }
  }, [onError, query, visible]);

  const { customers, total_num_records } = customersPage ?? {};

  const options = orderBy(customers, ['automatically_insured', 'name'])?.map<
    CheckboxOption<Customer>
  >((customer) => ({
    value: customer.id,
    payload: customer,
    checked:
      selectedCustomerIds.includes(customer.id) ||
      customer.automatically_insured,
    disabled: customer.automatically_insured,
    title: customer.company_name,
  }));

  const closeModal = useCallback(() => {
    onClose();
    setCustomersPage(undefined);
    setQuery('');
    setSelectedCustomerIds([]);
  }, [onClose]);

  const handleQueryChange = debounce(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(value);
    },
    SEARCH_DEBOUNCE_MS,
  );

  const handleChange = useCallback(
    (checked: boolean, { value }: CheckboxOption<Customer>) => {
      setSelectedCustomerIds(
        checked
          ? [...selectedCustomerIds, value as number]
          : selectedCustomerIds.filter((id) => id !== value),
      );
    },
    [selectedCustomerIds],
  );

  const handleCustomerSelection = useCallback(
    (customerIds: number[]) => {
      const payload = customerIds.map<UpdateCustomerByIdPayload>((id) => ({
        customer_id: id,
        automatically_insured: true,
      }));

      setLoading(true);
      onConfirmSelection(payload).finally(() => {
        setLoading(false);
        closeModal();
      });
    },
    [closeModal, onConfirmSelection],
  );

  return (
    // TODO THEMING Modal to be updated to not override the global theme
    <Modal
      visible={visible}
      onClose={closeModal}
      className={styles.customersModal}
      closable={!!customers}
      maskClosable={false}
      role="customers-selection-modal"
    >
      <div className={styles.header}>
        <Typography level="h3" role="title">
          Select customers from the list below
        </Typography>
      </div>
      <div className={styles.content}>
        <SearchInput
          onChange={(e) => {
            setCustomersPage(undefined);
            handleQueryChange(e);
          }}
          placeholder="Search by customer name to refine the list..."
          className={styles.search}
        />
        <Loading
          visible={!customers}
          loader={<Spinner />}
          className={styles.loader}
        >
          {options && (
            <div className={styles.list}>
              <CheckboxList<Customer>
                options={options}
                onChange={handleChange}
              />
            </div>
          )}
        </Loading>
      </div>
      {customersPage && (
        <div className={styles.listCount}>
          <Typography level="subtext">
            Showing {customers?.length} out of {total_num_records} customers
          </Typography>
        </div>
      )}
      <div className={styles.footer}>
        <div className={styles.helperText}>
          <Typography variant="secondary">
            {selectedCustomerIds.length} selected
          </Typography>
        </div>
        <div className={styles.actions}>
          <Button
            size="large"
            width="auto"
            variant="secondary"
            onPress={closeModal}
            isDisabled={!customers}
            aria-describedby="cancel-button"
            data-testid="cancel-button"
            label="Cancel"
          />
          <Button
            size="large"
            variant="primary"
            width="auto"
            isLoading={loading}
            onPress={() => handleCustomerSelection(selectedCustomerIds)}
            isDisabled={loading || selectedCustomerIds.length === 0}
            aria-describedby="confirm-button"
            data-testid="confirm-button"
            label="Select"
          />
        </div>
      </div>
    </Modal>
  );
};
