import { type SelfDescribingJson } from '@snowplow/browser-tracker';

import { type TrackingObject } from '../../model/Snowplow';
import { type User } from '../../model/User';
import {
  spGenerateClaimContext,
  spGenerateCustomerContext,
  spGenerateDistributorContext,
  spGenerateMetaContext,
  spGeneratePolicyContext,
  spGenerateQuoteContext,
  spGenerateUserContext,
} from './globalContexts';

export const normalizeSpContextPageView = (
  trackingObject: TrackingObject | undefined,
  user: User | undefined,
): Array<SelfDescribingJson> => {
  const contexts = [];
  const normalizedMetaSpContext = {
    event_source: 'web',
    event_feature: trackingObject?.eventFeature,
    event_subfeature: trackingObject?.eventSubfeature,
    event_action: trackingObject?.eventAction,
    event_object: trackingObject?.eventObject,
    event_trigger: trackingObject?.eventTrigger,
    page_content: trackingObject?.pageContent,
  };
  contexts.push(spGenerateMetaContext(normalizedMetaSpContext));

  if (user?.id) {
    contexts.push(
      spGenerateUserContext({ user_id: user?.id, user_email: user?.email }),
    );
  }

  if (user?.distributor?.id) {
    contexts.push(
      spGenerateDistributorContext({ distributor_id: user?.distributor?.id }),
    );
  }

  if (trackingObject?.customerId) {
    contexts.push(
      spGenerateCustomerContext({ customer_id: trackingObject?.customerId }),
    );
  }

  if (trackingObject?.quoteId) {
    contexts.push(
      spGenerateQuoteContext({ quote_id: trackingObject?.quoteId }),
    );
  }

  if (trackingObject?.policyId) {
    contexts.push(
      spGeneratePolicyContext({ policy_id: trackingObject?.policyId }),
    );
  }

  if (trackingObject?.claimId) {
    contexts.push(
      spGenerateClaimContext({ claim_id: trackingObject?.claimId }),
    );
  }

  return contexts;
};
