export const removeInlineStyles = (htmlMarkup: string) => {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlMarkup, 'text/html');

  // Get all elements with a style attribute
  const elementsWithStyle = doc.querySelectorAll('[style]');

  // Remove the style attribute from each element
  elementsWithStyle.forEach((element) => {
    element.removeAttribute('style');
  });

  // Serialize the DOM document back to an HTML string
  return doc.body.innerHTML;
};
