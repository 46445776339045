/**
 * A simple utility file to encapsulate any external usage of js-cookie across
 * the platform codebase.
 */
import Cookies from 'js-cookie';

type CookieAttributes = {
  domain?: string;
  expires?: number | Date;
  path?: string;
  secure?: boolean;
  sameSite?: 'Lax' | 'Strict' | 'None';
};

export const getCookie = (name: string): string | undefined =>
  Cookies.get(name);

export const setCookie = (
  name: string,
  value: string,
  options?: CookieAttributes,
): string | undefined => Cookies.set(name, value, options);

export const removeCookie = (name: string, options?: CookieAttributes): void =>
  Cookies.remove(name, options);
