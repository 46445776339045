import moment from 'moment';

import { DEFAULT_LOCALE } from '../app-constants';

const browserLocale = navigator?.languages?.[0] ?? navigator?.language;
export const initialLocale = browserLocale ?? DEFAULT_LOCALE;

export const getAppLocale = () => moment.locale();

export const setAppLocale = (locale: string) => {
  if (moment.locale() !== locale) {
    moment.updateLocale(locale, {});
  }
};

setAppLocale(initialLocale);
