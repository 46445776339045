import { ClientHintsPlugin } from '@snowplow/browser-plugin-client-hints';
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger';
import { ErrorTrackingPlugin } from '@snowplow/browser-plugin-error-tracking';
import { FormTrackingPlugin } from '@snowplow/browser-plugin-form-tracking';
import { GaCookiesPlugin } from '@snowplow/browser-plugin-ga-cookies';
import { LinkClickTrackingPlugin } from '@snowplow/browser-plugin-link-click-tracking';
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone';
import { WebVitalsPlugin } from '@snowplow/browser-plugin-web-vitals';
import { newTracker } from '@snowplow/browser-tracker';

import { isTestEnv } from '../utils/env';

const collectorURL = import.meta.env.VITE_APP_SNOWPLOW_COLLECTOR_URL;
const env = import.meta.env.VITE_APP_ENV;

export const enableTracking = !isTestEnv && env === 'production';

export const initializeSnowplow = () => {
  if (!enableTracking) return;

  const devPlugins = [DebuggerPlugin()];

  const plugins = [
    ClientHintsPlugin(),
    FormTrackingPlugin(),
    TimezonePlugin(),
    LinkClickTrackingPlugin(),
    GaCookiesPlugin(),
    WebVitalsPlugin(),
    ErrorTrackingPlugin(),
  ];

  if (env === 'development') {
    plugins.push(...devPlugins);
  }

  if (collectorURL) {
    newTracker('sp1', collectorURL, {
      appId: 'breeze-web',
      plugins,
      contexts: {
        webPage: true,
        session: true,
        browser: true,
      },
    });
  } else {
    console.warn('Missing Snowplow collector URL');
  }
};
