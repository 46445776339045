export function Dark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="19"
      viewBox="0 0 96 19"
      fill="none"
    >
      <path
        d="M93.2699 18.7932C94.7807 18.7932 96.0054 17.5685 96.0054 16.0577C96.0054 14.5469 94.7807 13.3222 93.2699 13.3222C91.7592 13.3222 90.5345 14.5469 90.5345 16.0577C90.5345 17.5685 91.7592 18.7932 93.2699 18.7932Z"
        fill="#47CBE3"
      />
      <path
        d="M14.3935 11.0929C13.7366 10.3229 12.7243 9.85442 11.3727 9.67133V9.6498C12.5842 9.46671 13.4835 9.01439 14.0705 8.28206C14.6628 7.54973 14.9536 6.68816 14.9536 5.68659C14.9536 4.15193 14.4366 2.97804 13.4027 2.15417C12.3689 1.33568 10.7642 0.926437 8.58874 0.926437H0V18.5832H8.98721C11.1142 18.5832 12.7081 18.1739 13.7797 17.35C14.8674 16.5154 15.4113 15.3361 15.4113 13.823C15.4113 12.7514 15.072 11.8414 14.3935 11.0929ZM4.44245 4.29732H7.7864C9.42876 4.29732 10.2473 4.9381 10.2473 6.22507C10.2473 7.51203 9.42876 8.14744 7.7864 8.14744H4.44245V4.29732ZM8.2118 15.3469H4.44245V11.4375H8.2118C9.87032 11.4375 10.6996 12.0837 10.6996 13.3653C10.6996 14.6469 9.87032 15.3469 8.2118 15.3469Z"
        fill="#08283D"
      />
      <path
        d="M17.969 18.5832V5.20734H21.9807L22.1692 8.09897C22.4922 7.11894 22.9823 6.34892 23.6392 5.79967C24.3177 5.22888 25.1846 4.94349 26.2347 4.94349C26.5739 4.94349 26.8593 4.98118 27.0909 5.05118C27.3386 5.10503 27.5378 5.16965 27.6778 5.23965L27.2255 8.98747C27.0478 8.93362 26.8162 8.87977 26.5308 8.82592C26.2616 8.77207 25.9331 8.74515 25.54 8.74515C24.7215 8.74515 24.0054 9.00362 23.4023 9.52056C22.8153 10.0375 22.5192 10.8129 22.5192 11.8468V18.5885H17.969V18.5832Z"
        fill="#08283D"
      />
      <path
        d="M41.479 8.01282C40.9621 7.05432 40.2082 6.30045 39.2067 5.76736C38.2266 5.21273 37.0312 4.9381 35.6204 4.9381C34.2096 4.9381 33.0088 5.21273 31.9587 5.76736C30.9195 6.30045 30.101 7.0974 29.4925 8.14744C28.9056 9.18132 28.6148 10.4306 28.6148 11.8952C28.6148 14.1084 29.2556 15.8208 30.5371 17.0323C31.8403 18.2439 33.6065 18.8524 35.8358 18.8524C36.762 18.8524 37.6451 18.7178 38.4851 18.4485C39.3413 18.1847 40.0736 17.7916 40.6767 17.2747C41.3013 16.7362 41.7375 16.0846 41.9906 15.32L38.3505 14.1138C38.1566 14.5984 37.8497 14.97 37.4404 15.2392C37.0474 15.5084 36.5143 15.6377 35.8358 15.6377C35.0496 15.6377 34.4196 15.4438 33.9349 15.0507C33.4557 14.6415 33.1542 13.9738 33.0249 13.0476H42.1521C42.1683 12.8322 42.1844 12.5737 42.206 12.2668C42.2383 11.9491 42.2544 11.6206 42.2544 11.2814C42.2544 10.0483 41.996 8.96054 41.479 8.01282ZM33.0518 10.6083C33.3211 8.94977 34.1773 8.12051 35.6204 8.12051C36.9774 8.12051 37.7528 8.94977 37.952 10.6083H33.0518Z"
        fill="#08283D"
      />
      <path
        d="M56.9765 8.01282C56.4595 7.05432 55.7003 6.30045 54.6987 5.76736C53.7187 5.21273 52.5232 4.9381 51.1178 4.9381C49.7124 4.9381 48.5008 5.21273 47.4508 5.76736C46.4169 6.30045 45.593 7.0974 44.9899 8.14744C44.403 9.18132 44.1068 10.4306 44.1068 11.8952C44.1068 14.1084 44.7476 15.8208 46.0346 17.0323C47.3323 18.2439 49.0985 18.8524 51.3278 18.8524C52.2594 18.8524 53.1425 18.7178 53.9771 18.4485C54.8333 18.1847 55.5656 17.7916 56.1741 17.2747C56.7988 16.7362 57.2349 16.0846 57.4826 15.32L53.8425 14.1138C53.6487 14.5984 53.3471 14.97 52.9379 15.2392C52.5448 15.5084 52.0063 15.6377 51.3278 15.6377C50.547 15.6377 49.9116 15.4438 49.4324 15.0507C48.9477 14.6415 48.6462 13.9738 48.5223 13.0476H57.6442C57.6603 12.8322 57.6819 12.5737 57.698 12.2668C57.7303 11.9491 57.7519 11.6206 57.7519 11.2814C57.7519 10.0483 57.4934 8.96054 56.9765 8.01282ZM48.5493 10.6083C48.8131 8.94977 49.6693 8.12051 51.1178 8.12051C52.4694 8.12051 53.2502 8.94977 53.444 10.6083H48.5493Z"
        fill="#08283D"
      />
      <path
        d="M59.5988 18.5832V15.7992L64.9244 9.75211L66.3406 8.33591L64.2298 8.38975H59.6527V5.20734H71.37V7.98589L66.1306 13.9792L64.6552 15.4546L67.0137 15.4007H71.6123V18.5832H59.5988Z"
        fill="#08283D"
      />
      <path
        d="M86.1835 8.01282C85.6666 7.05432 84.9127 6.30045 83.9112 5.76736C82.9311 5.21273 81.7357 4.9381 80.3249 4.9381C78.9141 4.9381 77.7133 5.21273 76.6632 5.76736C75.624 6.30045 74.8055 7.0974 74.197 8.14744C73.61 9.18132 73.3193 10.4306 73.3193 11.8952C73.3193 14.1084 73.9601 15.8208 75.2416 17.0323C76.5448 18.2439 78.311 18.8524 80.5403 18.8524C81.4665 18.8524 82.3496 18.7178 83.1896 18.4485C84.0458 18.1847 84.7781 17.7916 85.3812 17.2747C86.0058 16.7362 86.4474 16.0846 86.6951 15.32L83.055 14.1138C82.8611 14.5984 82.5542 14.97 82.1449 15.2392C81.7519 15.5084 81.2188 15.6377 80.5403 15.6377C79.7541 15.6377 79.1241 15.4438 78.6394 15.0507C78.1602 14.6415 77.8587 13.9738 77.7294 13.0476H86.8566C86.8728 12.8322 86.8889 12.5737 86.9105 12.2668C86.9428 11.9491 86.9589 11.6206 86.9589 11.2814C86.9589 10.0483 86.7005 8.96054 86.1835 8.01282ZM77.7563 10.6083C78.0256 8.94977 78.8818 8.12051 80.3249 8.12051C81.6818 8.12051 82.4573 8.94977 82.6565 10.6083H77.7563Z"
        fill="#08283D"
      />
    </svg>
  );
}
