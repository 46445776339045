import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  useReducer,
} from 'react';

import { ClaimCreationFlowSteps } from '../../constants';
import { claimCreationFlowReducer } from './reducer';
import {
  type ClaimCreationContextAction,
  type ClaimCreationState,
} from './types';

type ClaimCreationContextType = {
  state: ClaimCreationState;
  dispatch: Dispatch<ClaimCreationContextAction>;
};

export const claimCreationInitialState: ClaimCreationState = {
  step: ClaimCreationFlowSteps.CLAIM_DETAILS,
  formsData: {},
  formErrors: {},
};

export const ClaimCreationContext = createContext<ClaimCreationContextType>({
  state: claimCreationInitialState,
  dispatch: () => undefined,
});

export const ClaimCreationContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    claimCreationFlowReducer,
    claimCreationInitialState,
  );

  return (
    <ClaimCreationContext.Provider value={{ state, dispatch }}>
      {children}
    </ClaimCreationContext.Provider>
  );
};
