import { extendTailwindMerge } from 'tailwind-merge';

export const twMergeConfig = {
  extend: {
    theme: {
      borderRadius: [
        'radius-buttns-group-inner-button',
        'radius-button',
        'radius-field-radius',
        'radius-cards',
        'radius-suface-1',
        'radius-suface-2',
      ],
    },
  },
};

/**
 * Extend tailwind-merge to work with the custom class suffixes defined in
 * tailwind.config.ts.
 *
 * See https://github.com/dcastil/tailwind-merge/blob/v1.14.0/docs/configuration.md#configuration
 */
export const customTwMerge = extendTailwindMerge(twMergeConfig);
