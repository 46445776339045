import { FaCircleExclamation } from 'react-icons/fa6';
import { Typography } from '@breezeai-frontend/cargo-ui';
import DOMPurify from 'dompurify';

import { removeInlineStyles } from '../../../../../../packages/cargo-ui/src/utils/tools';
import { type AdditionalClause } from '../../../model/AdditionalClause';
import { DeductibleMessage } from '../../components/DeductibleMessage/DeductibleMessage';

interface QuoteAdditionalClauseProps {
  clause: AdditionalClause;
}

export function QuoteAdditionalClause({ clause }: QuoteAdditionalClauseProps) {
  const { clause_text, ...deductibleProps } = clause;

  const cleanTextMarkup = DOMPurify.sanitize(clause_text);

  return (
    <div className="w-full px-3.5 py-4 border border-system-grey-400 rounded-2xl">
      <div className="flex flex-col md:flex-row items-start gap-1.5">
        <div className="flex items-center gap-1.5">
          <FaCircleExclamation className="w-[16px] h-[16px] text-system-grey-700 shrink-0" />
          <Typography customStyles="text-text-secondary text-nowrap">
            Additional clauses
          </Typography>
        </div>
        <DeductibleMessage className="font-bold" {...deductibleProps} />
      </div>
      {cleanTextMarkup && (
        <div
          data-testid="additional-clause-markup"
          dangerouslySetInnerHTML={{
            __html: removeInlineStyles(cleanTextMarkup),
          }}
          className="ol-styling ul-styling content-spacing-y mt-2 text-text-secondary text-sm leading-3 text-left"
        />
      )}
    </div>
  );
}
