import { FaCircleInfo } from 'react-icons/fa6';
import {
  Button,
  Tooltip,
  TooltipTriggerWrapper,
  Typography,
} from '@breezeai-frontend/cargo-ui';

import { type Distributor } from '../../../model/Distributor';
import { useDistributorActiveOpenCover } from '../../../network/apis/distributors/distributors';

export function ActiveOpenCoverTooltip({
  distributor,
  defaultOpen = false,
}: {
  distributor: Distributor;
  defaultOpen?: React.ComponentProps<typeof TooltipTriggerWrapper>['defaultOpen'];
}) {
  const { data, isLoading, error } = useDistributorActiveOpenCover({
    distributorId: distributor.id,
  });

  // TODO: to be handled by the data fetching refactor
  if (isLoading || error) {
    return <></>;
  }

  return (
    <TooltipTriggerWrapper delay={0} defaultOpen={defaultOpen}>
      <div className="pl-5">
        <div className="flex items-center gap-1 text-blue-600">
          <span className="whitespace-nowrap">Policy Details</span>
          <Button
            data-testid="title-active-open-cover-tooltip-btn"
            variant="ghost"
            customStyles="p-0 w-min h-min min-w-0 rounded-full hover:bg-transparent text-blue-600"
          >
            <FaCircleInfo className="size-3" />
          </Button>
        </div>
      </div>

      <Tooltip
        placement="bottom"
        className="bg-system-grey-900 p-4 rounded-md min-w-[300px]"
      >
        <div className="flex justify-between items-center text-white">
          <Typography text="bold">{distributor.company_name}</Typography>
          <Typography customStyles="bg-white text-black px-2 py-1 rounded-md text-center text-sm">
            {data?.underwriting_year}
          </Typography>
        </div>
        <div className="grid gap-3 grid-cols-[70px_1fr] mt-2 text-white">
          <Typography customStyles="text-system-grey-200">Policy ID</Typography>
          <Typography text="bold">{data?.open_cover_policy_number}</Typography>

          <Typography customStyles="text-system-grey-200">
            Valid from
          </Typography>
          <div className="flex gap-3">
            <Typography text="bold">{data?.inception_date}</Typography>
            <Typography customStyles="text-system-grey-200">to</Typography>
            <Typography text="bold">{data?.termination_date}</Typography>
          </div>
        </div>
      </Tooltip>
    </TooltipTriggerWrapper>
  );
}
