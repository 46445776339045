import { type ReactNode } from 'react';
import {
  Asset,
  CSVAsset,
  DOCAsset,
  JPGAsset,
  PDFAsset,
  PNGAsset,
  TXTAsset,
  XLSAsset,
} from '@breeze-ai/ui-library';

export const defaultFileIcon = <Asset />;
export const fileTypeIcons: { [fileType: string]: ReactNode } = {
  pdf: <PDFAsset />,
  png: <PNGAsset />,
  jpg: <JPGAsset />,
  jpeg: <JPGAsset />,
  doc: <DOCAsset />,
  docx: <DOCAsset />,
  xls: <XLSAsset />,
  xlsx: <XLSAsset />,
  csv: <CSVAsset />,
  txt: <TXTAsset />,
};
