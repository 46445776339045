import { Dialog, Loader } from '@breeze-ai/ui-library';

import { usePolicyWizardContext } from '../../hooks/context-hooks';
import styles from './WizardLoadingDialog.module.scss';

type WizardLoadingDialogProps = {
  visible?: boolean;
};

export const WizardLoadingDialog = ({ visible }: WizardLoadingDialogProps) => {
  const { error } = usePolicyWizardContext();

  if (error) {
    return null;
  }

  return (
    <Dialog
      role="loading-dialog"
      data-testid="policy-wizard-loading-dialog"
      visible={visible}
      closable={false}
      className={styles.dialogWrapper}
      width={480}
    >
      <Dialog.Illustration slot="illustration" width="fit-content">
        <Loader type="bars" />
      </Dialog.Illustration>
      <Dialog.Title slot="title">Alright!</Dialog.Title>
      <Dialog.Content slot="content">
        We’re working on it. Just a few seconds...
      </Dialog.Content>
    </Dialog>
  );
};
