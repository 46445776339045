import { type FieldMeta } from '@tanstack/react-form';

export const getUpdatedValue = <T extends string | number>(
  value: T | null | undefined,
  formMeta: Record<string, FieldMeta>,
  fieldName: string,
): T | null | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (!formMeta[fieldName]) {
    return undefined;
  }

  if (!formMeta[fieldName].isDirty) {
    return undefined;
  }

  if (typeof value === 'string') {
    // check for empty string
    return value ? value : null;
  }

  if (typeof value === 'number') {
    return value;
  }

  return null;
};
