import { useState } from 'react';
import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
} from '@breezeai-frontend/cargo-ui';
import { useDebounce } from '@uidotdev/usehooks';

import { useCustomers } from '../../../network/apis/customers/hooks';
import { type CustomerOption } from './types';

export function CustomerComboBox({
  ...props
}: Omit<ComboBoxProps<CustomerOption>, 'children'>) {
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebounce(query, 500);

  const { data, isLoading } = useCustomers({
    options: {
      useErrorBoundary: true,
      suspense: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 0,
    },
    params: {
      limit: 15,
      query: debouncedQuery,
    },
  });

  const onInputChange = (value: string) => {
    setQuery(value);
    props.onInputChange?.(value);
  };

  return (
    <ComboBox
      {...props}
      items={data}
      onInputChange={onInputChange}
      isFetchingItems={isLoading}
      allowsCustomValue
      allowsEmptyCollection
    >
      {data?.map(({ company_name, id, address }) => (
        <ComboBoxItem
          key={JSON.stringify({ id, company_name, address })}
          id={JSON.stringify({ id, company_name, address })}
          textValue={company_name}
        >
          {company_name}
        </ComboBoxItem>
      ))}
    </ComboBox>
  );
}
