import { type Claim } from '../../../model/Claim';
import { type ClaimFormsData } from '../../../ui/claims/ClaimCreationFlow/context/types';
import { toISODate } from '../../../utils/dates';
import { normalizePlaceResponse } from '../places/normalizers';
import { normalizePolicyResponse } from '../policies/normalizers';
import { type ClaimModel, type CreateClaimPayload } from './types';

export const createClaimPayload = (
  data: Required<ClaimFormsData>,
): CreateClaimPayload => ({
  status: 'unassigned',
  certificate_id: data.certificateId,
  description: data.eventDescription,
  event_start_time: toISODate(data.eventDate, { time: true }),
  event_place: data.eventAddress,
  event_location_type: data.eventLocationType,
  current_cargo_place: data.currentCargoPlace,
  claim_contact_person_name: data.contactName,
  claim_contact_person_email: data.contactEmail,
  claim_contact_person_mobile: data.contactPhone,
  reported_amount: data.reportedAmount,
  reported_amount_currency_code: data.reportedAmountCurrency,
});

export const normalizeClaimResponse = (response: ClaimModel): Claim => ({
  id: response.id,
  policy_id: response.policy_id,
  claim_number: response.claim_number,
  status: response.status,
  paying_company: response.paying_company,
  reported_time: response.reported_time,
  description: response.description,
  currency: response.reported_amount_currency?.code,
  paid_amount: response.paid_amount,
  reported_amount: response.reported_amount,
  reserved_amount: response.reserved_amount,
  applied_deductible_amount: response.applied_deductible_amount,
  recovered_amount: response.recovered_amount,
  total_amount: response?.total_amount,
  payment_time: response.payment_time,
  policy: response.policy
    ? normalizePolicyResponse(response.policy)
    : undefined,
  event: {
    start_time: response.event_start_time,
    end_time: response.event_end_time,
    location_type: response.event_location_type,
    location: normalizePlaceResponse(response.event_place),
    current_cargo_place: normalizePlaceResponse(response.current_cargo_place),
  },
  contact_person: {
    name: response.claim_contact_person_name,
    email: response.claim_contact_person_email,
    mobile: response.claim_contact_person_mobile,
  },
  opening_user: {
    email: response.opening_user_email,
    name: response.opening_user_name,
  },
  claim_adjuster_name: response.claim_adjuster_name,
  created_by_user: response.created_by_user,
});
