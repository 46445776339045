import { useEffect } from 'react';

import { normalizeQuoteResponse } from '../../../network/apis/quotes/normalizers';
import { getQuoteById } from '../../../network/apis/quotes/quotes';
import { normalizeShipmentResponse } from '../../../network/apis/shipments/normalizers';
import { getShipmentById } from '../../../network/apis/shipments/shipments';
import { useRouteParams } from '../../../router/router-hooks';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';
import {
  type PolicyWizardUrlParams,
  type PolicyWizardUrlSearchParams,
} from '../context/types';
import { useSetQuoteData, useSetShipmentData } from './context-hooks';

export const useFetchExistingQuoteData = () => {
  const setQuoteData = useSetQuoteData();
  const { setError } = useSectionError();
  const { navigateToPolicy } = useAppNavigation();
  const { params } = useRouteParams<PolicyWizardUrlParams>();
  const { quoteId } = params;

  useEffect(() => {
    if (quoteId) {
      getQuoteById(quoteId)
        .then((response) => {
          const quote = normalizeQuoteResponse(response);

          // if this Quote has already become a Policy, redirect to it
          quote.active_policy_id
            ? navigateToPolicy(quote.active_policy_id)
            : setQuoteData(quote);
        })
        .catch((e) => {
          reportException(e);
          setError(e);
        });
    }
  }, [setQuoteData, quoteId, setError, navigateToPolicy]);

  return quoteId;
};

export const useFetchExistingShipmentData = () => {
  const setShipmentData = useSetShipmentData();
  const { setError } = useSectionError();
  const { searchParams } = useRouteParams<never, PolicyWizardUrlSearchParams>();
  const { shipment_id } = searchParams;

  useEffect(() => {
    if (shipment_id) {
      getShipmentById(shipment_id)
        .then((response) =>
          setShipmentData(normalizeShipmentResponse(response)),
        )
        .catch((e) => {
          reportException(e);
          setError(e);
        });
    }
  }, [setError, setShipmentData, shipment_id]);

  return shipment_id;
};
