import capitalize from 'lodash/capitalize';

import { type ConveyanceType } from '../../../../model/Shipment';

export const getModeOfTransportDisplayString = (
  primaryTransportMode: ConveyanceType,
  hasSecondaries: boolean,
) => {
  const capitalizedTransportMode = capitalize(primaryTransportMode);

  return hasSecondaries
    ? `${capitalizedTransportMode}, Road`
    : capitalizedTransportMode;
};
