import { type ComponentProps, useMemo } from 'react';
import { Autocomplete } from '@breeze-ai/ui-library';

import { type Port, type PortType } from '../../../model/Port';
import { getPorts } from '../../../network/apis/ports/ports';

type PortAutocompleteProps = Omit<
  ComponentProps<typeof Autocomplete<Port>>,
  'getOptionValue' | 'onChange'
> & {
  authApi?: boolean;
  onChange: (port: Port) => void;
  type?: PortType;
};

const DEFAULT_OPTIONS_LIMIT = 15;
const DEBOUNCE_MS = 1000;
const DEFAULT_PORT_TYPE: PortType[] = ['marine', 'air'];
const defaultLabel = 'Port';
const defaultPlaceholder = 'Search by code or name...';

const isSamePort = (port1?: Port, port2?: Port): boolean => {
  if (!port1 || !port2) {
    return false;
  }

  return port1.code === port2.code && port1.type === port2.type;
};

export const PortAutocomplete = ({
  type,
  value,
  onChange,
  inputProps,
  authApi = true,
  ...props
}: PortAutocompleteProps) => {
  const portType = useMemo(() => (type ? [type] : DEFAULT_PORT_TYPE), [type]);

  const fetchPorts = (query?: string) => {
    return getPorts(
      {
        port_type: portType,
        limit: DEFAULT_OPTIONS_LIMIT,
        query,
      },
      authApi,
    );
  };

  return (
    <Autocomplete<Port>
      value={value}
      defaultValue={value}
      fetchOptions={fetchPorts}
      debounceMs={DEBOUNCE_MS}
      getOptionValue={(option) => option.id}
      onChange={onChange}
      getOptionLabel={({ name, code }) => `${code} ${name}`}
      isOptionEqualToValue={isSamePort}
      blurOnSelect={true}
      disableClearable={true}
      inputProps={{
        ...inputProps,
        type: 'text',
        required: inputProps?.required ?? true,
        label: inputProps?.label ?? defaultLabel,
        placeholder: inputProps?.placeholder ?? defaultPlaceholder,
      }}
      {...props}
      key={`port-autocomplete-${type}`}
    />
  );
};
