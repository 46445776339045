import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useTracking } from '../../utils/snowplow/hooks';
import { ShipmentsTable } from './ShipmentsTable/ShipmentsTable';

export const ShipmentsPage = () => {
  useTracking();

  return (
    <SectionLayout
      title="Shipments"
      data-testid="shipments-page"
      actions={
        <LinkButton
          size="small"
          width="auto"
          data-testid="get-quote-button"
          label=" Get A Quote"
          href="/quotes/create"
        />
      }
    >
      <ShipmentsTable />
    </SectionLayout>
  );
};
