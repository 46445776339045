import {
  Button as RACButton,
  type ButtonProps as RACButtonProps,
  composeRenderProps,
} from 'react-aria-components';

import { clsxMerge } from '../common/utils/classNameUtils';
import { inputStyles } from '../Field/Field';

interface SelectButtonProps extends RACButtonProps {
  isInvalid?: boolean;
}

export function SelectButton({ isInvalid, ...props }: SelectButtonProps) {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        clsxMerge(
          inputStyles({ ...renderProps, isInvalid }),
          'flex items-center justify-between min-w-fit gap-2 whitespace-nowrap',
          className,
        ),
      )}
    />
  );
}
