import React, { type HTMLProps, type PropsWithChildren } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

import { clsxMerge } from '../common/utils/classNameUtils';

export const typography = tv({
  base: ` font-default font-normal`,
  variants: {
    level: {
      h1: `text-3xl leading-[48px] font-bold tracking-[0.2px]`,
      h2: `text-2xl leading-8 font-bold tracking-[0.2px]`,
      h3: `text-xl leading-6 font-bold`,
      h4: `text-lg leading-4 font-bold`,
      h5: `text-base leading-5 font-bold`,
      base: `text-base leading-5`,
      button: `text-[13px] leading-5 font-bold`,
      links: `text-base leading-6`,
      subtext: `text-sm leading-4`,
      'subtext-bold': `text-sm leading-4 font-bold`,
      caption: `text-xs leading-3`,
    },
    color: {
      primary: `text-text-primary`,
      secondary: `text-text-secondary`,
      tertiary: `text-text-tertiary`,
      success: `text-success`,
      warning: `text-warning`,
      error: `text-error`,
      disabled: `text-text-disabled`,
    },
    text: {
      bold: `font-bold`,
      noBold: `font-normal`,
      italic: `italic`,
      underline: `underline`,
      upperCase: `uppercase`,
      lowerCase: `lowercase`,
      capitalize: `capitalize`,
      clickable: `cursor-pointer`,
      disabled: `cursor-not-allowed`,
      ellipsis: `overflow-ellipsis`,
      block: `block`,
    },
  },
  defaultVariants: {
    level: `base`,
  },
  compoundVariants: [
    {
      level: 'links',
      className: `text-text-link-default hover:text-text-link-hover active:text-text-link-selected cursor-pointer`,
    },
  ],
});

// exclude color and level from the props
export interface TypographyProps
  extends Omit<
      HTMLProps<HTMLParagraphElement>,
      'color' | 'level' | 'className'
    >,
    VariantProps<typeof typography> {
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  customStyles?: string;
}

export const Typography = ({
  prefixIcon,
  suffixIcon,
  customStyles,
  children,
  ...props
}: PropsWithChildren<TypographyProps>) => {
  return (
    <p
      role="typography"
      className={clsxMerge(typography(props), customStyles)}
      {...props}
    >
      {prefixIcon}
      {children}
      {suffixIcon}
    </p>
  );
};
