import {
  FileUpload,
  type FileUploadRules,
  Typography,
} from '@breeze-ai/ui-library';

import { FileList } from '../../../components/FileList/FileList';
import { NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import { PageSection } from '../../../components/PageSection/PageSection';
import { ClaimCreationFlowSteps } from '../../constants';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import { useClaimCreationFormValidations } from '../form-validations';
import styles from './ClaimFilesUpload.module.scss';

const fileRules: FileUploadRules = {
  maxSizeMB: 15,
  allowedTypes: [
    'csv',
    'doc',
    'docx',
    'jpg',
    'jpeg',
    'pdf',
    'png',
    'xls',
    'xlsx',
  ],
};

export const ClaimFilesUpload = () => {
  const { formsData, formErrors } = useClaimCreationFlowContext();
  const { setStep, setFormData, setFormError } = useClaimCreationStateAction();
  const validateForms = useClaimCreationFormValidations();
  const formsValid = validateForms([]);

  const { files } = formsData;

  return (
    <div className={styles.wrapper} data-testid="claim-files-upload">
      <PageSection
        scroll
        title="Upload files"
        subtitle={
          <div className={styles.instructions}>
            <Typography>
              Please provide event documents and photos for claim assessment.
            </Typography>
            <Typography level="subtext" variant="input">
              Examples: Event photos, bill of lading or airway bill, consignment
              note, cargo valuation, packing list, proof of delivery etc.
            </Typography>
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <FileUpload
            value={files}
            title="Drag your files here"
            message="Or click here to select from your computer"
            rules={fileRules}
            renderRules={({ allowedTypes, maxSizeMB }) => (
              <Typography level="h5" variant="input">
                {allowedTypes?.join(', ').toUpperCase()} files up to {maxSizeMB}{' '}
                MB are allowed
              </Typography>
            )}
            filesPreview={{
              external: true,
              render: (files, onRemoveFile) => (
                <div className={styles.filesPreview}>
                  <Typography level="h5">Shared files</Typography>
                  <FileList
                    files={files}
                    errors={formErrors.files}
                    onRemove={onRemoveFile}
                  />
                </div>
              ),
            }}
            onChange={(files) => {
              setFormData({ files });
              setFormError({ field: 'files', errors: undefined });
            }}
            onError={(errors) => setFormError({ field: 'files', errors })}
            data-testid="claim-files-upload-input"
          />
        </div>
      </PageSection>
      <NavigationFooter
        backText="Back"
        nextText="Next"
        onBack={() => setStep(ClaimCreationFlowSteps.CLAIM_DETAILS)}
        onNext={() => setStep(ClaimCreationFlowSteps.REVIEW)}
        backProps={{ isDisabled: !formsValid }}
        nextProps={{ isDisabled: !formsValid }}
      />
    </div>
  );
};
