import { FaInfoCircle } from 'react-icons/fa';
import { PiClockCountdownLight } from 'react-icons/pi';
import { Currency, Tooltip, Typography } from '@breeze-ai/ui-library';
import { Badge } from '@breezeai-frontend/cargo-ui';
import { toPercentage } from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Quote } from '../../../model/Quote';
import {
  formatCurrencyByValueAndCode,
  formatCurrencyValue,
} from '../../../utils/Internationalization';
import { getAppLocale } from '../../../utils/locale';
import { COVERAGE_PACKAGES } from '../../constants';
import { QuoteAdditionalClause } from '../../quotes/components/QuoteAdditionalClause';
import { QuotePricingDetails } from '../../quotes/components/QuotePricingDetails/QuotePricingDetails';
import { JourneySummary } from '../JourneySummary/JourneySummary';
import styles from './QuoteDetails.module.scss';

type QuoteDetailsProps = {
  quote: Quote;
};

const getOrdinalIndicator = (day: number) => {
  const lastDigit = day % 10,
    lastTwoDigits = day % 100;
  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return day + 'st';
  }
  if (lastDigit === 2 && lastTwoDigits !== 12) {
    return day + 'nd';
  }
  if (lastDigit === 3 && lastTwoDigits !== 13) {
    return day + 'rd';
  }
  return day + 'th';
};

const formatExpiringOn = (expiring_on: string) => {
  if (!expiring_on) {
    return null;
  }

  const date = new Date(expiring_on);
  const day = date.getUTCDate(); // Use UTC methods
  const month = date.toLocaleString('default', {
    month: 'long',
    timeZone: 'UTC',
  }); // Specify UTC time zone
  const year = date.getUTCFullYear(); // Use UTC methods
  const ordinalIndicator = getOrdinalIndicator(day);
  const locale = getAppLocale();
  if (locale === 'en-US') return `${month} ${ordinalIndicator}, ${year}`;
  if (locale === 'en-GB') return `${ordinalIndicator} of ${month} ${year}`;
  return null; // Handle unsupported locales
};

export const QuoteDetails = ({
  quote,
  quote: {
    origin_place,
    origin_port,
    destination_place,
    destination_port,
    primary_transport_mode_code,
    loading_place,
    loading_transport_mode_code,
    delivery_place,
    delivery_transport_mode_code,
    customer,
    commodity_category,
    premium_value,
    premium_currency,
    insured_value = 0,
    insured_value_currency,
    deductible_value = 0,
    deductible_currency,
    freight_cost = 0,
    freight_cost_currency,
    duty_cost = 0,
    duty_cost_currency,
    coverage_package,
    external_reference,
    expiring_on,
    quote_additional_clauses,
    premium_rate,
    uplift_percentage,
  },
}: QuoteDetailsProps) => {
  invariant(
    primary_transport_mode_code,
    'primary_transport_mode_code is required',
  );
  const showDutyCost = useFeatureToggle('enable_shipments_duty_cost');
  const showCoveragePackage = useFeatureToggle(
    'enable_coverage_package_selection',
  );

  const origin = origin_place ?? origin_port;
  const destination = destination_place ?? destination_port;

  const insuredValueAdditionalCosts = [
    {
      label: 'Freight Cost',
      value: freight_cost,
      currency: freight_cost_currency,
    },
    {
      label: 'Duty Cost',
      value: duty_cost,
      currency: duty_cost_currency,
      hide: !showDutyCost,
    },
  ].filter(({ value, hide }) => value && !hide);
  formatCurrencyValue({
    value: insured_value,
    currency_code: insured_value_currency,
  });

  formatCurrencyByValueAndCode(freight_cost, freight_cost_currency);

  if (!origin || !destination) {
    return null;
  }

  let stops = [origin, destination];
  let conveyances = [primary_transport_mode_code];
  if (loading_place && loading_transport_mode_code) {
    stops = [loading_place, ...stops];
    conveyances = [loading_transport_mode_code, ...conveyances];
  }
  if (delivery_place && delivery_transport_mode_code) {
    stops = [...stops, delivery_place];
    conveyances = [...conveyances, delivery_transport_mode_code];
  }

  // There might be multiple additional clauses in the future, but
  // we are only interested in the first result for now.
  const additionalClause = quote_additional_clauses?.[0];

  return (
    <div className={styles.quoteDetailsWrapper}>
      <Typography level="h1" className={styles.title}>
        Here's your insurance offer
      </Typography>
      <div className={styles.shipmentSummary}>
        {showCoveragePackage && coverage_package && (
          <Badge outlined className="my-5" data-testid="coverage-package">
            {COVERAGE_PACKAGES[coverage_package].badgeLabel}
          </Badge>
        )}
        <Typography data-testid="submission-summary" className="inline-block">
          {external_reference && `${external_reference}, `}
          {customer?.company_name}, shipment of {commodity_category}, insured
          for{' '}
          {insured_value_currency && (
            <span className="flex gap-1 items-center pl-1">
              <Currency
                value={insured_value}
                currency={insured_value_currency}
              />
              <Tooltip
                content={`Insured Value is Commercial Invoice Value + ${toPercentage(
                  uplift_percentage,
                )}%`}
              >
                <FaInfoCircle data-testid="info-icon" />
              </Tooltip>
            </span>
          )}
        </Typography>
        <div className={styles.additionalCosts}>
          {insuredValueAdditionalCosts.map(({ label, value, currency }, i) => (
            <Typography key={i} block>
              {label}{' '}
              {currency && <Currency value={value} currency={currency} />}
              {i < insuredValueAdditionalCosts.length - 1 && ` ,`}
            </Typography>
          ))}
        </div>
      </div>
      <JourneySummary stops={stops} conveyances={conveyances} />
      <div className="w-full max-w-[848px] flex flex-col gap-10 items-center">
        {additionalClause && (
          <QuoteAdditionalClause clause={additionalClause} />
        )}
        {premium_value && premium_currency && (
          <QuotePricingDetails
            premium_value={premium_value}
            premium_currency={premium_currency}
            premium_rate={premium_rate}
            exchange_rate={quote.exchange_rate}
            tax={quote.tax}
            converted_customer_premium_value={
              quote.converted_customer_premium_value
            }
            converted_tax_amount={quote.converted_tax_amount}
            hide_tax_info={quote.distributor?.hide_tax_info}
          />
        )}
      </div>
      <div className={styles.additionalFees}>
        {deductible_value > 0 && deductible_currency && (
          <Typography level="subtext" className={styles.deductible}>
            Deductible{' '}
            <Currency
              typographyProps={{ level: 'subtext' }}
              value={deductible_value}
              currency={deductible_currency}
            />
          </Typography>
        )}
      </div>
      {expiring_on ? (
        <Typography level="subtext" className={styles.expiringOn}>
          <PiClockCountdownLight />
          This quote is valid until <b>{formatExpiringOn(expiring_on)}</b>
        </Typography>
      ) : null}
    </div>
  );
};
