import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { enableErrorTracking } from '@snowplow/browser-plugin-error-tracking';
import { enableFormTracking } from '@snowplow/browser-plugin-form-tracking';
import { enableLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import {
  enableActivityTracking,
  setUserId,
  trackPageView,
} from '@snowplow/browser-tracker';

import { useUser } from '../../context/auth/auth-hooks';
import { enableTracking } from '../../frameworks/snowplow';
import { type TrackingObject } from '../../model/Snowplow';
import { normalizeSpContextPageView } from './normalizers';

/**
 * Automatically track page views and user activity.
 * Automatically set user id to user context if user is logged in.
 *
 * @see https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#global-context
 */
export const useTracking = (trackingObject?: TrackingObject) => {
  const { pathname } = useLocation();
  const title = pathname.replace('/', '');
  const user = useUser();

  useEffect(() => {
    if (!enableTracking) return;
    enableActivityTracking({
      minimumVisitLength: 10,
      heartbeatDelay: 10,
    });
    enableErrorTracking();
    enableFormTracking();
    enableLinkClickTracking();
  }, []);

  // Set user id
  useEffect(() => {
    if (!enableTracking) return;
    if (user?.id) {
      setUserId(String(user.id));
    }
  }, [user?.id]);

  // Track page view
  // TODO: firing twice on login and insights due to fullcontext in dependency
  useEffect(() => {
    const fullcontext = normalizeSpContextPageView(trackingObject, user);

    if (!enableTracking || !trackingObject) return;
    trackPageView({
      title,
      context: fullcontext,
    });
  }, [trackingObject, title, user]);
};
