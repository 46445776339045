interface FlowStep {
  step: ClaimCreationFlowSteps;
  title: string;
}

export enum ClaimCreationFlowSteps {
  CLAIM_DETAILS = 0,
  UPLOAD_FILES = 1,
  REVIEW = 2,
}

export const claimCreationFlowSteps: FlowStep[] = [
  { step: ClaimCreationFlowSteps.CLAIM_DETAILS, title: 'Claim Details' },
  { step: ClaimCreationFlowSteps.UPLOAD_FILES, title: 'Upload Files' },
  { step: ClaimCreationFlowSteps.REVIEW, title: 'Review & Confirm' },
];
