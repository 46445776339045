import { Icon, Typography, useWindowSize } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';

import { useTracking } from '../../../../utils/snowplow/hooks';
import { useDTCFlowContext, useDTCStateAction } from '../../context/hooks';
import { useDTCNavigation } from '../../dtc-navigation';
import styles from './GeneralWelcomePage.module.scss';

type GeneralWelcomePageProps = {
  title: string;
};

const items = [
  'We’ll ask you to share some details so we can provide a quote',
  "We'll follow up within 2 business days of your submission",
];

export const GeneralWelcomePage = ({ title }: GeneralWelcomePageProps) => {
  useTracking();
  const { isMobile } = useWindowSize();
  const { brand } = useDTCFlowContext();
  const { setStep } = useDTCStateAction();
  const { navigateToDTCSection, getNextStep } = useDTCNavigation();

  const setFlowStartStep = () => {
    setStep(getNextStep());
  };

  return (
    <div className={styles.wrapper} data-testid="dtc-welcome-page">
      <Typography level={isMobile ? 'h3' : 'h2'}>
        Welcome to {brand.name}
      </Typography>
      <Typography level={isMobile ? 'h2' : 'h1'} className={styles.title}>
        {title}
      </Typography>
      <div className={styles.overview}>
        {items.map((text, i) => (
          <div key={i} className={styles.item}>
            <Icon icon="check-circle" variant="success" />
            <Typography>{text}</Typography>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        <Button
          aria-details="start-button"
          data-testid="start-button"
          onPress={setFlowStartStep}
          width="full"
          label="Got it, Let's go!"
        />
        <Button
          width="full"
          variant="secondary"
          aria-details="back-button"
          data-testid="back-button"
          onPress={() => navigateToDTCSection('start')}
          label="Back to Select Insurance Type"
        />
      </div>
    </div>
  );
};
