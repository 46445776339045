import { create } from 'zustand';

interface ModalStore {
  isCreationConfirmedModalOpen: boolean;
  setIsCreationConfirmedModalOpen: (isOpen: boolean) => void;
  isCancelChangesModalOpen: boolean;
  setIsCancelChangesModalOpen: (isOpen: boolean) => void;
  isConfirmChangesModalOpen: boolean;
  setIsConfirmChangesModalOpen: (isOpen: boolean) => void;
}

export const useModalStore = create<ModalStore>((set) => ({
  isCreationConfirmedModalOpen: false,
  setIsCreationConfirmedModalOpen: (isOpen: boolean) =>
    set({ isCreationConfirmedModalOpen: isOpen }),
  isCancelChangesModalOpen: false,
  setIsCancelChangesModalOpen: (isOpen: boolean) =>
    set({ isCancelChangesModalOpen: isOpen }),
  isConfirmChangesModalOpen: false,
  setIsConfirmChangesModalOpen: (isOpen: boolean) =>
    set({ isConfirmChangesModalOpen: isOpen }),
}));
