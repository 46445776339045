import { useEffect } from 'react';

import { useUser } from '../../../context/auth/auth-hooks';
import { getPolicyWizardConfiguration } from '../../../network/apis/configurations/configurations';
import { normalizePolicyWizardConfigResponse } from '../../../network/apis/configurations/normalizers';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import {
  usePolicyWizardContext,
  usePolicyWizardContextAction,
} from './context-hooks';

/**
 * Triggers an API for fetching the Quote form configuration
 * in the initialization phase of the flow.
 */
export const usePolicyWizardConfiguration = () => {
  const dispatch = usePolicyWizardContextAction();
  const user = useUser();
  const { configuration } = usePolicyWizardContext();

  useEffect(() => {
    if (!user || configuration) {
      return;
    }

    getPolicyWizardConfiguration()
      .then((configuration) => {
        dispatch({
          type: 'set-form-configuration',
          payload: normalizePolicyWizardConfigResponse(configuration),
        });
      })
      .catch(reportException);
  }, [user, configuration, dispatch]);
};
