import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocale } from '@breeze-ai/ui-library';
import { CargoProvider } from '@breezeai-frontend/cargo-ui';

import { ReCaptchaProvider } from '../../../context/recaptcha/ReCaptchaProvider';
import { getTheme } from '../../../utils/getTheme';
import { initialLocale, setAppLocale } from '../../../utils/locale';

export const GuestUserPage = () => {
  const { setLocale: setComponentsLocale } = useLocale();
  const theme = getTheme();
  const navigate = useNavigate();

  // Set default locale for unauthorized users.
  // This infers the browser's defined language or falls back to default.
  useEffect(() => {
    setAppLocale(initialLocale);
    setComponentsLocale?.(initialLocale);
  });

  return (
    <ReCaptchaProvider>
      <CargoProvider theme={theme} navigate={navigate}>
        <Outlet />
      </CargoProvider>
    </ReCaptchaProvider>
  );
};
