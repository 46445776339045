import { type ComponentProps, type ReactNode, useCallback } from 'react';
import { Autocomplete } from '@breeze-ai/ui-library';

import { type Customer } from '../../../../model/Customer';
import { getCustomers } from '../../../../network/apis/customers/customers';

type CustomerSelectorProps = Omit<
  ComponentProps<typeof Autocomplete<Customer>>,
  'value' | 'options' | 'getOptionValue' | 'onChange' | 'onBlur' | 'renderInput'
> & {
  selectedCustomer?: Customer;
  error?: boolean;
  helperText?: ReactNode;
  onBlur: (option: string | Customer) => void;
  onChange: (option: string | Customer) => void;
  required?: boolean;
};

export const CustomerSelector = ({
  selectedCustomer,
  error,
  helperText,
  onBlur,
  onChange,
  required = true,
  ...props
}: CustomerSelectorProps) => {
  const fetchCustomerOptions = useCallback((query?: string) => {
    return getCustomers({ limit: 15, query }).then(
      ({ customers }) => customers,
    );
  }, []);

  const validateAndSelect = (value: string | Customer) => {
    onBlur(value);

    if (value) {
      onChange(value);
    }
  };

  return (
    <Autocomplete<Customer>
      fetchOptions={fetchCustomerOptions}
      value={selectedCustomer}
      onChange={validateAndSelect}
      disableClearable={true}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.company_name ?? ''
      }
      isOptionEqualToValue={(option, value) =>
        value ? option.id === value.id : false
      }
      inputProps={{
        required,
        label: 'Cargo Owner Name',
        placeholder: 'Full name',
        error,
        errorHelperText: helperText,
        onBlur: ({ target: { value } }) => validateAndSelect(value),
        testId: 'customer-selector',
      }}
      {...props}
    />
  );
};
