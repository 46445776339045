import { createContext, type ReactNode, useReducer } from 'react';

import { WizardSteps } from '../constants';
import { policyWizardContextReducer } from './PolicyWizardContextReducer';
import { type PolicyWizardContextType, type PolicyWizardState } from './types';

const initialState: PolicyWizardState = {
  step: WizardSteps.INIT,
  forms: {
    errors: [],
  },
};

export const PolicyWizardContext = createContext<PolicyWizardContextType>({
  state: initialState,
  dispatch: () => undefined,
});

export const PolicyWizardContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    policyWizardContextReducer,
    initialState,
  );

  return (
    <PolicyWizardContext.Provider value={{ state, dispatch }}>
      {children}
    </PolicyWizardContext.Provider>
  );
};
