import { FormLayout, FormRow, Input, Typography } from '@breeze-ai/ui-library';

import { useDTCForms, useDTCStateAction } from '../../../context/hooks';
import sharedStyles from '../../../shared/dtc-styles.module.scss';
import { useDTCInputValidation } from '../../../shared/forms-validations';

export const ContactDetailsForm = () => {
  const { data, errors } = useDTCForms();
  const { setFormData } = useDTCStateAction();
  const validateInput = useDTCInputValidation();

  const { contactName, contactEmail, contactPhone } = data;

  return (
    <div
      className={sharedStyles.sectionWrapper}
      data-testid="contact-details-form"
    >
      <Typography level="h4">Contact Details</Typography>
      <hr />
      <FormLayout>
        <FormRow className={sharedStyles.formRow}>
          <Input
            type="text"
            label="Contact Name"
            placeholder="Enter full name"
            required
            value={contactName}
            onChange={({ target: { value } }) =>
              setFormData({ contactName: value })
            }
            validator={(value) => validateInput('contactName', value)}
            error={!!errors?.contactName}
            errorHelperText={errors?.contactName?.reason}
            testId="dtc-contact-name-input"
          />
          <Input
            type="text"
            label="Email"
            placeholder="Enter email"
            required
            value={contactEmail}
            onChange={({ target: { value } }) =>
              setFormData({ contactEmail: value })
            }
            validator={(value) => validateInput('contactEmail', value)}
            error={!!errors?.contactEmail}
            errorHelperText={errors?.contactEmail?.reason}
            testId="dtc-contact-email-input"
          />
          <Input
            type="tel"
            label="Phone"
            placeholder="+1 (111) 111-111"
            required
            value={contactPhone}
            onChange={({ target: { value } }) =>
              setFormData({ contactPhone: value })
            }
            validator={(value) => validateInput('contactPhone', value)}
            error={!!errors?.contactPhone}
            errorHelperText={errors?.contactPhone?.reason}
            testId="dtc-contact-phone-input"
          />
        </FormRow>
      </FormLayout>
    </div>
  );
};
