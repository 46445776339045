import {
  composeRenderProps,
  TextField as AriaTextField,
  type TextFieldProps as AriaTextFieldProps,
} from 'react-aria-components';

import { clsxMerge } from '../common/utils/classNameUtils';
import {
  Description,
  FieldError,
  fieldStyles,
  Input,
  Label,
  TextArea,
} from '../Field/Field';

export interface TextFieldProps
  extends Omit<AriaTextFieldProps, 'children' | 'className'> {
  label?: string;
  description?: string;
  errorMessage?: string;
  customStyles?: string;
  placeholder?: string;
  variant?: 'input' | 'area';
  inputStyles?: string;
}

export function TextField({
  variant = 'input',
  label,
  description,
  isInvalid,
  errorMessage,
  isRequired,
  placeholder,
  inputStyles,
  ...props
}: TextFieldProps) {
  return (
    <AriaTextField
      {...props}
      isInvalid={isInvalid}
      validationBehavior="aria"
      isRequired={isRequired}
      className={composeRenderProps(
        props.customStyles,
        (customStyles, renderProps) =>
          clsxMerge(fieldStyles({ ...renderProps }), customStyles),
      )}
    >
      {label && <Label isRequired={isRequired}>{label}</Label>}
      {variant === 'input' ? (
        <Input placeholder={placeholder} className={inputStyles} />
      ) : (
        <TextArea placeholder={placeholder} className={inputStyles} />
      )}
      {description && !isInvalid && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </AriaTextField>
  );
}
