import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  useReducer,
} from 'react';

import { ContainerModeEnum } from '../../../model/Shipment';
import { flexportConfig } from '../flexport/configuration';
import { DTCFlowSteps } from '../flows-steps-config';
import { dtcFlowReducer } from './reducer';
import { type DTCFlowContextAction, type DTCFlowState } from './types';

export const initialDTCState: DTCFlowState = {
  flowType: null,
  brand: flexportConfig,
  step: DTCFlowSteps.INIT,
  forms: {
    primaryTransportMode: 'sea',
    currency: 'USD',
    containerModeId: 1,
    containerMode: ContainerModeEnum.FCL,
  },
  formErrors: {},
};

type DTCContextType = {
  state: DTCFlowState;
  dispatch: Dispatch<DTCFlowContextAction>;
};

export const DTCFlowContext = createContext<DTCContextType>({
  state: initialDTCState,
  dispatch: () => undefined,
});

export const DTCFlowContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(dtcFlowReducer, initialDTCState);

  return (
    <DTCFlowContext.Provider value={{ state, dispatch }}>
      {children}
    </DTCFlowContext.Provider>
  );
};
