import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../../../../context/auth/auth-hooks';
import { type Distributor } from '../../../../../model/Distributor';
import labels from '../../../../labels';

interface DistributorSelectProps
  extends Omit<SelectProps<Distributor>, 'children'> {
  isWtw: boolean;
}

export function DistributorSelect(props: DistributorSelectProps) {
  const user = useAuthenticatedUser();

  const distributors = [...(user.distributor?.children ?? [])];

  if (
    !props.isWtw ||
    (props.isWtw && user?.distributor.company_name !== 'ligentia_poland_hq')
  ) {
    distributors.unshift(user.distributor);
  }

  return (
    <Select
      data-testid="distributor-selector"
      validationBehavior="aria"
      label={labels.fields.freightForwarder}
      placeholder={`Select ${labels.fields.freightForwarder}`}
      {...props}
    >
      {distributors.map(({ legal_name, id }) => (
        <SelectItem key={id} id={id} textValue={legal_name}>
          {legal_name}
        </SelectItem>
      ))}
    </Select>
  );
}
