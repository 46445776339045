import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { verifyReCAPTCHAToken } from '../../network/apis/auth/auth';
import { type ReCaptchaResult } from '../../network/apis/auth/types';
import { isTestEnv } from '../../utils/env';

type ReCaptchaProviderProps = PropsWithChildren<{
  onVerify?: () => void;
}>;

type ReCaptchaContextType = {
  verifyReCAPTCHA: (action?: string) => Promise<ReCaptchaResult | undefined>;
};

const siteKey = import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY ?? '';
export const ReCaptchaContext = createContext<ReCaptchaContextType | null>(
  null,
);

export const useReCAPTCHA = () => {
  return useContext(ReCaptchaContext) as ReCaptchaContextType;
};

const ReCAPTCHAProtection = ({ children }: PropsWithChildren) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const testEnvReCaptchaToken = isTestEnv ? 'test_recaptcha_token' : undefined;

  const verifyReCAPTCHA = useCallback(
    async (action?: string) => {
      if (executeRecaptcha) {
        const token = testEnvReCaptchaToken ?? (await executeRecaptcha(action));

        return await verifyReCAPTCHAToken(token);
      }
      return;
    },
    [executeRecaptcha, testEnvReCaptchaToken],
  );

  return (
    <ReCaptchaContext.Provider value={{ verifyReCAPTCHA }}>
      {children}
    </ReCaptchaContext.Provider>
  );
};

export const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey} useEnterprise={true}>
      <ReCAPTCHAProtection>{children}</ReCAPTCHAProtection>
    </GoogleReCaptchaProvider>
  );
};
