import { type ReactElement } from 'react';
import { type IconBaseProps } from 'react-icons';
import { tv, type VariantProps } from 'tailwind-variants';

import { clsxMerge } from '../common/utils/classNameUtils';

const iconBadge = tv({
  base: `min-w-10 min-h-10 w-10 h-10 flex
    justify-center items-center rounded-lg text-system-grey-750
  `,
  variants: {
    variant: {
      primary: 'bg-field-bg-faded',
      secondary: 'bg-system-grey-50 text-icons-primary',
      tertiary: 'bg-system-grey-white text-icons-primary',
    },
  },
});

export interface IconBadgeProps
  extends VariantProps<typeof iconBadge>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  children: ReactElement<IconBaseProps>;
  customStyles?: string;
}

export function IconBadge({
  children,
  variant = 'primary',
  customStyles,
  ...props
}: IconBadgeProps) {
  return (
    <div {...props} className={clsxMerge(iconBadge({ variant }), customStyles)}>
      {children}
    </div>
  );
}
