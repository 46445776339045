import { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from '@breeze-ai/ui-library';
import { Button, Logo } from '@breezeai-frontend/cargo-ui';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';

import { useUser } from '../../context/auth/auth-hooks';
import { get } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';
import { getTheme } from '../../utils/getTheme';
import { SidebarItem } from './components/SidebarItem/SidebarItem';
import { UserMenu } from './components/UserMenu/UserMenu';
import styles from './Sidebar.module.scss';
import { useActiveSidebarItem, useSidebarItems } from './sidebar-hooks';

const BREAKPOINT = 1024;

const AuthenticatedSidebar = (
  user: Exclude<ReturnType<typeof useUser>, undefined>,
) => {
  const [expanded, setExpanded] = useState<boolean>(
    window.innerWidth < BREAKPOINT ? false : true,
  );
  const items = useSidebarItems();
  const activeSection = useActiveSidebarItem();

  const THEME = getTheme();

  const { data: organizationLogoQuery } = useQuery({
    queryKey: ['distributorDisplayLogo', user.distributor?.id],
    queryFn: async () =>
      user.distributor === undefined
        ? { image_url: undefined }
        : get<{ image_url: string }>(
            `${bffServiceUrl}/bff-distributors/${user.distributor.id}/display-logo-url`,
          ),
  });

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < BREAKPOINT) {
        setExpanded(false);
      }

      if (window.innerWidth >= BREAKPOINT) {
        setExpanded(true);
      }
    });
  }, []);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      data-testid="sidebar"
      className={classnames(
        styles.sidebarWrapper,
        'bg-navbar-bg border border-navbar-bg-border',
        {
          [styles.expanded]: expanded,
        },
      )}
    >
      <div className={styles.header}>
        <Logo minified={!expanded} variant="white" theme={THEME} />
      </div>
      <div className={styles.body} data-testid="nav-items">
        {items.map((item, i) =>
          item.hidden ? null : (
            <SidebarItem
              {...item}
              key={i}
              minified={!expanded}
              active={activeSection === item.path}
            />
          ),
        )}
      </div>
      <div className={styles.footer}>
        <UserMenu user={user} minified={!expanded} />
        {organizationLogoQuery?.image_url && (
          <Link
            className={styles.organizationLogoWrapper}
            newTab={true}
            icon={false}
          >
            <img
              src={organizationLogoQuery?.image_url}
              alt="organization-logo"
              className={styles.organizationLogo}
            />
          </Link>
        )}
      </div>
      <Button
        leftIcon={expanded ? <FaChevronLeft /> : <FaChevronRight />}
        onPress={toggleSidebar}
        width="circle"
        size="xsmall"
        customStyles="z-10 absolute right-[-12px] top-11 rounded-full text-navbar-expand-button-icon hover:text-navbar-expand-button-icon-hover bg-navbar-expand-button-bg-default hover:bg-navbar-expand-button-bg-hover"
      />
    </div>
  );
};

export const Sidebar = () => {
  const user = useUser();

  return user ? <AuthenticatedSidebar {...user} /> : null;
};
