import { useMemo } from 'react';
import { FaCopy, FaDownload } from 'react-icons/fa6';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';
import { startCase } from 'lodash';
import capitalize from 'lodash/capitalize';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type Policy } from '../../../model/Policy';
import { ActionMenu } from '../../components/ActionMenu/ActionMenu';
import { QuotePriceBreakdownTooltip } from '../../components/QuotePriceBreakdownTooltip/QuotePriceBreakdownTooltip';
import { COVERAGE_PACKAGES } from '../../constants';
import labels from '../../labels';

export const usePoliciesTableColumns = (): TableColumn<Policy>[] => {
  const { isWtw } = usePlatform();

  const showCoveragePackage = useFeatureToggle(
    'enable_coverage_package_selection',
  );
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const enableDuplicateQuote = useFeatureToggle('enable_duplicate_quote');

  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition(
          'certificate_id',
          `${capitalize(labels.terms.policy.singular)} Number`,
        ),
        valueGetter: ({ row }) => row.certificate_id,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        minWidth: isWtw ? 190 : 150,
        sortable: true,
      },
      {
        ...getBaseColumnDefinition('created_at', 'Created On'),
        type: 'date',
        minWidth: 150,
        valueGetter: ({ row }) => row.created_time,
        renderCell: ({ row }) => (
          <DateCell
            format="SHORT_MONTH_NAME_DATE"
            value={row.created_time}
            createdBy={
              row.created_by_user && {
                avatarColor: row.created_by_user.avatar_color,
                name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
              }
            }
          />
        ),
        sortable: sortingEnabled,
      },
      ...(showCoveragePackage
        ? ([
            {
              ...getBaseColumnDefinition('coverage_package', 'Coverage Type'),
              minWidth: 150,
              valueGetter: ({ row: { quote } }) => quote?.coverage_package,
              renderCell: ({ row: { quote } }) => (
                <TextCell>
                  {quote?.coverage_package
                    ? COVERAGE_PACKAGES[quote.coverage_package].label
                    : undefined}
                </TextCell>
              ),
              sortable: true,
            },
          ] as TableColumn<Policy>[])
        : []),
      {
        ...getBaseColumnDefinition(
          'external_reference',
          labels.fields.bookingReference,
        ),
        valueGetter: ({ row }) => row.quote?.external_reference,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        minWidth: isWtw ? 130 : 180,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('customer_name', labels.fields.cargoOwner),
        description: 'Co-Insured',
        valueGetter: ({ row }) => row.customer?.company_name,
        renderCell: ({ value }) => <TextCell>{value as string}</TextCell>,
        minWidth: 150,
        sortable: false,
      },
      {
        ...getBaseColumnDefinition('insured_value', 'Insured Value'),
        type: 'number',
        valueGetter: ({ row: { quote } }) => quote?.insured_value,
        renderCell: ({ row: { quote } }) => (
          <CurrencyCell
            value={quote?.insured_value}
            currency={quote?.insured_value_currency}
          />
        ),
        minWidth: 150,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition(
          'commodity_type',
          labels.policiesTable.columns.commodity.title,
        ),
        valueGetter: ({ row: { quote } }) =>
          quote?.commodity_external_description,
        valueFormatter: ({ value }) => startCase(value as string),
        renderCell: ({ formattedValue }) => (
          <TextCell>{formattedValue as string}</TextCell>
        ),
        minWidth: isWtw ? 200 : 150,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('etd', 'Departure'),
        type: 'date',
        valueGetter: ({ row: { quote } }) => quote?.etd,
        renderCell: ({ row: { quote }, value }) => (
          <DateCell
            value={value as ISODate}
            title={quote?.origin_place?.display_name ?? quote?.origin_port_code}
          />
        ),
        minWidth: 150,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('eta', 'Arrival'),
        valueGetter: ({ row: { quote } }) => quote?.eta,
        renderCell: ({ row: { quote }, value }) => (
          <DateCell
            value={value as ISODate}
            title={
              quote?.destination_place?.display_name ??
              quote?.destination_port_code
            }
          />
        ),
        width: 160,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('customer_premium_value', 'Total'),
        type: 'number',
        valueGetter: ({ row: { quote = {} } }) => {
          const { tax, premium_value = 0 } = quote;
          return (tax?.value ?? 0) + premium_value;
        },
        renderCell: ({ row: { quote = {} }, value }) => {
          const {
            premium_value,
            premium_currency,
            tax,
            exchange_rate,
            converted_customer_premium_value,
            converted_tax_amount,
            distributor,
          } = quote;

          if (!premium_value || !premium_currency) {
            return <TextCell>—</TextCell>;
          }

          const shouldShowTooltip = Boolean(tax?.value || exchange_rate);

          return (
            <CurrencyCell
              value={value as number}
              currency={premium_currency}
              suffix={
                shouldShowTooltip && (
                  <QuotePriceBreakdownTooltip
                    className="w-3.5 h-3.5"
                    premium_value={premium_value}
                    premium_currency={premium_currency}
                    tax={tax}
                    converted_customer_premium_value={
                      converted_customer_premium_value
                    }
                    converted_tax_amount={converted_tax_amount}
                    exchange_rate={exchange_rate}
                    hide_tax_info={distributor?.hide_tax_info}
                  />
                )
              }
            />
          );
        },
        width: 160,
        sortable: sortingEnabled,
      },
      {
        ...getBaseColumnDefinition('action', ''),
        minWidth: 40,
        maxWidth: 60,
        renderCell: ({ row }) => {
          const items = [
            {
              label: 'Duplicate quote',
              href: `/quotes/duplicate/${row.quote?.id}`,
              leftIcon: <FaCopy />,
              isHidden: false,
              isDownload: false,
            },
            {
              label: 'Download certificate',
              href: row.certificate_url,
              leftIcon: <FaDownload />,
              isDisabled: !row.certificate_url,
              isDownload: true,
            },
          ];
          return enableDuplicateQuote ? (
            <div className="w-full flex justify-center">
              <ActionMenu items={items} id={String(row.id)} />
            </div>
          ) : null;
        },
      },
    ],
    [isWtw, showCoveragePackage, sortingEnabled, enableDuplicateQuote],
  );
};
