import cloneDeep from 'lodash/cloneDeep';

import { type Invoice, type InvoiceDetails } from '../../../model/Invoice';
import { get, post } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  FilterableFields,
  type FilterBaseParam,
  FilterOperators,
  type PaginationBaseQueryParams,
} from '../types';
import {
  type GetInvoicePoliciesResponse,
  type PoliciesSortableFields,
} from './types';

type InvoicesResponseBody = {
  invoices: Invoice[];
};

export const getInvoices = async (): Promise<Invoice[] | undefined> => {
  const {
    data: { invoices },
  } = await get<InvoicesResponseBody>(`${bffServiceUrl}/invoices/`);

  return invoices;
};

export const getInvoiceDetails = async (
  invoiceId: string,
): Promise<InvoiceDetails | undefined> => {
  const { data: invoice } = await get<InvoiceDetails>(
    `${bffServiceUrl}/invoices/${invoiceId}`,
  );

  return invoice;
};

export const getInvoicePolicies = async (
  invoiceId: string,
  params?: PaginationBaseQueryParams<PoliciesSortableFields, FilterableFields>,
): Promise<GetInvoicePoliciesResponse> => {
  const filter: FilterBaseParam<FilterableFields> = {
    field: FilterableFields.INVOICE_ID,
    operator: FilterOperators.EQUALS,
    values: [invoiceId],
  };
  const filters = cloneDeep(params?.filters);
  filters?.push(filter);

  const { data: policies } = await post<GetInvoicePoliciesResponse>(
    `${bffServiceUrl}/invoices/${invoiceId}/policies`,
    { ...params, filters: filters },
  );

  return policies;
};
