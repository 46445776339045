import { Select } from '@breeze-ai/ui-library';

import { useUser } from '../../../../../context/auth/auth-hooks';
import { type Distributor } from '../../../../../model/Distributor';
import styles from './DistributorSelector.module.scss';

type DistributorSelectorProps = {
  onChange: (distributorId: number) => void;
  selectedDistributorId?: number;
};

export const DistributorSelector = ({
  selectedDistributorId,
  onChange,
}: DistributorSelectorProps) => {
  const { distributor } = useUser() ?? {};
  const distributors = [
    distributor,
    ...(distributor?.children ?? []),
  ] as Distributor[];

  const options = distributors?.map(({ id, company_name, legal_name }) => ({
    value: company_name,
    label: legal_name,
    payload: id,
    selected: selectedDistributorId === id,
  }));

  const selectedOption = options.find((option) => option.selected);

  return (
    <div className={styles.distributorSelectorWrapper}>
      <Select
        testId="distributor-selector"
        label="Freight Forwarder"
        value={selectedOption?.value}
        options={options}
        onChange={({ payload }) => onChange(payload)}
      />
    </div>
  );
};
