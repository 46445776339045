import {
  composeRenderProps,
  Label,
  Link as RACLink,
  type LinkProps as RACLinkProps,
  ProgressBar,
} from 'react-aria-components';
import { ImSpinner8 } from 'react-icons/im';
import { type VariantProps } from 'tailwind-variants';

import { Typography } from '../Typography/Typography.tsx';
import { button } from './variants.ts';

interface LinkElementProps extends RACLinkProps {}

export interface LinkProps
  extends Omit<LinkElementProps, 'className'>,
    VariantProps<typeof button> {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  customStyles?: string;
  isLoading?: boolean;
  href?: string;
  label?: string;
  children?: React.ReactNode;
  loadingText?: string;
}

export function LinkButton({
  leftIcon,
  rightIcon,
  isLoading,
  loadingText,
  href,
  label,
  children,
  ...props
}: LinkProps) {
  return (
    <RACLink
      {...props}
      isDisabled={props.isDisabled || isLoading}
      href={href}
      className={composeRenderProps(
        props.customStyles,
        (_className, renderProps) =>
          button({
            ...renderProps,
            width: props.width,
            variant: props.variant,
            size: props.size,
            className: props.customStyles,
          }),
      )}
    >
      {Boolean(leftIcon && !isLoading) && <span>{leftIcon}</span>}
      {Boolean(!isLoading && children) && <span>{children}</span>}
      {Boolean(!isLoading && label) && (
        <Typography level="button">{label}</Typography>
      )}
      {Boolean(rightIcon && !isLoading) && <span>{rightIcon}</span>}
      {Boolean(isLoading) && (
        <Typography level="button">
          <ProgressBar
            aria-label="Loading"
            isIndeterminate
            className="flex flex-row justify-center items-center gap-2"
          >
            <ImSpinner8 className="animate-spin" />
            {loadingText && <Label>{loadingText}</Label>}
          </ProgressBar>
        </Typography>
      )}
    </RACLink>
  );
}
