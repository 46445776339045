import { useEffect, useRef } from 'react';

export function CertificateStatusCard({ children }: React.PropsWithChildren) {
  // Scroll into view
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center gap-6 rounded-3xl p-3 bg-white w-full py-56 mt-12"
      ref={ref}
    >
      {children}
    </div>
  );
}
