import {
  isPlace,
  isPlaceModel,
  type Place,
  type PlaceModel,
} from '../../../model/Place';

export const normalizePlaceResponse = (
  response?: PlaceModel,
): Place | undefined => {
  if (!response) {
    return;
  }

  if (isPlaceModel(response)) {
    const {
      name,
      provider,
      provider_place_uuid,
      geolocation,
      display_name,
      ...address
    } = response;
    return {
      name,
      provider,
      provider_place_uuid,
      geolocation,
      display_name,
      full_address: address.full_address,
      address: { ...address },
    };
  }

  if (isPlace(response)) {
    return response as Place;
  }

  return response;
};
