import { Modal, type ModalProps } from '../../layouts/Modal/Modal.tsx';
import { Button } from '../Button/Button.tsx';
import { Typography } from '../Typography/Typography.tsx';

interface AlertModalProps extends Omit<ModalProps, 'children'> {
  onOpenChange: (isOpen: boolean) => void;
  onConfirmChanges: () => void;
  title?: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonTestId?: string;
  cancelButtonTestId?: string;
}

export function ActionConfirmationModal({
  onOpenChange,
  onConfirmChanges,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  heading,
  confirmButtonTestId = 'action-confirmation-confirm-changes-button',
  cancelButtonTestId = 'action-confirmation-cancel-changes-button',
  ...props
}: AlertModalProps) {
  return (
    <Modal
      {...props}
      showExitButton={false}
      onExit={() => onOpenChange(false)}
      onOpenChange={onOpenChange}
      className="max-w-xl w-[544px]"
      heading={heading}
    >
      <>
        <div className="flex flex-col gap-1 mb-8">
          {title && <Typography level="h3">{title}</Typography>}
          {description && <Typography level="base">{description}</Typography>}
        </div>
        <div className="flex flex-row gap-3 w-full justify-between items-baseline">
          <Button
            width="full"
            variant="secondary"
            onPress={() => onOpenChange(false)}
            label={cancelButtonText ?? 'Cancel'}
            size="large"
            data-testid={cancelButtonTestId}
          />
          <Button
            width="full"
            onPress={() => {
              onConfirmChanges();
              onOpenChange(false);
            }}
            label={confirmButtonText ?? 'Confirm'}
            size="large"
            data-testid={confirmButtonTestId}
          />
        </div>
      </>
    </Modal>
  );
}
