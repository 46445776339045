import { stringify } from 'query-string';

import { type Port } from '../../../model/Port';
import { get } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { type PortsRequestQuery, type PortsResponse } from './types';

export const getPorts = async (
  params: PortsRequestQuery = {},
  auth: boolean = true,
): Promise<Port[]> => {
  const path = auth
    ? `${bffServiceUrl}/locations/ports`
    : `${bffServiceUrl}/externals/ports`;
  const queryParams = `?${stringify(params)}`;
  const { data } = await get<PortsResponse>(
    `${path}${queryParams}`,
    undefined,
    { auth },
  );

  return data.ports;
};
