import { type ReactNode } from 'react';
import {
  Radio as RACRadio,
  RadioGroup as RACRadioGroup,
  type RadioGroupProps as RACRadioGroupProps,
  type RadioProps,
  type ValidationResult,
} from 'react-aria-components';

import { tv } from '../../utils/customTv';
import {
  clsxMerge,
  composeTailwindRenderProps,
  focusRing,
} from '../common/utils/classNameUtils';
import { Description, FieldError, Label } from '../Field/Field';

export interface RadioGroupProps extends Omit<RACRadioGroupProps, 'children'> {
  label?: string;
  children?: ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  orientation?: 'horizontal' | 'vertical';
}

export function RadioGroup(props: RadioGroupProps) {
  return (
    <RACRadioGroup
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'group flex flex-col gap-2',
      )}
    >
      <Label isRequired={props.isRequired}>{props.label}</Label>
      <div
        className={clsxMerge(
          'flex group-orientation-vertical:flex-col gap-2',
          props.orientation === 'horizontal' &&
            'group-orientation-horizontal:gap-4',
          props.orientation === 'vertical' &&
            'group-orientation-vertical:gap-2',
        )}
      >
        {props.children}
      </div>
      {props.description && <Description>{props.description}</Description>}
      <FieldError>{props.errorMessage}</FieldError>
    </RACRadioGroup>
  );
}

const styles = tv({
  extend: focusRing,
  base: 'w-5 h-5 rounded-full border-2 bg-white transition-all',
  variants: {
    isSelected: {
      false: 'border-gray-400 group-pressed:border-gray-500',
      true: `border-[7px] border-buttons-primary-bg-default group-pressed:border-gray-800`,
    },
    isInvalid: {
      true: 'border-red-700 group-pressed:border-red-800',
    },
    isDisabled: {
      true: 'border-gray-200',
    },
  },
});

export function Radio(props: RadioProps) {
  return (
    <RACRadio
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'flex gap-2 items-center group disabled:text-text-disabled text-sm transition',
      )}
    >
      {(renderProps) => (
        <>
          <div className={styles(renderProps)} />
          {props.children}
        </>
      )}
    </RACRadio>
  );
}
