import { type ErrorResponse } from 'react-router';
import { flattenObject } from '@breezeai-frontend/cargo-ui';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import { bffServiceUrl } from '../../netconfig';
import { get } from '../utils';
import {
  type GetLocationsResponse,
  type Location,
  type LocationsQueryParams,
} from './types';

export const useLocations = ({
  params,
  options,
}: {
  params?: LocationsQueryParams;
  options?: UseQueryOptions<
    GetLocationsResponse,
    ErrorResponse,
    Location[],
    Array<string>
  >;
}) => {
  const flatParams = stringify(flattenObject(params || {}));

  const queryKey = ['locations', flatParams];
  const queryParams = params ? `?${flatParams}` : '';

  return useQuery({
    queryKey,
    queryFn: () =>
      get<GetLocationsResponse>(`${bffServiceUrl}/locations${queryParams}`),
    enabled: !!params?.query_text,
    ...options,
  });
};
