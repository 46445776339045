import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import '@breezeai-frontend/tailwind-config/styles';
import './scss/index.scss';
import './scss/fonts.scss';
import App from './App';
import { initializeFullStory } from './frameworks/fullstory';
import { initializeGoogleTagManager } from './frameworks/google-tag-manager';
import { initializeMuiXPro } from './frameworks/mui-x';
import { initializeSentry } from './frameworks/sentry';
import { initializeSnowplow } from './frameworks/snowplow';
import { setIOSMaxScale } from './utils/browser';
import { isTestEnv } from './utils/env';

// Initialize external libs
initializeSentry();
initializeMuiXPro();
initializeGoogleTagManager();
initializeFullStory();
initializeSnowplow();

setIOSMaxScale();

const container = document.getElementById('root');
const root = createRoot(container!);

// Avoid using StrictMode in tests environment as it might cause
// unexpected re-renders due to React 18 zero-check mechanism.
// StrictMode will only run for in development environment and has
// no effect on production build.
const app = <App />;
const appTree = isTestEnv ? app : <StrictMode>{app}</StrictMode>;

async function enableMocking() {
  // This will prevent MSW from being bundled in production
  if (import.meta.env.DEV && import.meta.env.VITE_APP_ENABLE_MSW === 'true') {
    const { browserServiceWorker } = await import('./msw/browser');
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return browserServiceWorker.start();
  }
  return;
}

enableMocking().then(() => {
  root.render(appTree);
});
