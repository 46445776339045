import React from 'react';
import {
  Button,
  ComboBox as AriaComboBox,
  type ComboBoxProps as AriaComboBoxProps,
  composeRenderProps,
  type ListBoxItemProps,
} from 'react-aria-components';
import { FaChevronDown } from 'react-icons/fa6';
import { ImSpinner8 } from 'react-icons/im';

import { clsxMerge } from '../common/utils/classNameUtils';
import {
  Description,
  FieldError,
  FieldGroup,
  fieldStyles,
  Input,
  Label,
} from '../Field/Field';
import {
  DropdownItem,
  DropdownSection,
  type DropdownSectionProps,
  ListBox,
} from '../ListBox/ListBox';
import { Popover } from '../Popover/Popover';
import { Typography } from '../Typography/Typography';

export interface ComboBoxProps<T extends object>
  extends Omit<AriaComboBoxProps<T>, 'children'> {
  label?: string;
  description?: string;
  errorMessage?: string;
  isFetchingItems?: boolean;
  emptyCollectionMessage?: string;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  placeholder?: string;
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

export function ComboBox<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  isFetchingItems,
  emptyCollectionMessage = 'No options',
  inputRef,
  placeholder,
  ...props
}: ComboBoxProps<T>) {
  return (
    <AriaComboBox
      {...props}
      validationBehavior="aria"
      className={composeRenderProps(props.className, (className, renderProps) =>
        clsxMerge(fieldStyles({ ...renderProps }), className),
      )}
    >
      <Label isRequired={props.isRequired}>{label}</Label>
      <FieldGroup>
        <Input
          role="input"
          className="w-full border-none"
          placeholder={placeholder}
          ref={inputRef}
        />

        <Button className="m-3">
          {isFetchingItems ? (
            <ImSpinner8 className="animate-spin" aria-description="Loading" />
          ) : (
            <FaChevronDown aria-hidden className="w-3 h-3 fill-gray-700" />
          )}
        </Button>
      </FieldGroup>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover className="w-[--trigger-width] overflow-hidden">
        <ListBox
          renderEmptyState={() => (
            <Typography customStyles="p-3">{emptyCollectionMessage}</Typography>
          )}
          items={items}
          className="outline-0 p-1 [clip-path:inset(0_0_0_0_round_.75rem)] overflow-auto max-h-96"
        >
          {children}
        </ListBox>
      </Popover>
    </AriaComboBox>
  );
}

export function ComboBoxItem(props: ListBoxItemProps) {
  return <DropdownItem {...props} />;
}

export function ComboBoxSection<T extends object>(
  props: DropdownSectionProps<T>,
) {
  return <DropdownSection {...props} />;
}
