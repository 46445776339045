import { stringify } from 'query-string';

import { type Customer } from '../../../model/Customer';
import { get, put } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  type GetCustomersQueryParams,
  type GetCustomersResponse,
  type UpdateCustomerByIdPayload,
  type UpdateMultipleCustomersResponse,
} from './types';

export const getCustomers = async (
  params?: GetCustomersQueryParams,
): Promise<GetCustomersResponse> => {
  const queryParams = params ? `?${stringify(params)}` : '';
  const { data } = await get<GetCustomersResponse>(
    `${bffServiceUrl}/customers${queryParams}`,
  );

  return data;
};

export const updateCustomer = async ({
  customer_id,
  ...updates
}: UpdateCustomerByIdPayload): Promise<Customer> => {
  const { data } = await put<Customer>(
    `${bffServiceUrl}/customers/${customer_id}`,
    {
      ...updates,
    },
  );

  return data;
};

export const updateMultipleCustomers = async (
  payload: UpdateCustomerByIdPayload[],
): Promise<Customer[]> => {
  const { data } = await put<UpdateMultipleCustomersResponse>(
    `${bffServiceUrl}/customers/batch`,
    {
      customers: payload,
    },
  );

  return data.customers;
};
