import { FullStory, init } from '@fullstory/browser';

import { isUserType, type User } from '../model/User';
import { getCookie, setCookie } from '../network/apis/auth/cookies';
import { type DTCCustomer } from '../network/apis/externals/types';
import { isTestEnv } from '../utils/env';

const isDisabledByCookie = getCookie('fs_disabled') === 'true';

export const initializeFullStory = () => {
  if (isTestEnv) return;
  if (isDisabledByCookie) return;

  const orgId = import.meta.env.VITE_APP_FULLSTORY_ORG_ID;

  if (orgId) {
    init({
      orgId,
      devMode: import.meta.env.VITE_APP_ENV != 'production',
    });
  } else {
    console.warn('Missing FullStory org ID');
  }
};

export const fsIdentifyUser = <T extends User | DTCCustomer>(user?: T) => {
  if (!user) return;
  if (isDisabledByCookie) return;

  const isPlatformUser = isUserType(user);
  const displayName = isPlatformUser
    ? `${user.first_name} ${user.last_name}`
    : `${user.contact_person} - ${user.company_name}`;
  const distributor = isPlatformUser ? user.distributor : 'flexport';
  const id = user.id;
  const email = isPlatformUser ? user.email : user.email;

  if (email?.includes('@breezeai.com')) {
    setCookie('fs_disabled', 'true', {
      expires: 365,
    });
  }

  FullStory('setProperties', {
    type: 'user',
    properties: {
      uid: String(id),
      displayName,
      email,
      distributor,
    },
  });
};
