import { tv } from 'tailwind-variants';

import { clsxMerge } from '../common/utils/classNameUtils';

const badge = tv({
  base: `flex items-center rounded-full justify-center px-3 py-1 text-sm min-w-20`,
  variants: {
    variant: {
      default: 'bg-system-grey-200 text-system-grey-750',
      success: 'bg-system-green-50 text-system-green-500',
      warning: 'bg-system-orange-50 text-system-orange-500',
      error: 'bg-system-red-50 text-system-red-500',
      info: 'bg-system-blue-50 text-system-blue-500',
    },
    outlined: {
      true: 'border bg-transparent',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      outlined: true,
      className: 'border-system-grey-750',
    },
    {
      variant: 'success',
      outlined: true,
      className: 'border-system-green-500',
    },
    {
      variant: 'warning',
      outlined: true,
      className: 'border-system-orange-500',
    },
    {
      variant: 'error',
      outlined: true,
      className: 'border-system-red-500',
    },
    {
      variant: 'info',
      outlined: true,
      className: 'border-system-blue-500',
    },
  ],
});

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'success' | 'warning' | 'error' | 'info';
  outlined?: boolean;
}

export function Badge({
  children,
  className,
  variant = 'default',
  outlined = false,
  ...props
}: BadgeProps) {
  return (
    <div
      {...props}
      className={clsxMerge(badge({ variant, outlined }), className)}
    >
      {children}
    </div>
  );
}
