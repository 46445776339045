import { useEffect } from 'react';
import {
  I18nProvider,
  RouterProvider as AriaRouterProvider,
} from 'react-aria-components';

import { setFavicon, setPageTitle } from '../common/utils/browser.ts';

export type CargoProviderProps = {
  children: React.ReactNode;
  theme: 'breeze' | 'wtw';
  navigate?: (to: string) => void;
  userLocaleOverride?: string;
};

export function CargoProvider({
  children,
  theme = 'breeze',
  navigate,
  userLocaleOverride,
}: CargoProviderProps) {
  useEffect(() => {
    let themeClass = 'breeze';
    let themeTitle = 'Breeze';
    let favicon = 'breeze-favicon.ico';
    switch (theme) {
      case 'breeze':
        themeClass = 'breeze';
        themeTitle = 'Breeze';
        favicon = 'breeze-favicon.ico';
        break;
      case 'wtw':
        themeClass = 'wtw';
        themeTitle = 'Trade Flow';
        favicon = 'wtw-favicon.svg';
        break;
    }
    const body = document.querySelector('body');
    const isFlexport = window.location.href.includes('flexport');
    if (body) {
      body.classList.add(themeClass);

      // Avoid changing favicon and title for Flexport
      !isFlexport && setFavicon(favicon);
      !isFlexport && setPageTitle(themeTitle);
    }
  }, [theme]);

  return (
    <I18nProvider locale={userLocaleOverride}>
      {navigate ? (
        <AriaRouterProvider navigate={navigate}>{children}</AriaRouterProvider>
      ) : (
        children
      )}
    </I18nProvider>
  );
}

export default CargoProvider;
