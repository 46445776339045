import { type SVGProps } from 'react';

export const White = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="-4 0 100 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3316_10816)">
      <path
        d="M93.2699 17.8667C94.7807 17.8667 96.0054 16.642 96.0054 15.1313C96.0054 13.6205 94.7807 12.3958 93.2699 12.3958C91.7592 12.3958 90.5345 13.6205 90.5345 15.1313C90.5345 16.642 91.7592 17.8667 93.2699 17.8667Z"
        fill="white"
      />
      <path
        d="M14.3935 10.1665C13.7366 9.39646 12.7243 8.92798 11.3727 8.7449V8.72336C12.5842 8.54027 13.4835 8.08795 14.0705 7.35562C14.6628 6.62329 14.9536 5.76172 14.9536 4.76015C14.9536 3.22549 14.4366 2.0516 13.4027 1.22773C12.3689 0.409244 10.7642 0 8.58874 0H0V17.6567H8.98721C11.1142 17.6567 12.7081 17.2475 13.7797 16.4236C14.8674 15.589 15.4113 14.4097 15.4113 12.8966C15.4113 11.825 15.072 10.915 14.3935 10.1665ZM4.44245 3.37088H7.7864C9.42876 3.37088 10.2473 4.01167 10.2473 5.29863C10.2473 6.5856 9.42876 7.221 7.7864 7.221H4.44245V3.37088ZM8.2118 14.4205H4.44245V10.5111H8.2118C9.87032 10.5111 10.6996 11.1573 10.6996 12.4389C10.6996 13.7204 9.87032 14.4205 8.2118 14.4205Z"
        fill="white"
      />
      <path
        d="M17.969 17.6567V4.28091H21.9807L22.1692 7.17255C22.4923 6.19251 22.9823 5.42249 23.6392 4.87324C24.3177 4.30245 25.1847 4.01706 26.2347 4.01706C26.5739 4.01706 26.8593 4.05475 27.0909 4.12475C27.3386 4.1786 27.5378 4.24322 27.6778 4.31322L27.2255 8.06104C27.0478 8.00719 26.8162 7.95334 26.5309 7.89949C26.2616 7.84564 25.9331 7.81872 25.5401 7.81872C24.7216 7.81872 24.0054 8.07719 23.4023 8.59413C22.8153 9.11107 22.5192 9.88648 22.5192 10.9204V17.6621H17.969V17.6567Z"
        fill="white"
      />
      <path
        d="M41.479 7.08639C40.9621 6.12789 40.2082 5.37402 39.2066 4.84093C38.2266 4.2863 37.0312 4.01167 35.6204 4.01167C34.2096 4.01167 33.0087 4.2863 31.9587 4.84093C30.9195 5.37402 30.101 6.17097 29.4925 7.22101C28.9055 8.25489 28.6148 9.50416 28.6148 10.9688C28.6148 13.182 29.2556 14.8943 30.5371 16.1059C31.8402 17.3175 33.6065 17.926 35.8358 17.926C36.7619 17.926 37.6451 17.7913 38.4851 17.5221C39.3413 17.2583 40.0736 16.8652 40.6767 16.3482C41.3013 15.8097 41.7375 15.1582 41.9906 14.3935L38.3505 13.1874C38.1566 13.672 37.8497 14.0435 37.4404 14.3128C37.0473 14.582 36.5142 14.7112 35.8358 14.7112C35.0496 14.7112 34.4196 14.5174 33.9349 14.1243C33.4557 13.7151 33.1541 13.0473 33.0249 12.1212H42.1521C42.1683 11.9058 42.1844 11.6473 42.206 11.3404C42.2383 11.0227 42.2544 10.6942 42.2544 10.355C42.2544 9.12184 41.996 8.03411 41.479 7.08639ZM33.0518 9.68186C33.3211 8.02334 34.1772 7.19408 35.6204 7.19408C36.9773 7.19408 37.7527 8.02334 37.952 9.68186H33.0518Z"
        fill="white"
      />
      <path
        d="M56.9764 7.08639C56.4595 6.12789 55.7002 5.37402 54.6987 4.84093C53.7186 4.2863 52.5232 4.01167 51.1178 4.01167C49.7124 4.01167 48.5008 4.2863 47.4507 4.84093C46.4169 5.37402 45.593 6.17097 44.9899 7.22101C44.403 8.25489 44.1068 9.50416 44.1068 10.9688C44.1068 13.182 44.7476 14.8943 46.0345 16.1059C47.3323 17.3175 49.0985 17.926 51.3278 17.926C52.2594 17.926 53.1425 17.7913 53.9771 17.5221C54.8333 17.2583 55.5656 16.8652 56.1741 16.3482C56.7987 15.8097 57.2349 15.1582 57.4826 14.3935L53.8425 13.1874C53.6486 13.672 53.3471 14.0435 52.9378 14.3128C52.5448 14.582 52.0063 14.7112 51.3278 14.7112C50.547 14.7112 49.9116 14.5174 49.4324 14.1243C48.9477 13.7151 48.6462 13.0473 48.5223 12.1212H57.6442C57.6603 11.9058 57.6818 11.6473 57.698 11.3404C57.7303 11.0227 57.7518 10.6942 57.7518 10.355C57.7518 9.12184 57.4934 8.03411 56.9764 7.08639ZM48.5492 9.68186C48.8131 8.02334 49.6693 7.19408 51.1178 7.19408C52.4694 7.19408 53.2502 8.02334 53.444 9.68186H48.5492Z"
        fill="white"
      />
      <path
        d="M59.5988 17.6567V14.8728L64.9244 8.82568L66.3406 7.40948L64.2297 7.46332H59.6527V4.28091H71.37V7.05947L66.1306 13.0527L64.6551 14.5282L67.0137 14.4743H71.6123V17.6567H59.5988Z"
        fill="white"
      />
      <path
        d="M86.1835 7.08639C85.6666 6.12789 84.9127 5.37402 83.9112 4.84093C82.9311 4.2863 81.7357 4.01167 80.3249 4.01167C78.9141 4.01167 77.7133 4.2863 76.6632 4.84093C75.624 5.37402 74.8055 6.17097 74.197 7.22101C73.61 8.25489 73.3193 9.50416 73.3193 10.9688C73.3193 13.182 73.9601 14.8943 75.2416 16.1059C76.5448 17.3175 78.311 17.926 80.5403 17.926C81.4665 17.926 82.3496 17.7913 83.1896 17.5221C84.0458 17.2583 84.7781 16.8652 85.3812 16.3482C86.0058 15.8097 86.4474 15.1582 86.6951 14.3935L83.055 13.1874C82.8611 13.672 82.5542 14.0435 82.1449 14.3128C81.7519 14.582 81.2188 14.7112 80.5403 14.7112C79.7541 14.7112 79.1241 14.5174 78.6394 14.1243C78.1602 13.7151 77.8587 13.0473 77.7294 12.1212H86.8566C86.8728 11.9058 86.8889 11.6473 86.9105 11.3404C86.9428 11.0227 86.9589 10.6942 86.9589 10.355C86.9589 9.12184 86.7005 8.03411 86.1835 7.08639ZM77.7563 9.68186C78.0256 8.02334 78.8818 7.19408 80.3249 7.19408C81.6818 7.19408 82.4573 8.02334 82.6565 9.68186H77.7563Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3316_10816">
        <rect width="96" height="17.926" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
