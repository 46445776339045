import { FaCircleInfo } from 'react-icons/fa6';
import {
  Button,
  clsxMerge,
  Tooltip,
  TooltipTriggerWrapper,
} from '@breezeai-frontend/cargo-ui';

export function TaxInfoTooltip({ className }: { className?: string }) {
  return (
    <TooltipTriggerWrapper delay={0}>
      <Button
        data-testid="quote-price-breakdown-tooltip-trigger"
        variant="ghost"
        customStyles="p-0 w-min h-min min-w-0 rounded-full hover:bg-[none]"
      >
        <FaCircleInfo
          className={clsxMerge('h-3 w-3 text-system-grey-750', className)}
        />
      </Button>
      <Tooltip placement="top">Includes all fees and taxes</Tooltip>
    </TooltipTriggerWrapper>
  );
}
