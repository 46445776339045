import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocale } from '@breeze-ai/ui-library';
import { CargoProvider } from '@breezeai-frontend/cargo-ui';

import { ReCaptchaProvider } from '../../context/recaptcha/ReCaptchaProvider';
import { getDTCFlowConfiguration } from '../../network/apis/externals/externals';
import { PlatformError } from '../../router/PlatformError/PlatformError';
import {
  scrollToTop,
  setDefaultFavicon,
  setDefaultPageTitle,
  setFavicon,
  setPageTitle,
} from '../../utils/browser';
import { reportException } from '../../utils/error-reporting/error-reporting';
import { setAppLocale } from '../../utils/locale';
import { useTracking } from '../../utils/snowplow/hooks';
import { DTCFlowContextProvider } from './context/DTCFlowContext';
import { useDTCFlowContext, useDTCStateAction } from './context/hooks';

const DTCFlowContent = () => {
  useTracking();

  const [error, setError] = useState<boolean>();
  const { setLocale: setComponentsLocale } = useLocale();
  const { brand, configuration, step } = useDTCFlowContext();
  const { setStep, setConfiguration } = useDTCStateAction();

  useEffect(() => {
    setAppLocale(brand.locale);
    setComponentsLocale?.(brand.locale);
  }, [brand.locale, setComponentsLocale]);

  useEffect(() => {
    if (!configuration) {
      getDTCFlowConfiguration()
        .then((configuration) => {
          setConfiguration(configuration);
        })
        .catch((e) => {
          reportException(e);
          setError(true);
        });
    }
  }, [configuration, setConfiguration, setStep]);

  useEffect(scrollToTop, [step]);

  useEffect(() => {
    setFavicon(brand.favicon);
    setPageTitle(brand.pageTitle);

    return () => {
      setDefaultFavicon();
      setDefaultPageTitle();
    };
  }, [brand.favicon, brand.pageTitle]);

  if (error) {
    return <PlatformError />;
  }

  return <Outlet />;
};

export const DTCFlow = () => {
  const navigate = useNavigate();

  return (
    <ReCaptchaProvider>
      <DTCFlowContextProvider>
        <CargoProvider theme="breeze" navigate={navigate}>
          <div className="w-screen h-screen">
            <DTCFlowContent />
          </div>
        </CargoProvider>
      </DTCFlowContextProvider>
    </ReCaptchaProvider>
  );
};
