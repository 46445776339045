import {
  CurrencyCell,
  Icon,
  Tooltip,
  Typography,
  useWindowSize,
} from '@breeze-ai/ui-library';

import { useDTCFlowContext } from '../../../context/hooks';
import styles from './PremiumBreakdown.module.scss';

export const PremiumBreakdown = () => {
  const { quote } = useDTCFlowContext();
  const { isMobile } = useWindowSize();

  if (!quote) {
    return null;
  }

  const { premium_value, tax, premium_currency } = quote;
  const total = premium_value ? premium_value + (tax?.value ?? 0) : undefined;

  return (
    <div className={styles.premiumBreakdownWrapper}>
      {tax && (
        <>
          <div className={styles.row}>
            <Typography>Premium</Typography>
            <CurrencyCell value={premium_value} currency={premium_currency} />
          </div>
          <div className={styles.row}>
            <div className={styles.tax}>
              <Typography>Taxes</Typography>
              <Tooltip
                content={tax.description}
                placement={isMobile ? 'right' : 'top'}
              >
                <Icon
                  icon="info-circle"
                  size="xs"
                  className={styles.infoIcon}
                />
              </Tooltip>
            </div>
            <CurrencyCell value={tax.value} currency={tax.currency_code} />
          </div>
        </>
      )}
      <div className={styles.totalRow}>
        <Typography level="h5">Total</Typography>
        <CurrencyCell
          value={total}
          currency={premium_currency}
          typographyProps={{ level: 'h2', bold: true }}
          testId="total-premium"
        />
      </div>
    </div>
  );
};
