import { cloneDeep } from 'lodash';

import { DTCFlowSteps } from '../flows-steps-config';
import { initialDTCState } from './DTCFlowContext';
import {
  type DTCFlowContextAction,
  type DTCFlowState,
  type DTCFormErrors,
  type DTCFormsData,
} from './types';

export const dtcFlowReducer = (
  state: DTCFlowState,
  { type, payload }: DTCFlowContextAction,
): DTCFlowState => {
  switch (type) {
    case 'reset-dtc-state':
      return {
        // cache configuration and reset all other state
        ...cloneDeep(initialDTCState),
        configuration: state.configuration,
        step: DTCFlowSteps.WELCOME,
      };

    case 'set-dtc-flow-type':
      return { ...state, flowType: payload };

    case 'set-step':
      return { ...state, step: payload };

    case 'set-configuration':
      return { ...state, configuration: payload };

    case 'set-forms-data':
      return {
        ...state,
        forms: { ...state.forms, ...payload } as DTCFormsData,
      };

    case 'set-form-error':
      const { field, error, reason } = payload;

      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          [field]: error ? { reason } : undefined,
        },
      };

    case 'reset-form-fields':
      return {
        ...state,
        forms: {
          ...state.forms,
          ...payload.reduce((acc, field) => {
            acc[field] = undefined;
            return acc;
          }, {} as Partial<DTCFormsData>),
        },
        formErrors: {
          ...state.formErrors,
          ...payload.reduce((acc, field) => {
            acc[field] = undefined;
            return acc;
          }, {} as DTCFormErrors),
        },
      };

    case 'reset-forms-errors':
      return {
        ...state,
        formErrors: {},
      };

    case 'set-customer':
      return { ...state, customer: payload };

    case 'set-quote':
      return { ...state, quote: payload };
  }
};
