import { type ErrorResponse } from 'react-router-dom';
import {
  useMutation,
  type UseMutationOptions,
  useQuery,
} from '@tanstack/react-query';
import { stringify } from 'query-string';
import invariant from 'tiny-invariant';

import { type NetworkResponse } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { type PaginationBaseQueryParams } from '../types';
import { get, post, put } from '../utils';
import { normalizeQuoteResponse } from './normalizers';
import {
  type EnrichedQuoteModel,
  type GetQuotesResponse,
  type QuoteMutationActions,
  type QuotesSortableFields,
  type QuoteSubmissionPayload,
  type QuoteUpdatePayload,
} from './types';

export const useQuotes = (
  params?: PaginationBaseQueryParams<QuotesSortableFields>,
) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  return useQuery(
    ['quotes', params],
    () => get<GetQuotesResponse>(`${bffServiceUrl}/bff_quotes${queryParams}`),
    {
      select: (data) => ({
        ...data,
        quotes: data.quotes.map(normalizeQuoteResponse),
      }),
      suspense: true,
      enabled: !!params,
    },
  );
};

export const useQuote = ({ quoteId }: { quoteId: string | undefined }) => {
  return useQuery(
    ['quotes', quoteId],
    () => get<EnrichedQuoteModel>(`${bffServiceUrl}/bff_quotes/${quoteId}`),
    {
      suspense: true,
      enabled: !!quoteId,
      select: normalizeQuoteResponse,
    },
  );
};

export const useQuoteMutation = ({
  quoteId,
  action,
  options,
}: {
  quoteId?: string;
  action: QuoteMutationActions;
  options: UseMutationOptions<
    NetworkResponse<EnrichedQuoteModel>,
    ErrorResponse,
    QuoteSubmissionPayload | QuoteUpdatePayload
  >;
}) => {
  let endpoint = `${bffServiceUrl}/form/quote`;
  let method = post;

  switch (action) {
    case 'update':
      invariant(quoteId, 'quoteId is required for update action');
      endpoint = `${bffServiceUrl}/bff_quotes/${quoteId}`;
      method = put;
      break;
    case 'duplicate':
      endpoint = `${bffServiceUrl}/bff_quotes/duplicate`;
      method = post;
      break;
    case 'create':
      break;
  }

  return useMutation({
    mutationFn: (payload) => method<EnrichedQuoteModel>(endpoint, payload),
    ...options,
  });
};
