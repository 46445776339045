import { type ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Icon, SearchInput } from '@breeze-ai/ui-library';
import { Button, clsxMerge } from '@breezeai-frontend/cargo-ui';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from '../../../../../components/TableFilters/components/MultiSelectRadioDropdown/MultiSelectRadioDropdown.module.scss';
import { TriggerButton } from '../../../../../components/TableFilters/components/TriggerButton/TriggerButton.tsx';
import { type StorageLocationAddress } from '../../../../types.ts';

interface SingleSelectionDropdownProps {
  name: string;
  label: string;
  placeholder?: string;
  options: StorageLocationAddress[];
  appliedOptions: StorageLocationAddress[];
  onSubmit: (selected: string[]) => void;
  onReset: () => void;
  onSearch?: (search: string) => void;
  isLoading?: boolean;
  selected: string[];
  setSelected: (selected: string[]) => void;
  triggerTestId?: string;
  contentTestId?: string;
  isError: boolean;
  dropdownIcon?: IconName;
  optionsCount?: number;
}

export const StorageLocationCityCountryDropdown = ({
  name,
  label,
  placeholder,
  options,
  selected,
  optionsCount,
  dropdownIcon,
  setSelected,
  appliedOptions,
  onSubmit,
  onReset,
  onSearch,
  isLoading,
  isError,
  triggerTestId,
  contentTestId,
}: SingleSelectionDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnSelect = useCallback(
    (option: string) => {
      if (selected.includes(option)) {
        setSelected([]);
      } else {
        setSelected([option]);
      }
    },
    [selected, setSelected],
  );

  const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch?.(e.target.value);
  };

  const isSelectedAndAppliedSame = useMemo(() => {
    return (
      selected.length === appliedOptions.length &&
      selected.every((value) =>
        appliedOptions
          .map((o) => `${o.city}__${o.country}__${o.place_id || ''}`)
          .includes(value),
      )
    );
  }, [selected, appliedOptions]);

  if (isError) return null;

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger
        asChild
        disabled={isLoading}
        data-testid={triggerTestId}
      >
        <TriggerButton
          selectedCount={optionsCount}
          name={name}
          label={label}
          prefixIcon={dropdownIcon}
          suffixIcon="chevron-down"
          isLoading={isLoading}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          data-testid={contentTestId}
          sideOffset={5}
          align="start"
          className="min-w-[220px] bg-white rounded-lg p-2 shadow-[0px_10px_38px_-10px_rgba(22,23,24,0.35),_0px_10px_20px_-15px_rgba(22,23,24,0.2)]"
        >
          {onSearch && (
            //  this placeholders prevents dropdown item from gaining focus
            <div
              className="sticky top-0 z-10 bg-white p-1"
              onKeyDown={(e) => e.stopPropagation()}
            >
              <SearchInput
                placeholder={placeholder ?? 'Search'}
                className={styles.searchInput}
                onChange={handleOnInput}
                onClear={() => onSearch('')}
              />
            </div>
          )}

          <div className="max-h-[400px] overflow-y-auto">
            {options.map(({ city, country, place_id }) => (
              <DropdownMenu.DropdownMenuItem
                key={`${city}__${country}__${place_id}`}
                onSelect={(e) => {
                  e.preventDefault();
                  handleOnSelect(`${city}__${country}__${place_id ?? ''}`);
                }}
                className={styles.dropdownMenuItem}
              >
                <div
                  className={clsxMerge(
                    'flex gap-2 my-2 h-12 items-center',
                    selected.includes(
                      `${city}__${country}__${place_id ?? ''}`,
                    ) && 'border border-blue-500',
                  )}
                >
                  <Icon icon="location-dot" className="mx-4" />
                  <div className="flex flex-col">
                    <span className="text-black">{city}</span>
                    <span className="text-system-grey-700">{country}</span>
                  </div>
                </div>
              </DropdownMenu.DropdownMenuItem>
            ))}
          </div>

          <hr className={styles.divider} />
          <footer className={styles.footer}>
            <Button
              data-testid="reset-button"
              isDisabled={appliedOptions.length === 0}
              variant="ghost"
              width="auto"
              size="small"
              onPress={() => {
                setSelected([]);
                onReset();
                setIsOpen(false);
              }}
              label="Reset"
            />
            <Button
              isDisabled={isSelectedAndAppliedSame}
              size="small"
              variant="ghost"
              width="auto"
              type="submit"
              onPress={() => {
                onSubmit(selected);
                setIsOpen(false);
              }}
              data-testid="apply-button"
              label="Apply"
            />
          </footer>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
