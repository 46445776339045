import { useState } from 'react';
import { type IconType } from 'react-icons';
import {
  Button,
  Select,
  type SelectOptionType,
  Typography,
} from '@breeze-ai/ui-library';
import classnames from 'classnames';
import { capitalize } from 'lodash';

import { type ConveyanceType } from '../../../../../model/Shipment';
import { CONVEYANCE_TYPE_ICONS } from '../../../../constants';
import styles from './ConveyanceTypeSelector.module.scss';

type ConveyanceSelectionType = ConveyanceType;

type ConveyanceTypeOption = {
  value: ConveyanceSelectionType;
  title: string;
  Icon?: IconType;
};

export type ConveyanceTypeSelectorProps = {
  defaultValue?: ConveyanceSelectionType;
  selected?: ConveyanceSelectionType;
  onChange: (conveyance: ConveyanceSelectionType) => void;
  type?: 'buttons' | 'select';
  required?: boolean;
  label?: string;
  testId?: string;
};

const conveyanceTypes: ConveyanceType[] = ['sea', 'air', 'road', 'rail'];

export const ConveyanceTypeSelector = ({
  selected,
  onChange,
  type = 'select',
  required = false,
  // TODO This is a problem if the field is required but the conveyance type is initially undefined. Required flag becomes redundant.
  defaultValue = required ? 'sea' : undefined,
  label,
  testId,
}: ConveyanceTypeSelectorProps) => {
  const [internalValue, setInternalValue] = useState<
    ConveyanceSelectionType | undefined
  >(defaultValue);

  // null is a valid value and falls back to defaultValue
  const value =
    selected === undefined ? internalValue : selected ?? defaultValue;

  const options = conveyanceTypes.map<ConveyanceTypeOption>((value) => ({
    value,
    title: capitalize(value),
    Icon: CONVEYANCE_TYPE_ICONS[value],
  }));

  const handleChange = (newConveyanceType: ConveyanceSelectionType) => {
    if (newConveyanceType) {
      setInternalValue(newConveyanceType);
      onChange(newConveyanceType);
    }
  };

  switch (type) {
    case 'select':
      const selectOptions = options.map<
        SelectOptionType<ConveyanceSelectionType>
      >(({ value, title }) => ({ value, payload: value, label: title }));

      return (
        <Select<ConveyanceSelectionType>
          value={value}
          options={selectOptions}
          onChange={({ payload }) => handleChange(payload)}
          required={false}
          label={label}
          testId={testId}
          placeholder="Select if applicable..."
        />
      );

    case 'buttons':
      return (
        <div className={styles.buttonsWrapper} data-testid={testId}>
          <Typography
            level="subtext"
            variant="secondary"
            ellipsis
            className={styles.label}
          >
            {label}
            <span className={styles.asterix}> *</span>
          </Typography>
          <div className={styles.optionsWrapper}>
            {options.map(({ value: conveyance, title, Icon }) => {
              const selected = value === conveyance;
              return (
                <Button
                  key={conveyance}
                  level={selected ? 'primary' : 'secondary'}
                  className={classnames(styles.conveyanceButton, {
                    [styles.selected]: selected,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!selected) {
                      handleChange(conveyance);
                    }
                  }}
                  role="conveyance-button"
                  data-testid={`conveyance-type-button-${conveyance}`}
                  aria-valuetext={conveyance}
                  aria-selected={selected}
                >
                  {Icon && <Icon className="w-5 h-5" />}
                  <Typography level="h5">{title}</Typography>
                </Button>
              );
            })}
          </div>
        </div>
      );
  }
};
